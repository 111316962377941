import React from 'react';
import { Typography } from 'amn-ui-core';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { makeStyles } from 'tss-react/mui';
import { ISkillChecklistSection } from '../Config/model';

const useTextStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  dateContainer: {
    justifyContent: 'flex-end',
  },
  dateRegion: {
    textAlign: 'left',
    font: 'normal normal medium 12px/19px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.dimGray,
  },
  textRegion: {
    textAlign: 'left',
    font: 'normal normal normal 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.charcoal,
    opacity: 1,
    paddingRight: '5px',
  },
  questionRegion: {
    textAlign: 'left',
    font: 'normal normal medium 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.dimGray,
    opacity: 1,
  },
}));

export const TextRegion = ({ currentItem, index }: { currentItem: ISkillChecklistSection; index: number }) => {
  const { classes } = useTextStyles();

  const getItemText = (currentItem: ISkillChecklistSection) => {
    const text = currentItem?.text?.split('#');
    return <Typography variant="body2">{`${text[0]?.replace(/:/g, '')?.replace(/&amp;/g, '&')}`}</Typography>;
  };

  return (
    <LayoutGridItem container item key={index} display="flex">
      <LayoutGridItem item xs={4} className={classes.questionRegion}>
        {getItemText(currentItem)}
      </LayoutGridItem>
      <LayoutGridItem item xs={4} paddingLeft={'8px'}>
        {currentItem?.hasAnswer && currentItem?.tValue && (
          <Typography variant="body2" className={classes.textRegion}>
            {currentItem.tValue}
          </Typography>
        )}
      </LayoutGridItem>
    </LayoutGridItem>
  );
};
