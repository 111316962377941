import React from 'react';
import { Typography } from '@mui/material';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { useTranslation } from 'react-i18next';
import { ISkillCheckListItem } from '../Config/model';
import moment from 'moment';
import { missingField } from 'app/constants';

/**
 *  Functional component which renders skill checklist header
 */
export const SkillChecklistHeader = ({
  skillChecklistItem,
  credentialDateCompleted,
}: {
  skillChecklistItem: ISkillCheckListItem;
  credentialDateCompleted: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <LayoutGridItem item>
        <Typography color="#1B3F69" variant="h5" fontWeight={'bold'} pt={'12px'}>
          {`${skillChecklistItem.firstName} ${skillChecklistItem.lastName}`}
        </Typography>
      </LayoutGridItem>

      <LayoutGridItem item>
        <Typography>{`${skillChecklistItem?.phone ? skillChecklistItem?.phone : missingField} | ${
          skillChecklistItem?.email ? skillChecklistItem?.email : missingField
        }`}</Typography>
      </LayoutGridItem>

      <LayoutGridItem container item mt={'12px'}>
        <LayoutGridItem item ml={'-22px'} pt={'11px'}>
          <Typography component="div" style={{ backgroundColor: '#1B3F69' }}>
            <Typography
              component="span"
              sx={{
                color: '#FFFFFF',
                fontSize: '18px !important',
                width: '189px',
                height: '35px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {t('candidate.credentials.attachments.viewSkillChecklist.skillChecklistHeader')}
            </Typography>
          </Typography>
        </LayoutGridItem>
        <LayoutGridItem item ml={'14px'}>
          <Typography component="div" variant="h6" sx={{ color: '#1B3F69', fontWeight: 'Bold' }}>
            {`${skillChecklistItem.type} - ${skillChecklistItem.note}`}
          </Typography>
          <Typography component="div" sx={{ color: '#333333' }} variant="body1">
            {` ID : ${skillChecklistItem.id} | Completed on ${moment(credentialDateCompleted).format('MM/DD/YYYY')}`}
          </Typography>
        </LayoutGridItem>
      </LayoutGridItem>
    </>
  );
};
