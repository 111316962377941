import { globalActions } from 'app/ApplicationRoot/Global.redux';
import {
  CallPurpose,
  ChannelType,
  ContactType,
  FormName,
  SendType,
  UseType,
} from 'app/components/Notification/Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { formatPhoneNumber, TwilioExtensionCode, unformatPhoneNumber } from 'app/helpers/phoneHelpers';
import { ICandidateDetails } from 'app/models/Candidate/CandidateProfile';
import { ContactProps, IEmployee, IToken } from 'app/models/Notification/Notification';
import { GetUserLogs } from 'app/services/NotificationServices/NotificationService';
import { IUser } from 'oidc/user.redux';
import { Concatenate } from 'utils/string/string';
import { ICoveredUserInboxes } from 'app/models/UserCoverage/UserCoverage';

interface IChatHistory {
  chats: [];
  candidateDetails: ICandidateDetails;
}
const PAGE_SIZE = 20;

export const defaultAssociatedRecord = [
  { name: 'travelerId', value: '' },
  { name: 'recruiterId', value: '' },
  { name: 'recruiter', value: '' },
  { name: 'brandId', value: '' },
];

export const getDefaultSender = user => {
  const sender: IEmployee = {
    name: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    email: user.userInfo?.email,
    senderId: `${user.userInfo?.employeeId}`,
    userId: `${user.userInfo?.employeeId}`,
    NtUserName: user.userInfo?.ntUserName,
    deviceName: '',
    lineNumber: '',
    phoneNumber: user.userInfo?.phoneNumber,
  };
  return sender;
};

export const callUnknownNumber = (user: IUser, smsData, dispatch, notificationData, channel: ChannelType) => {
  let data = {};

  data = {
    associatedRecords: defaultAssociatedRecord,
    channel: channel,
    sender: getDefaultSender(user),
    sendType: SendType.one_to_one,
    tos: [
      {
        phoneNumber: formatPhoneNumber(smsData?.tos?.[0]?.phoneNumber),
        contactType: 'candidate',
      },
    ],
    useType: UseType.Candidate,
    brand: '',
    brandId: null,
    userRole: Concatenate(user?.userInfo?.roles || [], ','),
    formName: FormName[FormName.Traveler],
    reason: CallPurpose[CallPurpose.Prospecting],
  };
  if (!notificationData.voice.open && notificationData.voice.minimized) {
    dispatch(
      notificationDataActions.setVoiceInteraction({
        open: true,
        minimized: false,
        showDisposition: true,
      }),
    );
  } else {
    dispatch(
      notificationDataActions.setVoiceData({
        data: data,
        open: true,
        minimized: false,
      }),
    );
  }
};

export const setNotificationData = async (
  user: IUser,
  twilioNumber: string | null | undefined,
  candidateDetails,
  dispatch,
  notificationData,
  channel: ChannelType,
  contactType: ContactType | null,
  manualLog: boolean = false,
  skills,
  setShowNotificationBackdrop?,
  sendWithoutConsent?: boolean,
  coveredInbox?: ICoveredUserInboxes,
) => {
  let data = {};
  const smsLogsPayload = {
    contactId: `${candidateDetails?.travelerId}`,
    pageSize: PAGE_SIZE,
    pageNumber: 1,
    channel: ['SMS'],
    initiatedBy: twilioNumber,
  };
  const smsActiveUserLogsPayload = {
    contactId: `${`${candidateDetails?.travelerId}`}`,
    pageSize: PAGE_SIZE,
    pageNumber: 1,
    channel: ['SMS'],
    initiatedBy: !!coveredInbox?.activeInbox ? coveredInbox?.activeInbox?.phoneNumber : undefined,
  };

  const chatHistoryPayload: IChatHistory = { chats: [], candidateDetails };
  await GetUserLogs(!!coveredInbox?.activeInbox ? smsActiveUserLogsPayload : smsLogsPayload)
    .then(response => {
      chatHistoryPayload.chats = response;
    })
    .catch(error => {
      dispatch(
        globalActions.setSnackBar({
          message: `Failed to fetch user logs`,
          severity: 'error',
        }),
      );
      setShowNotificationBackdrop && setShowNotificationBackdrop(false);
      return;
    });
  const substitutions: IToken[] = [];
  substitutions.push({
    name: 'Signature',
    input: '{{signature}}',
  });

  substitutions.push({
    name: 'First Name',
    input: '{{first_name}}',
    value: candidateDetails?.name?.first,
  });
  substitutions.push({
    name: 'Last Name',
    input: '{{last_name}}',
    value: candidateDetails?.name?.last,
  });

  substitutions.push({
    name: 'Skillset',
    input: '{{skillset}}',
    value: skills(),
  });

  substitutions.push({
    name: 'Brand',
    input: '{{brand}}',
    value: candidateDetails?.brand,
  });

  substitutions.push({
    name: 'Email Address',
    input: '{{email_address}}',
    value: contactType === ContactType.secondary ? candidateDetails?.secondaryEmail : candidateDetails?.primaryEmail,
  });

  substitutions.push({
    name: 'Phone Number',
    input: '{{phone_number}}',
    value:
      contactType === ContactType?.secondary
        ? candidateDetails?.mobilePhoneNumber
        : candidateDetails?.primaryPhoneNumber,
  });
  const sender: IEmployee = {
    name: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
    email: user.userInfo?.email,
    senderId: `${user.userInfo?.employeeId}`,
    userId: `${user.userInfo?.employeeId}`,
    NtUserName: user.userInfo?.ntUserName,
    deviceName: '',
    lineNumber: '',
    phoneNumber: twilioNumber,
  };

  const candidate: ContactProps = {
    brand: candidateDetails?.brand,
    name: Concatenate([candidateDetails?.name?.first, candidateDetails?.name?.last], ' '),
    email:
      contactType === ContactType.secondary
        ? candidateDetails?.secondaryEmail
        : contactType === ContactType.primary
        ? candidateDetails?.primaryEmail
        : '',
    phoneNumber:
      channel === ChannelType.sms
        ? TwilioExtensionCode +
          unformatPhoneNumber(
            contactType === ContactType?.secondary
              ? candidateDetails?.mobilePhoneNumber
              : contactType === ContactType?.primary
              ? candidateDetails?.primaryPhoneNumber
              : '',
          )
        : contactType === ContactType?.secondary
        ? candidateDetails?.mobilePhoneNumber
        : contactType === ContactType?.primary
        ? candidateDetails?.primaryPhoneNumber
        : '',
    brandId: candidateDetails?.brandId,
    contactId: `${candidateDetails?.travelerId}`,
    contactType: 'candidate',
  };
  let preFilteredRecords = [
    { name: 'travelerId', value: `${candidateDetails?.travelerId}` },
    { name: 'recruiterId', value: `${candidateDetails?.recruiter?.recruiterId}` },
    {
      name: 'recruiter',
      value: Concatenate([candidateDetails?.recruiter?.first, candidateDetails?.recruiter?.last], ' '),
    },
    { name: 'brandId', value: `${candidateDetails?.brandId}` },
  ];
  const associatedRecord = filterAndMapRecords(preFilteredRecords);
  data = {
    manualLog: manualLog,
    channel: channel,
    associatedRecords: associatedRecord,
    sender: sender,
    sendType: SendType.one_to_one,
    tos: [candidate],
    useType: UseType.Candidate,
    brand: candidateDetails?.brand,
    brandId: candidateDetails?.brandId,
    userRole: Concatenate(user?.userInfo?.roles || [], ','),
    formName: FormName[FormName.Traveler],
    reason: CallPurpose[CallPurpose.Prospecting],
    emailType: SendType.bulk,
  };

  switch (channel) {
    case ChannelType.voice:
      if (!manualLog) {
        if (!notificationData.voice.open && notificationData.voice.minimized) {
          dispatch(
            notificationDataActions.setVoiceInteraction({
              open: true,
              minimized: false,
              showDisposition: true,
              displayAddToSummary: true,
            }),
          );
          if (notificationData.voice.data.tos.find(Boolean).contactId !== candidate.contactId) {
            dispatch(notificationDataActions.setSnackBarData({ channel: 'voice', manual: false, changeWarning: true }));
          }
        } else {
          dispatch(
            notificationDataActions.setVoiceData({
              data: data,
              open: true,
              minimized: false,
              displayAddToSummary: true,
            }),
          );
        }
      }

      if (manualLog) {
        if (!notificationData.log.open && notificationData.log.minimized) {
          dispatch(
            notificationDataActions.setLogInteraction({
              open: true,
              minimized: false,
              showDisposition: true,
              displayAddToSummary: true,
            }),
          );
          if (notificationData.log.data.tos.find(Boolean).contactId !== candidate.contactId) {
            dispatch(notificationDataActions.setSnackBarData({ channel: 'voice', manual: true, changeWarning: true }));
          }
        } else {
          dispatch(
            notificationDataActions.setLogdata({
              ...notificationData,
              data: data,
              log: {
                channel: ChannelType.voice,
                data: data,
                open: true,
                minimized: false,
                displayAddToSummary: true,
              },
              snackbarData: null,
            }),
          );
        }
      }
      break;
    case ChannelType.email:
      data['bccs'] = [sender];
      data['substitutions'] = substitutions;
      data['body'] = '<br/><br/> {{signature}}';
      if (!notificationData.email.open && notificationData.email.minimized) {
        dispatch(
          notificationDataActions.setEmailInteraction({
            open: true,
            minimized: false,
          }),
        );
        dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
      } else {
        dispatch(
          notificationDataActions.setNotificationData({
            ...notificationData,
            data: data,
            email: {
              data: data,
              open: true,
              minimized: false,
              sendWithoutConsent: sendWithoutConsent ?? false,
            },
            snackbarData: null,
          }),
        );
      }
      break;
    case ChannelType.sms:
      const sms = notificationData.sms;
      data['body'] = sms.data?.body ?? '';
      if (
        (sms.open || sms.minimized) &&
        (data['body'].length > 0 || sms.newNotes?.length > 0 || sms.attachments !== undefined)
      ) {
        dispatch(
          notificationDataActions.setSmsInteraction({
            open: true,
            minimized: false,
            readonly: false,
          }),
        );
        if (notificationData.sms.data?.tos.find(Boolean)?.contactId !== candidate.contactId) {
          dispatch(notificationDataActions.setSnackBarData({ channel: 'sms', manual: false, changeWarning: true }));
        }
      } else {
        dispatch(
          notificationDataActions.setNotificationData({
            ...notificationData,
            data: data,
            sms: {
              ...notificationData.sms,
              chatHistory: chatHistoryPayload,
              data: data,
              open: true,
              minimized: false,
              readonly: false,
              sendType: SendType.one_to_one,
            },
            snackbarData: null,
          }),
        );
      }
      break;
  }
};

export function filterAndMapRecords(records) {
  if (!records) {
    return [];
  }

  return records
    .filter(
      record =>
        record?.name !== undefined &&
        record?.name !== '' &&
        record?.value !== undefined &&
        record?.value !== '' &&
        record?.value !== '--' &&
        record?.value !== '0',
    )
    .map(record => ({
      name: typeof record?.name === 'string' ? record?.name : String(record?.name),
      value: typeof record?.value === 'string' ? record?.value : String(record?.value),
    }));
}
