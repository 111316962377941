import React from 'react';
import { Typography } from 'amn-ui-core';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { RatingValue } from '../Config/enums';
import { getRatingTitle } from '../Config/helper';
import { RatingConfiguration } from '../Config/constants';
import ActiveCheckButton from 'app/assets/images/CandidateProfile/check_button_active.svg';
import NonActiveCheckButton from 'app/assets/images/CandidateProfile/check_button_nonactive.svg';
import ActiveCheckButtonGray from 'app/assets/images/CandidateProfile/check_button_active_gray.svg';
import NonActiveCheckButtonGray from 'app/assets/images/CandidateProfile/check_button_nonactive_gray.svg';
import { makeStyles } from 'tss-react/mui';
import { ISkillChecklistSection } from '../Config/model';
import { handleSubScripts } from './helper';

const useRatingPanelStyles = makeStyles()(theme => ({
  textRegion: {
    textAlign: 'left',
    font: 'normal normal normal 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.charcoal,
    opacity: 1,
  },
  questionRegion: {
    display: 'flex',
    textAlign: 'left',
    font: 'normal normal medium 12px/16px Roboto !important',
    letterSpacing: '0px',
    color: theme.palette.framework.system.dimGray,
    opacity: 1,
    wordBreak: 'keep-all',
  },
}));
/**
 *  Component which renders rating color panel with text
 */
export const RatingContainer = ({
  ratingId,
  showDescription,
  yesOrNoType,
  hasAnswer,
  textType,
  textValue,
}: {
  ratingId?: number;
  showDescription?: boolean;
  yesOrNoType?: boolean;
  hasAnswer?: boolean;
  textType?: boolean;
  textValue?: string;
}) => {
  const selectedRating = RatingConfiguration?.find(item => item.id === ratingId);
  const colorPanelArray: any = selectedRating ? selectedRating?.color : [];
  const ratingTitle = getRatingTitle(ratingId, showDescription);
  const { classes } = useRatingPanelStyles();
  return (
    <>
      {yesOrNoType && (
        <LayoutGridItem item xs={3} sx={{ display: 'flex', marginTop: '1px' }}>
          <>
            {hasAnswer && (
              <>
                <img src={ActiveCheckButton} alt="emr-active-checkbox" />
                <img src={NonActiveCheckButtonGray} alt="emr-active-checkbox" style={{ paddingLeft: '6px' }} />
                <Typography style={{ paddingLeft: '6px', whiteSpace: 'nowrap' }}>{hasAnswer ? 'Yes' : 'No'}</Typography>
              </>
            )}

            {!hasAnswer && (
              <>
                <img src={ActiveCheckButtonGray} alt="emr-active-checkbox" />
                <img src={NonActiveCheckButton} alt="emr-active-checkbox" style={{ paddingLeft: '6px' }} />
                <Typography style={{ paddingLeft: '6px', whiteSpace: 'nowrap' }}>{hasAnswer ? 'Yes' : 'No'}</Typography>
              </>
            )}
          </>
        </LayoutGridItem>
      )}

      <LayoutGridItem item xs={12} display={'flex'}>
        {!yesOrNoType && !textType ? (
          <LayoutGridItem item xs={6} justifyContent={'flex-start'}>
            {textValue && (
              <Typography variant="body2" className={classes.textRegion}>
                {textValue}
              </Typography>
            )}
          </LayoutGridItem>
        ) : null}
        <LayoutGridItem item xs={6} justifyContent={'flex-end'}>
          {!yesOrNoType && !textType && (
            <LayoutGridItem display={'flex'}>
              <LayoutGridItem item xs={5}>
                {colorPanelArray?.map(itemColor => {
                  return (
                    <span
                      style={{
                        background: itemColor,
                        width: '15px',
                        height: '15px',
                        display: 'inline-block',
                        marginLeft: '2px',
                      }}
                    />
                  );
                })}
              </LayoutGridItem>
              <LayoutGridItem item xs={7} paddingLeft={'10px'}>
                <span
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {ratingTitle}
                </span>
              </LayoutGridItem>
            </LayoutGridItem>
          )}
        </LayoutGridItem>
      </LayoutGridItem>
    </>
  );
};

/**
 * Component rendering the rating field and its corresponding rating panel
 */
export const RatingPanel = ({ currentItem, key }: { currentItem: ISkillChecklistSection; key: number }) => {
  const { classes } = useRatingPanelStyles();
  return (
    <>
      <LayoutGridItem container item key={key} direction="row">
        <LayoutGridItem item xs={3.5}>
          <Typography variant="body2" className={classes.questionRegion}>
            {handleSubScripts(currentItem?.text)}
          </Typography>
        </LayoutGridItem>

        <LayoutGridItem item sx={{ display: 'flex', alignItems: 'center' }} xs={8.5}>
          <RatingContainer
            ratingId={currentItem?.hasAnswer && currentItem.iValue ? currentItem?.iValue : RatingValue.No_Experience}
            yesOrNoType={currentItem?.type === 'Yes/No' ? true : false}
            hasAnswer={currentItem?.hasAnswer && currentItem?.iValue == 1}
            textType={currentItem?.type === 'Text'}
            textValue={currentItem?.tValue}
          />
        </LayoutGridItem>
      </LayoutGridItem>
    </>
  );
};
