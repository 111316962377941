import { put, call, select, getContext, cancelled, delay, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  manuallyDecrementPromiseCounter,
  manuallyIncrementPromiseCounter,
  manuallyResetPromiseCounter,
  trackPromise,
} from 'react-promise-tracker';
import {
  getPlacementOrderTypeValue,
  IPlacementStatusSource,
  IPlacementStatusSuccessType,
  IPlacementStatusUpdateRequest,
  IPlacementUpdateAllRequest,
  MiscPlacementDialogType,
  PlacementOrderType,
} from 'app/models/Placement/PlacementDetails';
import { IPlacementTimestampResponse } from 'app/models/Placement';
import _ from 'lodash';
import {
  selectEditPlacementRegion,
  selectPlacementContactsData,
  selectPlacementHeaderData,
  selectPlacementOrderType,
  selectPlacementStrikeData,
  selectPlacementTimestamp,
} from './selectors';
import {
  checkPlacementTimestamps,
  getAndSetPlacementTimestamp,
  getPlacementTimestamp,
  requestCandidateDOB,
  requestCandidateSSN,
  requestPlacementContacts,
  requestPlacementExtensionStatusDetails,
  requestPlacementProgress,
  requestPlacementStatusDetails,
  TrackCandidateDOB,
  TrackCandidateSSN,
} from './request.saga';
import { placementDetailsAction } from './slice';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { IBannerAction, ISnackBarSeverity } from 'app/models/Global/Global';
import { httpClientError, httpSuccess } from 'app/services/serviceHelpers';
import { PromiseTrackerKeys } from 'app/constants/PromiseTrackerKeys';
import {
  extractPlacementServiceKeys,
  extractStrikePlacementServiceKeys,
  isPlacementEdited,
  isShiftEdited,
  isStrikePropsEdited,
} from '../../../app/components/Placement/PlacementDetails/editUtils';
import {
  getPlacementUpdates,
  getStrikePlacementUpdates,
} from '../../../app/components/Placement/PlacementDetails/Edit/helper';
import {
  archivePlacement,
  postFinalizedByQs,
  updateStrikePlacementDetails,
} from 'app/services/PlacementServices/PlacementServices';
import { selectOfferInfo } from '../placement-summary/selectors';
import { placementSummaryActions } from '../placement-summary/slice';
import {
  refreshEditableData,
  requestDOBStatusOrderRequirement,
  requestSsnStatusOrderRequirement,
} from '../placement-summary/saga';
import { placementStatusAction } from '../placement-status/slice';
import { selectUser } from 'oidc/user.selectors';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { selectPlacementStatus } from '../placement-status/selectors';
import {
  isArchivePlacement,
  isBooked,
  isBookingApproved,
  isFC,
  isOnAssignment,
  isRFO,
  isSent,
  isSubmitPacket,
  isTA,
} from 'app/components/Placement/NextPlacementStatus/StatusDefaults';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { CandidateService } from 'app/services/CandidateServices/candidate-service';
import { IExtensionRequest, IExtensionUpdate } from '../placement-status/types';
import { pageAttributesActions } from 'app/components/Placement/PlacementDetails/Edit/store/placementPageAttributes.redux';
import {
  ICSPNote,
  ISubmitPacketCommand,
  IUpdateComplianceStatusComments,
  IUpdateComplianceStatusNotes,
  IUpdateDirectHire,
  IUpdateDocDeadline,
  IUpdateOrderId,
  IUpdateOrderIdAction,
} from './types';
import axios from 'axios';
import { placementStatusOptions } from 'app/components/Placement/NextPlacementStatus/StatusDefaults';
import { Concatenate } from 'utils/string/string';
import {
  getKeyContact,
  placementContactsFlattened,
} from '@AMIEWEB/Placement/PlacementDetails/PlacementDetailsHeader/utils';
import i18next from 'i18next';
import queryString from 'query-string';
import { requestBookingApprovedCheckboxSpecs } from '../placement-mods/request.saga';
import { placementModsAction } from '../placement-mods/slice';
import { handleResponse } from './helper';
import {
  requestBookingApprovedRequirements,
  requestBookingRequirements,
  requestDatesOrderRequirement,
  requestAssignmentInformationRequirement,
  requestVirtualInterviewRequirement,
} from '../placement-summary/common-saga';
import { END } from 'redux-saga';

const TrackPlacementUpdateWrapper = (fn, ...args) =>
  trackPromise(fn(...args), PromiseTrackerKeys.placements.updateAllDetails);
const TrackPlacementStatusUpdate = (fn, ...args) => trackPromise(fn(...args), 'update-placement-status');
const TrackExtensionStatusUpdate = (fn, ...args) => trackPromise(fn(...args), 'update-extension-status');
const TrackSaveSubmissionPackets = (fn, ...args) => trackPromise(fn(...args), 'save-submission-packets-to-cosmos');
const TrackComplianceStatusNotesUpdate = (fn, ...args) => trackPromise(fn(...args), 'compliance-status-notes-update');
const TrackDirectHireUpdate = (fn, ...args) => trackPromise(fn(...args), 'direct-hire-update');
const TrackComplianceStatusCommentUpdate = (fn, ...args) =>
  trackPromise(fn(...args), 'compliance-status-comment-update');
const TrackUpdateOrderIdForPlacement = (fn, ...args) => trackPromise(fn(...args), 'update-orderId-placement');

export function* updateCandidateSSN(action: PayloadAction<{ candidateId; brandId; ssn; timestamp }>) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const postResponse = yield call(TrackCandidateSSN, candidateService.postCandidateSSN, {
      ...action.payload,
      ignoreTimestamp: false,
    });
    let newSSNResponse: any = null;
    if (httpSuccess(postResponse?.status)) {
      // Get new SSN timestamp since post API does not return latest timestamp
      newSSNResponse = yield call(requestCandidateSSN, {
        payload: { candidateId: action.payload.candidateId, disableFormReset: true },
        type: placementDetailsAction.getCandidateSSN.type,
      });
      yield put(placementDetailsAction.setSSN(action.payload.ssn));
    }
    return {
      ...postResponse,
      data: {
        ssn: newSSNResponse?.data?.ssn || postResponse?.data,
        timestamp: newSSNResponse?.data?.timestamp,
      },
    };
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateCandidateSSN',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
    return { status: 500, data: { ssn: undefined, timestamp: undefined } };
  }
}

export function* updateCandidateDOB(action: PayloadAction<{ candidateId; brandId; dob; timestamp }>) {
  try {
    const candidateService: CandidateService = yield getContext('candidateService');
    const postResponse = yield call(TrackCandidateDOB, candidateService.postCandidateDOB, {
      ...action.payload,
      ignoreTimestamp: false,
    });
    let newDOBResponse: any = null;
    if (httpSuccess(postResponse?.status)) {
      // Get new DOB timestamp since post API does not return latest timestamp
      newDOBResponse = yield call(requestCandidateDOB, {
        payload: { candidateId: action.payload.candidateId, disableFormReset: true },
        type: placementDetailsAction.getCandidateDOB.type,
      });
      yield put(placementDetailsAction.setDOB(action.payload.dob));
      return {
        ...postResponse,
        data: {
          dob: newDOBResponse?.data?.dob || postResponse?.data,
          timestamp: newDOBResponse?.data?.timestamp,
        },
      };
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateCandidateDOB',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
    return { status: 500, data: { dob: undefined, timestamp: undefined } };
  }
}

/** @RIJASH - Bug #173782: Revise implementaion and sync into one setter - Due: 25h Nov 2022*/
export function* updatePlacementAll(action: PayloadAction<IPlacementUpdateAllRequest>) {
  try {
    const { userInfo } = yield select(selectUser);
    const originalTimestamp: IPlacementTimestampResponse = yield select(selectPlacementTimestamp);
    const placementService: PlacementService = yield getContext('placementService');
    const timestamp: IPlacementTimestampResponse = {
      placementTimestamp: _.cloneDeep(originalTimestamp.placementTimestamp),
      candidateTimestamp: _.cloneDeep(originalTimestamp.candidateTimestamp),
    };
    const placementOrderType = yield select(selectPlacementOrderType);
    const dirtyFieldKeys = Object.keys(action.payload.dirtyFields);
    const originalStrikeData = yield select(selectPlacementStrikeData);

    /**
     * Validate the placement has no conflicts before proceeding
     */
    if (action.payload.validateConflict) {
      // Placement has been updated. Return from function before updating placement
      manuallyIncrementPromiseCounter(PromiseTrackerKeys.placements.updateAllDetails);
      const timestampValidationResponse = yield call(checkPlacementTimestamps, {
        payload: {
          source: action.payload.validateConflict.source,
          placement: {
            id: action.payload.placementId,
            timestamp: timestamp.placementTimestamp,
          },
          candidate: {
            id: 0,
            timestamp: timestamp.candidateTimestamp,
          },
          placementStrikeDetails:
            placementOrderType === PlacementOrderType.StrikeOrderInHsg ? originalStrikeData : undefined,
          fields: [
            'candidate',
            'placement',
            ...(placementOrderType === PlacementOrderType.StrikeOrderInHsg ? ['strike'] : []),
          ],
        },
        type: placementDetailsAction.validatePlacementTimestamp.type,
      });
      if (!timestampValidationResponse) {
        if (
          timestampValidationResponse === false &&
          action.payload.validateConflict.source === IPlacementStatusSource.pageActions
        ) {
          yield put(
            globalActions.setBanner({
              message: 'placement.profile.conflict',
              severity: 'error',
              justify: 'center',
              action: IBannerAction.refreshPage,
            }),
          );
        } else if (timestampValidationResponse === null) {
          yield call(placementUpdateMessaging, { payload: { severity: 'error' }, type: 'placement_update_messaging' });
        }
        manuallyResetPromiseCounter(PromiseTrackerKeys.placements.updateAllDetails);
        return;
      }
    }

    /**
     * Update SSN
     * If succeeds, proceed with DOB
     */
    if (
      dirtyFieldKeys.includes('ssn') &&
      action.payload.updates.ssn &&
      (action.payload.updates?.ssn?.length || 0) > 0
    ) {
      const header = yield select(selectPlacementHeaderData);
      const ssnResponse = yield call(updateCandidateSSN, {
        payload: {
          candidateId: header?.candidate?.id,
          brandId: header?.brandId,
          ssn: action.payload.updates.ssn,
          timestamp: timestamp.candidateTimestamp,
        },
        type: placementDetailsAction.updateCandidateSSN.type,
      });
      if (!httpSuccess(ssnResponse?.status)) {
        yield call(placementUpdateMessaging, {
          payload: {
            severity: 'error',
            message: `Candidate SSN ${dirtyFieldKeys?.includes('dob') ? 'and DOB ' : ''}update failed. ${
              ssnResponse?.data?.Title || ''
            }`,
          },
          type: 'placement_update_messaging',
        });
        if (
          action.payload.validateConflict &&
          ssnResponse.status === 409 &&
          action.payload.validateConflict.source === IPlacementStatusSource.pageActions
        ) {
          yield put(
            globalActions.setBanner({
              message: 'placement.profile.conflict',
              severity: 'error',
              justify: 'center',
              action: IBannerAction.refreshPage,
            }),
          );
        }
        manuallyResetPromiseCounter(PromiseTrackerKeys.placements.updateAllDetails);
        return;
      } else {
        timestamp.candidateTimestamp = ssnResponse?.data?.timestamp;
        //101-00-9019
        if (action.payload.requirementCheckFlagEnabled) {
          yield call(requestSsnStatusOrderRequirement, {
            payload: {
              placementId: action.payload.placementId,
              isRetryingService: undefined,
            },
            type: placementSummaryActions.getSsnStatusOrderRequirement.type,
          });
        }
      }
    }

    /**
     * Update DOB
     * If SSN succeeds, continue with DOB
     */
    if (dirtyFieldKeys.includes('dob') && action.payload.updates?.dob) {
      const header = yield select(selectPlacementHeaderData);
      const dobResponse = yield call(updateCandidateDOB, {
        payload: {
          candidateId: header.candidate.id,
          brandId: header.brandId,
          dob: action.payload.updates.dob,
          timestamp: timestamp.candidateTimestamp,
        },
        type: placementDetailsAction.updateCandidateDOB.type,
      });
      if (!httpSuccess(dobResponse?.status)) {
        yield call(placementUpdateMessaging, {
          payload: { severity: 'error', message: 'Candidate DOB update failed.' },
          type: 'placement_update_messaging',
        });
        if (
          action.payload.validateConflict &&
          dobResponse.status === 409 &&
          action.payload.validateConflict.source === IPlacementStatusSource.pageActions
        ) {
          yield put(
            globalActions.setBanner({
              message: 'placement.profile.conflict',
              severity: 'error',
              justify: 'center',
              action: IBannerAction.refreshPage,
            }),
          );
        }
        manuallyResetPromiseCounter(PromiseTrackerKeys.placements.updateAllDetails);
        return;
      } else {
        // On success, set the new timestamp that was retrieved from updateCandidateDOB
        timestamp.candidateTimestamp = dobResponse?.data?.timestamp;
        if (action.payload.requirementCheckFlagEnabled) {
          yield call(requestDOBStatusOrderRequirement, {
            payload: {
              placementId: action.payload.placementId,
              isRetryingService: undefined,
            },
            type: placementSummaryActions.getDOBStatusOrderRequirement.type,
          });
        }
      }
    }

    /*
     * STRIKE UPDATES
     * Save strike related data if it was edited
     */
    if (isStrikePropsEdited(dirtyFieldKeys)) {
      const request = getStrikePlacementUpdates(action.payload.updates, action.payload.dirtyFields);
      const strikeDirtyFields = extractStrikePlacementServiceKeys(action.payload.dirtyFields);

      const response = yield call(TrackPlacementUpdateWrapper, updateStrikePlacementDetails, {
        ...request,
        placementId: action.payload.placementId,
        placementOrderType: getPlacementOrderTypeValue(placementOrderType),
        fields: strikeDirtyFields,
        placementStrikeDetails: originalStrikeData,
        lastUpdatedById: userInfo?.employeeId,
        lastUpdatedBy: Concatenate([userInfo?.firstName, userInfo?.lastName], ' '),
      });
      if (httpSuccess(response?.status)) {
        var existingTransportationDetails = yield select(selectOfferInfo);
        yield put(placementSummaryActions.setStrikeUpdatedData({ ...existingTransportationDetails, ...response.data }));

        // Get new candidate timestamp since that timestamp is updated regardless if there is a change or not
        const timestampResponse: IPlacementTimestampResponse = yield call(getPlacementTimestamp, {
          payload: { placementId: action.payload.placementId },
          type: placementDetailsAction.getPlacementTimestamp.type,
        });
        if (timestampResponse) {
          yield put(
            placementDetailsAction.setTimestamps({
              candidateTimestamp: timestampResponse.candidateTimestamp,
            }),
          );
        }

        yield call(refreshEditableData, { placementId: action.payload.placementId });
      } else {
        if (!!response && !!response.status && response.status === 409) {
          if (action.payload.validateConflict.source === IPlacementStatusSource.pageActions) {
            yield put(
              globalActions.setBanner({
                message: 'placement.profile.conflict',
                severity: 'error',
                justify: 'center',
                action: IBannerAction.refreshPage,
              }),
            );
          }
          yield put(
            placementStatusAction.setUpdatePlacementStatusResponse({
              success: false,
              source: action.payload.validateConflict.source,
              successType: IPlacementStatusSuccessType.placementConflict,
            }),
          );
        } else {
          yield put(
            placementStatusAction.setUpdatePlacementStatusResponse({
              success: false,
              source: action.payload.validateConflict.source,
              successType: IPlacementStatusSuccessType.placementUpdate,
            }),
          );
        }
        manuallyResetPromiseCounter(PromiseTrackerKeys.placements.updateAllDetails);
        yield call(placementUpdateMessaging, { payload: { severity: 'error' }, type: 'placement_update_messaging' });
        return;
      }
    }

    /**
     * STANDARD PLACEMENT UPDATE
     * Save any other placement details data
     */
    if (isPlacementEdited(dirtyFieldKeys)) {
      const request = {
        placementId: action.payload.placementId,
        ...getPlacementUpdates(action.payload.updates, action.payload.dirtyFields),
        userId: userInfo?.employeeId,
        fields: extractPlacementServiceKeys(action.payload.dirtyFields),
      };

      const response = yield TrackPlacementUpdateWrapper(placementService.updatePlacementDetails, {
        ...request,
        placementTimestamp: timestamp.placementTimestamp,
      });

      response.data.summaryRequestedTimeOff = {
        isRequestedTimeOffMakeUp: response.data.isRequestedTimeOffMakeUp,
        requestedTimeOff: response.data.requestedTimeOff,
        requestedTimeOffMode: response.data.requestedTimeOffMode,
        requestedTimeOffNote: response.data.requestedTimeOffNote,
      };
      if (httpSuccess(response?.status)) {
        yield call(requestAssignmentInformationRequirement, {
          payload: { placementId: action.payload.placementId },
          type: placementSummaryActions.getAssignmentInformationRequirement.type,
        });

        // re-run available start date call/logic
        yield call(requestDatesOrderRequirement, {
          payload: {
            placementId: action.payload.placementId,
            isRetryingService: undefined,
          },
          type: 'GET_DATES_ORDER_REQIREMENT',
        });
        if (isShiftEdited(dirtyFieldKeys))
          yield put(placementSummaryActions.getScheduleOrderRequirement({ placementId: action.payload.placementId }));
        yield put(
          placementDetailsAction.setUpdatedPlacementFields({
            data: response.data,
            dirtyFields: action.payload.dirtyFields,
          }),
        );
        /** @RIJASH - Temporary fix Bug #173782: Revise implementaion and sync into one setter - Due: 25h Nov 2022*/
        yield put(
          placementSummaryActions.setUpdatedPlacementFieldsInSummary({
            data: response.data,
            dirtyFields: action.payload.dirtyFields,
          }),
        );
      } else {
        if (!!response && !!response.status && response.status === 409) {
          yield put(
            globalActions.setBanner({
              message: 'placement.profile.conflict',
              severity: 'error',
              justify: 'center',
              action: IBannerAction.refreshPage,
            }),
          );
        }
        yield put(
          placementStatusAction.setUpdatePlacementStatusResponse({
            success: false,
            source: action.payload.validateConflict.source,
            successType: IPlacementStatusSuccessType.placementUpdate,
          }),
        );
        manuallyResetPromiseCounter(PromiseTrackerKeys.placements.updateAllDetails);
        yield call(placementUpdateMessaging, { payload: { severity: 'error' }, type: 'placement_update_messaging' });
        return;
      }
    }

    // After everything has finished, Get new timestamp, and reset the form with the latest values
    yield call(getAndSetPlacementTimestamp, {
      payload: { placementId: action.payload.placementId },
      type: 'get_set_placement_timestamp',
    });
    if (
      isPlacementEdited(dirtyFieldKeys) ||
      isStrikePropsEdited(dirtyFieldKeys) ||
      (dirtyFieldKeys.includes('dob') && action.payload.updates?.dob) ||
      (dirtyFieldKeys.includes('ssn') && action.payload.updates.ssn && (action.payload.updates?.ssn?.length || 0) > 0)
    ) {
      // Show success message only if data was updated
      if (action.payload.validateConflict.source === IPlacementStatusSource.pageActions)
        yield call(placementUpdateMessaging, { payload: { severity: 'success' }, type: 'placement_update_messaging' });
    }
    yield put(placementDetailsAction.resetForm());
    manuallyResetPromiseCounter(PromiseTrackerKeys.placements.updateAllDetails);
    return true;
  } catch (error) {
    yield call(placementUpdateMessaging, { payload: { severity: 'error' }, type: 'placement_update_messaging' });
    manuallyResetPromiseCounter(PromiseTrackerKeys.placements.updateAllDetails);
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updatePlacementAll',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}

export function* placementUpdateMessaging(action: PayloadAction<{ severity: ISnackBarSeverity; message?: string }>) {
  try {
    yield put(
      globalActions.setSnackBar({
        severity: action.payload.severity,
        message:
          action.payload.message ??
          (action.payload.severity === 'error'
            ? i18next.t('placement.profile.update.failureMessage')
            : action.payload.severity === 'success' || action.payload.severity === 'info'
            ? i18next.t('placement.profile.update.successMessage')
            : 'Unknown issue'),
      }),
    );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'placementUpdateMessaging',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}

/**
 * Method to update finalized by QS
 */
export function* updateFinalizedByQs() {
  try {
    const user = yield select(selectUser);
    const placementDetails = yield select(selectPlacementHeaderData);
    const postRequest = {
      placementId: placementDetails.placementId,
      isFinalizedByQS: false,
      finalizedByUserId: user.userInfo?.employeeId || 8410,
    };
    const response = yield call(() => postFinalizedByQs(postRequest));
    if (httpSuccess(response?.status)) {
      yield put(
        globalActions.setSnackBar({
          message: `Placement Finalized by CA updated successfully`,
          severity: 'success',
        }),
      );
      yield put(placementDetailsAction.setIsFinalisedByQs(response?.data?.isFinalizedByQS));
    } else {
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('common.failedLoad'),
          severity: 'error',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'postFinalizedByQs',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}

export function* updatePlacementStatus(action: PayloadAction<IPlacementStatusUpdateRequest>) {
  try {
    const { placementUpdate } = action.payload;
    const user = yield select(selectUser);
    const placementOrderType = yield select(selectPlacementOrderType);
    const placementStatus = yield select(selectPlacementStatus);
    manuallyIncrementPromiseCounter('update-placement-status');

    const placementService: PlacementService = yield getContext('placementService');

    // reset success while new request is being made
    yield put(
      placementStatusAction.setUpdatePlacementStatusResponse({
        success: undefined,
        source: placementUpdate.validateConflict.source,
      }),
    );

    //--------------------------------------------------------------
    // Update and placement details that were changed
    const updatePlacementResponse = yield call(updatePlacementAll, { payload: placementUpdate, type: '' });
    if (!updatePlacementResponse) return;

    //--------------------------------------------------------------

    let response: any = null;
    if (!isArchivePlacement(action.payload.placementStatus)) {
      /**
       * Get latest timestamps after
       */
      const timestampResponse: IPlacementTimestampResponse = yield call(getPlacementTimestamp, {
        payload: { placementId: placementUpdate.placementId },
        type: placementDetailsAction.getPlacementTimestamp.type,
      });

      if (timestampResponse) {
        yield put(placementDetailsAction.setTimestamps(timestampResponse));
        /**
         * Standard placement status update
         */
        response = yield call(TrackPlacementStatusUpdate, placementService.updateStatus, {
          placementId: placementUpdate.placementId,
          activityTypeId: action.payload.placementStatus.activityTypeId,
          activityStatusId: action.payload.placementStatus.activityStatusId,
          userId: user.userInfo.employeeId,
          placementOrderType: getPlacementOrderTypeValue(placementOrderType),
          candidateId: action.payload.candidateId,
          timestamp: timestampResponse.placementTimestamp,
          travelerOptionTimestamp: isBookingApproved(action.payload.placementStatus)
            ? timestampResponse?.travelerOptionTimestamp
            : undefined,
        });
      }
    } else {
      /**
       * Archive the placement
       */
      response = yield call(TrackPlacementStatusUpdate, archivePlacement, {
        placements: [
          {
            placementId: placementUpdate.placementId,
            activityStatusId: placementStatus.currentPlacementStatus.activityStatusId,
          },
        ],
        userId: user.userInfo.employeeId,
      });
    }

    if (httpSuccess(response?.status) && response?.data.activityStatus !== null) {
      let supportCallCountFailure = 0;

      /** CODE: US #121654: Remove all the failures under SubmitPacket if placement gets updated from RFO to Sent */
      if (isRFO(placementStatus.currentPlacementStatus) && isSubmitPacket(placementStatus.selectedStatus))
        yield put(
          placementStatusAction.resetFailedRequirementForStatus({ status: placementStatusOptions.submitPacket }),
        );
      yield put(placementStatusAction.setCurrentPlacementStatus(action.payload.placementStatus));

      // set placement status options to an empty array while the API call is made to retrieve the new status list
      yield put(placementStatusAction.setAvailablePlacementStatus([]));

      if (response.data.placementsUpdated === 1) yield put(placementDetailsAction.setIsArchived(true));

      // get new progress and set the progress bar
      const progressCallIsSuccessful = yield call(requestPlacementProgress, {
        payload: { placementId: placementUpdate.placementId },
        type: placementDetailsAction.getPlacementProgressAction.type,
      });
      if (!progressCallIsSuccessful) ++supportCallCountFailure;

      // get new available statuses for status drop down
      const statusResponseIsSuccessful = yield call(requestPlacementStatusDetails, {
        payload: { placementId: placementUpdate.placementId },
        type: placementStatusAction.getPlacementStatusDetails.type,
      });
      if (!statusResponseIsSuccessful) ++supportCallCountFailure;

      yield put(
        placementStatusAction.setUpdatePlacementStatusResponse({
          success: true,
          supportAPIFailure: supportCallCountFailure > 0 ? true : undefined,
          source: placementUpdate.validateConflict.source,
          successType: IPlacementStatusSuccessType.placementUpdate,
        }),
      );

      // Get latest placement timestamp because the status was changed
      yield call(getAndSetPlacementTimestamp, {
        payload: { placementId: placementUpdate.placementId },
        type: 'get_set_placement_timestamp',
      });

      // Get available extension status list if new status is onAssignment
      if (isOnAssignment(action.payload.placementStatus)) {
        yield call(requestPlacementExtensionStatusDetails, {
          payload: { placementId: action.payload.placementUpdate.placementId },
          type: placementStatusAction.getExtentionStatusDetails.type,
        });
      }

      if (isSent(action.payload.placementStatus)) {
        yield call(requestVirtualInterviewRequirement, {
          payload: { placementId: action.payload.placementUpdate.placementId },
          type: placementSummaryActions.getVirtualInterviewRequirement.type,
        });
      }

      yield call(requestPlacementContacts, {
        payload: { placementId: action.payload.placementUpdate.placementId },
        type: placementDetailsAction.getPlacementContactsAction.type,
      });

      //update placement Assignment details
      yield call(requestAssignmentInformationRequirement, {
        payload: { placementId: action.payload.placementUpdate.placementId },
        type: placementSummaryActions.getAssignmentInformationRequirement.type,
      });

      if (isBookingApproved(action.payload.placementStatus)) {
        yield put(pageAttributesActions.setMiscDialog({ key: MiscPlacementDialogType.generate_candidate_contract }));
        yield call(requestBookingApprovedRequirements, {
          payload: { placementId: action.payload.placementUpdate.placementId, placementOrderType },
          type: placementSummaryActions.getBookingApprovedRequirements.type,
        });
        if (queryString.parse(window.location.search) === '3')
          yield call(requestBookingApprovedCheckboxSpecs, {
            payload: { placementId: action.payload.placementUpdate.placementId },
            type: placementModsAction.getBookingApprovedCheckboxSpecs.type,
          });
      }

      if (
        isTA(action.payload.placementStatus) ||
        isFC(action.payload.placementStatus) ||
        isBooked(action.payload.placementStatus)
      ) {
        yield all([
          call(requestBookingApprovedRequirements, {
            payload: { placementId: action.payload.placementUpdate.placementId, placementOrderType },
            type: placementSummaryActions.getBookingApprovedRequirements.type,
          }),
          call(requestBookingRequirements, {
            payload: { placementId: action.payload.placementUpdate.placementId, placementOrderType },
            type: placementSummaryActions.getBookingRequirement.type,
          }),
        ]);
      }
    } else if (!!response && !!response.status && response.status === 500) {
      yield call(getAndSetPlacementTimestamp, {
        payload: { placementId: placementUpdate.placementId },
        type: 'get_set_placement_timestamp',
      });
      const offerInfo = yield select(selectOfferInfo);
      yield put(
        placementSummaryActions.setOfferConfirmationInfo({
          ...offerInfo.confirmation,
          travelConfirmed: {
            ...offerInfo.confirmation.travelConfirmed,
            data: {
              ...offerInfo.confirmation.travelConfirmed.data,
              checked: false,
            },
          },
        }),
      );
      yield put(
        placementStatusAction.setUpdatePlacementStatusResponse({
          success: false,
          source: placementUpdate.validateConflict.source,
          successType: IPlacementStatusSuccessType.placementStatusUpdateStepsError,
        }),
      );
    } else if (response && response?.status === 409) {
      yield put(
        placementStatusAction.setUpdatePlacementStatusResponse({
          success: false,
          source: placementUpdate.validateConflict.source,
          successType: IPlacementStatusSuccessType.placementConflict,
        }),
      );
    } else if (response && response?.status === 400) {
      if (response?.data?.errorId === 61326 || response?.data?.errorId === 60055 || response?.data?.errorId === 61307) {
        yield put(placementStatusAction.setIsModificationError({ isModificationError: true }));
      }

      const errorIdMappings = {
        61326: IPlacementStatusSuccessType.bookingToNegativeStatusError,
        60055: IPlacementStatusSuccessType.onAssignmentToNegativeStatusEndDateError,
        61307: IPlacementStatusSuccessType.onAssignmentToNegativeStatusStartDateError,
        60023: IPlacementStatusSuccessType.missingStartDateEndDateOrNumberOfWeeks,
        60073: IPlacementStatusSuccessType.numberofWeeksMismatch,
        61348: IPlacementStatusSuccessType.BookedStatusPermOrderError,
        60185: IPlacementStatusSuccessType.shiftError,
        60026: IPlacementStatusSuccessType.placementVerificationScreenError,
        60050: IPlacementStatusSuccessType.confirmationStatusError,
        60051: IPlacementStatusSuccessType.contractStatusError,
        60214: IPlacementStatusSuccessType.payCycleError,
        60014: IPlacementStatusSuccessType.billRateError,
        60016: IPlacementStatusSuccessType.housingTypeError,
        60015: IPlacementStatusSuccessType.payRateError,
        60021: IPlacementStatusSuccessType.travelAllowanceError,
        60024: IPlacementStatusSuccessType.missingFurnitureHousingInsurancePayRateBillRateError,
        60022: IPlacementStatusSuccessType.optionsScreenError,
        61361: IPlacementStatusSuccessType.checklistsError,
        60304: IPlacementStatusSuccessType.onAssignmentStartDateError,
      };

      const successType =
        response?.data?.errorId && errorIdMappings.hasOwnProperty(response?.data?.errorId)
          ? errorIdMappings[response?.data?.errorId]
          : IPlacementStatusSuccessType.placementStatusUpdateStepsError;

      const errorMessage = [
        60023, 60073, 61348, 60185, 60026, 60050, 60051, 60214, 60014, 60016, 60015, 60021, 60024, 60022, 61361, 60304,
      ].includes(response?.data?.errorId)
        ? response?.data?.errorMessage
        : null;

      yield put(
        placementStatusAction.setUpdatePlacementStatusResponse({
          success: false,
          source: placementUpdate.validateConflict.source,
          successType: successType,
          errorMessage: errorMessage,
        }),
      );
    } else {
      yield put(
        placementStatusAction.setUpdatePlacementStatusResponse({
          success: false,
          source: placementUpdate.validateConflict.source,
          successType: IPlacementStatusSuccessType.placementUpdate,
        }),
      );
    }
    // Reset redux form after everything is updated
    yield put(placementDetailsAction.resetForm());
  } catch (error) {
    yield put(
      placementStatusAction.setUpdatePlacementStatusResponse({
        success: false,
        source: action.payload.placementUpdate.validateConflict.source,
        successType: IPlacementStatusSuccessType.placementUpdate,
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updatePlacementStatus',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  } finally {
    manuallyResetPromiseCounter('update-placement-status');
  }
}

export function* updateExtensionStatusDetails(action: PayloadAction<IExtensionRequest | IExtensionUpdate>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const isBeingEdited = action.payload.hasOwnProperty('extensions');
    const response = yield call(
      TrackExtensionStatusUpdate,
      isBeingEdited ? placementService.updateExtensionDetails : placementService.createNewExtensionDetails,
      action.payload,
    );
    if (httpSuccess(response?.status)) {
      yield put(
        globalActions.setSnackBar({
          message: isBeingEdited
            ? i18next.t('placement.profile.summary.extension.updateExtensionDetails')
            : i18next.t('placement.profile.summary.extension.updateExtensionStatus'),
          severity: 'success',
        }),
      );

      yield put(pageAttributesActions.closeExtensionModal());
      yield call(requestPlacementExtensionStatusDetails, {
        payload: { placementId: action.payload.placementId },
        type: placementStatusAction.getExtentionStatusDetails.type,
      });
      yield call(requestAssignmentInformationRequirement, {
        payload: { placementId: action.payload.placementId },
        type: placementSummaryActions.getAssignmentInformationRequirement.type,
      });
    } else
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('common.failedLoad'),
          severity: 'error',
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateExtensionStatusDetails',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}

export function* updateExtensionStatus(
  action: PayloadAction<{
    placementId: number;
    extensionStatusId: number | null;
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const appUser = yield select(selectUser);
    const payload = {
      ...action.payload,
      userId: appUser.userInfo.employeeId,
    };
    const response = yield call(TrackExtensionStatusUpdate, placementService.updateExtensionStatus, payload);
    if (httpSuccess(response?.status)) {
      yield put(placementStatusAction.setSelectedStatus({ status: undefined }));
      yield put(
        globalActions.setSnackBar({
          message: !action.payload.extensionStatusId
            ? i18next.t('placement.profile.summary.extension.resetExtensionStatus')
            : i18next.t('placement.profile.summary.extension.updateExtensionStatus'),
          severity: 'success',
        }),
      );

      yield call(requestPlacementExtensionStatusDetails, {
        payload: { placementId: payload.placementId },
        type: placementStatusAction.getExtentionStatusDetails.type,
      });
      yield call(requestAssignmentInformationRequirement, {
        payload: { placementId: action.payload.placementId },
        type: placementSummaryActions.getAssignmentInformationRequirement.type,
      });
    } else
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('common.failedLoad'),
          severity: 'error',
        }),
      );
  } catch (error) {
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateExtensionStatus',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}

export function* saveSubmissionPacketsToCosmos(action: PayloadAction<ISubmitPacketCommand>) {
  const cancellationSource = axios.CancelToken.source();
  try {
    const placementService: PlacementService = yield getContext('placementService');
    yield call(TrackSaveSubmissionPackets, placementService.saveSubmissionPacketsToCosmos, action.payload);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateExtensionStatus',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  } finally {
    if (yield cancelled()) {
      cancellationSource.cancel();
    }
  }
}

export function* saveComplianceStatusNote(action: PayloadAction<{ newNote: ICSPNote }>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(placementService.saveComplianceStatusNote, action.payload.newNote);
    if (httpSuccess(response?.status)) {
      yield put(placementDetailsAction.getComplianceStatusProgress({ placementId: response.data }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveComplianceStatusNote',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}

export function* saveComplianceStatusDocDeadline(action: PayloadAction<IUpdateDocDeadline>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(placementService.saveComplianceStateNoteDocdeadline, action.payload);
    if (httpSuccess(response?.status)) {
      yield put(
        globalActions.setSnackBar({
          message: "'Doc Deadline' date has been updated successfully",
          severity: 'success',
        }),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveComplianceStatusDocDeadline',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
    yield put(
      globalActions.setSnackBar({
        message: i18next.t('common.failedLoad'),
        severity: 'error',
      }),
    );
  }
}

export function* updateEfoDocToOkToSend(
  action: PayloadAction<{
    documentId: string;
    status: string;
  }>,
) {
  try {
    const { currentPlacementStatus } = yield select(selectPlacementStatus);

    if (action.payload.status !== 'Not Sent' && !isRFO(currentPlacementStatus)) return;
    const { placementId } = yield select(selectPlacementHeaderData);
    const placementOrderType = yield select(selectPlacementOrderType);
    const {
      userInfo: { employeeId: userId, firstName, lastName },
    } = yield select(selectUser);

    const placementService: PlacementService = yield getContext('placementService');

    const originalTimestamp: IPlacementTimestampResponse = yield select(selectPlacementTimestamp);

    const response = yield call(placementService.updateEfoDocToOkToSend, {
      placementId,
      placementOrderType: getPlacementOrderTypeValue(placementOrderType),
      userId,
      userName: Concatenate([firstName, lastName], ' '),
      timestamp: originalTimestamp.placementTimestamp,
      documentId: action.payload.documentId,
    });

    if (httpSuccess(response?.status))
      yield all([
        call(requestPlacementStatusDetails, {
          payload: { placementId },
          type: placementStatusAction.getPlacementStatusDetails.type,
        }),
        call(getAndSetPlacementTimestamp, {
          payload: { placementId },
          type: 'get_set_placement_timestamp',
        }),
      ]);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateEfoDocToOkToSend',
        area: 'src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}

export function* updateKeyContact(action: PayloadAction<any>) {
  const serviceTracker = `save-contact-${action.payload.key}`;
  try {
    manuallyIncrementPromiseCounter(serviceTracker);
    const placementService: PlacementService = yield getContext('placementService');
    const {
      userInfo: { employeeId },
    } = yield select(selectUser);
    const { placementId } = yield select(selectPlacementHeaderData);

    const { placementTimestamp } = yield select(selectPlacementTimestamp);

    const payload = {
      ...action.payload,
      placementId,
      keyContact: getKeyContact(action.payload.key),
      employeeId: parseInt(action.payload.id),
      userId: employeeId,
      placementTimestamp,
    };

    const response = yield call(placementService.updateKeyContact, payload);

    if (httpSuccess(response?.status)) {
      const existingData = yield select(selectPlacementContactsData);
      const newData = { ...existingData, [action.payload.key]: response.data };

      const flattenedContacts = placementContactsFlattened(newData);
      const updatedContact = flattenedContacts.keyInternalPartners[action.payload.key];

      yield call(getAndSetPlacementTimestamp, {
        payload: { placementId },
        type: 'get_set_placement_timestamp',
      });
      yield put(placementDetailsAction.setPlacementContacts({ ...newData, flattenedContacts }));
      manuallyDecrementPromiseCounter(serviceTracker);

      yield put(
        placementDetailsAction.editKeyInternalContact({
          key: action.payload.key,
          data: {
            value: updatedContact.id
              ? { name: updatedContact.title, value: updatedContact.id, id: updatedContact.id }
              : null,
            error: null,
            isDirty: false,
            helperText: 'Saved',
          },
        }),
      );
      yield delay(2000);
      yield put(placementDetailsAction.removeKeyInternalContactHelperText(action.payload.key));
    } else {
      yield put(
        placementDetailsAction.setKeyInternalContactUpdateError({
          key: action.payload.key,
          error: { message: 'Error. Try again' },
        }),
      );
      manuallyDecrementPromiseCounter(serviceTracker);
    }
  } catch (error) {
    manuallyDecrementPromiseCounter(serviceTracker);
    yield put(
      placementDetailsAction.setKeyInternalContactUpdateError({
        key: action.payload.key,
        error: { message: 'Error. Try again' },
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveContact',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}

export function* updatePlacementRegion(action: PayloadAction<any>) {
  const serviceTracker = `update-placement-region`;
  const placementService: PlacementService = yield getContext('placementService');
  const {
    userInfo: { employeeId, firstName, lastName },
  } = yield select(selectUser);
  const { placementId } = yield select(selectPlacementHeaderData);
  const currentPlacement = yield select(selectPlacementHeaderData);

  const payload = {
    ...action.payload,
    placementId,
    currentEmployeeName: Concatenate([firstName || '', lastName || ''], ' '),
    currentEmployeeId: employeeId,
  };

  try {
    manuallyIncrementPromiseCounter(serviceTracker);
    const response = yield call(placementService.updatePlacementRegion, payload);
    const editedRegion = yield select(selectEditPlacementRegion);
    if (httpSuccess(response.status)) {
      yield call(getAndSetPlacementTimestamp, {
        payload: { placementId },
        type: 'get_set_placement_timestamp',
      });

      yield put(
        placementDetailsAction.setPlacementHeaderDetails({
          ...currentPlacement,
          locationRegionId: editedRegion?.id,
          locationRegionName: editedRegion?.name,
        }),
      );
      yield put(placementDetailsAction.editPlacementRegion({ ...editedRegion, helperText: 'Saved' }));
      manuallyDecrementPromiseCounter(serviceTracker);
      yield delay(2000);
      yield put(placementDetailsAction.editPlacementRegion(undefined));
    } else {
      yield put(placementDetailsAction.setEditPlacementRegionError({ message: 'Error. Try Again' }));
      manuallyDecrementPromiseCounter(serviceTracker);
    }
  } catch (error) {
    manuallyDecrementPromiseCounter(serviceTracker);
    yield put(placementDetailsAction.setEditPlacementRegionError({ message: 'Error. Try Again' }));

    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIRequestError,
        functionName: 'UpdatePlacementRegion',
        area: 'src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}

export function* createClassicRequestContract(action: PayloadAction<{ placementId: number }>) {
  try {
    manuallyIncrementPromiseCounter('update-placement-status');
    const placementService: PlacementService = yield getContext('placementService');
    const placementOrderType = yield select(selectPlacementOrderType);
    const {
      userInfo: { employeeId: userId, firstName, lastName },
    } = yield select(selectUser);
    const response = yield call(placementService.createClassicRequestContract, {
      placementId: action.payload.placementId,
      userId,
      userName: Concatenate([firstName, lastName], ' '),
    });

    if (httpSuccess(response.status)) {
      yield call(requestPlacementStatusDetails, {
        payload: { placementId: action.payload.placementId },
        type: placementStatusAction.getPlacementStatusDetails.type,
      });
      yield put(placementStatusAction.setSelectedStatus({ status: undefined }));
      yield* handleResponse(response);

      /** Code: The above service call, would move the contract to booked conditionally and hence require refresh mentioned below */
      yield all([
        call(requestBookingApprovedRequirements, {
          payload: { placementId: action.payload.placementId, placementOrderType },
          type: placementSummaryActions.getBookingApprovedRequirements.type,
        }),
        call(requestBookingRequirements, {
          payload: { placementId: action.payload.placementId, placementOrderType },
          type: placementSummaryActions.getBookingRequirement.type,
        }),
      ]);
    } else if (response.status === 400) yield* handleResponse(response);
    else
      yield put(
        globalActions.setStackSnackBar({
          message: i18next.t('placement.profile.placementStatusUpdate.error'),
          severity: 'error',
        }),
      );
  } catch (error) {
    yield put(
      globalActions.setStackSnackBar({
        message: i18next.t('placement.profile.placementStatusUpdate.error'),
        severity: 'error',
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'createClassicRequestContract',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  } finally {
    manuallyResetPromiseCounter('update-placement-status');
  }
}

export function* updateComplianceStatusNotes(action: PayloadAction<IUpdateComplianceStatusNotes>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const postResponse = yield call(
      TrackComplianceStatusNotesUpdate,
      placementService.updateComplianceStatusNotesByPlacementId,
      action.payload,
    );
    if (httpSuccess(postResponse?.status)) {
      yield put(
        globalActions.setSnackBar({
          message: 'Compliance Status notes updated successfully',
          severity: 'success',
        }),
      );
      yield put(placementDetailsAction.getComplianceStatusProgress({ placementId: action.payload.placementId }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateComplianceStatusNotes',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}

export function* updateComplianceStatusComments(action: PayloadAction<IUpdateComplianceStatusComments>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const patchResponse = yield call(
      TrackComplianceStatusCommentUpdate,
      placementService.updateComplianceStatusCommentByPlacementId,
      action.payload,
    );
    if (httpSuccess(patchResponse?.status)) {
      yield put(placementDetailsAction.setComplianceStatusHeader({ saveStatus: 2 }));
      // yield put(
      //   globalActions.setSnackBar({
      //     message: 'Compliance Status notes updated successfully',
      //     severity: 'success',
      //   }));
    } else {
      yield put(placementDetailsAction.setComplianceStatusHeader({ saveStatus: 3 }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateComplianceStatusNotes',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}

export function* updateDirectHire(action: PayloadAction<IUpdateDirectHire>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const postResponse = yield call(TrackDirectHireUpdate, placementService.postDirectHireDetail, action.payload);
    if (httpSuccess(postResponse?.status)) {
      const response = yield call(
        TrackDirectHireUpdate,
        placementService.getPlacementAssignmentInformation,
        postResponse.data,
      );

      if (httpSuccess(response?.status)) {
        yield put(
          placementDetailsAction.selectedDirectHireDetail({
            directHireOptionId: response.data.directHireTypeId,
            directHireTypeDescription: response.data.directHireTypeDescription,
            directHireLastUpdated: response.data.directHireLastUpdated,
            directHireLastUpdatedBy: response.data.directHireLastUpdatedBy,
          }),
        );
        yield put(
          globalActions.setSnackBar({
            message: 'Direct Hire updated successfully',
            severity: 'success',
          }),
        );

        const timestampResponse: IPlacementTimestampResponse = yield call(getPlacementTimestamp, {
          payload: { placementId: action.payload.placementId },
          type: placementDetailsAction.getPlacementTimestamp.type,
        });
        if (timestampResponse) {
          yield put(
            placementDetailsAction.setTimestamps({
              candidateTimestamp: timestampResponse.candidateTimestamp,
              placementTimestamp: timestampResponse.placementTimestamp,
              travelerOptionTimestamp: timestampResponse.travelerOptionTimestamp,
            }),
          );
        }
      } else {
        yield put(
          placementSummaryActions.setServiceFailureByKey({
            key: 'assignmentInformationRequirement',
            failureStatus: true,
          }),
        );
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateComplianceStatusNotes',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
  }
}
export function* updateOrderIdForPlacement(action: PayloadAction<IUpdateOrderIdAction>) {
  const resultChannel = action.payload.meta?.resultChannel;

  try {
    yield put(placementDetailsAction.setIsUpdatedOrderId(false));
    const placementService: PlacementService = yield getContext('placementService');
    const updateOrderIdResponse = yield call(
      TrackUpdateOrderIdForPlacement,
      placementService.updateOrderIdForPlacement,
      action.payload.payload,
    );
    if (httpSuccess(updateOrderIdResponse?.status)) {
      yield put(placementDetailsAction.setIsUpdatedOrderId(true));
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('placement.profile.update.orderIDChangeSucessMessage'),
          severity: 'success',
        }),
      );
      if (resultChannel) {
        resultChannel({ success: true }); // Emit success
      }
    } else if (httpClientError(updateOrderIdResponse?.status)) {
      if (updateOrderIdResponse?.data?.errorMessage) {
        yield put(
          globalActions.setSnackBar({
            message: updateOrderIdResponse?.data?.errorMessage,
            severity: 'error',
          }),
        );
        if (resultChannel) {
          resultChannel({ success: false, error: updateOrderIdResponse?.data?.errorMessage });
        }
      } else {
        yield put(
          globalActions.setSnackBar({
            message: 'An error occurred while updating order details',
            severity: 'error',
          }),
        );
        if (resultChannel) {
          resultChannel({ success: false, error: 'An error occurred while updating order details' });
        }
      }
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'TrackUpdateOrderIdForPlacement',
        area: '/src/store/redux-store/placement-details/update.saga.ts',
      },
    });
    if (resultChannel) {
      resultChannel({ success: false, error });
    }
  } finally {
    if (resultChannel) {
      resultChannel(END); // Ensure the channel is closed when the saga is done
    }
  }
}
