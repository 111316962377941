import React, { useRef, useState } from 'react';
import { Row, RowHead, RowContent, SubTitle, TextContent } from '../styles';
import { Box, ClickAwayListener, Grid, IconButton, Skeleton, Typography } from 'amn-ui-core';
import { RowContainer } from '@AMIEWEB/Candidate/Common/RowContainer';
import Gender from '../Gender/Gender';
import { EquipmentProcedure } from '../EMR/EquipmentProcedure';
import { SellingNotesConfirmation } from '../SellingNotes/SellingNotesConfirmation';
import { SellingNotesWrapper } from '../SellingNotes/SellingNotesWrapper';
import { CustomTooltip } from '@AMIEWEB/Common/Tooltips';
import EditTextIcon from 'app/assets/images/Credentialing/PersonalInfo/editText.svg';
import { isRecruitment, useReadOnly } from 'oidc/userRoles';
import { SellingNotes } from '../SellingNotes';
import { ActionButton, PersonalInfoStyles } from '../styles';
import { usePromiseTracker } from 'react-promise-tracker';
import { useTranslation } from 'react-i18next';
import { useDecision } from '@optimizely/react-sdk';
import { ff_autoGenerateSellingNotes, ff_editSellingNotes } from 'app/constants/FeatureFlags/Candidate/Keys';
import { withReadOnly } from 'oidc/withReadOnly';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { CandidateContainer } from '../../../SummaryTab/Common/CandidateContainer';
import PersonalInfoIconV2 from 'app/assets/images/CardBuilderCandidate/PersonalInformationIcon.svg';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

function PersonalInfoCardUIV2({
  info,
  isCalendarViewableByUser,
  isSSNViewableByUser,
  isSSNLastFourViewableByUser,
  isGenderEditableByUser,
  candidateDetails,
  isGenderViewableByUser,
  openTemplateConfirm,
  setOpenTemplateConfirm,
  handleOpenSellingNotes,
  openTemplate,
  handleClose,
  sellingNoteValue,
  setOpenTemplate,
  handleChangeValue,
  isHandleClick,
  handleSave,
  resetValue,
  saveResult,
  sellingNoteValidation,
  setIsHandleClick,
}) {
  const { promiseInProgress } = usePromiseTracker({ area: 'get-candidate-details', delay: 0 });
  const { t } = useTranslation();
  const [editSellingNotesDecision] = useDecision(ff_editSellingNotes);
  const [autoGenerateSellingNotesDecision] = useDecision(ff_autoGenerateSellingNotes);
  const user = useSelector(selectUser);
  const { readOnly } = useReadOnly();
  const { classes } = PersonalInfoStyles();
  const [isHandleSSNClick, setIsHandleSSNClick] = useState<boolean>(false);
  const textInput = useRef<HTMLElement>(null);

  return (
    <CandidateContainer
      title={t('candidate.personalInfoCard.title')}
      icon={<img src={PersonalInfoIconV2} alt="PersonalInfoIcon" />}
    >
      {promiseInProgress ? (
        <Grid item mr={2}>
          <Skeleton height={100} />
          <Skeleton height={100} />
          <Skeleton height={100} />
        </Grid>
      ) : (
        <Grid container direction="column" spacing={2} paddingRight="12px">
          <Grid item container spacing={1} direction="column" justifyContent="space-between">
            <Grid item>
              <RowContainer>
                <Row>
                  <RowHead>{t('candidate.personalInfoCard.dateOfBirth')}</RowHead>
                  <RowContent>
                    {info.dateOfBirth.length > 0 ? (
                      isCalendarViewableByUser ? (
                        <span>{info.dateOfBirth}</span>
                      ) : (
                        <CustomTooltip
                          tooltipContent={t('candidate.personalInfoCard.protectedDataTooltip')}
                          contentStyle={{ alignSelf: 'center' }}
                        >
                          <span>{`**/**/****`}</span>
                        </CustomTooltip>
                      )
                    ) : (
                      <span>{`--`}</span>
                    )}
                  </RowContent>
                </Row>
              </RowContainer>
            </Grid>
            <Grid item>
              <RowContainer>
                <Row>
                  <RowHead>{t('candidate.personalInfoCard.socialSecurityNum')}</RowHead>
                  <RowContent>
                    <ClickAwayListener onClickAway={e => setIsHandleSSNClick(false)}>
                      {isSSNViewableByUser ? (
                        <Box onClick={e => setIsHandleSSNClick(true)}>
                          {isHandleSSNClick ? (
                            <span>{info.socialSecurityNumber}</span>
                          ) : (
                            <span>{`*** ** ${info.socialSecurityNumber.toString().slice(-4)}`}</span>
                          )}
                        </Box>
                      ) : isSSNLastFourViewableByUser ? (
                        <span>{`*** ** ${info.socialSecurityNumber.toString().slice(-4)}`}</span>
                      ) : (
                        <CustomTooltip
                          tooltipContent={t('candidate.personalInfoCard.protectedDataTooltip')}
                          contentStyle={{ alignSelf: 'center' }}
                        >
                          <span>{'*** ** ****'}</span>
                        </CustomTooltip>
                      )}
                    </ClickAwayListener>
                  </RowContent>
                </Row>
              </RowContainer>
            </Grid>
          </Grid>
          {isGenderEditableByUser ? (
            <Gender />
          ) : (
            <Grid item container spacing={1} direction="column">
              <Grid item>
                <SubTitle>{t('candidate.personalInfoCard.gender')}</SubTitle>
              </Grid>
              <Grid item>
                <TextContent>
                  {candidateDetails.gender ? (isGenderViewableByUser ? candidateDetails.gender : '*****') : '--'}
                </TextContent>
              </Grid>
            </Grid>
          )}
          <Grid item container spacing={1} direction="column">
            <Grid item>
              <SubTitle>{t('candidate.personalInfoCard.otherName')}</SubTitle>
            </Grid>
            <Grid item>
              <TextContent>{candidateDetails.other}</TextContent>
            </Grid>
          </Grid>
          <EquipmentProcedure />
          <Grid item container direction="column">
            <Grid container direction="row" className={classes.sellingNotesHeading}>
              <Grid item>
                <SubTitle>{t('candidate.personalInfoCard.sellingNotes')}</SubTitle>
              </Grid>
              {editSellingNotesDecision.enabled && (
                <>
                  <SellingNotesConfirmation
                    open={openTemplateConfirm}
                    setDialogOpen={setOpenTemplateConfirm}
                    handleOpenSellingNotes={handleOpenSellingNotes}
                  />
                  <SellingNotesWrapper open={openTemplate} handleClose={handleClose} />
                  <Grid item marginRight="3px">
                    {autoGenerateSellingNotesDecision.enabled && (
                      <IconButton
                        disabled={!isRecruitment(user?.userInfo?.roles || [])}
                        style={{ padding: '8px' }}
                        onClick={() => {
                          if (sellingNoteValue?.length > 0) {
                            setOpenTemplateConfirm(true);
                          } else {
                            setOpenTemplate(true);
                          }
                          textInput?.current?.focus();
                        }}
                        size="medium"
                        color="secondary"
                      >
                        <CustomTooltip
                          tooltipContent={
                            <Typography style={{ fontSize: '12px' }} component="span">{`Open Template`}</Typography>
                          }
                          placement="bottom"
                        >
                          <img src={EditTextIcon} alt="Edit text" className={classes.iconSize} />
                        </CustomTooltip>
                      </IconButton>
                    )}
                    {withReadOnly(IconButton)({
                      className: classes.editIcon,
                      disabled: !isRecruitment(user.userInfo?.roles || []) || isHandleClick,
                      onClick: () => {
                        if (!readOnly) {
                          setIsHandleClick(true);
                          textInput?.current?.focus();
                        }
                      },
                      color: 'secondary',
                      medium: 'medium',
                      children: <ModeEditOutlinedIcon style={{ width: isHandleClick ? 0 : undefined }} />,
                    })}
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item>
              <Grid item>
                {!isHandleClick ? (
                  <Typography component="span" style={{ whiteSpace: 'pre-wrap' }}>
                    {sellingNoteValue ? sellingNoteValue : '--'}
                  </Typography>
                ) : (
                  <SellingNotes
                    data={sellingNoteValue}
                    handleChangeValue={handleChangeValue}
                    handleSave={handleSave}
                    isEditable={true}
                    resetValue={resetValue}
                    saveStatus={saveResult}
                    validationErrorText={sellingNoteValidation}
                    isHandleClick={isHandleClick}
                    setIsHandleClick={setIsHandleClick}
                    textInput={textInput}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item container direction="column">
            <Grid item>
              <SubTitle>{t('candidate.personalInfoCard.summary')}</SubTitle>
            </Grid>
            <Grid item>
              <TextContent>{info.candidateSummary}</TextContent>
            </Grid>
          </Grid>
          <Grid item>
            <ActionButton aria-label="view-resume" variant="contained" size="medium" color="primary" disableElevation>
              {t('candidate.personalInfoCard.viewResume')}
            </ActionButton>
          </Grid>
        </Grid>
      )}
    </CandidateContainer>
  );
}

export default PersonalInfoCardUIV2;
