import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import { selectClientContactDetails } from './store/ClientContact.selector';

export const useIsContactSubmitDisabled = (
  dirtyFields,
  selectedUnits,
  actionValues,
  errors,
  getValues,
  isInValidEmail,
  hasEmailRequired
) => {
  const contactDetails = useSelector(selectClientContactDetails);

  const areArraysEqual = (array1, array2, getValue) => {
    if (array1?.length !== array2?.length) return false;
    return array1.every((item, index) => {
      const value1 = getValue(item);
      const value2 = getValue(array2[index]);
      return value1 === value2;
    });
  };

  const areActionsEqual = (actions1, actions2) => {
    return areArraysEqual(actions1, actions2, (action) => action?.description || action?.value);
  };

  const areAUnitsEqual = (units1, units2) => {
    return areArraysEqual(units1, units2, (unit) => unit?.name || unit?.value);
  };

  const actionsEqual = areActionsEqual(contactDetails?.actions ?? [], actionValues);
  const unitsEqual = areAUnitsEqual(contactDetails?.units ?? [], selectedUnits);

  const isDataUpdated = contactDetails
    ? !actionsEqual || !unitsEqual
    : selectedUnits?.length > 0 || actionValues?.length > 0;
  const hasErrors = !isEmpty(errors) || isInValidEmail || hasEmailRequired;
  return contactDetails
    ? (isEmpty(dirtyFields) && !isDataUpdated) || hasErrors
    : !getValues?.firstName || !getValues?.lastName || hasErrors;

};