import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './consolidatedStoreSlice';
import { RootState } from 'types';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { passportPlacementCreatorId } from './init.types';

const selectDomain = (state: RootState) => state.placementSummaryConsolidatedStore || initialState;

export const selectPlacementConsolidatedSummary = createSelector([selectDomain], summary => summary);

export const selectPlacementConsolidatedPlacementId = createSelector([selectDomain], state => state.placementId);

export const selectPlacementConsolidatedDetails = createSelector([selectDomain], state => state?.consolidatedResult);

export const selectIsPassportPlacement = createSelector(
  [selectDomain],
  state => state?.dateEnteredByUserId === passportPlacementCreatorId,
);

export const selectPlacementDivisionType = createSelector([selectDomain], state => state?.placementDivisionType);
export const selectIsWfcPlacement = createSelector([selectDomain], state => state?.isWfcPlacement);

export const selectPlacementConsolidatedOrderType = createSelector([selectDomain], state => {
  if (
    state?.consolidatedResult?.placementOrderType !== null &&
    state?.consolidatedResult?.placementOrderType !== undefined
  ) {
    if (state?.consolidatedResult?.placementOrderType === 1) {
      return PlacementOrderType.StrikeOrderInHsg;
    } else {
      return PlacementOrderType.Default;
    }
  } else {
    return null;
  }
});

export const selectInitialDetailsLoadState = createSelector([selectDomain], state => state?.stage0);

export const selectBulkDetailsLoadState = createSelector([selectDomain], state => state?.stage1);

export const selectLoadingCompletedStatus = createSelector([selectDomain], state => state?.isLoadingCompleted ?? false);
export const selectErrorStatus = createSelector([selectDomain], state => state?.isError ?? false);
export const selectErrorMessage = createSelector([selectDomain], state => state?.message);
