import React from 'react';
import { RatingConfiguration } from '../Config/constants';
import { getRatingTitle } from '../Config/helper';

const LegendsPreview = ({ ratingId, width }) => {
  const selectedRating = RatingConfiguration?.find(item => item.id === ratingId);
  const colorPanelArray: any = selectedRating ? selectedRating?.color : [];
  const ratingTitle = getRatingTitle(ratingId, true);
  return (
    <>
      {colorPanelArray.map((itemColor, index) => {
        return (
          <span
            key={index}
            style={{
              background: itemColor,
              width: '15px',
              height: '15px',
              display: 'inline-block',
              marginLeft: '2px',
            }}
          />
        );
      })}

      <span
        style={{
          marginLeft: '10px',
          fontSize: '14px',
          width: width ? width : '151px',
          justifyContent: 'flex-end',
        }}
      >
        {ratingTitle}
      </span>
    </>
  );
};

export default LegendsPreview;
