import { contactPreferenceChoice, IClientContactDetails } from 'app/models/ClientContact/ClientContact';
import { formatActionValues, formatUnitValues } from './FormatData';
import states from 'app/assets/jsons/State.json';
import { formatPhoneNumbers } from '@AMIEWEB/Facility/FacilityDetails/helper';

export const formatUpdateClientContactDetails = (
  clientContactData,
  formData,
  facilityDetails,
  contactStatusChoice,
  actionValues,
  selectedUnits,
  selectedClientContactDetails: IClientContactDetails,
) => {
  clientContactData.phoneNumbers = formData?.phoneNumbers?.length > 0 ? formatPhoneNumbers(formData?.phoneNumbers, true) : [];
  clientContactData.facilityID = facilityDetails.facilityId;
  clientContactData.contactStatusId = contactStatusChoice.find(s => s.Description === formData?.contactStatus).Id;
  clientContactData.doNotCall = clientContactData?.contactPreferenceTypeId === 3;
  clientContactData.contactPreference = contactPreferenceChoice?.find(
    x => x.Id === clientContactData?.contactPreferenceTypeId,
  )?.Description;
  clientContactData.actions = formatActionValues(actionValues);
  clientContactData.units = formatUnitValues(selectedUnits);
  clientContactData.timestamp = selectedClientContactDetails?.timestamp;
  clientContactData.stateProvinceID = clientContactData?.country === 'USA' || clientContactData?.country === 'Canada' ? states.states.find(e => e.Description === clientContactData?.state)?.ID : clientContactData?.state;
  delete clientContactData.state;
};
