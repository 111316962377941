/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { PersonalInfoWrapper } from './PersonalInfoWrapper';
import { Theme, Grid } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { candidateDetailsSelection, selectPersonalInfoError } from '../../../Profile/CandidateDetails.selector';
import { useDispatch, useSelector } from 'react-redux';
import {
  beforeSubmitValidate,
  IsDateValid,
  processDataUpdate,
  setValues,
  processAddressData,
} from './PersonalInfoHelper';
import { selectUser } from 'oidc/user.selectors';
import { useTranslation } from 'react-i18next';
import { candidateDetailActions } from '../../../Profile/CandidateDetails.redux';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import _ from 'lodash';
import { CustomInfoConfirmation } from 'app/components/Notification/Common/CloseDialog';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import treeData from 'app/assets/jsons/placementStatusFilter.json';

import { ethnicityOptionsSelector } from 'store/redux-store/lookup/lookup.selectors';
import { IEditPersonalInfoCategory } from 'app/enums/PersonalInfo';
import { IPersonalInfoNonFormFields } from 'app/models/Candidate/CandidateProfile';

import { IsNameDobEditable } from './helper';

const useStyles = makeStyles()((theme: Theme) => ({
  dialogContainer: {
    minWidth: '960px !important',
    minHeight: 'calc(100% - 64px) !important',
  },
  dialogContent: {
    padding: 0,
    overflow: 'hidden',
  },
  MuiCircularProgressRoot: {
    left: '50%',
    position: 'absolute',
    top: '50vh',
  },
}));

export const processSsn = (ssn: any) => {
  const processedSsn = ssn?.replace(/ /g, '-');
  return processedSsn;
};

export const PersonalInfoForm = props => {
  const { open, handleClose, defaultTabIndex = 0, onSuccessfullSave = null, fromAssociateCandidate = false } = props;
  const { classes } = useStyles();
  const candidateDetailsSel = useSelector(candidateDetailsSelection);
  const raceEthnicityOptions = useSelector(ethnicityOptionsSelector);
  const emergencyContact = candidateDetailsSel.emergencyContact;
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cancel, setCancel] = useState<boolean>(false);
  const [isContactEdited, setIsContactEdited] = useState(false);
  const [isRoleTypeAdded, setIsRoleTypeAdded] = useState(false);
  const [contactDataClone, setContactDataClone] = useState<any>();
  const [setInfoMessage, setShowInfoMessage] = useState(false);
  const [isSSNViewableByUser, setIsSSNViewableByUser] = useState<boolean>(false);
  const [isSSNEditableByUser, setIsSSNEditableByUser] = useState<boolean>(false);
  const [isDOBViewableByUser, setIsDOBViewableByUser] = useState<boolean>(false);
  const [isDOBEditableByUser, setIsDOBEditableByUser] = useState<boolean>(false);
  const [isNameEditableByUser, setIsNameEditableByUser] = useState<boolean>(false);
  const [isGenderViewableByUser, setIsGenderViewableByUser] = useState<boolean>(true);
  const [isNUIDEditableByUser, setIsNUIDEditableByUser] = useState<boolean>(false);
  const [isGenderEditableByUser, setIsGenderEditableByUser] = useState<boolean>(false);
  const [isApplicationQuestionsEditable, setIsApplicationQuestionsEditable] = useState<boolean>(false);
  const [canSelectDeclineToState, setCanSelectDeclineToState] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const personalInfoError = useSelector(selectPersonalInfoError);
  const placementStatuses = treeData.filter(
    item => item.name === 'In Submission' || item.name === 'Offer' || item.name === 'No Active Placements',
  );

  const allowedStatuses: any[] =
    placementStatuses?.flatMap(status => status?.children?.map(child => child?.name)) || [];

  //you can populate the defaults fields
  const formMethods = useForm({ mode: 'all' });
  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    setError,
    formState: { dirtyFields, errors, touched },
  } = formMethods;

  useEffect(() => {
    populateFormFiels(candidateDetailsSel);
    if (!open) {
      reset(candidateDetailsSel);
      setIsContactEdited(false);
    }
    setContactDataClone(candidateDetailsSel.phoneNumbers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  //This method will populate the form fields
  const populateFormFiels = candidateDetailsSel => {
    if (candidateDetailsSel) {
      setValues(setValue, IEditPersonalInfoCategory.PersonalInfo, {
        ...candidateDetailsSel,
        socialSecurityNumber: isSSNEditableByUser
          ? candidateDetailsSel.socialSecurityNumber?.replace(/-/g, '')
          : isSSNViewableByUser
          ? `*****${candidateDetailsSel.socialSecurityNumber?.replace(/-/g, '').toString().slice(-4)}`
          : '*********',
        gender: isGenderEditableByUser
          ? candidateDetailsSel.gender
          : isGenderViewableByUser
          ? candidateDetailsSel.gender
          : '*****',
      });
      setValues(setValue, IEditPersonalInfoCategory.ContactInfo, candidateDetailsSel);
      setValues(setValue, IEditPersonalInfoCategory.EmergencyContact, candidateDetailsSel);
      setValues(setValue, IEditPersonalInfoCategory.ApplicationQuestions, candidateDetailsSel);
    }
  };

  useEffect(() => {
    //Check for View Access
    setIsSSNViewableByUser(
      Authorized(
        [
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
          userRoles.recruitment_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_Director,
          userRoles.clinical_Manager,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.clinical_ReviewAnalyst,
          userRoles.payrollSpecialist,
          userRoles.housingAccountExecutive,
          userRoles.timeProcessingSpecialist,
          userRoles.workersCompCoordinator,
          userRoles.riskManagement,
          userRoles.complianceAuditSpecialist,
          userRoles.benefitsCoordinator,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
        ],
        user.userInfo,
      ),
    );

    setIsDOBViewableByUser(
      Authorized(
        [
          userRoles.accountManagement_Leadership,
          userRoles.accountManagement_TeamMember,
          userRoles.recruitment_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_Director,
          userRoles.clinical_Manager,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.clinical_ReviewAnalyst,
          userRoles.payrollSpecialist,
          userRoles.customerSupport_Leadership,
          userRoles.customerSupport_TeamMember,
          userRoles.housingAccountExecutive,
          userRoles.timeProcessingSpecialist,
          userRoles.workersCompCoordinator,
          userRoles.riskManagement,
          userRoles.complianceAuditSpecialist,
          userRoles.benefitsCoordinator,
        ],
        user.userInfo,
      ),
    );

    setIsGenderViewableByUser(!Authorized([userRoles.clinical_ClientContractSpecialist], user.userInfo));

    const allowEditNameDoBGenderSSN = IsNameDobEditable(
      candidateDetailsSel?.placementStatus || '',
      candidateDetailsSel?.placement?.placementInfo || [],
    );

    const hasRecruitmentRole = Authorized(
      [userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
      user.userInfo,
    );

    const hasCredentialingOrClinicalRole = Authorized(
      [
        userRoles.credentialing_Leadership,
        userRoles.credentialing_TeamMember,
        userRoles.clinical_QualificationsSpecialist,
      ],
      user.userInfo,
    );

    if ((hasRecruitmentRole && allowEditNameDoBGenderSSN) || hasCredentialingOrClinicalRole) {
      setIsDOBEditableByUser(true);
      setIsNameEditableByUser(true);
    } else {
      setIsDOBEditableByUser(false);
      setIsNameEditableByUser(false);
    }

    if (Authorized([userRoles.benefitsCoordinator], user.userInfo)) {
      setIsNameEditableByUser(true);
    }

    if (hasRecruitmentRole && allowEditNameDoBGenderSSN) {
      setIsSSNEditableByUser(true);
    } else {
      setIsSSNEditableByUser(false);
    }

    if (
      Authorized(
        [
          userRoles.credentialing_Leadership,
          userRoles.credentialing_TeamMember,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.payrollSpecialist,
        ],
        user.userInfo,
      )
    ) {
      setIsSSNEditableByUser(true);
    }

    if (
      Authorized(
        [userRoles.recruitment, userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember],
        user.userInfo,
      )
    ) {
      setIsNUIDEditableByUser(true);
    }

    if (allowedStatuses.slice(0, allowedStatuses.length - 2).includes(candidateDetailsSel.placementStatus)) {
      setCanSelectDeclineToState(true);
    }
    if (
      Authorized(
        [
          userRoles.clinical_ClientContractSpecialist,
          userRoles.clinical_QualificationsSpecialist,
          userRoles.clinical_Director,
          userRoles.clinical_Manager,
          userRoles.clinical_ReviewAnalyst,
          userRoles.recruitment_TeamMember,
          userRoles.recruitment_Leadership,
          userRoles.recruitment,
        ],
        user.userInfo,
      )
    ) {
      setIsApplicationQuestionsEditable(true);
    }
    const hasRecruitmentandCredentialRole = Authorized(
      [
        userRoles.recruitment_Leadership,
        userRoles.recruitment_TeamMember,
        userRoles.credentialing_TeamMember,
        userRoles.credentialing_Leadership,
        userRoles.clinical_Manager,
        userRoles.clinical_Director,
      ],
      user.userInfo,
    );
    if (hasRecruitmentandCredentialRole) {
      setIsGenderEditableByUser(true);
      setIsGenderViewableByUser(true);
    } else {
      setIsGenderEditableByUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.userInfo, candidateDetailsSel.placementStatus]);

  /**
   * Method to add non form field values into the form
   */
  const addExistingFieldsToForm = (): IPersonalInfoNonFormFields => {
    return {
      raceEthnicityOptions: raceEthnicityOptions,
      emergencyContactCountryCode: emergencyContact.countryDialingCode,
      emergencyPhoneNumber: emergencyContact.phone ?? '',
    };
  };

  const submitFormData = async data => {
    const res = processDataUpdate(candidateDetailsSel, data, user?.userInfo, addExistingFieldsToForm());
    const addressData = processAddressData(res);
    const isAddressChanged = Object.keys(dirtyFields)?.some(key => key.startsWith('Address'));
    dispatch(
      candidateDetailActions.submitPersonalInfoForm({
        res,
        addressData,
        isAddressChanged,
        fromAssociateCandidate,
        candidateDetailsSel,
        handleClose,
        data,
        setIsLoading,
        populateFormFiels,
      }),
    );
  };

  const checkContactEdit = value => {
    if (value == 'contact') setIsContactEdited(true);
    if (value == 'roleType') setIsRoleTypeAdded(true);
    if (value == 'cancelAddRoleType') setIsRoleTypeAdded(false);
  };

  const handleExpandChange = (expanded: boolean) => setIsExpanded(expanded);

  const handleCancel = (e, reason) => {
    if (reason === 'backdropClick') return;
    if (!_.isEmpty(errors) || (!_.isEmpty(dirtyFields) && !_.isEmpty(touched)) || isContactEdited || isRoleTypeAdded) {
      setCancel(true);
    } else {
      yesAction();
    }
  };

  const noAction = () => {
    setCancel(false);
  };

  const yesAction = () => {
    handleClose();
    setCancel(false);
    const revertNewAddress = _.cloneDeep(candidateDetailsSel.addressData?.filter(x => x.travelerAddressId !== 0));
    const originalAddressData = _.cloneDeep(candidateDetailsSel.originalAddressData);
    if (revertNewAddress && revertNewAddress?.length > 0) {
      dispatch(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetailsSel,
          addressData: originalAddressData ? originalAddressData : revertNewAddress,
          phoneNumbers: contactDataClone,
        }),
      );
    }
    dispatch(candidateDetailActions.setPersonalInfoError([]));
    reset(candidateDetailsSel);
  };

  const onSubmit = async (data, e) => {
    if (!validatePersonalInfoForm(data)) return;
    if (beforeSubmitValidate(data, setError)) {
      await submitFormData(data).then(res => {
        if (onSuccessfullSave) {
          onSuccessfullSave();
        }
      });
    }
  };
  const validatePersonalInfoForm = data => {
    var isValid = true;
    if (data.piNUID?.length === 1) {
      setError('piNUID', {
        type: 'minLength',
        message: 'Minimum 2 characters required',
      });
      isValid = false;
    }
    if (data.piFirstName?.length > 255) {
      setError('piFirstName', {
        type: 'maxLength',
        message: 'Maximum Character Limit Reached 255',
      });
      isValid = false;
    }
    if (data.piMidName?.length > 255) {
      setError('piMidName', {
        type: 'maxLength',
        message: 'Maximum Character Limit Reached 255',
      });
      isValid = false;
    }
    if (data.piLastName?.length > 255) {
      setError('piLastName', {
        type: 'maxLength',
        message: 'Maximum Character Limit Reached 255',
      });
      isValid = false;
    }
    return isValid;
  };

  const onError = (errors, e) => {
    if (errors.MoveIn || errors.MoveOut) {
      const data = getValues(['MoveIn', 'MoveOut']);
      IsDateValid(setError, `MoveIn`, data.MoveIn);
      IsDateValid(setError, `MoveOut`, data.MoveOut);
    }
  };

  useEffect(() => {
    if (candidateDetailsSel) {
      setValues(setValue, 'ContactInfo', candidateDetailsSel);
    }
  }, [candidateDetailsSel, setValue]);

  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <>
            <GenericDialog
              variant={'blue'}
              fullWidth
              classes={{
                paper: classes.dialogContainer,
              }}
              open={open}
              disablePortal
              disableEscapeKeyDown
              dialogTitleProps={{
                text: 'Edit Personal Information',
                closeButton: true,
                expandable: true,
                onExpandChange: handleExpandChange,
              }}
              dialogActions={[
                {
                  onClick: handleCancel,
                  text: 'CANCEL',
                  variant: 'text',
                },
                {
                  text: 'SAVE',
                  variant: 'contained',
                  type: 'submit',
                  disabled:
                    !(Object.keys(errors).length === 0 && errors.constructor === Object) ||
                    personalInfoError.length > 0,
                },
              ]}
              dialogContentProps={{
                classes: { root: classes.dialogContent },
              }}
              onClose={handleCancel}
            >
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <PersonalInfoWrapper
                      checkContactEdit={checkContactEdit}
                      defaultTabIndex={defaultTabIndex}
                      handleShowInfoMessage={() => setShowInfoMessage(true)}
                      isSSNViewableByUser={isSSNViewableByUser}
                      isSSNEditableByUser={isSSNEditableByUser}
                      isDOBViewableByUser={isDOBViewableByUser}
                      isDOBEditableByUser={isDOBEditableByUser}
                      isNameEditableByUser={isNameEditableByUser}
                      isGenderViewableByUser={isGenderViewableByUser}
                      isGenderEditableByUser={isGenderEditableByUser}
                      isApplicationQuestionsEditable={isApplicationQuestionsEditable}
                      canSelectDeclineToState={canSelectDeclineToState}
                      isExpanded={isExpanded}
                      isNUIDEditableByUser={isNUIDEditableByUser}
                      isLoading={isLoading}
                    />
                  </Grid>
                </Grid>
                {setInfoMessage && (
                  <Grid
                    container
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{ position: 'absolute' }}
                  >
                    <Grid item>
                      <CustomInfoConfirmation
                        handleClose={() => setShowInfoMessage(false)}
                        infoMessage={t('candidate.personalInfoTabLabels.editPersonalInformation.editPhoneNumbers')}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            </GenericDialog>
            {cancel && <Cancel openDialog={cancel} handleConfirmAction={yesAction} handleCancelAction={noAction} />}
          </>
        </form>
      </FormProvider>
    </>
  );
};
