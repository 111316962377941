import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GenericDialog } from 'app/components/Alerts/GenericDialog';
import { Grid, Divider, FormControlLabel, Checkbox, CircularProgress, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { FormProvider, useForm } from 'react-hook-form';
import { Cancel } from 'app/components/Common/CancelModal/Cancel';
import CCCFormIcon from 'app/assets/images/IconImages/clientContact-2.svg';
import _cloneDeep from 'lodash/cloneDeep';
import { useTranslation } from 'react-i18next';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { IClientContactRequest } from '../ContactTab/Store/FacilityContacts.redux';
import { CCFirstName } from './FormFields/CCFirstName';
import { CCLastName } from './FormFields/CCLastName';
import { CCTitle } from './FormFields/CCTitle';
import { CCEmail } from './FormFields/CCEmail';
import { ContactPreference } from './FormFields/ContactPreference';
import { ContactStatus } from './FormFields/ContactStatus';
import { CCPhoneNumber } from './FormFields/CCPhoneNumber';
import { clientContactActions } from './store/ClientContact.redux';
import {
  selectClientContactDetails,
  selectclientContactPhoneNumber,
  selectContactId,
  selectIsClientContactEditModalOpen,
} from './store/ClientContact.selector';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { usePromiseTracker } from 'react-promise-tracker';
import { FacilityUnits } from './FacilityUnits';
import { CCAction } from './FormFields/CCAction';
import { IOptionsList } from 'app/models/Tasks/Tasks';
import {
  formatSelectedClientContact,
  formatSelectedCreateClientContact,
  formatSelectedValues,
} from './Transformers/FormatData';
import {
  contactStatusChoice,
  facilityPhoneType,
  IClientContactDetails,
  IContactTypeOption,
} from 'app/models/ClientContact/ClientContact';
import { formatUpdateClientContactDetails } from './Transformers/DataFormatter';
import { isEmpty } from 'lodash';
import AddressFields from './FormFields/AddressFields';
import { selectFacilityDetailLocation, selectFacilityDetail } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import states from 'app/assets/jsons/State.json';
import { formatPhoneNumbers, getErrorMessages } from '../../helper';
import { getSearchFilterOptions } from 'app/services/SharedServices/SharedServices';
import { Banner } from '@AMIEWEB/Common/Banner/CustomBanner';
import { useIsContactSubmitDisabled } from './helper';
import { ClientContactBanner } from './FormFields/ClientContactBanner';
import CCNotes from './FormFields/CCNotes';

export const clientContactModalStyles = makeStyles()(theme => ({
  modalContainer: {
    '& .MuiDialogContent-root': {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '500px',
        maxHeight: '704px',
      },
    },
    '& .MuiTypography-h6': {
      userSelect: 'none',
      msUserSelect: 'none',
      MozUserSelect: 'none',
      WebkitUserSelect: 'none',
    },
  },
  body: {
    flexDirection: 'row',
  },
  section: {
    margin: '22px 0px',
  },
  sectionHeader: {
    padding: '0px 0px 20px',
    display: 'inline-flex',
  },
  sectionAddressHeader: {
    display: 'inline-flex',
  },
  sectionFields: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: '14px',
  },
  addressSectionFields: {
    display: 'grid',
    marginTop: '10px',
  },
  actionsUnits: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  sectionText: {
    font: 'normal medium 19px Roboto',
    fontWeight: 600,
    fontSize: '18px',
    userSelect: 'none',
    msUserSelect: 'none',
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
  },
  divider: {
    height: '1px',
  },
  validationLoader: {
    position: 'absolute',
    left: 'calc(50% - 20px)',
    top: '50%',
    zIndex: 1,
  },
  doNotCall: {
    marginLeft: '26px',
    whiteSpace: 'nowrap',
    '& .MuiTypography-body1': {
      fontSize: '14px',
    },
    '& .MuiIconButton-colorSecondary:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.0)',
    },
    '& .MuiCheckbox-colorSecondary.Mui-checked:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.0)',
    },
  },
  actionError: {
    color: '#f44336',
    font: 'normal medium 10px Roboto',
    fontWeight: 400,
    fontSize: '12px',
  },
}));

export const CreateClientContactModal = ({
  open,
  facilityDetails,
  handleClose,
}: {
  open: boolean;
  facilityDetails?: FacilityResponse;
  handleClose: () => void;
}) => {
  const contactId = useSelector(selectContactId);
  const { t } = useTranslation();
  const { classes } = clientContactModalStyles();
  const selectedClientContactDetails = useSelector(selectClientContactDetails);
  const facilityLocation = useSelector(selectFacilityDetailLocation);
  const selectIsContactEditModelOpen = useSelector(selectIsClientContactEditModalOpen);
  const facilityDetail = useSelector(selectFacilityDetail);
  const clientContactPhoneNumber = useSelector(selectclientContactPhoneNumber);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [doNotCallIsChecked, setDoNotCallIsChecked] = useState<boolean>(false);
  const [actionValues, setActionValues] = useState<IOptionsList[]>([]);
  const [disableCheck, setDisableCheck] = useState<boolean>(false);
  const [isInValidEmail, setIsInValidEmail] = useState<boolean>(false);
  const [showBannerMessage, setShowBannerMessage] = useState<boolean>(false);
  const [bannerMessage, setBannerMessage] = useState<string>();
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [options, setOptions] = useState<IContactTypeOption[]>([]);
  const [hasEmailRequired, setEmailRequired] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  const { promiseInProgress: isCallingAPI } = usePromiseTracker({ area: 'save-client-contact', delay: 0 });
  const { promiseInProgress: isCallingUpdateAPI } = usePromiseTracker({ area: 'update-client-contact', delay: 0 });
  const { promiseInProgress: isCallingFetchAPI } = usePromiseTracker({ area: 'get-client-contacts-by-id', delay: 0 });
  const dispatch = useDispatch();

  const isApiLoading = isCallingAPI || isCallingUpdateAPI || isCallingFetchAPI;

  const formMethods = useForm({
    mode: 'onSubmit',
  });
  const {
    getValues,
    watch,
    errors,
    handleSubmit,
    trigger,
    setValue,
    reset,
    formState: { dirtyFields },
  } = formMethods;

  const clientContactPhoneNumbers = watch('clientContactPhoneNumbers');

  const submitClientContact = formData => {
    if (selectIsContactEditModelOpen) {
      const clientContactData = _cloneDeep(formData) as IClientContactDetails;
      formatUpdateClientContactDetails(
        clientContactData,
        formData,
        facilityDetails,
        contactStatusChoice,
        actionValues,
        selectedUnits,
        selectedClientContactDetails,
      );
      const editDetails = { contactId: selectedClientContactDetails?.contactId, ...clientContactData };
      dispatch(clientContactActions.updateClientContactDetails(editDetails));
    } else {
      const newClientContact = _cloneDeep(formData) as IClientContactRequest;
      newClientContact.phoneNumbers = formatPhoneNumbers(formData?.phoneNumbers, false);
      newClientContact.facilityId = facilityDetails?.facilityId;
      newClientContact.phoneTypeID = 6;
      newClientContact.extensionNotes = 'note';
      newClientContact.countryDialingCode = '1';
      newClientContact.activeSSC = false;
      newClientContact.roleActionIds = formatSelectedValues(actionValues);
      newClientContact.unitIds = formatSelectedValues(selectedUnits);
      newClientContact.stateProvinceID =
        newClientContact?.country === 'USA' || newClientContact?.country === 'Canada'
          ? states.states.find(e => e.Description === newClientContact?.state)?.ID
          : newClientContact?.state;
      delete newClientContact.state;
      dispatch(clientContactActions.saveClientContact(newClientContact));
    }
    dispatch(clientContactActions.setClientContactPhoneNumber([]));
  };

  const onCloseCall = () => {
    setCancelModalOpen(false);
    dispatch(clientContactActions.setIsClientContactEditModelOpen(false));
    dispatch(clientContactActions.setCreatedContactId(undefined));
    dispatch(clientContactActions.setClientContactDetails(undefined));
    dispatch(clientContactActions.setIsClientContactEditModelOpen(false));
    handleClose();
  };

  const onCloseModal = () => {
    if (!isEmpty(dirtyFields)) {
      setCancelModalOpen(true);
    } else {
      onCloseCall();
    }
    dispatch(clientContactActions.setClientContactPhoneNumber([]));
  };

  useEffect(() => {
    if (selectedClientContactDetails) {
      const contactDetails = formatSelectedClientContact(selectedClientContactDetails, options);
      reset(contactDetails, { isDirty: false });
      if (selectedClientContactDetails?.contactPreferenceTypeId === 3) {
        setDoNotCallIsChecked(true);
      }
    } else if (facilityLocation && !selectIsContactEditModelOpen) {
      const contactDetails = formatSelectedCreateClientContact(facilityLocation);
      reset(contactDetails, { isDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails, facilityLocation, options?.length]);

  useEffect(() => {
    setValue('contactPreference', undefined, { shouldDirty: false });
  }, [setValue]);

  useEffect(() => {
    if (contactId && Number(contactId) > 0) {
      onCloseCall();
    } else if (contactId && Number(contactId) <= 0) {
      dispatch(
        globalActions.setSnackBar({
          message: 'ERROR: Client Contact is not created',
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId]);

  useEffect(() => {
    if (facilityDetails?.facilityId) {
      dispatch(clientContactActions.getFacilityUnits(facilityDetails?.facilityId));
    }
    dispatch(clientContactActions.getActionsList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facilityDetails?.facilityId]);

  const getTypeOptions = async () => {
    const contactLookups = await getSearchFilterOptions('clientPhoneTypes', true);
    const clientContactTypes = contactLookups?.map(item => {
      return {
        ID: parseInt(item?.value),
        Description: item?.name,
      };
    });
    return clientContactTypes;
  };

  const getOptions = async () => {
    const options = await getTypeOptions();
    setOptions(options);
  };

  const checkBannerMessage = () => {
    const phoneType = clientContactPhoneNumber?.some(
      item => item.phoneType === facilityPhoneType.FAX_1 || item.phoneType === facilityPhoneType.FAX_2,
    );
    if (!phoneType) {
      const errorMessage = getErrorMessages(
        facilityDetail,
        actionValues,
        phoneType,
        t,
        selectIsContactEditModelOpen,
        selectedClientContactDetails,
      );
      setBannerMessage(errorMessage);
      return errorMessage ? true : false;
    } else {
      setShowBannerMessage(false);
      return false;
    }
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <FormProvider {...formMethods}>
      <GenericDialog
        className={classes.modalContainer}
        open={open}
        disableEnforceFocus
        variant="blue"
        onClose={(e, reason) => {
          if (reason === 'backdropClick') return;
          onCloseModal();
        }}
        dialogTitleProps={{
          text: selectIsContactEditModelOpen
            ? t('clientContactModal.editModalTitle')
            : t('clientContactModal.modalTitle'),
          expandable: true,
          closeButton: true,
          onExpandChange: expanded => {
            setExpanded(expanded);
          },
          leadingIcon: <img src={CCCFormIcon} alt="form" />,
        }}
        fullWidth
        maxWidth={'md'}
        dialogActions={[
          {
            text: t('clientContactModal.cancelBtn'),
            variant: 'contained',
            color: 'tertiary',
            onClick: onCloseModal,
          },
          {
            text: selectIsContactEditModelOpen ? t('clientContactModal.saveBtn') : t('clientContactModal.submitBtn'),
            variant: 'contained',
            disabled: useIsContactSubmitDisabled(
              dirtyFields,
              selectedUnits,
              actionValues,
              errors,
              getValues(),
              isInValidEmail,
              hasEmailRequired,
            ),
            onClick: async e => {
              const valid = await trigger();
              setShowBannerMessage(true);
              const isValidPhoneType = checkBannerMessage();
              if (valid && !hasEmailRequired && !isInValidEmail && !isValidPhoneType) {
                handleSubmit(submitClientContact)().catch(err => {
                  console.log('ERROR: ', err.message | err);
                });
              }
            },
          },
        ]}
      >
        {showBannerMessage && bannerMessage && (
          <ClientContactBanner
            setShowBannerMessage={setShowBannerMessage}
            bannerMessage={bannerMessage}
            isExpanded={expanded}
          />
        )}
        <Grid item className={classes.body}>
          {isApiLoading && <CircularProgress className={classes.validationLoader} />}
          <Grid item className={classes.section}>
            <Grid item xs={12} className={classes.sectionHeader}>
              <Typography className={classes.sectionText}>
                {t('clientContactModal.subHeaders.personalInformation')}
              </Typography>
            </Grid>
            <Grid item className={classes.sectionFields}>
              <CCFirstName formMethods={formMethods} disabled={isApiLoading} />
              <CCLastName formMethods={formMethods} disabled={isApiLoading} />
              <CCTitle formMethods={formMethods} disabled={isApiLoading} />
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Grid item className={classes.section}>
            <Grid item xs={12} className={classes.sectionHeader}>
              <Grid item xs={12} className={classes.sectionText} style={{ margin: 'auto' }}>
                {t('clientContactModal.subHeaders.preferences')}
              </Grid>
              <Grid item xs={12} className={classes.doNotCall}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={doNotCallIsChecked}
                      onClick={() => setDoNotCallIsChecked(!doNotCallIsChecked)}
                      disabled={disableCheck || isApiLoading}
                      disableFocusRipple={true}
                      disableTouchRipple={true}
                      disableRipple={true}
                    />
                  }
                  label={t('clientContactModal.doNotCall')}
                />
              </Grid>
            </Grid>
            <Grid item className={classes.sectionFields}>
              <ContactPreference
                formMethods={formMethods}
                doNotCallIsChecked={doNotCallIsChecked}
                setDisableCheck={setDisableCheck}
                disabled={isApiLoading}
                actionValues={actionValues}
                setShowBannerMessage={setShowBannerMessage}
                setBannerMessage={setBannerMessage}
              />
              <ContactStatus formMethods={formMethods} disabled={isApiLoading} />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item className={classes.section}>
            <Grid item xs={12} className={classes.sectionHeader}>
              <Typography className={classes.sectionText}>
                {t('clientContactModal.subHeaders.summary')}
              </Typography>
            </Grid>
            <Grid container direction="column">
              <CCNotes
                controllerName={`notes`}
                notesLabel={t('clientContactModal.subHeaders.summary')}
                maxRows={3}
                disabled={isApiLoading}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item className={classes.section}>
            <Grid item xs={12} className={classes.sectionHeader}>
              <Typography className={classes.sectionText}>
                {t('clientContactModal.subHeaders.contactDetails')}
              </Typography>
            </Grid>
            <Grid container direction="column">
              <CCPhoneNumber options={options} />
              <CCEmail
                formMethods={formMethods}
                setIsInValidEmail={setIsInValidEmail}
                isInValidEmail={isInValidEmail}
                disabled={isApiLoading}
                actionValues={actionValues}
                setShowBannerMessage={setShowBannerMessage}
                hasEmailRequired={hasEmailRequired}
                setEmailRequired={setEmailRequired}
                dirtyFields={dirtyFields}
              />
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item className={classes.addressSectionFields}>
            <Grid item xs={12} className={classes.sectionAddressHeader}>
              <Typography className={classes.sectionText}>
                {t('clientContactModal.subHeaders.address')}
              </Typography>
            </Grid>
            <AddressFields
              disabled={isApiLoading}
              facilityLocation={facilityLocation}
              editMode={selectIsContactEditModelOpen}
              formMethods={formMethods}
            />
          </Grid>
          <Divider className={classes.divider} />
          <Grid item className={classes.section}>
            <Grid container spacing={4} className={classes.actionsUnits}>
              <Grid item xs={6} className={classes.sectionHeader}>
                <Grid item className={classes.sectionText} style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {t('clientContactModal.subHeaders.actions')}
                </Grid>
              </Grid>
              <Grid item xs={6} className={classes.sectionHeader}>
                <Grid item className={classes.sectionText}>
                  {t('clientContactModal.subHeaders.units')}
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={8} className={classes.actionsUnits} style={{ padding: '6px 6px' }}>
              <Grid item xs={6}>
                <CCAction selectActionValues={setActionValues} disabled={isApiLoading} />
              </Grid>
              <Grid item xs={6}>
                <FacilityUnits setSelectedUnits={setSelectedUnits} disabled={isApiLoading} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </GenericDialog>
      <Cancel
        openDialog={cancelModalOpen}
        handleConfirmAction={() => onCloseCall()}
        handleCancelAction={() => {
          setCancelModalOpen(false);
        }}
      />
    </FormProvider>
  );
};
