import { OrderConstants } from 'app/constants/OrderCreationForm';
import { unformatPhoneNumber } from 'app/helpers/phoneHelpers';
import { ActionDescription, facilityDeliveryType, IClientContactDetails, IClientPhoneNumber, IContactTypeOption, IFormClientPhoneNumber } from 'app/models/ClientContact/ClientContact';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { IOptionsList } from 'app/models/Tasks/Tasks';

export const calculateIsCreditHold = (creditStatus: string | undefined) => {
  return creditStatus === OrderConstants.STATUS_ON_HOLD;
};

export const calculateIsCreditPending = (creditStatus: string | undefined) => {
  return creditStatus === OrderConstants.STATUS_PENDING;
};

export const getAddress = (location: { address1: string; city: string; state: string; zipCode: string }) => {
  let data = '';
  if (location?.zipCode) data = location.city + ', ' + location.state + ', ' + location.zipCode;
  else data = location?.city + ', ' + location?.state;
  return {
    addressLine1: location?.address1,
    addressLine2: data,
  };
};

export const calculateIsMaxBookingLimit = (bookingLimit: number, openOrderCount: number) => {
  return bookingLimit !== null && bookingLimit >= openOrderCount;
};

export const getValue = (value: boolean | null) => {
  if (value) return 'Y';
  if (value === false) return 'N';
  return '-';
};

export const getCreditCheck = (creditStatus: string) => {
  let isCreditHold = calculateIsCreditHold(creditStatus);
  let isCreditPending = calculateIsCreditPending(creditStatus);
  return getValue(isCreditHold || isCreditPending);
};

export const NOTES_CHAR_LIMIT = 255;

/**
 * Method to register the non form field values for client phoneNumber
 */
export const registerNonFormFieldValuesForPhoneNumber = (
  register,
  setValue,
  phoneNumberFormArray,
  item: { timeStamp: string; softDelete: boolean; phoneTypeId: number; countryDialingCode: string; phoneType: string },
) => {
  register(`${phoneNumberFormArray}.timeStamp`);
  register(`${phoneNumberFormArray}.softDelete`);
  register(`${phoneNumberFormArray}.phoneTypeId`);
  register(`${phoneNumberFormArray}.countryDialingCode`);
  register(`${phoneNumberFormArray}.phoneType`);
  setValue(`${phoneNumberFormArray}.timeStamp`, item?.timeStamp);
  setValue(`${phoneNumberFormArray}.softDelete`, item?.softDelete);
  setValue(`${phoneNumberFormArray}.phoneTypeId`, item?.phoneTypeId);
  setValue(`${phoneNumberFormArray}.countryDialingCode`, item?.countryDialingCode);
  setValue(`${phoneNumberFormArray}.phoneType`, item?.phoneType);
};

export const formatNumberWithoutCountryCode = (phoneNumber: string, countryCode: string): string => {
  const phoneNumberLength = phoneNumber?.match(/\d/g)?.length - countryCode?.length;
  const number = phoneNumber?.replace(/\D/g, '')?.slice(-phoneNumberLength);
  const areaCode = number?.slice(0, 3);
  const centralOfficeCode = number?.slice(3, 6);
  const lineNumber = number?.slice(6, 10);
  return `(${areaCode}) ${centralOfficeCode}-${lineNumber}`;
};

export const formatPhoneNumbers = (phoneNumbers: IFormClientPhoneNumber[], isUpdate: boolean) => {
  const formattedPhoneNumbers = phoneNumbers?.map(phone => {
    let formattedNumber = '';
    if (phone?.phoneNumber) {
      formattedNumber = formatNumberWithoutCountryCode(phone?.phoneNumber, phone?.countryDialingCode);
    }
    const formattedPhone = {
      countryDialingCode: phone.countryDialingCode,
      phoneNumber: !phone.softDelete ? formattedNumber : null,
      extensionNotes: phone.extensionNotes,
      phoneTypeId: phone.phoneTypeId,
    };
    if (isUpdate) {
      formattedPhone['timeStamp'] = phone.timeStamp;
    }
    return formattedPhone;
  });
  return formattedPhoneNumbers;
};

export const getDefaultPhoneNumber = (newPhoneTypeId: number, options: IContactTypeOption[]) => {
  return {
    phoneTypeId: newPhoneTypeId,
    phoneNumber: '',
    countryDialingCode: '',
    extensionNotes: '',
    timeStamp: '',
    phoneType: getPhoneType(newPhoneTypeId, options),
    softDelete: false,
  };
};

export const getExistingPhoneNumbers = (phoneNumbers: IClientPhoneNumber[], options: IContactTypeOption[]) => {
  const clientPhoneNumbers = phoneNumbers?.map(phone => ({
    countryDialingCode: phone?.countryDialingCode,
    phoneNumber: phone?.countryDialingCode + unformatPhoneNumber(phone?.phoneNumber),
    extensionNotes: phone?.extensionNotes,
    phoneTypeId: phone?.phoneTypeId,
    timeStamp: phone?.timestamp,
    phoneType: getPhoneType(phone?.phoneTypeId, options),
    softDelete: false,
  }));
  return clientPhoneNumbers;
};

export const getPhoneType = (phoneTypeId: number, options: IContactTypeOption[]) => {
  return options?.find(x => x?.ID === phoneTypeId)?.Description;
};

export const emailFieldRequiredCheck = (facilityDetails: FacilityResponse, actionValue: IOptionsList[], hasContactEditModelOpen: boolean, clientContactDetails: IClientContactDetails) => {

  const facilityDeliveryMethodIds = {
    confirmationDeliveryMethodId: hasContactEditModelOpen ? clientContactDetails?.confirmationDeliveryTypeID : facilityDetails?.confirmationDeliveryMethodId,
    evaluationDeliveryMethodId: hasContactEditModelOpen ? clientContactDetails?.evaluationDeliveryTypeID : facilityDetails?.evaluationDeliveryMethodId,
    fileDeliveryMethodId: hasContactEditModelOpen ? clientContactDetails?.fileDeliveryTypeID : facilityDetails?.fileDeliveryMethodId,
  };

  const isEmailDelivery = (deliveryMethodId: number) => {
    return deliveryMethodId === facilityDeliveryType.EmailHtml || deliveryMethodId === facilityDeliveryType.EmailPdf;
  };

  const requiredActions = [
    ActionDescription.SEND_FILE_TO,
    ActionDescription.SEND_WRITTEN_MATERIALS_REQUIREMENTS_TO,
    ActionDescription.SEND_MEDICAL_REQUIREMENTS_TO,
    ActionDescription.SEND_CONFIRMATION_TO,
    ActionDescription.SEND_EVALUATION_TO
  ];

  const hasRequiredAction = actionValue?.some(action =>
    requiredActions.includes(action?.description as ActionDescription || action?.value as ActionDescription)
  );

  if (hasRequiredAction) {
    for (let action of actionValue) {
      const actionDescription = action?.description || action?.value;

      if (
        (actionDescription === ActionDescription.SEND_FILE_TO ||
          actionDescription === ActionDescription.SEND_WRITTEN_MATERIALS_REQUIREMENTS_TO ||
          actionDescription === ActionDescription.SEND_MEDICAL_REQUIREMENTS_TO) &&
        isEmailDelivery(facilityDeliveryMethodIds.fileDeliveryMethodId)
      ) {
        return true;
      } else if (
        actionDescription === ActionDescription.SEND_CONFIRMATION_TO &&
        isEmailDelivery(facilityDeliveryMethodIds.confirmationDeliveryMethodId)
      ) {
        return true;
      } else if (
        actionDescription === ActionDescription.SEND_EVALUATION_TO &&
        isEmailDelivery(facilityDeliveryMethodIds.evaluationDeliveryMethodId)
      ) {
        return true;
      }
    }
  }

  return false;
};


export const getErrorMessages = (facilityDetails: FacilityResponse, actionDetails: IOptionsList[], phoneType, t, hasContactEditModelOpen: boolean, clientContactDetails: IClientContactDetails) => {

  const facilityDeliveryMethodIds = {
    confirmationDeliveryMethodId: hasContactEditModelOpen ? clientContactDetails?.confirmationDeliveryTypeID : facilityDetails?.confirmationDeliveryMethodId,
    evaluationDeliveryMethodId: hasContactEditModelOpen ? clientContactDetails?.evaluationDeliveryTypeID : facilityDetails?.evaluationDeliveryMethodId,
    fileDeliveryMethodId: hasContactEditModelOpen ? clientContactDetails?.fileDeliveryTypeID : facilityDetails?.fileDeliveryMethodId,
  };

  const isEmailPdfDelivery = (deliveryMethodId) => deliveryMethodId === facilityDeliveryType.Fax;

  const requiredActions = [
    ActionDescription.SEND_FILE_TO,
    ActionDescription.SEND_WRITTEN_MATERIALS_REQUIREMENTS_TO,
    ActionDescription.SEND_MEDICAL_REQUIREMENTS_TO,
    ActionDescription.SEND_CONFIRMATION_TO,
    ActionDescription.SEND_EVALUATION_TO,
  ];

  const hasRequiredAction = actionDetails?.some(action =>
    requiredActions.includes(action?.description as ActionDescription || action?.value as ActionDescription)
  );

  if (hasRequiredAction && !phoneType) {
    for (let action of actionDetails) {
      const actionDescription = action?.description || action?.value;

      const constructErrorMessage = (actionType) => {
        return t('clientContactModal.PhoneTypeErrorMessage', { actionDescription, actionType });
      };

      if (
        [ActionDescription.SEND_FILE_TO, ActionDescription.SEND_WRITTEN_MATERIALS_REQUIREMENTS_TO, ActionDescription.SEND_MEDICAL_REQUIREMENTS_TO].includes(actionDescription as ActionDescription) &&
        isEmailPdfDelivery(facilityDeliveryMethodIds.fileDeliveryMethodId)
      ) {
        return constructErrorMessage(t('clientContactModal.filePacket'));
      }

      if (actionDescription === ActionDescription.SEND_CONFIRMATION_TO && isEmailPdfDelivery(facilityDeliveryMethodIds.confirmationDeliveryMethodId)) {
        return constructErrorMessage(t('clientContactModal.confirmation'));
      }

      if (actionDescription === ActionDescription.SEND_EVALUATION_TO && isEmailPdfDelivery(facilityDeliveryMethodIds.evaluationDeliveryMethodId)) {
        return constructErrorMessage(t('clientContactModal.evaluation'));
      }
    }
  }
};
export const extractIndexFromControllerName = (controllerName: string): number => {
  const match = controllerName.match(/\[(\d+)\]/);
  return match ? parseInt(match[1], 10) : -1;
};
export const isValidClientContactEmail = (email: string): boolean => {
  if (email === '') return true;

  const validCharsRegex = /^[0-9A-Za-z&;./@_'-]+$/;
  const atIndex = email?.indexOf('@');
  const dotIndexFromEnd = email?.lastIndexOf('.');

  return (
    email?.indexOf(' ') === -1 &&
    validCharsRegex?.test(email) &&
    email?.split('@')?.length === 2 &&
    atIndex > 0 &&
    dotIndexFromEnd > atIndex + 1 &&
    email?.length - dotIndexFromEnd >= 3 &&
    dotIndexFromEnd !== email?.length - 1
  );
};
