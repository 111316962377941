import React, { useEffect, useState } from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup } from 'amn-ui-core';
import { ControlledTypeAhead } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PreferencesTab/CustomComponents/ControlledTypeAhead';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import UnitCollapsible from '@AMIEWEB/Unit/Common/UnitCollapsible';
import { useDispatch, useSelector } from 'react-redux';
import { selectOrderRequirementTypes } from '@AMIEWEB/Order/Store/OrderCreation.selectors';
import { selectOrderSubCertification } from '@AMIEWEB/Order/Store/Order.selectors';
import { orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';
import { Certifications } from 'app/models/Orders/OrderDetails';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { AndOrOperator, AndOrText, CollectionType } from 'app/enums/Common';
import { CustomTooltip } from '@AMIEWEB/Common';
import { SubCertificateUnitTypeAheadDropdown } from './SubCertificate/SubCertificateUnitTypeAhead';
import { addCertificateHelper } from '../../UnitDetailDataTransformer';
import {
  selectSubCert,
  selectTransformedIssuedBy,
} from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.selector';
import { RequirementType } from 'app/enums/Facility';
import { validateDuplicateCertification } from '../../UnitDetailsFormValidator';
import { FacilityUnitActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { handleDeleteSubCert } from './SubCertificate/Helper';
import { radioCertificationTileStyles, useCertificationTileStyles } from './Experience.styles';
import { FormPageType } from './SubCertificate/SubCertificate.types';

export const CertificationsTile = ({
  skillSetIndex,
  groupIndex,
  skillSetField,
  duplicateCetificateData,
  canAddCertificate,
  setCanAddCertificate,
  numberOfSkillSets,
  setDuplicateCetificateData,
}) => {
  const { control } = useFormContext();
  const { classes } = useCertificationTileStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { certifications } = useSelector(selectOrderRequirementTypes);
  const subCertificationList = useSelector(selectOrderSubCertification);
  const [cerificationRequirements, setCerificationRequirements] = useState<any[]>([]);
  const [formattedSubCertificationList, setFormattedSubCertificationList] = useState<any[]>([]);
  const issuedByOptions = useSelector(selectTransformedIssuedBy);
  const [dataList, setDataList] = useState([]);
  const [isCertSelected, setIsCertSelected] = useState(false);
  const {
    fields: requirementFields,
    append: appendRequirement,
    remove: removeRequirement,
  } = useFieldArray({
    control,
    name: `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements`,
  });
  const [numberOfSubCert, setNumberOfSubCert] = useState<number>(0);
  const [recordAdded, setRecordAdded] = useState<boolean>(requirementFields[0]?.certificates !== null);

  useEffect(() => {
    if (canAddCertificate && skillSetIndex === numberOfSkillSets - 1) {
      if (duplicateCetificateData) {
        addCertificateHelper(duplicateCetificateData, appendRequirement);
      } else {
        addCertificate();
      }
    }
    setCanAddCertificate(false);
    setDuplicateCetificateData(null);
  }, [canAddCertificate]);

  const defaultCertificateValue = {
    documentAttachmentRequired: false,
    isRequired: false,
    certificates: null,
    subCertificate: null,
    issuedBy: null,
  };

  const getSelectionInfo = (searchQuery, options) => {
    if (!Array.isArray(options)) return [];
    const searchResult =
      searchQuery && searchQuery?.length >= 2
        ? options?.filter(item =>
            `${item.abbreviation} - ${item.credentialName}`
              .toLowerCase()
              .replace(/\s|\.|\(|\)/g, '')
              .includes(searchQuery.toLowerCase().replace(/\s|\.|\(|\)/g, '')),
          )
        : options;
    if (searchQuery?.toUpperCase() === 'CERT') {
      const indexToMove = searchResult?.findIndex(obj => obj?.requirementTypeID === Certifications.CERT_CERTIFICATE);

      if (indexToMove !== -1) {
        const objectToMove = searchResult?.splice(indexToMove, 1)[0];

        searchResult?.unshift(objectToMove);
      }
    }
    return searchResult;
  };

  const parseCertifications = () => {
    const certs = [];
    const subCerts = [];
    certifications?.map(rt => {
      rt.category ? certs.push(rt) : subCerts.push(rt);
    });
    dispatch(orderDataActions.setsubCertificationList(subCerts));
    if (Array.isArray(certs) && certs?.length > 0) {
      setCerificationRequirements(getSelectionInfo('', certs));
    }
  };

  useEffect(() => {
    if (certifications?.length > 0) {
      parseCertifications();
    }
  }, [certifications]);

  useEffect(() => {
    const formattedCertificationList = [];
    if (cerificationRequirements?.length > 0) {
      cerificationRequirements?.forEach(e => {
        const eachObject = {
          label: e.credentialName ? `${e.abbreviation} - ${e.credentialName}` : `${e.abbreviation}`,
          object: {
            ...e,
            id: e.requirementTypeID ?? e.requirementTypeId,
            certificationName: e.credentialName ? `${e.abbreviation} - ${e.credentialName}` : `${e.abbreviation}`,
          },
        };
        formattedCertificationList.push(eachObject);
      });
    }

    setDataList(formattedCertificationList);
  }, [cerificationRequirements]);

  useEffect(() => {
    const formattedList = subCertificationList?.map(s => {
      return {
        name: s?.abbreviation,
        id: s?.requirementTypeID,
        value: s?.abbreviation,
      };
    });
    setFormattedSubCertificationList(formattedList);
  }, [subCertificationList]);

  const addCertificate = () => {
    if (!recordAdded) {
      setRecordAdded(true);
    } else {
      appendRequirement(defaultCertificateValue);
    }
  };

  const removeCertificate = index => {
    removeRequirement(index);
    if (recordAdded && requirementFields.length === 0) {
      setRecordAdded(false);
    }
  };
  return (
    <Grid item xs={12}>
      <UnitCollapsible
        title={t('facility.units.unitDetails.unitRequirements.certifications', {
          certificationCount: recordAdded ? requirementFields.length : 0,
        })}
        iconToolTipContent={t('facility.units.unitDetails.unitRequirements.addCertificate')}
        onClickHandler={addCertificate}
        fieldCount={recordAdded ? requirementFields.length : 0}
        isCollapsible={requirementFields?.length > 0}
        children={
          <Grid container>
            {(requirementFields?.length > 1 ||
              (requirementFields?.length === 1 && isCertSelected && numberOfSubCert > 1)) && (
              <Grid item xs={1} alignSelf={'center'}>
                <Controller
                  name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].operation`}
                  control={control}
                  defaultValue={
                    skillSetField.collections[CollectionType.CertificationsIndex].operation
                      ? skillSetField.collections[CollectionType.CertificationsIndex].operation
                      : AndOrOperator.And
                  }
                  render={({ ref, onChange, ...rest }) => (
                    <RadioGroup
                      value={rest.value}
                      onChange={onChange}
                      sx={{ alignContent: 'center', minWidth: '75px' }}
                    >
                      <FormControlLabel
                        value={AndOrOperator.And}
                        control={<Radio sx={radioCertificationTileStyles} disableRipple disableFocusRipple />}
                        label={AndOrText.And}
                      />
                      <FormControlLabel
                        value={AndOrOperator.Or}
                        control={<Radio sx={radioCertificationTileStyles} disableRipple disableFocusRipple />}
                        label={AndOrText.Or}
                      />
                    </RadioGroup>
                  )}
                />
              </Grid>
            )}
            <Grid
              item
              xs={
                requirementFields?.length > 1 ||
                (requirementFields?.length === 1 && isCertSelected && numberOfSubCert > 1)
                  ? 11
                  : 12
              }
              className={classes.certificateWrapper}
            >
              {requirementFields?.map((requirementField, index) => {
                return (
                  <Grid key={requirementField.id}>
                    <CertificationsInfo
                      dataList={dataList}
                      formattedSubCertificationList={formattedSubCertificationList}
                      requirementIndex={index}
                      skillSetIndex={skillSetIndex}
                      removeCertificate={removeCertificate}
                      appendRequirement={appendRequirement}
                      groupIndex={groupIndex}
                      requirementField={requirementField}
                      issuedByOptions={issuedByOptions}
                      isCertSelected={isCertSelected}
                      setIsCertSelected={setIsCertSelected}
                      setNumberOfSubCert={setNumberOfSubCert}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};
const CertificationsInfo = ({
  dataList,
  formattedSubCertificationList,
  requirementIndex,
  skillSetIndex,
  removeCertificate,
  groupIndex,
  requirementField,
  issuedByOptions,
  isCertSelected,
  setIsCertSelected,
  setNumberOfSubCert,
  appendRequirement,
}) => {
  const { classes } = useCertificationTileStyles();
  const { t } = useTranslation();
  const {
    control,
    register,
    setValue,
    errors,
    trigger,
    watch,
    clearErrors,
    formState: { isSubmitted },
  } = useFormContext();
  const [filteredIssuedBy, setFilteredIssuedBy] = React.useState([]);
  const dispatch = useDispatch();
  const selectedSubCerts = useSelector(selectSubCert);

  const requirementValue = watch(
    `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements`,
  );

  const requirementError =
    errors?.skillSetGroup?.groups?.[groupIndex]?.skillsets?.[skillSetIndex]?.collections?.[
      CollectionType.CertificationsIndex
    ]?.requirements?.[requirementIndex];

  const collectionsErrors = errors?.skillSetGroup?.groups?.[groupIndex]?.skillsets?.[skillSetIndex]?.collections || {};
  const requirementsErrorValue = collectionsErrors[CollectionType.CertificationsIndex]?.requirements || [];
  const subCertificateError =
    (requirementValue?.length &&
      requirementValue[requirementIndex]?.certificates?.object?.requirementTypeID === RequirementType.cert &&
      requirementsErrorValue[requirementIndex]?.subCertificate) ||
    false;
  const setFieldValue = (fieldName, value) => {
    setValue(
      `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements[${requirementIndex}].${fieldName}`,
      value,
    );
  };
  const filterIssuedBy = (id: number, disableFields) => {
    if (disableFields) {
      setFieldValue('issuedBy', null);
    }
    const filteredIssuedByValues = issuedByOptions.filter(each => each.object.certificationRequirementTypeId === id);
    return filteredIssuedByValues || [];
  };

  const isCertificateCERT =
    requirementValue?.[requirementIndex]?.certificates?.object?.requirementTypeID === RequirementType.cert;

  useEffect(() => {
    if (requirementField.certificates) {
      setFilteredIssuedBy(filterIssuedBy(requirementField.certificates.object.requirementTypeID, false));
      if (requirementField.certificates.object.requirementTypeID === RequirementType.cert) {
        setIsCertSelected(true);
        setNumberOfSubCert(requirementValue?.[requirementIndex]?.subCertificate?.length);
      }
    }
  }, []);

  const removeSubCertFromGlobal = () => {
    const currentRow = watch(
      `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements[${requirementIndex}]`,
    );
    const allSelectedSubCerts = handleDeleteSubCert(
      selectedSubCerts,
      currentRow?.subCertificate?.[0]?.subCertificate?.id,
      skillSetIndex,
    );
    dispatch(FacilityUnitActions.setSelectedSubCert(allSelectedSubCerts));
  };

  return (
    <Grid container xs={12}>
      <Grid container gap={2} xs={11.65}>
        <Grid item xs={2.5}>
          <ControlledTypeAhead
            register={register}
            name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements[${requirementIndex}].certificates`}
            label={t('facility.units.unitDetails.unitRequirements.certificate')}
            control={control}
            options={dataList}
            onChange={value => {
              if (value?.object?.requirementTypeID !== RequirementType.cert) {
                removeSubCertFromGlobal();
                setFieldValue('subCertificate', []);
                setFieldValue('isRequired', true);

                setIsCertSelected(false);
              } else {
                if (!requirementValue?.[requirementIndex]?.subCertificate?.[0]?.subCertificate?.id) {
                  setFieldValue('isRequired', false);
                }
                setIsCertSelected(true);
              }
              if (value?.object?.requirementTypeID !== filteredIssuedBy[0]?.certificationRequirementTypeId) {
                setFilteredIssuedBy(filterIssuedBy(value?.object?.requirementTypeID, true));
              }
              if (isSubmitted) {
                trigger();
              }
              clearErrors(
                `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements[${requirementIndex}].certificates`,
              );
            }}
            customScroll
            disabled={false}
            id={'unitExperienceCertificate'}
            showDropdownIcon={true}
            defaultValue={requirementField.certificates}
            isHelperText={true}
            isNestedObject={true}
            isError={Boolean(requirementError?.certificates)}
            helperText={
              requirementError?.certificates
                ? `${t('facility.units.unitDetails.unitRequirements.duplicated')} ${t(
                    'facility.units.unitDetails.unitRequirements.certificate',
                  )}`
                : ''
            }
            validateTypeAhead={() =>
              validateDuplicateCertification(
                requirementIndex,
                watch(
                  `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements`,
                ),
                t,
              )
            }
          />
        </Grid>
        <Grid
          item
          xs={2.5}
          className={`${classes.subCertificate} ${
            Boolean(requirementError?.subCertificate) &&
            (subCertificateError || requirementValue?.[requirementIndex]?.subCertificate?.length)
              ? classes.subCertificateError
              : ''
          }`}
          sx={{ cursor: !isCertificateCERT ? 'default' : 'not-allowed' }}
        >
          <CustomTooltip
            standardMargin
            tooltipContent={
              isCertificateCERT ? '' : t('facility.units.unitDetails.unitRequirements.disableSubSertificateMessage')
            }
            hideToolTip={isCertificateCERT}
          >
            <Controller
              name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements[${requirementIndex}].subCertificate`}
              control={control}
              defaultValue={requirementField.subCertificate}
              render={({ ref, onChange, ...rest }) => (
                <SubCertificateUnitTypeAheadDropdown
                  name={'subCertificateTypeAheadDropdown'}
                  filterName={
                    !isCertificateCERT
                      ? t('facility.units.unitDetails.unitRequirements.subCertificate')
                      : t('facility.units.unitDetails.unitRequirements.subCertificateRequired')
                  }
                  options={formattedSubCertificationList}
                  isMultiSelect
                  value={rest.value}
                  removeCertificate={removeCertificate}
                  requirementIndex={requirementIndex}
                  skillSetIndex={skillSetIndex}
                  groupIndex={groupIndex}
                  appendRequirement={appendRequirement}
                  selectedSubCerts={selectedSubCerts}
                  parentPage={FormPageType.Unit}
                  minWidth={'200px'}
                  apply={newValue => {
                    onChange(newValue);
                    setFieldValue('isRequired', true);
                    if (isCertSelected) {
                      setNumberOfSubCert(newValue?.length);
                    }
                    if (isSubmitted) {
                      trigger();
                    }
                  }}
                  isDisabled={!isCertificateCERT}
                  controlErrorText={
                    subCertificateError
                      ? t('required')
                      : requirementValue?.[requirementIndex]?.subCertificate?.length
                      ? requirementError?.subCertificate
                        ? `${t('facility.units.unitDetails.unitRequirements.duplicated')} ${t(
                            'facility.units.unitDetails.unitRequirements.subCertificate',
                          )}`
                        : ''
                      : ''
                  }
                  customClass={classes.selectField}
                  hasControlError={
                    subCertificateError ||
                    (requirementValue?.[requirementIndex]?.subCertificate?.length
                      ? Boolean(requirementError?.subCertificate)
                      : false)
                  }
                />
              )}
              rules={{
                validate: () =>
                  validateDuplicateCertification(
                    requirementIndex,
                    watch(
                      `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements`,
                    ),
                    t,
                  ),
              }}
            />
          </CustomTooltip>
        </Grid>
        <Grid
          item
          xs={2.5}
          marginRight={2}
          className={`${
            Boolean(requirementValue?.[requirementIndex]?.certificates) && filteredIssuedBy.length && !isCertificateCERT
              ? ''
              : classes.disabledField
          }`}
        >
          <ControlledTypeAhead
            register={register}
            name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements[${requirementIndex}].issuedBy`}
            label={t('facility.units.unitDetails.unitRequirements.issuedBy')}
            control={control}
            options={filteredIssuedBy}
            onChange={() => {
              if (isSubmitted) {
                trigger();
              }
            }}
            customScroll
            disabled={false}
            id={'unitExperienceIssuedBy'}
            showDropdownIcon={true}
            defaultValue={requirementField.issuedBy}
            isHelperText={true}
            isNestedObject={true}
            isError={
              Boolean(requirementValue?.[requirementIndex]?.issuedBy) ? Boolean(requirementError?.issuedBy) : false
            }
            helperText={
              Boolean(requirementValue?.[requirementIndex]?.issuedBy)
                ? requirementError?.issuedBy
                  ? `${t('facility.units.unitDetails.unitRequirements.duplicated')} ${t(
                      'facility.units.unitDetails.unitRequirements.issuedBy',
                    )}`
                  : ''
                : ''
            }
            validateTypeAhead={() =>
              validateDuplicateCertification(
                requirementIndex,
                watch(
                  `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements`,
                ),
                t,
              )
            }
          />
        </Grid>
        <Grid item sx={{ alignSelf: 'center' }} className={classes.disabled} marginRight={2}>
          <Controller
            name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements[${requirementIndex}].isRequired`}
            control={control}
            defaultValue={requirementField.isRequired}
            render={({ onChange, ...rest }) => (
              <SimpleCheckbox
                isChecked={rest.value || false}
                handleChecked={event => {
                  onChange(event.target.checked);
                }}
                checkboxLabel={t('facility.units.unitDetails.unitRequirements.required')}
                checkboxName={'experienceCertificationsRequired'}
                id={'experienceCertificationsRequired'}
              />
            )}
          />
        </Grid>
        <Grid
          item
          sx={{ alignSelf: 'center' }}
          className={`${classes.checkbox} ${
            Boolean(requirementValue?.[requirementIndex]?.certificates) ? '' : classes.disabledField
          }`}
        >
          <Controller
            name={`skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements[${requirementIndex}].documentAttachmentRequired`}
            control={control}
            defaultValue={requirementField.documentAttachmentRequired}
            render={({ onChange, ...rest }) => (
              <SimpleCheckbox
                isChecked={rest.value || false}
                handleChecked={event => {
                  onChange(event.target.checked);
                }}
                checkboxLabel={t('facility.units.unitDetails.unitRequirements.attachDocument')}
                checkboxName={'experienceCertificationsAttachDocument'}
                id={'experienceCertificationsAttachDocument'}
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item gap={2} sx={{ alignSelf: 'center', textAlignLast: 'center' }} xs={0.35}>
        <CustomTooltip
          standardMargin
          tooltipContent={t('facility.units.unitDetails.unitRequirements.deleteCertificate')}
        >
          <DeleteOutlinedIcon
            className={classes.actionButtons}
            onClick={async () => {
              await removeSubCertFromGlobal();
              await removeCertificate(requirementIndex);
              if (isCertificateCERT) {
                setIsCertSelected(false);
                setNumberOfSubCert(0);
              }
              if (isSubmitted) {
                trigger();
              }
            }}
          />
        </CustomTooltip>
      </Grid>
    </Grid>
  );
};
