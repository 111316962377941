import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Zoom } from 'react-toastify';
import {
  FormControl,
  FormControlLabel,
  Grid,
  DatePicker,
  TimePicker,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  LocalizationProvider,
  Box,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { PhoneNumber } from 'app/components/Common/PhoneNumber/Phone';
import { convertToFormat } from 'app/helpers/dateHelper';
import { formatPhoneNumber, JabberExtensionCode, unformatPhoneNumber } from 'app/helpers/phoneHelpers';
import { IDisposition } from 'app/models/Notification/Notification';
import { SaveCallLog } from 'app/services/NotificationServices/NotificationService';
import styledComponent from 'styled-components';
import { ChangeBanner } from '../../Common/ChangeBanner';
import { SnackbarConfirmation } from '../../Common/CloseDialog';
import { ToasterContent } from '../../Common/ToasterContent';
import { ToasterHeader } from '../../Common/ToasterHeader';
import { ICallOutcome, ChannelType, IDirection, UseType } from '../../Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { NotificationContext } from '../../NotificationWrapper';
import { OutcomeIcon } from './OutcomeGridIcons';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import LogHeader from './LogHeader';
import Outcome from './Outcome';
import LogNotes from './LogNotes';
import { theme } from 'styles/global-styles';
import { closeModalData, getDefaultDisposition } from './Helper';
import { SimpleCheckbox } from '@AMIEWEB/Unit/Common/SimpleCheckbox';

const useStyles = makeStyles()(theme => ({
  modalContainer: {
    '& .MuiDialog-paperWidthSm': {
      minWidth: '540px',
      overflow: 'hidden',
    },
    '& .MuiDialogContent-root': {
      padding: 0,
    },
    '& .dialogContentRoot': {
      padding: 0,
    },
    overflow: 'hidden',
  },
  modalContent: {
    color: theme.palette.framework.system.charcoal,
    padding: '24px',
  },
  greenUnderline: {
    '&:before': {
      backgroundColor: theme.palette.framework.system.grassGreen,
    },
  },
  phoneField: {
    background: theme.palette.framework.system.white,
    borderBottom: `1px solid ${theme.palette.framework.system.shadeyGrey}`,
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    '&:hover': {
      background: 'none',
    },
  },
  picker: {
    background: theme.palette.framework.system.white,
    borderBottom: `1px solid ${theme.palette.framework.system.shadeyGrey}`,
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
  },
  typeRadio: {
    color: theme.palette.framework.system.charcoal,
    fontWeight: 500,
  },
  snackBar: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
  },
  outcomeContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '10px',
  }
}));

const FieldHeader = styledComponent(Typography)`
  margin-bottom: 1%;
  margin-top: 2%;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
  font: normal normal medium 16px/26px Roboto;
  letter-spacing: 0px;
  color: ${theme.palette.framework.system.charcoal};
  opacity: 1;
`;

export const ManualCallLog = () => {
  const dispatch = useDispatch();
  const globalData = useSelector(notificationSelection);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const { onClose, handleSubmitResponse } = useContext(NotificationContext);
  const data = globalData?.log?.data;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [notes, setNotes] = useState<string>('');
  const [addToSummary, setAddToSummary] = useState<boolean>(false);
  const [summaryCheckboxEnabled, setSummaryCheckboxEnabled] = useState<boolean>(false);
  const [disposition, setDisposition] = useState<IDisposition>(getDefaultDisposition(data));

  const [action, setAction] = useState({
    minimize: false,
    openManualLog: true,
  });
  const [loader, setLoader] = useState<boolean>(false);

  const saveManualCallLog = () => {
    setLoader(true);
    let voiceData = JSON.parse(JSON.stringify(disposition));
    if (data && data?.tos?.find(Boolean)?.phoneNumber) {
      voiceData.tos.find(Boolean).phoneNumber =
        JabberExtensionCode + unformatPhoneNumber(voiceData?.tos?.find(Boolean)?.phoneNumber);
    }
    voiceData.initiatedOn = moment(
      convertToFormat(voiceData.date, 'MM/DD/YYYY') + ' ' + convertToFormat(voiceData.time, 'HH:mm'),
    ).format();
    voiceData = { ...voiceData, updateCandidateSummary: addToSummary };
    SaveCallLog(voiceData)
      .then(res => {
        setLoader(false);
        handleSubmitResponse(true, 'success', t('notification.voice.loggedSuccessfully'));
        dispatch(
          notificationDataActions.setLogdata({
            log: { data: globalData.log?.data, open: false, minimized: false },
            snackbarData: {
              showCloseAction: false,
              showResponse: true,
              message: t('notification.voice.loggedSuccessfully'),
              severity: 'success',
            },
          }),
        );
        setAction({ ...action, openManualLog: false });
        if (
          candidateDetails.lastContactedOn === null ||
          moment(voiceData.initiatedOn).diff(moment(candidateDetails.lastContactedOn)) > 1
        ) {
          dispatch(candidateDetailActions.updateLastContactedOn(moment(voiceData.initiatedOn).toISOString()));
          dispatch(
            candidateDetailActions.setKpis({
              ...candidateDetails.kpis,
              lastContacted: moment(voiceData.initiatedOn).toISOString(),
            }),
          );
        }
        setNotes('');
        setSummaryCheckboxEnabled(false);
      })
      .catch(() => {
        setLoader(false);
        handleSubmitResponse(true, 'error', t('notification.voice.LoggingFailed'));
      });
  };

  const handleDisposition = (value: any, key: string) => {
    const dispositionData = JSON.parse(JSON.stringify(disposition));
    const dispositionType = data?.useType === UseType.ClientContacts ? ICallOutcome.SpokeToClient : ICallOutcome.SpokeToCandidate;
    dispositionData[key] = value;
    if (key === 'direction' && value === IDirection.Inbound) {
      dispositionData.disposition = dispositionType;
      setDisposition(prevState => ({ ...prevState, [key]: value, disposition: dispositionType }));
    } else setDisposition(prevState => ({ ...prevState, [key]: value }));
    dispatch(
      notificationDataActions.setNotificationData({
        ...globalData,
        log: {
          data: dispositionData,
          open: true,
          minimized: false,
          displayAddToSummary: globalData?.log?.displayAddToSummary,
        },
      }),
    );
  };

  const handleRecipientPhoneNumber = event => {
    const dispositionData = JSON.parse(JSON.stringify(disposition));
    dispositionData.tos.find(Boolean).phoneNumber = unformatPhoneNumber(event?.target?.value);
    setDisposition(dispositionData);
    dispatch(
      notificationDataActions.setLogdata({
        data: dispositionData,
        log: {
          data: dispositionData,
          open: true,
          minimized: false,
          displayAddToSummary: globalData?.log?.displayAddToSummary ?? false,
        },
      }),
    );
  };

  const handleMinimize = () => {
    const toasterId = 'manualVoiceCall';
    setAction({ ...action, minimize: true, openManualLog: false });
    dispatch(
      notificationDataActions.setLogInteraction({
        open: false,
        minimized: true,
        displayAddToSummary: globalData?.log?.displayAddToSummary ?? false,
      }),
    );
    dispatch(notificationDataActions.setSnackBarData(null));
    dispatch(notificationDataActions.incrementActiveToaster());
    toast(
      <ToasterContent
        channel={ChannelType.voice}
        isManualLog
        id={toasterId}
        info={globalData?.log?.data}
        header={ToasterHeader(data?.tos?.find(Boolean)?.name, data?.tos?.find(Boolean)?.contactId)}
        subheader={t('notification.voice.manualLog.log')}
        onClose={onClose}
      />,
      {
        toastId: toasterId,
        containerId: 'channel',
        position: 'bottom-right',
        closeButton: false,
        hideProgressBar: true,
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
        transition: Zoom,
      },
    );
  };

  const outcomeHandler = (outcomeValue: ICallOutcome) => {
    handleDisposition(outcomeValue, 'disposition');
    setDisposition({ ...disposition, disposition: outcomeValue });
  };

  const handleModalClose = () => {
    dispatch(
      notificationDataActions.setNotificationData({
        ...globalData,
        ...closeModalData,
      }),
    );
    dispatch(notificationDataActions.setSnackBarData(null));
    setNotes('');
    setSummaryCheckboxEnabled(false);
  };

  return (
    <React.Fragment>
      <GenericDialog
        open
        draggable
        disablePortal
        disableEscapeKeyDown
        dialogTitleProps={{
          text: t('notification.voice.manualLog.title'),
          expandable: false,
          closeButton: true,
          minimize: true,
          onMinimize: handleMinimize,
        }}
        dialogContentProps={{
          classes: { root: classes.modalContent },
        }}
        dialogActions={[
          {
            text: t('notification.voice.manualLog.cancel'),
            variant: 'contained',
            color: 'tertiary',
            onClick: () => onClose(ChannelType.voice, true),
          },
          {
            text: t('notification.voice.manualLog.submit'),
            variant: 'contained',
            color: 'primary',
            onClick: () => saveManualCallLog(),
            disabled:
              unformatPhoneNumber(globalData.log.data?.tos?.find(Boolean).phoneNumber)?.length !== 10 ||
              globalData.log.data.disposition == null,
          },
        ]}
        fullWidth={true}
        className={classes.modalContainer}
        variant={'blue'}
        onClose={() => onClose(ChannelType.voice, true)}
      >
        {globalData?.snackbarData?.channel === ChannelType.voice &&
          globalData?.snackbarData?.manual &&
          globalData?.snackbarData?.changeWarning && <ChangeBanner message={t('notification.finishLogWarning')} />}
        <LogHeader />
        <div className={classes.modalContent}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <DatePicker
                      inputFormat="MM/dd/yyyy"
                      label={t('notification.voice.manualLog.date')}
                      value={disposition.date}
                      maxDate={new Date()}
                      className={classes.picker}
                      readOnly={true}
                      onChange={date => handleDisposition(date, 'date')}
                      renderInput={p => (
                        <TextField
                          {...p}
                          fullWidth
                          id="dateSelector"
                          variant="filled"
                          aria-label="change date"
                          InputLabelProps={{
                            shrink: true,
                            color: 'primary',
                          }}
                          InputProps={{
                            ...p.InputProps,
                            disableUnderline: true,
                            autoComplete: 'off',
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TimePicker
                      label={t('notification.voice.manualLog.time')}
                      value={disposition.time}
                      className={classes.picker}
                      readOnly={true}
                      onChange={time => handleDisposition(time, 'time')}
                      renderInput={p => (
                        <TextField
                          {...p}
                          fullWidth
                          id="timeSelector"
                          aria-label="change time"
                          variant="filled"
                          InputProps={{
                            ...p.InputProps,
                            disableUnderline: true,
                            autoComplete: 'off',
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <PhoneNumber
                    disableUnderline
                    fullWidth
                    variant="filled"
                    label={t('notification.voice.manualLog.contactNumber')}
                    value={formatPhoneNumber(data?.tos?.find(Boolean)?.phoneNumber) || ''}
                    className={classes.phoneField}
                    onChange={handleRecipientPhoneNumber}
                    InputProps={{ classes: { underline: classes.greenUnderline } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl component="fieldset">
                    <FieldHeader style={{ marginBottom: '0', marginTop: '0' }}>
                      {t('notification.voice.manualLog.type')}
                    </FieldHeader>
                    <RadioGroup
                      row
                      aria-label="type"
                      name="Direction"
                      value={disposition.direction}
                      className={classes.typeRadio}
                      onChange={event => handleDisposition(event.target.value, 'direction')}
                    >
                      <FormControlLabel
                        value={IDirection.Outbound}
                        control={<Radio />}
                        label={t('notification.voice.manualLog.outbound')}
                      />
                      <FormControlLabel
                        value={IDirection.Inbound}
                        control={<Radio />}
                        label={t('notification.voice.manualLog.inbound')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <FieldHeader>{t('notification.voice.manualLog.outcome')}</FieldHeader>
          <Grid container gap={2.5} className={classes.outcomeContainer}>
            {OutcomeIcon(data?.useType === UseType.ClientContacts).map(item => (
              <Grid key={item.name} item>
                <Outcome
                  outcomeIcon={item.icon}
                  outcomeName={item.name}
                  outcomeValue={item.value}
                  disposition={disposition}
                  outcomeHandler={outcomeHandler}
                />
              </Grid>
            ))}
          </Grid>
          <FieldHeader>{t('notification.voice.manualLog.addNote')}</FieldHeader>
          <LogNotes
            logNotes={disposition.notes}
            handleDisposition={handleDisposition}
            notes={notes}
            setNotes={setNotes}
            setAddToSummary={setAddToSummary}
            setSummaryCheckboxEnabled={setSummaryCheckboxEnabled}
          />
          {globalData?.log?.displayAddToSummary && (
            <Grid style={{ padding: '10px' }}>
              <SimpleCheckbox
                id={'candidateSummaryCallLogCheckbox'}
                isChecked={addToSummary}
                isdisabled={!summaryCheckboxEnabled}
                hasRadio={false}
                handleChecked={() => setAddToSummary(!addToSummary)}
                checkboxLabel={t('notification.voice.addToSummary')}
                checkboxName={'candidateSummaryCallLogCheckbox'}
              />
            </Grid>
          )}
        </div>
        <Box className={classes.snackBar}>
          {globalData?.snackbarData?.channel === ChannelType.voice &&
            globalData?.snackbarData?.manual &&
            !globalData?.snackbarData?.changeWarning && (
              <SnackbarConfirmation
                showWarning
                snackbarText={t('notification.voice.manualLog.closeConfirmation')}
                yesAction={handleModalClose}
                noAction={() => dispatch(notificationDataActions.setSnackBarData(null))}
              />
            )}
        </Box>
      </GenericDialog>
    </React.Fragment>
  );
};
