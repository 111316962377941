import React from 'react';
import Divider from '@mui/material/Divider';
import { Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { NineCheckboxConfiguration, NineCheckboxType, IgnoreHeadingType, Heading4, AgeSpecificSubType, ExperienceAgeGroups } from '../Config/constants';
import { useTranslation } from 'react-i18next';
import { ISkillChecklistSection } from '../Config/model';
import { SkillChecklistFields } from '../FieldSections/SkillChecklistFields';

const useCheckboxStyles = makeStyles()(theme => ({
  checkboxTitle: {
    textAlign: 'left',
    font: 'normal normal normal 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.charcoal,
    opacity: 1,
    transform: 'rotate(300deg)',
    width: '139px',
    height: '30px',
  },
  checkedBox: {
    background: theme.palette.framework.system.main,
    width: '15px',
    height: '15px',
    display: 'inline-block',
  },
  unCheckedBox: {
    background: theme.palette.framework.system.lightGrey,
    width: '15px',
    height: '15px',
    display: 'inline-block',
  },
  checkboxItem: {
    display: 'flex',
    position: 'relative',
    '& span': {
      fontSize: '12px',
      transform: 'rotate(300deg)',
      position: 'absolute',
    },
  },
}));

const getCheckValues = inputValue => {
  let checkedValues = [];

  NineCheckboxConfiguration.map(item => {
    if ((inputValue & item.value) != 0) {
      checkedValues.push(item);
    }
  });

  return checkedValues;
};

const NineCheckbox = ({ inputValue }: { inputValue: number | null }) => {
  const checkedValues = getCheckValues(inputValue);
  const { classes } = useCheckboxStyles();

  return (
    <>
      {NineCheckboxConfiguration.map((item, index) => {
        return (
          <span
            key={index}
            style={{ marginLeft: index === 0 ? '14px' : '10px' }}
            className={
              checkedValues?.map(temp => temp.key).indexOf(item.key) >= 0 ? classes.checkedBox : classes.unCheckedBox
            }
          />
        );
      })}
    </>
  );
};

/**
 * Component to render rating legend section on view skills checklist
 */
const NineCheckboxQuestion = ({ skillChecklistSection }: { skillChecklistSection: ISkillChecklistSection[]}) => {
  const { t } = useTranslation();
  const { classes } = useCheckboxStyles();
  const nineCheckbox = skillChecklistSection?.filter(x => x.typeId === NineCheckboxType);
  const heading = skillChecklistSection?.find(item => item?.typeId === IgnoreHeadingType);
  const subHeading = skillChecklistSection?.find(item => item?.typeId === Heading4 &&  item?.text?.indexOf(AgeSpecificSubType) > -1);
  const skillChecklistCollection = skillChecklistSection?.filter(
    item =>
      item.typeId !== NineCheckboxType &&
      item.id !== heading?.id &&
      item.id !== subHeading?.id &&
      item.text !== ExperienceAgeGroups
  );

  if (nineCheckbox?.length) {
    return (
      <>
        <LayoutGridItem container item direction="column" rowSpacing={2} pb={'12px'} style={{ paddingTop: '25px' }}>
          <LayoutGridItem item>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '500 !important',
                color: theme => theme.palette.framework.system.charcoal,
              }}
            >
              {heading?.text?.replace(/&amp;/g, '&')}
            </Typography>
          </LayoutGridItem>
          <LayoutGridItem item>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '500 !important',
                color: theme => theme.palette.framework.system.charcoal,
                fontSize: '12px !important',
              }}
            >
              {subHeading?.text?.replace(/&amp;/g, '&')}
            </Typography>
          </LayoutGridItem>
          <LayoutGridItem container item>
            <LayoutGridItem item xs={6}>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '500 !important',
                  color: theme => theme.palette.framework.system.charcoal,
                  fontSize: '12px !important',
                  marginTop: '5px',
                }}
              >
                {t('candidate.credentials.attachments.viewSkillChecklist.experienceWithAgeGroups')}
              </Typography>
            </LayoutGridItem>
            <LayoutGridItem item xs={6} style={{ display: 'flex', height: '75px' }}>
              <div className={classes.checkboxItem}>
                <span style={{ top: '50px', left: '9px' }}>
                  {t('candidate.credentials.attachments.viewSkillChecklist.newborn')}
                </span>
                <span style={{ top: '58px', left: '37px' }}>
                  {t('candidate.credentials.attachments.viewSkillChecklist.infant')}
                </span>
                <span style={{ top: '52px', left: '60px' }}>
                  {t('candidate.credentials.attachments.viewSkillChecklist.toddler')}
                </span>
                <span style={{ top: '40px', left: '78px' }}>
                  {t('candidate.credentials.attachments.viewSkillChecklist.preschooler')}
                </span>
                <span style={{ top: '23px', left: '92px' }}>
                  {t('candidate.credentials.attachments.viewSkillChecklist.schoolAgeChildren')}
                </span>
                <span style={{ top: '39px', left: '127px' }}>
                  {t('candidate.credentials.attachments.viewSkillChecklist.adolescents')}
                </span>
                <span style={{ position: 'relative', top: '24px', left: '177px' }}>
                  {t('candidate.credentials.attachments.viewSkillChecklist.youngAdults')}
                </span>
                <span style={{ position: 'relative', top: '23px', left: '131px' }}>
                  {t('candidate.credentials.attachments.viewSkillChecklist.middleAdults')}
                </span>
                <span style={{ position: 'relative', top: '27px', left: '84px' }}>
                  {t('candidate.credentials.attachments.viewSkillChecklist.olderAdults')}
                </span>
              </div>
            </LayoutGridItem>
          </LayoutGridItem>
          {nineCheckbox.map((item, index) => (
            <LayoutGridItem container item>
              <LayoutGridItem item xs={6}>
                <Typography>{item.text}</Typography>
              </LayoutGridItem>
              <LayoutGridItem item xs={6}>
                <NineCheckbox inputValue={item.iValue} />
              </LayoutGridItem>
            </LayoutGridItem>
          ))}
        </LayoutGridItem>
        <Divider style={{ width: '98%' }} />
        {skillChecklistCollection?.length > 0 && <SkillChecklistFields skillChecklistFields={skillChecklistCollection} />}
      </>
    );
  }
};

export { NineCheckbox, NineCheckboxQuestion };
