import { GridTag } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { selectGridState } from 'app/components/Common/Grid/GridStateManagement/GridState.selectors';
import { selectFacility } from 'app/components/Facility/FacilityStore/Facility.selector';
import {
  CallPurpose,
  ChannelType,
  FormName,
  SendType,
  UseSubType,
  UseType,
} from 'app/components/Notification/Constants';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { ContactProps, IEmployee, IToken } from 'app/models/Notification/Notification';
import { selectUser } from 'oidc/user.selectors';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import { selectContactsGridData, selectedContactsData } from '../Store/FacilityContacts.selector';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import { CreateClientContactModal } from '../../CreateClientContact/CreateClientContact';
import { ff_task_ui_createclientcontact } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { useDecision } from '@optimizely/react-sdk';
import { usePromiseTracker } from 'react-promise-tracker';
import { XGrid, calculateComponentRemainingHeight } from '@AMIEWEB/Common';
import { contactColumns } from './utils';
import { missingField } from 'app/constants';
import { materialUiXGrid } from 'amn-ui-core';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import ManualCallLogIcon from '@mui/icons-material/AddIcCallOutlined';
import GroupAddIcon from 'app/assets/images/IconImages/GroupAdd.svg';
import { selectGridSelection } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.selector';
import { FacilityContactsActions } from '../Store/FacilityContacts.redux';
import { GridCellParams, GridSortModel } from '@mui/x-data-grid-pro';
import { getHomePageSupportComponentHeight } from 'app/layout/pages/HomePage';
import { useWindowResize } from 'utils/customHooks/useWindowResize';
import { clientContactActions } from '../../CreateClientContact/store/ClientContact.redux';
import {
  selectClientContactDetails,
  selectClientContactUpdated,
  selectIsClientContactEditModalOpen,
} from '../../CreateClientContact/store/ClientContact.selector';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';
import { gridSelectionActions } from '@AMIEWEB/Common/Grid/GridSelection/GridSelection.redux';
import { formatUpdatePayload } from '../../CreateClientContact/Transformers/FormatData';
import { ResponseCodes } from 'app/models/ClientContact/ClientContact';
import { IContactDetail } from 'app/models/Facility/FacilityDetails';
import { makeStyles } from 'tss-react/mui';
import {
  filterAndMapRecords,
  getDefaultSender,
} from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';

const useStyles = makeStyles()(theme => ({
  tooltipLayout: {
    display: 'flex',
    gap: '8px',
  },
}));
interface IContactsGridFields {
  firstName: string;
  lastName: string;
  prefix: string;
  goesBy: string;
  email: string;
  phoneNumbers: IContactDetail[];
  title: string;
  contactPreference: string;
  doNotCallIn: boolean;
  state: string;
  actions: string;
  units: string;
}

export const FacilityContactsList = props => {
  const gridState = useSelector(selectGridState);
  const facilitySelector = useSelector(selectFacility);
  const user = useSelector(selectUser);
  const selectedContacts = useSelector(selectedContactsData);
  const notificationGlobalData = useSelector(notificationSelection);
  const contactsFlattenedData = useSelector(selectContactsGridData);
  const selectedClientContactDetails = useSelector(selectClientContactDetails);
  const selectIsClientContactUpdated = useSelector(selectClientContactUpdated);
  const selectIsContactEditModelOpen = useSelector(selectIsClientContactEditModalOpen);
  const { selectedData } = useSelector(selectGridSelection);
  const [createClientContactFlag] = useDecision(ff_task_ui_createclientcontact, { autoUpdate: true });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [createContactModalOpen, setCreateContactModalOpen] = useState(false);
  const [deleteContactModalOpen, setDeleteContactModalOpen] = useState<boolean>(false);
  const [deleteContactConfirmationModalOpen, setDeleteContactConfirmationModalOpen] = useState<boolean>(false);
  const createClientContactDecision = createClientContactFlag?.enabled;
  const { promiseInProgress: isContactGridLoading } = usePromiseTracker({ area: 'get-fau-client-contacts', delay: 0 });
  const { promiseInProgress: isDeleteApiLoading } = usePromiseTracker({ area: 'get-client-contacts-by-id', delay: 0 });
  const { promiseInProgress: isUpdateApiLoading } = usePromiseTracker({ area: 'update-client-contact', delay: 0 });

  const [mailRecipients, setMailRecipients] = useState([]);
  const [gridHeight, setGridHeight] = useState<number | undefined>(50);
  const [rows, setRows] = useState([]);
  const [selectedModel, setSelectedModel] = useState<any[]>([]);
  const [selectedDeletionModel, setSelectedDeletionModel] = useState<any[]>([]);
  const [isInactiveStatusUpdate, setInactiveStatusUpdate] = useState<boolean>(false);
  const MAX_ALLOWED_EMAIL_SELECTION = 250;
  const DEFAULT_PAGE_SIZE = 25;
  const CREATE_EMAIL_WARNING = `Maximum of ${MAX_ALLOWED_EMAIL_SELECTION} recipients permitted. Please reduce your selections and try again.`;
  const Create_Client_Contact = 'Create Client Contact'; // FIXME: Use i18next translation for this text
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const apiRef = materialUiXGrid.useGridApiRef();
  const [sortModel, setSortModel] = useState<any>([{ field: 'firstName', sort: 'desc' }]);
  const defaultSortModel: any[] = [{ field: 'firstName', sort: 'desc' }];
  const { classes } = useStyles();

  const updateGridHeight = () =>
    setGridHeight(calculateComponentRemainingHeight('home-page-paper', getHomePageSupportComponentHeight, 12));
  useWindowResize(updateGridHeight, updateGridHeight);

  const onStarIcon = params =>
    dispatch(FacilityContactsActions.updateStarIconForContactsGrid({ contactId: params.contactId }));

  const [columns, setColumns] = useState<any>(contactColumns(t, onStarIcon, classes));

  const fetchContactList = () => {
    dispatch(
      clientContactActions.getFacilityAndUnitClientContacts({
        facilityId: facilitySelector.facilityResponse?.facilityId,
        pageNumber: 1,
        pageSize: 1000,
      }),
    );
  };

  /**
   * Method to handle Manual Log for contact
   */
  const handleLogCallClick = () => {
    const selectedRows = rows?.filter((row) => selectedData?.includes(row?.id));
    const contactDetails = selectedRows[0];
    const candidate: ContactProps = {
      name: contactDetails.name,
      email: contactDetails.email,
      phoneNumber: contactDetails.phoneNumbers[0]?.phoneNumber,
      contactId: contactDetails?.contactId?.toString(),
      contactType: 'facility',
      clientContactDetails: {
        statusId: contactDetails?.statusId?.toString(),
        unitIds: [],
      },
    };

    const associatedRecord = [
      { name: 'facilityId', value: facilitySelector.facilityResponse?.facilityId?.toString() },
      { name: 'facilityName', value: facilitySelector.facilityResponse?.facilityName?.toString() },
      { name: 'clientContactId', value: contactDetails?.contactId?.toString() },
    ];
    const data = {
      manualLog: true,
      channel: ChannelType.voice,
      sender: getDefaultSender(user),
      tos: [candidate],
      useType: UseType.ClientContacts,
      userRole: Concatenate(user?.userInfo?.roles || [], ','),
      formName: FormName[FormName.Contact],
      reason: CallPurpose[CallPurpose.Prospecting],
      associatedRecords: associatedRecord,
    };

    if (!notificationGlobalData.log.open && notificationGlobalData.log.minimized) {
      dispatch(
        notificationDataActions.setLogInteraction({
          open: true,
          minimized: false,
          showDisposition: true,
        }),
      );
      if (notificationGlobalData.log.data.tos.find(Boolean).contactId !== candidate[0].contactId) {
        dispatch(notificationDataActions.setSnackBarData({ channel: 'voice', manual: true, changeWarning: true }));
      }
    } else {
      dispatch(
        notificationDataActions.setLogdata({
          ...notificationGlobalData,
          data: data,
          log: {
            channel: ChannelType.voice,
            data: data,
            open: true,
            minimized: false,
          },
          snackbarData: null,
        }),
      );
    }
  };

  /**
   * Method to handle Email for multiple recipients
   */
  const handleEmailClick = React.useCallback(() => {
    if (!notificationGlobalData.email.open && notificationGlobalData.email?.minimized) {
      dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
      dispatch(notificationDataActions.setEmailInteraction({ open: true, minimized: false }));
      return;
    }

    const substitutions: IToken[] = [];
    substitutions.push({
      name: 'Signature',
      input: '{{signature}}',
    });
    const sender: IEmployee = {
      name: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
      email: user.userInfo?.email,
      senderId: user.userInfo?.employeeId?.toString(),
      userId: user.userInfo?.employeeId?.toString(),
      NtUserName: user.userInfo?.ntUserName,
      deviceName: '',
      lineNumber: '',
    };
    const candidate: ContactProps[] = mailRecipients?.map(contactDetails => {
      return {
        name: contactDetails.name,
        email: contactDetails.email,
        phoneNumber: contactDetails.phoneNumber,
        contactId: contactDetails?.contactId?.toString(),
        contactType: 'facility',
        clientContactDetails: {
          statusId: contactDetails?.statusId?.toString(),
          unitIds: [],
        },
      };
    });
    const data = {
      channel: ChannelType.email,
      sender: sender,
      tos: candidate,
      bccs: [sender],
      body: '<br/><br/> {{signature}}',
      substitutions: substitutions,
      useType: UseType.ClientContacts,
      UseSubType: UseSubType.KeyContactBulkEmail.toString(),
      userRole: Concatenate(user?.userInfo?.roles || [], ','),
      sendType: SendType.bulk,
      emailType: SendType.one_to_one,
      associatedRecords: [
        { name: 'facilityId', value: facilitySelector.facilityResponse?.facilityId?.toString() },
        { name: 'facilityName', value: facilitySelector.facilityResponse?.facilityName?.toString() },
      ],
    };
    dispatch(
      notificationDataActions.setEmailData({
        data: {
          ...EmailDefaultValues(),
          ...data,
        },
        open: true,
        minimized: false,
      }),
    );
  }, [rows, selectedData, mailRecipients]);

  /**
   * Method to set units grid data
   */
  const setUnitGridData = () => {
    const formattedRows: IContactsGridFields[] = contactsFlattenedData.map((item, index: number) => ({
      ...item,
      id: index,
      firstName: item?.firstNameSortable ?? missingField,
      lastName: item?.lastName ?? missingField,
      prefix: item?.prefix ?? missingField,
      goesBy: item?.goesBy ?? missingField,
      email: item?.email ?? missingField,
      phoneNumbers: item?.phoneNumbers ?? [],
      title: item?.title ?? missingField,
      contactPreference: item?.contactPreference ?? missingField,
      doNotCallIn: item?.doNotCallIn,
      state: item?.state ?? missingField,
      actions: item?.actions ?? missingField,
      units: item?.units ?? missingField,
      address1: item?.address1 ?? missingField,
      address2: item?.address2 ?? missingField,
      city: item?.city ?? missingField,
      zip: item?.zip ?? missingField,
      country: item?.country ?? missingField,
      status: item?.descStatus ?? missingField,
      notes: item?.notes ?? missingField,
    }));
    setRows(formattedRows);
    setTotalCount(formattedRows.length ? formattedRows.length : 0);
  };

  /**
   * Effect to setup grid data for units
   */
  useEffect(() => {
    setUnitGridData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactsFlattenedData]);

  useEffect(() => {
    if (selectIsContactEditModelOpen) {
      setCreateContactModalOpen(true);
    } else {
      setCreateContactModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectIsContactEditModelOpen]);

  /**
   * Method to trigger on page change
   * @param page
   */
  const onPageChange = (page: number) => {
    setPageNumber(page);
  };

  /**
   * Method to trigger on page size change
   * @param page
   */
  const onPageSizeChange = (offset: number) => {
    setPageSize(offset);
  };

  /**
   * Method to trigger on sort model change
   * @param page
   */
  const onSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
  };

  const selectionModelChange = model => {
    setSelectedModel(model);
  };

  useEffect(() => {
    const recipients = rows.reduce((prev, curr) => {
      if (selectedData.includes(curr.id) && curr.email !== missingField) {
        return [...prev, curr];
      } else return prev;
    }, []);
    setMailRecipients(recipients);
  }, [selectedData]);

  useEffect(() => {
    if (selectIsClientContactUpdated) {
      setDeleteContactModalOpen(false);
    }
    if (
      selectIsClientContactUpdated &&
      selectIsClientContactUpdated?.closeModal &&
      selectIsClientContactUpdated?.responseStatus === 200
    ) {
      fetchContactList();
      dispatch(clientContactActions.setIsClientContactEditModelOpen(false));
      setCreateContactModalOpen(false);
      setDeleteContactConfirmationModalOpen(false);
      dispatch(
        gridSelectionActions.setGridSelections({
          selectedData: [],
        }),
      );
      setSelectedModel([]);
      setSelectedDeletionModel([]);
      dispatch(clientContactActions.setIsClientContactEditModelOpen(false));
      dispatch(clientContactActions.setClientContactUpdateStatus(null));
      dispatch(clientContactActions.setClientContactDetails(undefined));
      if (isInactiveStatusUpdate) {
        setInactiveStatusUpdate(false);
        dispatch(
          globalActions.setSnackBar({
            message: t('clientContactModal.inactiveSuccessfulUpdate'),
            severity: 'success',
          }),
        );
      }
    } else if (
      selectIsClientContactUpdated &&
      selectIsClientContactUpdated?.refreshModal &&
      selectIsClientContactUpdated?.responseStatus === ResponseCodes.conflict
    ) {
      dispatch(
        globalActions.setSnackBar({
          message: t('clientContactModal.errorMessage'),
          severity: 'error',
          actions: [
            {
              action: () => fetchLatestClientContactDetails(),
              closeSnackbar: true,
              text: t('defaultPage.yes'),
            },
            {
              action: () => {
                setCreateContactModalOpen(false);
                dispatch(clientContactActions.setIsClientContactEditModelOpen(false));
                dispatch(clientContactActions.setClientContactDetails(undefined));
              },
              closeSnackbar: true,
              text: t('defaultPage.no'),
            },
          ],
        }),
      );
    } else if (
      selectIsClientContactUpdated?.responseStatus === ResponseCodes.inActiveStatus ||
      selectIsClientContactUpdated?.responseStatus === ResponseCodes.noDelete
    ) {
      setSelectedModel(selectedDeletionModel);
      setDeleteContactConfirmationModalOpen(true);
    } else if (selectIsClientContactUpdated?.responseStatus === ResponseCodes.refresh) {
      setDeleteContactConfirmationModalOpen(false);
      dispatch(
        globalActions.setSnackBar({
          message: t('clientContactModal.noClientExists'),
          severity: 'error',
        }),
      );
      fetchContactList();
    } else if (
      selectIsClientContactUpdated?.responseStatus === ResponseCodes.notFound &&
      selectIsClientContactUpdated?.closeModal
    ) {
      dispatch(
        globalActions.setSnackBar({
          message: t('clientContactModal.noClientExists'),
          severity: 'error',
        }),
      );
      fetchContactList();
      setCreateContactModalOpen(false);
      dispatch(clientContactActions.setIsClientContactEditModelOpen(false));
      dispatch(clientContactActions.setClientContactUpdateStatus(null));
    } else {
      setDeleteContactConfirmationModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectIsClientContactUpdated?.responseStatus]);

  const fetchLatestClientContactDetails = () => {
    const contactId = selectedClientContactDetails.contactId;
    dispatch(clientContactActions.setClientContactDetails(undefined));
    dispatch(clientContactActions.getClientContactById(contactId));
    dispatch(clientContactActions.setClientContactUpdateStatus(null));
  };

  /**
   * Method to handle Click on any XGrid cell
   */
  const onCellClick = (params: GridCellParams) => {
    const contactDetails = params.row;
    if (params.formattedValue !== missingField) {
      let data = {};

      const sender: IEmployee = {
        name: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
        email: user.userInfo?.email,
        senderId: user.userInfo?.employeeId?.toString(),
        userId: user.userInfo?.employeeId?.toString(),
        NtUserName: user.userInfo?.ntUserName,
        deviceName: '',
        lineNumber: '',
      };
      const candidate: ContactProps = {
        name: contactDetails.name,
        email: contactDetails.email,
        phoneNumber: contactDetails.phoneNumber,
        contactId: contactDetails.contactId,
        contactType: 'facility',
        clientContactDetails: {
          statusId: contactDetails?.statusId?.toString(),
          unitIds: [],
        },
      };

      switch (params.field) {
        case ChannelType.email:
          const substitutions: IToken[] = [];
          substitutions.push({
            name: 'Signature',
            input: '{{signature}}',
          });
          data = {
            channel: ChannelType.email,
            associatedRecords: [
              { name: 'facilityId', value: facilitySelector?.facilityResponse?.facilityId?.toString() },
              { name: 'facilityName', value: facilitySelector?.facilityResponse?.facilityName?.toString() },
              { name: 'clientContactId', value: contactDetails?.contactId?.toString() },
            ],
            emailType: SendType.one_to_one,
            sender: sender,
            tos: [candidate],
            bccs: [sender],
            body: '<br/><br/> {{signature}}',
            substitutions: substitutions,
            useType: UseType.ClientContacts.toString(),
            UseSubType: UseSubType.ClientContactGrid.toString(),
            userRole: Concatenate(user?.userInfo?.roles || [], ','),
            sendType: SendType.one_to_one,
            facilityOneToOne: true,
          };
          if (!notificationGlobalData.email.open && notificationGlobalData.email.minimized) {
            dispatch(
              notificationDataActions.setEmailInteraction({
                open: true,
                minimized: false,
              }),
            );
            dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
          } else {
            dispatch(
              notificationDataActions.setEmailData({
                data: {
                  ...EmailDefaultValues(),
                  ...data,
                },
                open: true,
                minimized: false,
              }),
            );
          }
          break;
      }
    }
  };

  const initiateDeleteContact = () => {
    setSelectedDeletionModel(selectedModel);
    const deletionContactId = rows?.find(x => x?.id === selectedData[0])?.contactId;
    dispatch(clientContactActions.deleteClientContactById(deletionContactId));
  };

  const initiateContactInactive = responseId => {
    if (responseId === ResponseCodes.inActiveStatus) {
      if (selectedClientContactDetails && selectedClientContactDetails?.contactId) {
        const updatePayload = formatUpdatePayload(selectedClientContactDetails);
        setInactiveStatusUpdate(true);
        dispatch(clientContactActions.updateClientContactDetails(updatePayload));
      } else {
        dispatch(
          globalActions.setSnackBar({
            message: t('common.failedLoad'),
            severity: 'error',
            actions: [
              {
                action: () => {
                  fetchContactList();
                  setCreateContactModalOpen(false);
                  setDeleteContactModalOpen(false);
                  setDeleteContactConfirmationModalOpen(false);
                  dispatch(clientContactActions.setIsClientContactEditModelOpen(false));
                },

                closeSnackbar: true,
                text: t('unableRefreshModal.confirmation.ok'),
              },
            ],
          }),
        );
      }
    } else {
      setSelectedModel([]);
      dispatch(
        gridSelectionActions.setGridSelections({
          selectedData: [],
        }),
      );
      setDeleteContactConfirmationModalOpen(false);
    }
  };

  const AddClientContactSVG = () => {
    return <img src={GroupAddIcon} alt="Add Client Contact" height={17} />;
  };

  const rightActions = React.useMemo(() => {
    const isEmailDisabled = () => {
      return mailRecipients?.length < 1 || mailRecipients?.length > MAX_ALLOWED_EMAIL_SELECTION;
    };
    const isManualLogDisabled = () => {
      return selectedModel?.length !== 1;
    };
    const rightIcons = [
      {
        title: 'global.xgrid.toolbar.email',
        Icon: EmailOutlinedIcon,
        disabled: isEmailDisabled(),
        onClick: handleEmailClick,
        tooltipProps: {
          tooltipContent: CREATE_EMAIL_WARNING,
          disabled: selectedData?.length <= MAX_ALLOWED_EMAIL_SELECTION,
        },
      },
      {
        title: '',
        Icon: ManualCallLogIcon,
        disabled: isManualLogDisabled(),
        onClick: handleLogCallClick,
        tooltipProps: {
          tooltipContent: t('notification.voice.manualLog.addManualLog'),
          disabled: isManualLogDisabled(),
        },
      },
      {
        title: '',
        Icon: AddClientContactSVG,
        onClick: () => setCreateContactModalOpen(true),
        tooltipProps: {
          tooltipContent: Create_Client_Contact,
        },
      },
      {
        title: '',
        Icon: DeleteOutlineIcon,
        disabled: selectedModel?.length !== 1,
        onClick: () => setDeleteContactModalOpen(true),
        tooltipProps: {
          tooltipContent: t('clientContactModal.deleteBtn'),
        },
      },
    ];

    return user?.userInfo?.roles?.includes('AccountManagement')
      ? rightIcons
      : rightIcons.filter(Icon => Icon.title !== null);
  }, [selectedData?.length, selectedContacts?.length, mailRecipients, selectedModel]);

  const contactsGrid = useMemo(() => {
    return (
      <XGrid
        gridTag={GridTag.FacilityClientContactsGrid}
        apiRef={apiRef}
        loading={isContactGridLoading || isDeleteApiLoading || isUpdateApiLoading}
        columns={columns}
        rows={rows}
        page={pageNumber}
        pageSize={pageSize}
        autoHeight={false}
        checkboxSelection
        height={gridHeight}
        rowsPerPageOptions={[10, 25, 50, 100, 250]}
        defaultRowsPerPage={gridState.pageSize || DEFAULT_PAGE_SIZE}
        disableSelectionOnClick
        rowCount={totalCount}
        sortModel={sortModel}
        defaultSortModel={defaultSortModel}
        onSelectionModelChange={selectionModelChange}
        selectionModel={selectedModel}
        title={t('navStick.clientContact')}
        pagination={true}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortModelChange={onSortModelChange}
        onCellClick={onCellClick}
        toolbarProps={{
          rightActions: rightActions,
          refreshButton: true,
          resetButton: true,
          refreshGrid: () => {
            fetchContactList();
          },
          resetColumns: () => setColumns(contactColumns(t, onStarIcon, classes)),
        }}
      />
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, rows, onSortModelChange, mailRecipients, isContactGridLoading, pageSize, onPageChange, selectedModel]);

  return (
    <>
      {contactsGrid}
      {createClientContactDecision && createContactModalOpen && (
        <CreateClientContactModal
          open={createContactModalOpen}
          facilityDetails={facilitySelector?.facilityResponse!}
          handleClose={() => {
            setCreateContactModalOpen(false);
            dispatch(clientContactActions.setIsClientContactEditModelOpen(false));
          }}
        />
      )}
      {deleteContactModalOpen && (
        <GenericDialog
          open={deleteContactModalOpen}
          maxWidth="sm"
          dialogTitleProps={{ text: t('clientContactModal.deleteModalHeader') }}
          dialogActions={[
            {
              text: t('cancelModal.confirmation.cancel'),
              onClick: () => setDeleteContactModalOpen(false),
              color: 'tertiary',
            },
            {
              text: t('cancelModal.confirmation.yes'),
              onClick: initiateDeleteContact,
              variant: 'contained',
              disabled: isDeleteApiLoading,
            },
          ]}
        >
          {t('clientContactModal.deleteConfirmationText')}
        </GenericDialog>
      )}
      {deleteContactConfirmationModalOpen && (
        <GenericDialog
          open={deleteContactConfirmationModalOpen}
          maxWidth="sm"
          dialogTitleProps={{ text: t('clientContactModal.deleteConfirmationHeader') }}
          dialogActions={[
            {
              text: t('cancelModal.confirmation.cancel'),
              onClick: () => {
                setDeleteContactConfirmationModalOpen(false);
                dispatch(clientContactActions.setClientContactUpdateStatus(null));
              },
              color: 'tertiary',
              hidden: selectIsClientContactUpdated?.responseStatus === ResponseCodes.noDelete,
            },
            {
              text:
                selectIsClientContactUpdated?.responseStatus === ResponseCodes.inActiveStatus
                  ? t('clientContactModal.inactiveBtn')
                  : t('unableRefreshModal.confirmation.ok'),
              onClick: () => initiateContactInactive(selectIsClientContactUpdated?.responseStatus),
              variant: 'contained',
              disabled: isUpdateApiLoading,
            },
          ]}
        >
          {selectIsClientContactUpdated?.responseStatus === ResponseCodes.inActiveStatus
            ? t('clientContactModal.InactiveConfirmationText')
            : t('clientContactModal.noDeleteConfirmationText')}
        </GenericDialog>
      )}
    </>
  );
};
