import React, { useMemo, useState } from 'react';
import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select } from 'amn-ui-core';
import { useFormContext, Controller } from 'react-hook-form';
import { FormInputProps } from './FormInputProps';
import { ErrorMessage } from '@hookform/error-message';
import ArrowIcon from 'app/assets/images/Credentialing/PersonalInfo/arrow.svg';
import { InputAdornment, TextField, ListSubheader } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { WhiteTooltip } from '@AMIEWEB/Common';

export const FormInputDropdown: React.FC<FormInputProps> = ({
  name,
  label,
  require,
  message,
  options,
  handleChange,
  inputValue,
  readOnly,
  isCheckBox,
  search,
  standard,
  clearButton,
  disabled,
  multiple,
  maxWidthDropdown,
  useMenuItem = false,
  disabledOptions,
  helperText,
  customClass,
  labelClass,
  tooltipMsg,
  displayMenuTop,
  showErrorAttribute = false,
  isOrderCreationElement = false,
  defaultValue = '',
  shrink,
  isError,
  hasCountryField = false,
  isContactPreference = false,
}) => {
  const useStyles = makeStyles()(() => ({
    paper: {
      maxHeight: isOrderCreationElement ? '200px' : '300px',
      maxWidth: maxWidthDropdown,
      overflowX: 'scroll',
    },
    menuItem: {
      fontSize: '14px',
      padding: isOrderCreationElement ? '10px 0px 10px 20px' : undefined,
      color: isOrderCreationElement ? '#333333' : undefined,
      '&:hover': {
        backgroundColor: isOrderCreationElement ? '#EFEFEF !important' : '#f5f5f5 !important',
      },
    },
    selected: {
      fontSize: '14px',
      '&:hover': {
        backgroundColor: '#f5f5f5 !important',
      },
      backgroundColor: 'transparent !important',
    },
  }));
  const { classes } = useStyles();
  const { control, errors } = useFormContext();
  const [searchText, setSearchText] = useState('');
  const containsText = (text, searchText) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
  const displayedOptions = useMemo(() => {
    return search ? options.filter(option => containsText(option.Description, searchText)) : options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, options]);

  return (
    <FormControl
      variant="filled"
      style={{ width: '100%' }}
      error={showErrorAttribute ? !!errors[name] : false || isError}
    >
      <InputLabel
        style={{ pointerEvents: 'auto' }}
        shrink={multiple ? inputValue?.length > 0 : inputValue || shrink}
        id="select-filled-label"
        className={labelClass}
        defaultValue={defaultValue}
      >
        {label}
      </InputLabel>
      <Controller
        render={({ ref, value, onChange, ...rest }) => (
          <React.Fragment>
            <Select
              style={{
                pointerEvents: disabled || readOnly ? 'none' : 'auto',
                height: isOrderCreationElement ? '48px' : undefined,
              }}
              native={!standard && !search}
              labelId="select-filled-label"
              id={`drop${name}`}
              value={value || inputValue}
              onChange={e => {
                handleChange(e);
                setSearchText('');
              }}
              readOnly={readOnly}
              disabled={disabled}
              multiple={multiple}
              renderValue={multiple ? selected => selected?.join(', ') : selected => selected}
              MenuProps={{
                anchorOrigin: {
                  vertical: displayMenuTop ? 'top' : 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: displayMenuTop ? 'bottom' : 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                classes: {
                  paper: classes.paper,
                },
              }}
              className={customClass}
              IconComponent={props =>
                !clearButton ? (
                  <img
                    src={ArrowIcon}
                    style={{ marginRight: '10px', marginLeft: '10px', marginTop: '4px' }}
                    {...props}
                  />
                ) : inputValue && !readOnly ? (
                  <ClearIcon
                    style={{ marginRight: '14px', marginLeft: '10px', color: 'rgba(0, 0, 0, 0.4)', cursor: 'pointer' }}
                    onClick={e => handleChange(e)}
                  />
                ) : !readOnly ? (
                  <img
                    src={ArrowIcon}
                    style={{ marginRight: '10px', marginLeft: '10px', marginTop: '4px' }}
                    {...props}
                  />
                ) : null
              }
            >
              {search && !hasCountryField && (
                <ListSubheader style={{ background: '#fff', paddingTop: '10px' }}>
                  <TextField
                    size="small"
                    // eslint-disable-next-line jsx-a11y/no-autofocus
                    autoFocus
                    variant="outlined"
                    color="secondary"
                    placeholder={`Type to search...`}
                    fullWidth
                    disabled={disabled}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    onChange={e => setSearchText(e.target.value)}
                    onKeyDown={e => {
                      if (e.key !== 'Escape') {
                        e.stopPropagation();
                      }
                    }}
                  />
                </ListSubheader>
              )}
              {multiple
                ? displayedOptions?.map(x => {
                    return useMenuItem ? (
                      <MenuItem
                        className={value?.indexOf(x?.Description) > -1 ? classes.selected : classes.menuItem}
                        value={x?.Description}
                        tabIndex={displayedOptions.indexOf(x)}
                        disabled={disabledOptions?.find(item => item?.ID === x?.ID)}
                      >
                        <Checkbox checked={value?.indexOf(x?.Description) > -1} />
                        <ListItemText primary={x?.Description ?? ''} />
                      </MenuItem>
                    ) : (
                      <option
                        className={classes.menuItem}
                        value={x?.Description}
                        tabIndex={displayedOptions.indexOf(x)}
                        disabled={disabledOptions?.find(item => item?.ID === x?.ID)}
                      >
                        {x?.Description ?? ''}
                      </option>
                    );
                  })
                : name !== 'piSpecialty' && name !== 'piUnitSpecialty' && name !== 'specialty'
                ? displayedOptions?.map((x, index) => {
                    return useMenuItem ? (
                      <MenuItem
                        className={classes.menuItem}
                        key={index}
                        value={x?.Description}
                        tabIndex={displayedOptions.indexOf(x)}
                        disabled={disabledOptions?.find(item => item?.ID === x?.ID)}
                        sx={{
                          'pointer-events': disabledOptions?.find(item => item?.ID === x?.ID)
                            ? tooltipMsg
                              ? 'auto !important'
                              : 'none'
                            : '',
                        }}
                      >
                        {disabledOptions?.find(item => item?.ID === x?.ID) ? (
                          <WhiteTooltip
                            title={tooltipMsg ? tooltipMsg : ''}
                            PopperProps={{
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, -15],
                                  },
                                },
                              ],
                            }}
                          >
                            <span>{x?.Description}</span>
                          </WhiteTooltip>
                        ) : (
                          x?.Description ?? ''
                        )}
                      </MenuItem>
                    ) : (
                      <option
                        className={classes.menuItem}
                        key={index}
                        value={x?.Description}
                        tabIndex={displayedOptions.indexOf(x)}
                        disabled={disabledOptions?.find(item => item?.ID === x?.ID)}
                      >
                        {x?.Description ?? ''}
                      </option>
                    );
                  })
                : displayedOptions?.map((x, index) => {
                    return useMenuItem ? (
                      <MenuItem
                        className={classes.menuItem}
                        key={index}
                        value={x?.object?.Description}
                        tabIndex={displayedOptions.indexOf(x)}
                        disabled={disabledOptions?.find(item => item?.ID === x?.ID)}
                      >
                        {x?.object?.Description ?? ''}
                      </MenuItem>
                    ) : (
                      <option
                        className={classes.menuItem}
                        key={index}
                        value={x?.object?.Description}
                        tabIndex={displayedOptions.indexOf(x)}
                        disabled={disabledOptions?.find(item => item?.ID === x?.ID)}
                      >
                        {x?.object?.Description ?? ''}
                      </option>
                    );
                  })}
            </Select>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {!(value || inputValue) && (
              <ErrorMessage
                errors={errors}
                name={name}
                render={({ message }) => (
                  <span
                    style={{
                      color: isOrderCreationElement ? '#D90000' : '#f44336',
                      fontSize: isOrderCreationElement ? '12px' : '0.75rem',
                      paddingLeft: isOrderCreationElement || isContactPreference ? '12px' : undefined,
                    }}
                  >
                    {errors[name]?.type === 'required' ? 'Required' : message}
                  </span>
                )}
              />
            )}
          </React.Fragment>
        )}
        control={control}
        name={name}
        error={!!errors[name]}
        helperText={errors[name] ? (errors[name]?.type === 'required' ? 'Required' : message) : ''}
        rules={{
          required: require,
        }}
      />
    </FormControl>
  );
};
