import { ISmsResult } from 'app/models/Sms/ISmsResult';

export function GetOldestChatId(chatResults : ISmsResult[]) {
    if (chatResults.length === 0) return null;
  
    const oldestRecord = chatResults.reduce((oldest, current) => {
      return new Date(current.publishedOn) < new Date(oldest.publishedOn) ? current : oldest;
    }, chatResults[0]);
  
    return oldestRecord.id;
}
