/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable i18next/no-literal-string */
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CloseIcon from '@mui/icons-material/Close';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import EmailDisabled from 'app/assets/images/CandidateHeader/email-Disabled.svg';
import CallDisabledIcon from 'app/assets/images/Notification/Inbox/CallDisabledIcon.svg';
import DisableMailIcon from 'app/assets/images/Notification/Inbox/DisableMailIcon.svg';
import AddNotes from '@mui/icons-material/NoteAddOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Toolbar,
  Tooltip,
} from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import AddNotesDisabled from 'app/assets/images/Notification/add-notes-disabled.svg';
import EmojiIcon from 'app/assets/images/Notification/SMS/Images/emoji.svg';
import SendIcon from 'app/assets/images/Notification/SMS/Images/Send.svg';
import SendIconDisabled from 'app/assets/images/Notification/SMS/Images/SendDisabled.svg';
import {
  candidateDetailActions,
  loadGridUserLogs,
  loadUserLogs,
} from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { TwilioExtensionCode, formatPhoneNumber, unformatPhoneNumber } from 'app/helpers/phoneHelpers';
import { ConsentType, IEmployee, IInboxReadStatusCommand, ISmsPayload } from 'app/models/Notification/Notification';
import {
  FetchConversationNotes,
  GetUserLogs,
  publishSms,
  SaveConversationNotes,
} from 'app/services/NotificationServices/NotificationService';
import _, { cloneDeep } from 'lodash';
import moment from 'moment';
import { selectUser } from 'oidc/user.selectors';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast, Zoom } from 'react-toastify';
import uuid from 'react-uuid';
import styledComponent from 'styled-components';
import { CapitalizeEveryFirstLetter, Concatenate } from 'utils/string/string';
import { getAttachmentIcon } from '../../Common/AttachmentFileTypes';
import { ChangeBanner } from '../../Common/ChangeBanner';
import { CloseConfirmation } from '../../Common/CloseDialog';
import { nameInitial } from '../../Common/StringHelper';
import { ToasterContent } from '../../Common/ToasterContent';
import { ToasterHeader } from '../../Common/ToasterHeader';
import { COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT, ChannelType, ContactType, IDirection } from '../../Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import {
  attachmentDetails,
  conversationNotes,
  newConversationNotes,
  notificationSelection,
  selectSmsCandidateConsents,
} from 'store/redux-store/notification/notification.selector';
import { NotificationContext } from '../../NotificationWrapper';
import { Chat } from './Chats';
import ChooseCandidate from './Helpers/AssociateCandidate';
import DividerWithText from './Helpers/DividerWithText';
import { EMOJI_LIST } from './Helpers/EmojiList';
import { canShowSidePanel, groupMessages, smsPayloadToFormData } from './Helpers/Helper';
import AddBrand from './Helpers/AddBrand';
import { UploadFileBackdropSms } from '../../Common/UploadBackdrop';
import SendKofaxEmail from './Helpers/SendKofax';
import { ISmsResult } from 'app/models/Sms/ISmsResult';
import messageStack from '../../MessageInbox/MessageStack';
import { CompareAttachmentsInInbox } from 'app/components/GlobalSearch/helper';
import { IChat } from 'app/models/Sms/IChat';
import {
  setNotificationData,
  callUnknownNumber,
  getDefaultSender,
} from 'app/components/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';
import {
  useEnableEmailCommunication,
  useEnableOneTrustConsents,
  useEnableVoiceCommunication,
} from 'oidc/CommunicationEnabler';
import PhoneDisabled from 'app/assets/images/CandidateHeader/PhoneDisabled.svg';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import {
  selectCandidateDrawer,
  selectDetailsPageDrawer,
  selectFacilityDrawer,
  selectOrderDrawer,
  selectSmsIconDrawer,
} from '../../../../ApplicationRoot/Global.selectors';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { usePromiseTracker } from 'react-promise-tracker';
import { CustomBackdrop } from '@AMIEWEB/Common';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CandidateAvatar } from './CandidateAvatar';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { logOutboundCoveredSMS } from '../BulkSms/MessageHelpers';
import { ff_notifications_coverage_sms } from 'app/constants/FeatureFlags/EmployeeCoverage/keys';
import { useDecision } from '@optimizely/react-sdk';
import { theme } from 'styles/global-styles';
import ManualEntryField from './Helpers/ManualEntryField';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { ff_notifications_enable_shareddesk_communication } from 'app/constants/FeatureFlags/Tasks/Notifications/Keys';
import { initialsmsCandidatesConsents } from 'store/redux-store/notification/initial-state';
import { ModalAppBar, useMessagingStyles } from './Messaging.styles';
import { GetOldestChatId } from './MessagingHelper';

interface chat {
  period: string;
  chats: ISmsResult[];
}
export const DISCARD_MESSAGE = `Are you sure you want to discard this message?`;
const FILE_LIMIT_MESSAGE = `Attachment limit: 1 per message`;

export const Sms = ({
  enableBrandSelection,
  handleBrandSelection,
  isReadonlyView = false,
  displayCandidateSidePanel,
  handleSmsClose,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [sharedDeskFlag] = useDecision(ff_notifications_enable_shareddesk_communication);
  const [smsCoverageFlag] = useDecision(ff_notifications_coverage_sms);

  const { onClose, handleSubmitResponse } = useContext(NotificationContext);

  const enableEmailCommunication = useEnableEmailCommunication();
  const enableVoiceCommunication = useEnableVoiceCommunication();
  const enableOneTrustConsents = useEnableOneTrustConsents();

  const { promiseInProgress } = usePromiseTracker({ area: 'notification-saga', delay: 0 });

  const inputRef = useRef<any>(null);
  const scrollRef = useRef<any>(null);
  const chatsRef = useRef<any>();
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const storedChatId = useRef('');

  const globalData = useSelector(notificationSelection);
  const conversationNotesData = useSelector(conversationNotes);
  const newNotes = useSelector(newConversationNotes);
  const user = useSelector(selectUser);
  const attachment = useSelector(attachmentDetails);
  const coveredInbox = useSelector(selectCoveredInbox);
  const { drawerData } = useSelector(selectSearchResults);
  const candidateDrawer = useSelector(selectCandidateDrawer);
  const facilityDrawer = useSelector(selectFacilityDrawer);
  const orderDrawer = useSelector(selectOrderDrawer);
  const detailsPageDrawer = useSelector(selectDetailsPageDrawer);
  const smsIconDrawer = useSelector(selectSmsIconDrawer);
  const consents = useSelector(selectSmsCandidateConsents);

  const isLoggedUserInActiveInbox = coveredInbox?.activeInbox.coveredUserId === String(user?.userInfo?.employeeId);
  const manualEntry = globalData?.sms?.manualEntry;
  const unknownNum = !globalData?.sms?.data?.tos?.find(Boolean)?.contactId;
  const warningMessage = globalData.snackbarData?.changeWarning;
  const unreadMessageId = globalData?.sms?.chatHistory!.chats?.results?.findLast(
    chat => chat.message?.readStatus?.isRead === false,
  )?.id;
  const smsData = globalData?.sms?.data;
  const hasActiveInbox =
    (smsCoverageFlag?.enabled || sharedDeskFlag?.enabled) && !isLoggedUserInActiveInbox && !!coveredInbox?.activeInbox;
  const manuallyEnteredNumber = globalData?.sms?.manuallyEnteredNumber;
  const candidateDetails = globalData.sms.chatHistory!.candidateDetails;
  const candidateName = Concatenate([candidateDetails?.name?.first, candidateDetails?.name?.last], ' ');
  const validFileFormats = /(\.jpeg|\.jpg|\.png)$/i;

  const { classes } = useMessagingStyles({ unknownNum, warningMessage, enableBrandSelection });

  const [loader, setLoader] = useState(false);
  const [chats, setChats] = useState<chat[]>(groupMessages(globalData?.sms?.chatHistory?.chats?.results || []));
  const [messages, setMessages] = useState(globalData?.sms?.chatHistory?.chats?.results);
  const [selectedKofaxLinks, setSelectedKofaxLinks] = useState<[string, string, string][]>([]);
  const [isEmojiListOpen, setEmojiListOpen] = useState(false);
  const [isNotesOpen, setNotesOpen] = useState(false);
  const [notes, setNotes] = useState<string>(newNotes);
  const [attachments, setAttachments] = useState<File>();
  const [textfieldCursorPosition, setTextfieldCursorPosition] = React.useState<any>(0);
  const [activeScrollPosition, setActiveScrollPosition] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState(isReadonlyView || (globalData.sms.readonly ?? false));
  const [chatData, setChatData] = React.useState(globalData.sms.chatHistory.chats);
  const [chatResults, setChatResults] = React.useState(chatData?.results);
  const [phoneNumber, setPhoneNumber] = useState<string>(manuallyEnteredNumber || '');
  const [outboundMessage, setOutboundMessage] = React.useState<any>({});

  const hasActiveSharedInbox =
    sharedDeskFlag?.enabled &&
    !!coveredInbox?.activeInbox &&
    coveredInbox?.activeInbox?.coveredUserId == user.userInfo?.sharedProfile?.employeeId;

  const SENDER: IEmployee = getDefaultSender(user);

  const activeCoveredSender: IEmployee = {
    name: `${coveredInbox?.activeInbox?.firstName} ${coveredInbox?.activeInbox?.lastName}`,
    email: coveredInbox?.activeInbox?.coveredUserEmail,
    senderId: coveredInbox?.activeInbox?.coveredUserId,
    userId: coveredInbox?.activeInbox?.coveredUserId,
    NtUserName: `${coveredInbox?.activeInbox?.firstName}.${coveredInbox?.activeInbox?.lastName}`,
    deviceName: '',
    lineNumber: '',
    phoneNumber: coveredInbox?.activeInbox?.phoneNumber,
  };

  const activeSharedDeskSender: IEmployee = {
    name: `${user.userInfo.firstName} ${user.userInfo.lastName}`,
    email: coveredInbox?.activeInbox?.coveredUserEmail,
    senderId: `${user.userInfo.employeeId}`,
    userId: `${user.userInfo.employeeId}`,
    NtUserName: user.userInfo?.ntUserName,
    deviceName: '',
    lineNumber: '',
    phoneNumber: coveredInbox?.activeInbox?.phoneNumber,
  };

  const drawerOpened =
    drawerData.open ||
    candidateDrawer.open ||
    facilityDrawer.open ||
    orderDrawer.open ||
    detailsPageDrawer.open ||
    smsIconDrawer?.open;

  //---Drag & Drop variables--
  const acceptedTypes = { images: ['.png', '.jpeg', '.jpg'] };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedTypes,
    maxSize: 614400,
    noClick: true,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length !== 0) {
        fileRejections.map(f => {
          var err = f.errors;
          err.map(e => {
            if (e.message === 'File type must be .png,.jpeg,.jpg') {
              dispatch(
                globalActions.setSnackBar({
                  message: 'notification.sms.validation.validFileTypes',
                  severity: 'error',
                }),
              );
            } else if (e.message === 'File is larger than 614400 bytes') {
              dispatch(
                globalActions.setSnackBar({
                  message: 'notification.sms.validation.maxFileSize',
                  severity: 'error',
                }),
              );
            }
          });
        });
      }

      if (acceptedFiles.length !== 0) {
        if (acceptedFiles.length > 1 || attachments) {
          dispatch(
            globalActions.setSnackBar({
              message: 'notification.sms.validation.attachmentLimit',
              severity: 'error',
            }),
          );
        } else {
          const newAttachment = acceptedFiles[0];
          setAttachments(newAttachment);
          dispatch(
            notificationDataActions.setAttachments({
              url: URL.createObjectURL(acceptedFiles[0]),
              name: acceptedFiles[0].name,
              type: newAttachment!.name.split('.')[1].toLowerCase(),
            }),
          );

          setOutboundMessage(prevState => ({
            ...prevState,
            attachment: newAttachment,
            message: { attachmentUrls: [newAttachment!.name] },
          }));
        }
      }
    },
  });

  useEffect(() => {
    const candidateIds = globalData?.sms?.data?.tos?.find(Boolean)?.contactId;
    const brandId = globalData?.sms?.data?.tos?.find(Boolean)?.brandId;
    if (candidateIds !== null && candidateIds !== undefined) {
      enableOneTrustConsents &&
        dispatch(
          notificationDataActions.getCandidatesConsentsAction({
            candidateIds: [candidateIds],
            brandId: brandId,
            consentType: ConsentType.smsModal,
          }),
        );
    } else {
      dispatch(notificationDataActions.setSmsConsents(initialsmsCandidatesConsents));
    }
  }, [candidateDetails?.travelerId]);

  useEffect(() => {
    setIsReadOnly(globalData.sms.readonly || false);
  }, [globalData.sms.readonly]);

  useEffect(() => {
    if (scrollRef.current && !activeScrollPosition) {
      scrollToMessage();
    }
  }, [scrollRef.current]);

  useEffect(() => {
    if (messagesEndRef && smsData?.scrollToMessage === undefined) {
      scrollToBottom();
    }
  }, [promiseInProgress]);

  useEffect(
    () => {
      if (newNotes === undefined || newNotes?.length === 0) {
        setNotes(conversationNotesData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [conversationNotesData],
  );

  useEffect(() => {
    if (newNotes !== undefined && newNotes.length > 0) {
      setNotesOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotes]);

  const getFile = async () => {
    const getFileFromUrl = async (url, name, type) => {
      const response = await fetch(url);
      const data = await response.blob();
      return new File([data], name, {
        type: type,
      });
    };
    if (attachment) {
      const attachmentTemp = await getFileFromUrl(attachment.url, attachment.name, attachment.type);
      setAttachments(attachmentTemp);
      setOutboundMessage(prevState => ({
        ...prevState,
        attachment: attachmentTemp,
        message: { attachmentUrls: [attachment.name] },
      }));
    }
  };

  useEffect(() => {
    getFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 100);
    }
  };
  const scrollToMessage = () => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    setOutboundMessage({
      ...smsData,
      id: uuid(),
      expand: false,
      inProgress: true,
      error: false,
      processed: false,
      direction: IDirection.Outbound,
      message: {
        attachmentUrls: [],
      },
    });
    if (newNotes === undefined || newNotes.length === 0) {
      setNotesOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsData]);

  useEffect(() => {
    setSelectedKofaxLinks([]);
  }, [candidateDetails?.travelerId]);

  useEffect(() => {
    const searchMessageId = globalData.sms?.chatHistory?.chats?.results?.find(
      message => message?.id === smsData?.scrollToMessage,
    );

    if (
      !searchMessageId &&
      globalData.sms.chatHistory?.chats?.results?.length < globalData.sms.chatHistory?.chats?.totalRecords &&
      smsData?.scrollToMessage !== undefined
    ) {
      fetchLog();
    } else if (!!searchMessageId && smsData?.scrollToMessage !== undefined) {
      scrollToMessage();
    }

    chatsRef.current = messages;
  }, [globalData?.sms?.chatHistory?.chats?.results, smsData?.scrollToMessage, messages]);

  const fetchLog = async (refresh = false) => {
    const payload = {
      contactId: `${candidateDetails?.travelerId ?? ''}`,
      pageSize: globalData.sms.chatHistory!.chats!.pageSize,
      pageNumber: refresh ? 1 : globalData.sms.chatHistory!.chats!.pageNumber + 1,
      channel: ['SMS'],
      initiatedBy: hasActiveInbox ? coveredInbox?.activeInbox?.phoneNumber : smsData.sender.phoneNumber,
      contactPhone:
        globalData.sms.chatHistory!.chats!.results.find(Boolean)?.message?.tos?.find(Boolean)?.phoneNumber ||
        globalData.sms.data?.tos?.find(Boolean)?.phoneNumber,
    };
    try {
      const response = await GetUserLogs(payload);
      setLoader(false);
      const updatedResult = refresh
        ? response.results
        : [...globalData.sms.chatHistory!.chats!.results, ...response.results];

      response.results = _.uniqBy(updatedResult, 'id');

      if (!refresh) {
        messageStack.Reset();
        messageStack.messageStack = CompareAttachmentsInInbox(response) as IChat;
      }
      dispatch(
        notificationDataActions.updateChatHistory({
          ...globalData.sms.chatHistory,
          chats: response,
        }),
      );
    } catch (err) {
      setLoader(false);
      handleSubmitResponse(true, 'error', 'Failed to fetch older messages');
    }
  };

  useEffect(() => {
    if (globalData.sms.chatHistory!.chats) {
      setMessages(globalData.sms.chatHistory!.chats.results);
      setChats(groupMessages(globalData.sms.chatHistory!.chats.results));
    } else {
      setMessages([]);
      setChats([]);
    }

    const updatedInboxMessage = globalData.inbox
      ? globalData.inbox?.map(x =>
          x.travelerId === globalData.sms.data.tos?.find(Boolean).contactId ? { ...x, unreadLogs: 0 } : x,
        )
      : [];
    dispatch(notificationDataActions.updateInbox(updatedInboxMessage));

    if (globalData.sms.chatHistory?.chats?.notifySmsUponAttachmentSendViaEmail) {
      setSelectedKofaxLinks([]);
      setChats(groupMessages(globalData.sms.chatHistory!.chats!.results));
      dispatch(notificationDataActions.setFalseSmsUponAttachmentSendViaEmail());
    }
  }, [dispatch, globalData.sms.chatHistory]);

  const handleMinimize = () => {
    const toasterId = smsData?.channel;
    dispatch(
      notificationDataActions.setSmsInteraction({
        open: false,
        minimized: true,
        showDisposition: false,
        readonly: false,
      }),
    );
    dispatch(notificationDataActions.setManuallyEnteredNumber(phoneNumber));
    dispatch(notificationDataActions.incrementActiveToaster());
    dispatch(notificationDataActions.setSnackBarData(null));
    toast(
      <ToasterContent
        channel={ChannelType.sms}
        id={toasterId}
        info={globalData?.sms?.data}
        header={
          globalData?.sms?.data?.tos?.find(Boolean)?.contactId
            ? ToasterHeader(
                globalData?.sms?.data?.tos?.find(Boolean)?.name,
                globalData?.sms?.data?.tos?.find(Boolean)?.contactId,
              )
            : formatPhoneNumber(globalData?.sms?.data?.tos?.find(Boolean)?.phoneNumber)
        }
        onClose={onClose}
      />,
      {
        toastId: toasterId,
        containerId: 'channel',
        position: 'bottom-right',
        closeButton: false,
        hideProgressBar: true,
        autoClose: false,
        closeOnClick: false,
        draggable: false,
        pauseOnHover: true,
        transition: Zoom,
      },
    );
  };
  const closeModalData = {
    sms: {
      ...globalData.sms,
      chatHistory: {},
      data: null,
      open: false,
      minimized: false,
      manualEntry: false,
      manuallyEnteredNumber: null,
    },
  };
  const resendMessage = async id => {
    //Api is not yet ready
    if (!messageStack.messageStack) return;
    const messageIndex = chatResults.findIndex(chat => chat.id === id);
    const message: ISmsPayload = _.cloneDeep(chatResults[messageIndex]);
    const dt = new Date();
    dt.setMinutes(dt.getMinutes() + 5);
    message.publishedOn = dt.toISOString();
    message.inProgress = true;
    message.error = false;
    message.processed = false;
    message.id = uuid();
    message.sender ??= message.message.sender;
    message.tos ??= message.message.tos;
    message.body ??= message.message.body;

    let chatResultsCopy = cloneDeep(chatResults);
    chatResultsCopy[messageIndex] = _.cloneDeep(message);
    setChats(groupMessages(_.cloneDeep(chatResultsCopy)));
    if (
      !message?.userRole
        .toLowerCase()
        .split(/[,.]/)
        .some(role => role.trim() === 'recruitment')
    ) {
      message.associatedRecords = message.associatedRecords.filter(record => record.name !== 'brandId');
      delete message.brand;
      delete message.brandId;
    }
    if (message?.associatedRecords) {
      message.associatedRecords = message?.associatedRecords
        .filter(
          record =>
            record?.name !== undefined && record?.name !== '' && record?.value !== undefined && record?.value !== '',
        )
        .map(record => ({
          name: typeof record?.name === 'string' ? record?.name : String(record?.name),
          value: typeof record?.value === 'string' ? record?.value : String(record?.value),
        }));
    }
    const messageForm = smsPayloadToFormData(message, attachments);
    await publishSms(messageForm);
    dispatch(candidateDetailActions.updateLastContactedOn(moment(new Date()).toISOString()));
  };
  useEffect(
    () => {
      if (isEmojiListOpen) {
        inputRef.current && inputRef.current.focus();
        inputRef.current && inputRef.current.setSelectionRange(textfieldCursorPosition, textfieldCursorPosition);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [textfieldCursorPosition],
  );

  useEffect(() => {
    let heightMultiplier = selectedKofaxLinks.length < 1 ? 120 : 85;
    if (!globalData?.sms?.data?.tos?.find(Boolean)?.contactId) {
      heightMultiplier = 73;
    } else if (enableBrandSelection) {
      heightMultiplier = 95;
    }
    setTimeout(() => {
      const header = document.getElementById('draggableArea')!;
      const content = document.getElementById('scrollArea')!;
      if (content) {
        content.style.height = 525 - header.childElementCount * heightMultiplier + 'px';
      }
    }, 0);
  }, [globalData.sms, enableBrandSelection, selectedKofaxLinks, globalData.snackbarData, globalData, isNotesOpen]);

  React.useEffect(() => {
    setChatData(globalData.sms.chatHistory?.chats);
    setChatResults(globalData.sms.chatHistory?.chats?.results);
  }, [globalData?.sms?.chatHistory?.chats]);

  const handleEmojiOnClick = val => {
    setTextfieldCursorPosition(textfieldCursorPosition + val.value.length);
    const body =
      outboundMessage?.body && outboundMessage.body.length !== textfieldCursorPosition
        ? outboundMessage?.body.substring(0, textfieldCursorPosition) +
          val.value +
          outboundMessage?.body.substring(textfieldCursorPosition)
        : outboundMessage?.body && outboundMessage?.body.length === textfieldCursorPosition
        ? outboundMessage?.body + val.value
        : val.value;
    setOutboundMessage({
      ...outboundMessage,
      body: body,
    });

    dispatch(
      notificationDataActions.setSmsData({
        ...globalData.sms,
        data: { ...outboundMessage, body: body },
      }),
    );
    inputRef.current.selectionStart = textfieldCursorPosition + val.value.length;
    inputRef.current.selectionEnd = textfieldCursorPosition + val.value.length;

    setEmojiListOpen(false);
    inputRef.current.focus();
  };
  const handleSend = async () => {
    const dt = new Date();
    dt.setMinutes(dt.getMinutes() + 5);
    outboundMessage.publishedOn = dt.toISOString();
    const outboundForPublish = _.cloneDeep(outboundMessage);
    outboundForPublish.brand = globalData?.sms?.isBrandSelectionSkipped ? null : outboundForPublish.brand;
    //Clear the textfield
    const clearedOutboundField = _.cloneDeep({
      ...outboundMessage,
      attachment: null,
      body: '',
      expand: false,
      id: Date.now().toString(36) + Math.random().toString(36).substring(2),
      message: {
        attachmentUrls: [],
      },
    });
    clearedOutboundField.brand = globalData?.sms?.isBrandSelectionSkipped ? null : clearedOutboundField.brand;
    setOutboundMessage(clearedOutboundField);

    //Clear the value in the redux store
    const messageData = {
      ..._.cloneDeep(outboundMessage),
      message: {
        attachmentUrls: attachments ? [attachments.name] : [],
        attachments: attachments
          ? [
              {
                contentUrl: attachments.name,
                dateDeliveredToKofax: null,
                thumbnailUrl: null,
              },
            ]
          : null,
      },
    };
    const result = !isNullOrUndefined(chatResults) ? [...chatResults, messageData] : [messageData];
    setChatResults(result);
    const messageStackCopy = cloneDeep(messageStack);
    messageStack.Reset();
    messageStack.messageStack = {
      ...messageStackCopy.messageStack,
      results: result,
    };
    dispatch(
      notificationDataActions.setSmsData({
        ...globalData.sms,
        attachments: undefined,
        data: { ...globalData.sms.data, body: '', attachment: attachments, scrollToMessage: undefined },
      }),
    );
    const updatedChatData = {
      ...chatData,
      results: result,
    };
    dispatch(
      notificationDataActions.updateChatHistory({
        ...globalData.sms.chatHistory,
        chats: _.cloneDeep(updatedChatData),
      }),
    );
    const { ...publishSmsPayload }: ISmsPayload = _.cloneDeep(outboundMessage);
    publishSmsPayload.isBrandSelectionSkipped =
      globalData?.sms?.isBrandSelectionSkipped || enableBrandSelection ? true : false;
    publishSmsPayload.brand = !!globalData.sms.isBrandSelectionSkipped ? null : publishSmsPayload.brand;

    publishSmsPayload.sender = hasActiveInbox
      ? hasActiveSharedInbox
        ? activeSharedDeskSender
        : (publishSmsPayload.sender = {
            name: `${coveredInbox?.activeInbox?.firstName} ${coveredInbox?.activeInbox?.lastName}`,
            email: coveredInbox?.activeInbox?.coveredUserEmail,
            senderId: coveredInbox?.activeInbox.coveredUserId,
            userId: coveredInbox?.activeInbox.coveredUserId,
            NtUserName: `${coveredInbox?.activeInbox?.firstName}.${coveredInbox?.activeInbox?.lastName}`,
            deviceName: '',
            lineNumber: '',
            phoneNumber: coveredInbox?.activeInbox?.phoneNumber,
          })
      : publishSmsPayload.sender;

    publishSmsPayload.associatedRecords = hasActiveInbox
      ? hasActiveSharedInbox
        ? [
            ...publishSmsPayload.associatedRecords,
            {
              name: 'sharedUserId',
              value: `${user.userInfo?.sharedProfile?.employeeId}`,
            },
          ]
        : [
            ...publishSmsPayload.associatedRecords,
            {
              name: 'coveringEmployeeId',
              value: `${user.userInfo?.employeeId}`,
            },
            {
              name: 'coveringEmployeeName',
              value: `${user.userInfo?.firstName} ${user.userInfo?.lastName}`,
            },
          ]
      : !manualEntry
      ? publishSmsPayload.associatedRecords
      : [];

    const tos = [
      {
        phoneNumber: TwilioExtensionCode.concat(unformatPhoneNumber(phoneNumber)),
        contactType: 'candidate',
      },
    ];

    if (manualEntry) {
      publishSmsPayload.tos = tos;
      publishSmsPayload.manualEntry = manualEntry;
    }

    if (
      !publishSmsPayload?.userRole
        .toLowerCase()
        .split(/[,.]/)
        .some(role => role.trim() === 'recruitment') ||
      globalData?.sms?.isBrandSelectionSkipped ||
      enableBrandSelection ||
      !globalData?.sms?.data?.tos?.find(Boolean)?.contactId
    ) {
      publishSmsPayload.associatedRecords = publishSmsPayload.associatedRecords.filter(
        record => record.name !== 'brandId',
      );
      delete publishSmsPayload.brandId;
      delete publishSmsPayload.brand;
      publishSmsPayload.tos = publishSmsPayload.tos?.map(item => {
        const { brand, brandId, ...rest } = item;
        return rest;
      });
    }

    setChats(groupMessages(_.cloneDeep(result!)));
    setMessages(_.cloneDeep(chatResults!));

    if (publishSmsPayload?.associatedRecords) {
      publishSmsPayload.associatedRecords = publishSmsPayload?.associatedRecords
        .filter(
          record =>
            record?.name !== undefined && record?.name !== '' && record?.value !== undefined && record?.value !== '',
        )
        .map(record => ({
          name: typeof record?.name === 'string' ? record?.name : String(record?.name),
          value: typeof record?.value === 'string' ? record?.value : String(record?.value),
        }));
    }
    const messageForm = smsPayloadToFormData(publishSmsPayload, attachments);
    setAttachments(undefined);
    const response = await publishSms(messageForm);
    if (response.data.published && isNullOrUndefined(chatResults)) {
      dispatch(
        notificationDataActions.setNotificationData({
          ...globalData,
          sms: {
            ...globalData.sms,
            data: { ...globalData.sms.data, tos: tos, body: '' },
            manualEntry: false,
            chatHistory: { ...globalData.sms.chatHistory, chats: _.cloneDeep(updatedChatData) },
            attachments: undefined,
          },
        }),
      );
    }
    if (response.data.attachmentUrls && !!response.data.attachmentUrls.length) {
      const publishedIndex = messageStack.messageStack?.results.findIndex(x => x.id === response.data.id)!;
      if (!!messageStack.messageStack && !!messageStack.messageStack?.results) {
        const attachmentsUrls = response.data.attachmentUrls as string[];
        messageStack.messageStack.results[publishedIndex].message.attachmentUrls = _.cloneDeep(attachmentsUrls);
        let chatDataResults: any = cloneDeep(messageStack.messageStack.results);
        chatDataResults[publishedIndex].message.attachments = {
          contentUrl: response.data.attachmentUrls,
          dateDeliveredToKofax: null,
          thumbnailUrl: null,
        };
        setChatResults(chatDataResults);
        const messageStackCopy = cloneDeep(messageStack);
        messageStack.Reset();
        messageStack.messageStack = {
          ...messageStackCopy.messageStack,
          results: chatDataResults,
        };
        const updatedChatData = {
          ...chatData,
          results: result,
        };
        dispatch(
          notificationDataActions.updateChatHistory({
            ...globalData.sms.chatHistory,
            chats: _.cloneDeep(updatedChatData),
          }),
        );
      }
    }
    handleInboxUpdate(outboundForPublish, response.data.attachmentUrls);
    dispatch(candidateDetailActions.updateLastContactedOn(moment(new Date()).toISOString()));
    hasActiveInbox &&
      !hasActiveSharedInbox &&
      logOutboundCoveredSMS(
        smsCoverageFlag.enabled,
        coveredInbox?.activeInbox.coveredUserId,
        String(user.userInfo?.employeeId),
        outboundMessage.publishedOn,
      );
    scrollToBottom();
  };

  const handleScroll = async (e: any, flag = false) => {
    const element = e?.target;
    if ((element?.scrollTop === 0 || flag) && globalData?.sms?.chatHistory?.chats?.nextPageExists) {
      setCurrentChatId();
      setLoader(true);
      await fetchLog().then(() => {
        setActiveScrollPosition(true);
      });
    }
  };

  useEffect(() => {
    if (activeScrollPosition) {
      setScrollPosition();
    }
    return () => {
      setActiveScrollPosition(false);
    };
  }, [activeScrollPosition]);

  const setCurrentChatId = () => {
    storedChatId.current = GetOldestChatId(chatResults);
  };
  
  const setScrollPosition = () => {
    setTimeout(() => {
      const msg = document.getElementById(storedChatId.current);
      msg?.scrollIntoView();
    }, 250);
  };

  const updateReadStatus = () => {
    const activeInboxSender = {
      name: `${coveredInbox?.activeInbox?.firstName} ${coveredInbox?.activeInbox?.lastName}`,
      email: coveredInbox?.activeInbox?.coveredUserEmail,
      senderId: coveredInbox?.activeInbox.coveredUserId,
      userId: coveredInbox?.activeInbox.coveredUserId,
      NtUserName: `${coveredInbox?.activeInbox?.firstName}.${coveredInbox?.activeInbox?.lastName}`,
      deviceName: '',
      lineNumber: '',
      phoneNumber: coveredInbox?.activeInbox?.phoneNumber,
    };
    const readStatusPayload: IInboxReadStatusCommand = {
      sender: {
        phoneNumber: hasActiveInbox ? activeInboxSender?.phoneNumber : globalData?.sms?.data?.sender?.phoneNumber,
      },
      contactId: globalData.sms.data.tos?.find(Boolean)?.contactId?.toString() || '',
      isRead: true,
      channel: ChannelType.sms,
      readOn: new Date(),
      phoneNumber: globalData.sms.data.tos?.find(Boolean)?.phoneNumber || '',
    };
    dispatch(notificationDataActions.updateMessageReadStatus(readStatusPayload));
  };

  const handleClose = () => {
    if (
      outboundMessage?.body?.length > 0 ||
      attachments !== undefined ||
      (isNotesOpen &&
        (globalData.sms.newNotes !== undefined ||
          globalData.sms.newNotes !== '' ||
          globalData.sms.notes !== globalData.sms.newNotes))
    ) {
      dispatch(notificationDataActions.setAttachments(undefined));
      onClose(ChannelType.sms, false, DISCARD_MESSAGE);
    } else {
      if (!manualEntry) updateReadStatus();
      dispatch(
        notificationDataActions.setSmsInteraction({
          open: false,
          minimized: false,
          readonly: false,
        }),
      );
      dispatch(notificationDataActions.setManualEntry(false));
      dispatch(notificationDataActions.setManuallyEnteredNumber(null));
      handleSmsClose();
    }
  };

  const handleInboxUpdate = (message, attachmentUrls: string[]) => {
    let updatedInboxResult = _.cloneDeep([...globalData.inbox]);
    var existingMessage = updatedInboxResult
      .filter(item =>
        item.travelerId !== ''
          ? item.travelerId === message.tos?.find(Boolean).contactId
          : item.name === message.tos?.find(Boolean).phoneNumber,
      )
      .find(Boolean);
    if (existingMessage) {
      //Removing the value from existing inbox records
      updatedInboxResult = updatedInboxResult.filter(item => item !== existingMessage);
      //Updating the existing record value in inbox
      existingMessage.log.id = message.id;
      existingMessage.log.publishedOn = new Date();
      existingMessage.log.message.body = message?.body;
      existingMessage.log.message.attachmentUrls = attachmentUrls;
      existingMessage.log.message.direction = IDirection.Outbound;
      existingMessage.log.associatedRecords = [
        {
          name: 'travelerId',
          value: candidateDetails?.travelerId,
        },
        {
          name: 'recruiterId',
          value: candidateDetails?.recruiter?.recruiterId,
        },
        {
          name: 'recruiter',
          value: Concatenate([candidateDetails?.recruiter?.first, candidateDetails?.recruiter?.last], ' '),
        },
        {
          name: 'brandId',
          value: candidateDetails?.brandId,
        },
      ];
      updatedInboxResult.unshift(existingMessage);
    } else {
      const associatedRecords = [
        {
          name: 'travelerId',
          value: candidateDetails?.travelerId,
        },
        {
          name: 'recruiterId',
          value: candidateDetails?.recruiter?.recruiterId,
        },
        {
          name: 'recruiter',
          value: Concatenate([candidateDetails?.recruiter?.first, candidateDetails?.recruiter?.last], ' '),
        },
        {
          name: 'brandId',
          value: candidateDetails?.brandId,
        },
      ];
      const logMessage = {
        sender: message?.sender,
        tos: message?.tos,
        body: message?.body,
        direction: IDirection.Outbound,
      };
      const newLog = {
        name: message.tos?.find(Boolean).name,
        travelerId: message.tos?.find(Boolean).contactId,
        log: { associatedRecords: associatedRecords, message: logMessage, publishedOn: new Date() },
        unreadLogs: 0,
        publishedOn: new Date(),
      };
      updatedInboxResult.unshift(newLog);
    }
    dispatch(notificationDataActions.updateInbox(updatedInboxResult));
  };

  const handleConfirmationModalClose = () => {
    dispatch(
      notificationDataActions.setNotificationData({
        ...globalData,
        ...closeModalData,
      }),
    );
    dispatch(notificationDataActions.setSnackBarData(null));
    if (!manualEntry) updateReadStatus();
  };

  const handleYesAction = () => {
    setNotesOpen(false);
    setNotes(conversationNotesData || '');
    dispatch(notificationDataActions.setNotes(''));
    dispatch(notificationDataActions.setSnackBarData(null));
    setTimeout(() => {
      scrollToBottom();
    }, 0);
  };

  const handleAddNotesOnClick = async () => {
    const request = {
      brand: smsData?.brand,
      brandId: !!smsData?.tos[0].brandId ? smsData?.tos[0].brandId?.toString() : null,
      sender: hasActiveInbox ? activeCoveredSender : SENDER,
      tos: smsData?.tos[0],
      publishedOn: new Date(),
    };
    await FetchConversationNotes(request)
      .then(response => dispatch(notificationDataActions.updateNotes(response)))
      .catch(err => console.log(err));
    setNotesOpen(true);
  };

  const handleMouseDown = event => {
    event.preventDefault();
  };

  const saveNotes = async () => {
    let newTosData = _.cloneDeep(smsData?.tos[0]);
    newTosData = { ...newTosData, brandId: !!newTosData?.brandId ? `${newTosData?.brandId}` : null };
    const addNotesData = {
      brandId: !!newTosData?.brandId ? newTosData?.brandId?.toString() : null,
      sender: hasActiveInbox ? activeCoveredSender : SENDER,
      tos: newTosData,
      notes: notes,
      publishedOn: new Date(),
    };
    await SaveConversationNotes(addNotesData)
      .then(response => {
        dispatch(
          globalActions.setSnackBar({
            message: globalData?.sms?.notes
              ? 'Note has been updated successfully!'
              : `Note has been added successfully!`,
            severity: 'success',
          }),
        );
        onClose();
      })
      .catch(error => {
        dispatch(
          globalActions.setSnackBar({
            message: `Failed to SMS save notes`,
            severity: 'error',
          }),
        );
      });
    dispatch(notificationDataActions.setNotes(''));
    setNotesOpen(false);
    const payload = {
      brand: smsData?.brand,
      brandId: smsData?.brandId?.toString(),
      contactId: globalData?.sms?.data?.tos?.find(Boolean)?.contactId,
      channel: ['Conversation', 'Email', 'Voice'],
    };
    scrollToBottom();
    setTimeout(() => {
      dispatch(loadUserLogs({ payload }));
      dispatch(loadGridUserLogs({ ...payload, pageSize: 50 }));
    }, 4000);
  };

  const handleNotesCancelOnclick = () => {
    if (conversationNotesData && conversationNotesData !== notes) {
      onClose(ChannelType.sms, false, 'Are you sure you want to cancel?');
    } else if (!conversationNotesData && notes?.length > 0) {
      onClose(ChannelType.sms, false, 'Are you sure you want to cancel?');
    } else {
      setNotesOpen(false);
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  };

  /**
   * Read sms attachment  @constructor
   * @param {Event} event - Payload for saving conversation notes .
   */
  const MAX_FILE_SIZE = 600;
  const handleSmsAttachment = (event, attachmentFile?) => {
    if (event.target.id === 'sms-btn-upload' || attachmentFile) {
      const newAttachment = attachmentFile ?? event.target.files[0];
      if (attachment && attachmentFile) {
        dispatch(
          globalActions.setSnackBar({
            message: 'notification.sms.validation.maxFilesSent',
            severity: 'error',
          }),
        );
        return;
      }
      if (!validFileFormats.exec(newAttachment.name.toLowerCase())) {
        dispatch(
          globalActions.setSnackBar({
            message: 'notification.sms.validation.validFileTypes',
            severity: 'error',
          }),
        );
      } else if (newAttachment.size / 1024 > MAX_FILE_SIZE) {
        dispatch(
          globalActions.setSnackBar({
            message: 'notification.sms.validation.maxFileSize',
            severity: 'error',
          }),
        );
      } else {
        setAttachments(newAttachment);
        dispatch(
          notificationDataActions.setAttachments({
            url: URL.createObjectURL(newAttachment),
            name: newAttachment.name,
            type: newAttachment!.name!.split('.')[1].toLowerCase(),
          }),
        );
        setOutboundMessage(prevState => ({
          ...prevState,
          attachment: newAttachment,
          message: { attachmentUrls: [newAttachment.name] },
        }));
      }
    }
  };

  const skills = () => {
    const verifiedSkills: [string, string][] = [];

    candidateDetails?.verifiedSkills.forEach(skill => {
      if (verifiedSkills.findIndex(x => x[0] === skill.disciplineAbbr && x[1] === skill.specialtyAbbr) === -1) {
        verifiedSkills.push([skill.disciplineAbbr, skill.specialtyAbbr]);
      }
    });
    return verifiedSkills.map(x => `${x[0]}, ${x[1]}`).join(' | ');
  };

  const handleNotificationData = async (
    channel: ChannelType,
    contactType: ContactType | null,
    manualLog: boolean = false,
  ) => {
    if (smsData?.tos?.find(Boolean)?.contactId) {
      setNotificationData(
        user,
        user.userInfo?.phoneNumber,
        candidateDetails,
        dispatch,
        globalData,
        channel,
        contactType,
        manualLog,
        skills,
        coveredInbox,
      );
    } else {
      callUnknownNumber(user, smsData, dispatch, globalData, channel);
    }
  };

  const Header = () => {
    const headerName = globalData?.sms?.data?.tos?.find(Boolean)?.name
      ? CapitalizeEveryFirstLetter(
          (globalData?.sms?.data?.tos?.find(Boolean)?.name.length > 15
            ? globalData?.sms?.data?.tos?.find(Boolean)?.name.substring(0, 15) + '...'
            : globalData?.sms?.data?.tos?.find(Boolean)?.name
          ).toLowerCase(),
        )
      : formatPhoneNumber(globalData?.sms?.data?.tos?.find(Boolean)?.phoneNumber);
    const todayMessages = chats.filter(chat => chat.period === 'Today');
    return !isNotesOpen ? (
      <Tooltip
        classes={{ tooltip: classes.headerTooltip, arrow: classes.headerTooltipArrow }}
        arrow
        title={
          <>
            {canShowSidePanel(globalData?.sms?.chatHistory?.chats?.results, globalData?.sms?.data?.tos) && (
              <AccountCircleOutlinedIcon
                fontSize="small"
                className={classes.addNotesIcon}
                onMouseDown={handleMouseDown}
                onClick={() => {
                  dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
                  dispatch(globalActions.resetAllRecentHistoryDrawers());
                  displayCandidateSidePanel();
                  dispatch(globalActions.setSmsIconDrawer({ open: true }));
                  dispatch(globalActions.setCandidateProfileDrawer({ open: false }));
                }}
              />
            )}
            {!consents?.hasCallConsents ? (
              <img src={CallDisabledIcon} alt="Phone disabled" className={classes.phoneDisabledIcon} />
            ) : (
              <>
                {!unknownNum ? (
                  enableVoiceCommunication ? (
                    candidateDetails?.primaryPhoneNumber ? (
                      globalData?.sms?.data?.tos?.find(Boolean)?.contactId && (
                        <PhoneOutlinedIcon
                          className={classes.addNotesIcon}
                          onMouseDown={handleMouseDown}
                          onClick={() => handleNotificationData(ChannelType.voice, ContactType.primary)}
                        />
                      )
                    ) : candidateDetails?.secondary ? (
                      globalData?.sms?.data?.tos?.find(Boolean)?.contactId && (
                        <PhoneOutlinedIcon
                          className={classes.addNotesIcon}
                          onMouseDown={handleMouseDown}
                          onClick={() => handleNotificationData(ChannelType.voice, ContactType.secondary)}
                        />
                      )
                    ) : (
                      <PhoneOutlinedIcon
                        className={classes.addNotesIcon}
                        onMouseDown={handleMouseDown}
                        onClick={() => handleNotificationData(ChannelType.voice, ContactType.primary)}
                      />
                    )
                  ) : (
                    <img src={PhoneDisabled} alt="Phone disabled" />
                  )
                ) : (
                  <></>
                )}
              </>
            )}

            {!consents?.hasEmailConsents ? (
              <img src={DisableMailIcon} alt="mail disabled" className={classes.emailDisabledIcon} />
            ) : (
              <>
                {' '}
                {enableEmailCommunication ? (
                  candidateDetails?.primaryEmail ? (
                    globalData?.sms?.data?.tos?.find(Boolean)?.contactId && (
                      <MailOutlinedIcon
                        className={classes.addNotesIcon}
                        onMouseDown={handleMouseDown}
                        onClick={() => handleNotificationData(ChannelType.email, ContactType.primary)}
                      />
                    )
                  ) : candidateDetails?.secondaryEmail ? (
                    globalData?.sms?.data?.tos?.find(Boolean)?.contactId && (
                      <MailOutlinedIcon
                        className={classes.addNotesIcon}
                        onMouseDown={handleMouseDown}
                        onClick={() => handleNotificationData(ChannelType.email, ContactType.secondary)}
                      />
                    )
                  ) : (
                    <img src={EmailDisabled} alt="Email disabled" />
                  )
                ) : (
                  <img src={EmailDisabled} alt="Email disabled" />
                )}
              </>
            )}

            {todayMessages.length === 0 ? (
              <img src={AddNotesDisabled} style={{ marginLeft: '4px', marginRight: '4px' }} />
            ) : (
              <AddNotes
                fontSize="small"
                className={classes.addNotesIcon}
                onMouseDown={handleMouseDown}
                onClick={() => handleAddNotesOnClick()}
              />
            )}
          </>
        }
      >
        <Grid className={classes.candidateHeader}>
          <Box className={classes.header} style={{ fontSize: '19px' }}>
            {globalData?.sms?.data?.tos?.find(Boolean)?.contactId
              ? `${headerName} (${globalData?.sms?.data?.tos?.find(Boolean)?.contactId})`
              : !manualEntry
              ? formatPhoneNumber(globalData?.sms?.data?.tos?.find(Boolean)?.phoneNumber)
              : t('notification.sms.newNumber')}
          </Box>
          <Box className={classes.otConsentheader}>{!consents?.hasTextConcents && t('SMS consent withdrawn')}</Box>
        </Grid>
      </Tooltip>
    ) : (
      <Box className={classes.header}>
        {globalData?.sms?.data?.tos?.find(Boolean)?.contactId
          ? `${headerName} (${globalData?.sms?.data?.tos?.find(Boolean)?.contactId})`
          : formatPhoneNumber(globalData?.sms?.data?.tos?.find(Boolean)?.phoneNumber)}
      </Box>
    );
  };

  const updateSelectionStart = () => {
    setTextfieldCursorPosition(inputRef.current.selectionStart);
  };

  const handleDeleteAttachment = () => {
    setAttachments(undefined);
    dispatch(notificationDataActions.setAttachments(undefined));
    setOutboundMessage(prevState => ({
      ...prevState,
      message: { attachmentUrls: [] },
    }));
  };

  const sendEnabled = () => {
    const enabled = outboundMessage?.body?.length > 0 || attachments !== undefined;
    if (!manualEntry) {
      return enabled;
    } else {
      return phoneNumber.length === 14 && enabled;
    }
  };

  const updateNotes = () => {
    if (conversationNotesData?.length === 0 || conversationNotesData !== notes) {
      dispatch(notificationDataActions.setNotes(notes));
    } else {
      dispatch(notificationDataActions.setNotes(''));
    }
  };

  const handleSnippetPaste = event => {
    handleSmsAttachment(event, event.clipboardData.files[0]);
  };

  return (
    <React.Fragment>
      {promiseInProgress ? (
        <CustomBackdrop open={promiseInProgress} />
      ) : (
        <Box className={`${classes.paper} ${drawerOpened ? classes.paperRightWithDrawerOpened : classes.paperRight}`}>
          {isDragActive && <UploadFileBackdropSms open getRootProps={getRootProps} />}
          <ModalAppBar id="draggableArea" position="relative">
            <Toolbar disableGutters className={classes.modalToolbar}>
              <Avatar className={!consents?.hasTextConcents ? classes.disableAvatar : classes.avatar}>
                {candidateName ? (
                  nameInitial(candidateName)
                ) : !manualEntry ? (
                  <PersonIcon />
                ) : (
                  nameInitial('', manualEntry)
                )}
              </Avatar>
              <Grid>
                <Grid item>
                  <Header />
                </Grid>
              </Grid>
              <Grid item className={classes.headerNavigation}>
                <RefreshIcon
                  onClick={() => {
                    fetchLog(true);
                    scrollToBottom();
                  }}
                  className={classes.modalIcon}
                />
                <IndeterminateCheckBoxOutlinedIcon onClick={handleMinimize} className={classes.modalIcon} />
                <CloseIcon onClick={() => handleClose()} className={classes.modalIcon} />
              </Grid>
            </Toolbar>
            {!globalData?.sms?.data?.tos?.find(Boolean)?.contactId && !manualEntry && <ChooseCandidate />}
            {!manualEntry && enableBrandSelection && <AddBrand handleBrandSelection={handleBrandSelection} />}
            {manualEntry && (
              <ManualEntryField
                phoneNumber={phoneNumber}
                setPhoneNumber={setPhoneNumber}
                disabled={!isNullOrUndefined(chatResults)}
              />
            )}
            {!!selectedKofaxLinks.length && (
              <SendKofaxEmail
                attachmentUrls={selectedKofaxLinks}
                travelerId={candidateDetails?.travelerId}
                name={candidateName}
              />
            )}
            {selectedKofaxLinks.length === 5 && (
              <ChangeBanner message={t('notification.sms.maxAttachmentValidation')} />
            )}
            {globalData?.snackbarData?.channel === ChannelType.sms && globalData.snackbarData?.changeWarning && (
              <ChangeBanner message={t('notification.finishSmsWarning')} />
            )}
          </ModalAppBar>

          {isNotesOpen && (
            <>
              <TextField
                label={t('notification.voice.enterNotes')}
                className={classes.notesModal}
                rows={
                  globalData?.snackbarData?.channel === ChannelType.sms && globalData.snackbarData?.changeWarning
                    ? 16
                    : 19
                }
                value={notes}
                onBlur={() => updateNotes()}
                multiline
                inputProps={{
                  maxLength: COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT,
                }}
                InputProps={{
                  startAdornment:
                    notes?.length >= COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT ? (
                      <InputAdornment
                        position="start"
                        style={{ position: 'absolute', top: '0', right: '0', color: theme.palette.system.errorRed }}
                      >
                        <InfoOutlinedIcon className={classes.errorInfoIcon} />
                      </InputAdornment>
                    ) : (
                      <></>
                    ),
                }}
                variant="filled"
                error={notes?.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}
                onChange={e => setNotes(e.target.value)}
              />
              <LayoutGrid container justifyContent="space-between" style={{ width: '97%', marginLeft: '0px' }} xs={12}>
                <LayoutGridItem style={{ paddingTop: '3px', paddingLeft: '20px' }}>
                  <label className={classes.errorText}>{`${
                    notes?.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT
                      ? t('notification.voice.maxCharLimit')
                      : ' '
                  }`}</label>
                </LayoutGridItem>
                <LayoutGridItem style={{ paddingTop: '3px' }}>
                  <label
                    className={
                      notes?.length === COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT
                        ? classes.errorText
                        : classes.characterCount
                    }
                  >{`${notes?.length}/${COMMUNICATION_LOG_NOTES_CHARACTER_LIMIT}`}</label>
                </LayoutGridItem>
              </LayoutGrid>
              <Box className={classes.button}>
                <Button onClick={() => handleNotesCancelOnclick()} style={{ color: 'gray' }}>{`CANCEL`}</Button>
                <Button
                  disabled={conversationNotesData === notes || notes?.length === 0}
                  onClick={() => saveNotes()}
                  color="primary"
                >{`SAVE`}</Button>
              </Box>
            </>
          )}
          {!outboundMessage.expand && !isNotesOpen && (
            <div
              {...getRootProps()}
              className={`${classes.scroll} scroll-class`}
              onScroll={handleScroll}
              id="scrollArea"
              style={{
                height: isReadOnly ? '480px' : '400px',
              }}
            >
              {loader && <CircularProgress className={classes.MuiCircularProgressRoot} />}

              {chats.map(function (messages, i) {
                return (
                  <React.Fragment>
                    <DividerWithText>{messages.period}</DividerWithText>
                    <div>
                      {messages.chats.map((chat, index) => {
                        return (
                          <>
                            {unreadMessageId === chat.id ? <DividerWithText>{`Last Read`}</DividerWithText> : undefined}
                            {smsData?.scrollToMessage && smsData?.scrollToMessage === chat.id ? (
                              <div ref={scrollRef} key={chat?.id} />
                            ) : undefined}
                            <Chat
                              resetSelected={!globalData.sms.chatHistory?.chats?.notifySmsUponAttachmentSendViaEmail}
                              attachments={chat?.message?.attachments}
                              chatId={chat.id}
                              message={
                                chat?.inProgress || chat?.error || chat.processed ? chat?.body : chat?.message?.body
                              }
                              direction={chat?.message?.direction}
                              time={chat?.publishedOn}
                              inProgress={chat?.inProgress}
                              error={chat?.error || chat?.deliveryStatus === 'Error'}
                              resendMessage={resendMessage}
                              removeKofaxLink={(fileName: string) => {
                                const copiedLinks: [string, string, string][] = [];
                                for (const link of selectedKofaxLinks) {
                                  if (link[0] === fileName) continue;
                                  copiedLinks.push([link[0], link[1], link[2]]);
                                }
                                setSelectedKofaxLinks(copiedLinks);
                              }}
                              addKofaxLink={(fileName: string, url: string, signedUrl: string) => {
                                const copiedLinks: [string, string, string][] = [];
                                for (const link of selectedKofaxLinks) {
                                  copiedLinks.push([link[0], link[1], link[2]]);
                                }
                                copiedLinks.push([fileName, url, signedUrl]);
                                setSelectedKofaxLinks(copiedLinks);
                              }}
                              selectedKofaxCount={selectedKofaxLinks.length}
                              hasResponse={!!chat?.response}
                              errorText={chat?.response?.errors?.[0]}
                              allowResend={chat.response?.canRetry}
                            />
                          </>
                        );
                      })}
                    </div>
                    <div ref={smsData?.scrollToMessage === undefined ? messagesEndRef : null} />
                  </React.Fragment>
                );
              })}
            </div>
          )}
          {globalData?.snackbarData?.channel === ChannelType.sms &&
            !globalData.snackbarData?.changeWarning &&
            !isNotesOpen && (
              <Box className={classes.snackBar}>
                <CloseConfirmation
                  yesAction={handleConfirmationModalClose}
                  noAction={() => dispatch(notificationDataActions.setSnackBarData(null))}
                />
              </Box>
            )}
          {globalData?.snackbarData?.channel === ChannelType.sms &&
            !globalData.snackbarData?.changeWarning &&
            isNotesOpen && (
              <Box className={classes.confirmationMessage}>
                <CloseConfirmation
                  yesAction={handleYesAction}
                  noAction={() => dispatch(notificationDataActions.setSnackBarData(null))}
                />
              </Box>
            )}

          {!isNotesOpen && !isReadOnly && (
            <Grid container alignItems="flex-start" justifyContent="flex-start" className={classes.messageBar}>
              <Grid item xs={1} className={classes.attachFileIcon}>
                <Tooltip arrow title={attachments !== undefined ? FILE_LIMIT_MESSAGE : ''}>
                  <label htmlFor="sms-btn-upload" className={classes.attachmentLabel}>
                    <input
                      disabled={attachments !== undefined}
                      value=""
                      style={{ display: 'none' }}
                      id="sms-btn-upload"
                      name="sms-btn-upload"
                      type="file"
                      onChange={event => handleSmsAttachment(event)}
                    />
                    <input {...getInputProps()} type="file" value="" />
                    <IconButton
                      color="primary"
                      disabled={attachments !== undefined}
                      aria-label="upload picture"
                      component="span"
                      size="small"
                      sx={{
                        height: 45,
                        width: 55,
                      }}
                    >
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                </Tooltip>
              </Grid>

              <Grid item xs={9}>
                {attachments && (
                  <Chip
                    className={classes.attachmentChip}
                    icon={
                      <img
                        src={
                          attachments &&
                          getAttachmentIcon(attachments && attachments?.name?.split('.')?.pop()?.toLowerCase())
                        }
                        alt={t('FileIcon')}
                      />
                    }
                    label={
                      attachments?.name?.length > 15 ? attachments?.name.substring(0, 15) + ' ...' : attachments?.name
                    }
                    deleteIcon={<CloseIcon />}
                    onDelete={() => handleDeleteAttachment()}
                  />
                )}

                <TextField
                  onPaste={handleSnippetPaste}
                  onSelect={updateSelectionStart}
                  inputRef={inputRef}
                  className={classes.messageBox}
                  value={outboundMessage?.body}
                  placeholder={t('Enter Message')}
                  maxRows={
                    attachments !== undefined ||
                    (globalData?.snackbarData?.channel === ChannelType.sms && globalData.snackbarData?.changeWarning)
                      ? 19
                      : 21
                  }
                  fullWidth
                  onChange={event => {
                    setOutboundMessage({
                      ...outboundMessage,
                      body: event.target.value,
                    });
                  }}
                  onBlur={event => {
                    dispatch(
                      notificationDataActions.setSmsData({
                        ...globalData.sms,
                        data: { ...outboundMessage, body: event.target.value },
                      }),
                    );
                  }}
                  onKeyDown={e => {
                    if (
                      e.keyCode === 13 &&
                      !e.shiftKey &&
                      (outboundMessage?.body.replace(/\s+/g, '').length > 0 ||
                        outboundMessage?.message?.attachmentUrls?.length > 0) &&
                      sendEnabled()
                    ) {
                      e.preventDefault();
                      scrollToBottom();
                      handleSend();
                    }
                    if (e.keyCode === 13 && outboundMessage?.body.replace(/\s+/g, '').length === 0 && sendEnabled()) {
                      e.preventDefault();
                      setOutboundMessage({
                        ...outboundMessage,
                        body: '',
                      });
                    }
                  }}
                  variant="outlined"
                  multiline
                  inputProps={{ maxLength: 1600 }}
                  InputProps={{
                    className: classes.input,
                    autoFocus: !manualEntry,
                    startAdornment:
                      hasActiveInbox && !hasActiveSharedInbox ? (
                        <InputAdornment position="start">
                          <CandidateAvatar
                            firstName={coveredInbox?.activeInbox?.firstName}
                            lastName={coveredInbox?.activeInbox?.lastName}
                            avatarColor={coveredInbox?.activeInbox?.avatarColor}
                          />
                        </InputAdornment>
                      ) : null,
                    endAdornment: (
                      <InputAdornment position="end">
                        <ClickAwayListener onClickAway={() => setEmojiListOpen(false)}>
                          <Tooltip
                            classes={{
                              tooltip: classes.emojiGrid,
                              arrow: classes.emojiTooltipArrow,
                            }}
                            PopperProps={{
                              disablePortal: true,
                            }}
                            arrow
                            onClose={() => setEmojiListOpen(false)}
                            open={isEmojiListOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={
                              <Grid className={classes.grid} alignItems="center" container spacing={3}>
                                {EMOJI_LIST.map(emoji => {
                                  return (
                                    <Grid
                                      item
                                      key={emoji.id}
                                      className={classes.emoji}
                                      onClick={() => handleEmojiOnClick(emoji)}
                                    >
                                      <Tooltip
                                        title={emoji.name}
                                        classes={{
                                          tooltip: classes.tooltipBackground,
                                          arrow: classes.tooltipArrow,
                                        }}
                                        arrow
                                      >
                                        <span>{emoji.value}</span>
                                      </Tooltip>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            }
                          >
                            <IconButton
                              color="primary"
                              className={classes.expandedModeEmoji}
                              onClick={() => setEmojiListOpen(!isEmojiListOpen)}
                              size="small"
                            >
                              <img src={EmojiIcon} />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1} style={{ paddingBottom: '1%', paddingRight: '10%' }}>
                <IconButton color="primary" className={classes.sendIcon} disabled={!sendEnabled()} size="small">
                  {sendEnabled() ? (
                    <img
                      alt="SendIcon"
                      src={SendIcon}
                      onClick={() => {
                        scrollToBottom();
                        handleSend();
                      }}
                    />
                  ) : (
                    <img src={SendIconDisabled} alt="SendIconDisabled" />
                  )}
                </IconButton>
              </Grid>
            </Grid>
          )}
        </Box>
      )}
    </React.Fragment>
  );
};
