import { Checkbox, Typography } from 'amn-ui-core';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import Divider from '@mui/material/Divider';
import { ISkillChecklistSection } from '../Config/model';
import ActiveCheckButton from 'app/assets/images/CandidateProfile/ActiveCheckButton.svg';
import NonActiveCheckButton from 'app/assets/images/CandidateProfile/NonActiveCheckButton.svg';
import { useTranslation } from 'react-i18next';
import { ConversionHeading } from '../Config/constants';
import { RatingValue } from '../Config/enums';
import TextAndDatePanel from './TextAndDatePanel';
import LegendsPreview from './LegendsPreview';
import { getFieldSections } from './helper';

/**
 * Component to render rating legend section on view skills checklist
 */
const RatingLegend = () => {
  const { t } = useTranslation();
  return (
    <LayoutGridItem container item direction="column" rowSpacing={2} pb={'12px'}>
      <LayoutGridItem item>
        <Typography variant="subtitle1" color="#1B3F69" sx={{ fontWeight: 'bold' }}>
          {t('candidate.credentials.attachments.viewSkillChecklist.ratingLegend')}
        </Typography>
      </LayoutGridItem>
      {[
        RatingValue.No_Experience,
        RatingValue.Intermittent_Experience,
        RatingValue.Proficient,
        RatingValue.Expert_Level,
      ].map((item, index) => (
        <LayoutGridItem item key={index}>
          <LegendsPreview ratingId={item} width={'auto'} />
        </LayoutGridItem>
      ))}
    </LayoutGridItem>
  );
};

/**
 * Component to render Acknowledgment section on view skills checklist
 */
const Acknowledgment = () => {
  const { t } = useTranslation();
  return (
    <LayoutGridItem container item direction="column" rowSpacing={2}>
      <LayoutGridItem item>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          {t('candidate.credentials.attachments.viewSkillChecklist.acknowledgement')}
        </Typography>
      </LayoutGridItem>
      <LayoutGridItem item>
        <Typography variant="body1">
          {t('candidate.credentials.attachments.viewSkillChecklist.acknowledgementAgreement1')}
        </Typography>
      </LayoutGridItem>

      <LayoutGridItem container item>
        <LayoutGridItem item xs={0.5}>
          <Checkbox style={{ padding: 0 }} defaultChecked disabled={true} />
        </LayoutGridItem>
        <LayoutGridItem item xs={8}>
          <Typography>{t('candidate.credentials.attachments.viewSkillChecklist.acknowledgementAgreement2')}</Typography>
        </LayoutGridItem>
      </LayoutGridItem>
    </LayoutGridItem>
  );
};

/**
 * Component to render Conversion section on view skills checklist
 */
const Conversion = ({ conversionData, key }: { conversionData: ISkillChecklistSection; key: number }) => {
  return (
    <LayoutGridItem container item key={key}>
      <LayoutGridItem item>
        <Typography color="#1B3F69" variant="subtitle1" fontWeight="500">
          {`CONVERSION`}
        </Typography>
      </LayoutGridItem>
      <LayoutGridItem container item pb="12px">
        <LayoutGridItem item xs={3}>
          <Typography>EMR</Typography>
        </LayoutGridItem>
        <LayoutGridItem item xs={3} style={{ display: 'flex' }}>
          <>
            <img src={NonActiveCheckButton} alt="emr-active-checkbox" />
            <img src={ActiveCheckButton} alt="emr-active-checkbox" style={{ paddingLeft: '6px' }} />
            <Typography style={{ paddingLeft: '6px' }}>{conversionData.hasAnswer ? 'Yes' : 'No'} </Typography>
          </>
        </LayoutGridItem>
      </LayoutGridItem>
      <Divider style={{ width: '98%' }} />
    </LayoutGridItem>
  );
};

/**
 * Component to render Certification section on view skills checklist
 */
const Certification = ({ certificateData }: { certificateData: ISkillChecklistSection[] }) => {
  const certificationList = certificateData.filter(item => item.typeId !== ConversionHeading && item?.hasAnswer);
  const middleIndex = Math.ceil(certificationList.length / 2);
  const firstHalf = certificationList.slice(0, middleIndex);
  const secondHalf =
    certificationList?.length === 1
      ? []
      : certificationList?.length % 2 === 0
        ? certificationList?.slice(-middleIndex)
        : certificationList?.slice(-(middleIndex - 1));

  return (
    <>
      <LayoutGridItem container item direction="column">
        <LayoutGridItem>
          <Typography color="#1B3F69" variant="subtitle1" fontWeight="500">
            {`CERTIFICATIONS`}
          </Typography>
        </LayoutGridItem>
        <LayoutGridItem container item direction="row" pb="12px">
          <LayoutGridItem item xs={5.8} justifyContent={'space-between'}>
            {firstHalf?.map((currentItem, index) => getFieldSections({ currentItem, index }))}
          </LayoutGridItem>
          <LayoutGridItem item xs={0.2} marginRight={'12px'}>
            <Divider orientation="vertical" />
          </LayoutGridItem>
          <LayoutGridItem item xs={5.8} justifyContent={'space-between'}>
            {secondHalf?.map((currentItem, index) => getFieldSections({ currentItem, index }))}
          </LayoutGridItem>
        </LayoutGridItem>
        <Divider style={{ width: '98%' }} />
      </LayoutGridItem>
    </>
  );
};

export { RatingLegend, Certification, Conversion, Acknowledgment };
