import { Avatar, Typography } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { makeStyles } from 'tss-react/mui';
import { CapitalizeEveryFirstLetter } from 'utils/string/string';
import { formatLogPhoneNumber, getAvatar } from './Helper';

const useStyles = makeStyles<{
  isManualLog: boolean;
}>()((theme, { isManualLog }) => ({
  headerContainer: {
    padding: '16px 24px',
    backgroundColor: theme.palette.framework.system.backgroundGrey,
    margin: '0px !important',
    width: '100%',
  },
  avatar: {
    color: theme.palette.components.avatar.color,
    fontSize: '30px',
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(10),
      width: theme.spacing(10),
    },
    background: isManualLog ? theme.palette.framework.system.grassGreen : theme.palette.framework.system.violet,
  },
  headerText: {
    fontSize: '16px',
    color: isManualLog ? theme.palette.framework.system.grassGreen : theme.palette.framework.system.violet,
    fontWeight: 500,
  },
  titleContainer: {
    marginLeft: '6px',
  },
}));

const LogHeader: React.FC = () => {
  const { t } = useTranslation();
  const globalData = useSelector(notificationSelection);
  const data = globalData?.log?.data;
  const { classes } = useStyles({ isManualLog: data?.manualLog });
  const contactName = data?.tos?.find(Boolean)?.name?.toLowerCase();
  const contactNumber = formatLogPhoneNumber(data?.tos?.find(Boolean).phoneNumber);

  return (
    <React.Fragment>
      <LayoutGrid container className={classes.headerContainer}>
        <Avatar className={classes.avatar}>{getAvatar(data)}</Avatar>
        <LayoutGridItem className={classes.titleContainer}>
          <LayoutGridItem item>
            <Typography className={classes.headerText}>{`${CapitalizeEveryFirstLetter(contactName || '')}`}</Typography>
          </LayoutGridItem>
          <LayoutGridItem item>
            <Typography fontWeight={500}>
              {`${contactNumber ? `${contactNumber} | `: ''}${t(
                'notification.voice.manualLog.contactId',
              )} ${data?.tos?.find(Boolean)?.contactId}`}
            </Typography>
          </LayoutGridItem>
        </LayoutGridItem>
      </LayoutGrid>
    </React.Fragment>
  );
};

export default LogHeader;
