import { ITypeAheadOption } from 'app/components/Tasks/CustomComponents/ControlledTypeAheadWithAvatar';

export interface ILookupOptions {
  isOptionsLoaded: boolean;
  divisionOptions: [];
  disciplineOptions: [] | any;
  specialtiesOptions: [] | any;
  subSpecialtiesOptions: [] | any;
  facilityStatusOptions: [] | any;
  orderTypeOptions: [] | any;
  orderPriorityOptions: [] | any;
  compactStateOptions: [] | any;
  stateOptions: [] | any;
  shiftListOptions: [] | any;
  accountManagerOptions: [] | any;
}

export const initialLookUpOptions: ILookupOptions = {
  isOptionsLoaded: false,
  divisionOptions: [],
  disciplineOptions: [],
  specialtiesOptions: [],
  subSpecialtiesOptions: [],
  facilityStatusOptions: [],
  orderTypeOptions: [],
  orderPriorityOptions: [],
  compactStateOptions: [],
  shiftListOptions: [],
  accountManagerOptions: [],
  stateOptions: [],
};

export interface ILookupState {
  disciplines: [] | any;
  specialties: [] | any;
  subSpecialties: [] | any;
  discSpecMapping: [] | any;
  divisionDisciplines?: IDivisionDiscipline[];
  majors: [] | any;
  degrees: [] | any;
  brands: [] | any;
  graduationMonth: [] | any;
  graduationYear: [] | any;
  countries: ICountry[];
  states: IState[];
  raceEthnicityOptions: ITypeAheadOption[];
  isLoaded: boolean;
  interviewReasons: [] | any;
  divisions: [];
  hearsayCertifications: [] | any;
  licenseTypes: [] | any;
  auditType: [] | any;
  auditAction: [] | any;
  auditStatus: [] | any;
  auditResponsible: [] | any;
  skillsetRejectionReason: [] | any;
  maritalStatus: [] | any;
  furnitureExtras: [] | any;
  keyFacts: [] | any;
  typeBed: [] | any;
  housingType: [] | any;
  lookUpOptions?: [] | any;
  facilityLookUp?: string[] | undefined
}

export interface IState {
  ID: string;
  Description: string;
  StateNumber: number;
  CountryID: number;
}

export interface ICountry {
  ID: number;
  Description: string;
  CountryDialingCode: string | null;
}

export interface IMonth {
  id: number;
  Description: string;
}
export interface IYear {
  id: number;
  Description: number;
}

export enum CalculationType {
  'Combined Experience' = 1,
  'Individual Experience' = 2,
}

export interface IDivisionDiscipline {
  amnDivisionId: number;
  disciplineId: number;
  specialtyId: number;
  calculationDescription: 'Combined Experience' | 'Individual Experience';
  experienceDays: number;
}
export const initialState: ILookupState = {
  disciplines: [],
  specialties: [],
  subSpecialties: [],
  discSpecMapping: [],
  divisionDisciplines: [],
  countries: [],
  states: [],
  majors: [],
  degrees: [],
  brands: [],
  graduationMonth: [],
  graduationYear: [],
  raceEthnicityOptions: [],
  isLoaded: false,
  interviewReasons: [],
  divisions: [],
  hearsayCertifications: [],
  licenseTypes: [],
  auditType: [],
  auditAction: [],
  auditStatus: [],
  auditResponsible: [],
  skillsetRejectionReason: [],
  maritalStatus: [],
  lookUpOptions: initialLookUpOptions,
  facilityLookUp: undefined
};
