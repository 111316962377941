import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { Authorized } from 'oidc/userHelper';
import { userRoles } from 'oidc/userRoles';
import { RestrictedSSNFormControl } from 'app/ComponentLibrary/SSNTextField/RestrictedSSNTextField';
import { selectEditStatus } from '../store/placementPageAttributes.selector';
import { selectPlacementProgress } from '../../../../../../store/redux-store/placement-details/selectors';
import { selectPlacementHeaderCanidate } from '../../../../../../store/redux-store/placement-details/selectors';
import { usePromiseTracker } from 'react-promise-tracker';
import { Skeleton } from 'amn-ui-core';
import { CustomTooltip } from 'app/components/Common/Tooltips';
import { selectPlacementHeaderData } from 'store/redux-store/placement-details/selectors';
import { useTranslation } from 'react-i18next';

export const SSN = ({ id }) => {
  const { userInfo } = useSelector(selectUser);
  const [overrideEdit, setOverrideEdit] = React.useState<boolean>(false);
  const { ssn: status } = useSelector(selectEditStatus);
  const { categoryProgress } = useSelector(selectPlacementProgress);
  const { ssn: originalSSN } = useSelector(selectPlacementHeaderCanidate);
  const { promiseInProgress } = usePromiseTracker({ area: 'candidate-ssn', delay: 0 });
  const placementHeaderData = useSelector(selectPlacementHeaderData);
  const { t } = useTranslation();

  React.useEffect(() => {
    // Editable for recruiters before the placement is booked
    const isNotInOrAboveBookingStage = categoryProgress.find(item => item.tag === 'Booking' && item.state === 1);
    setOverrideEdit(
      (Authorized([userRoles.recruitment_Leadership, userRoles.recruitment_TeamMember], userInfo) || false) &&
        isNotInOrAboveBookingStage !== undefined,
    );
    // Partial Only
  }, [categoryProgress, userInfo]);

  return (
    <React.Fragment>
      {promiseInProgress ? (
        <Skeleton />
      ) : (
        <CustomTooltip
          standardMargin
          tooltipContent={
            placementHeaderData && !placementHeaderData?.isSsnKeyValid
              ? t('placement.profile.header.ssnTechnicalIssuesLbl')
              : ''
          }
        >
          <RestrictedSSNFormControl
            id={id}
            overrideEdit={overrideEdit}
            readOnly={status?.disabled || false}
            originalSSN={originalSSN?.ssn}
          />
        </CustomTooltip>
      )}
    </React.Fragment>
  );
};
