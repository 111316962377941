import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import { CallPurpose, ChannelType, FormName, SendType, UseType } from 'app/components/Notification/Constants';
import { IToken } from 'app/models/Notification/Notification';
import { selectUser } from 'oidc/user.selectors';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Concatenate } from 'utils/string/string';
import Call from 'app/assets/images/WorkDesk/Call.png';
import email from 'app/assets/images/WorkDesk/email.png';
import EmailDisabled from 'app/assets/images/CandidateHeader/email-Disabled.svg';
import PhoneDisabled from 'app/assets/images/CandidateHeader/PhoneDisabled.svg';
import {
  selectDisplayGridData,
  selectFilterChips,
} from '../../RecruiterWorkDesk/MyPlacementsDesk/RecruiterPlacements.selector';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { EmailDefaultValues } from 'app/models/Email/IEmail';
import { useEnableEmailCommunication, useEnableVoiceCommunication } from 'oidc/CommunicationEnabler';
import { selectPlacementGridData } from 'app/components/CredentialingWorkDesk/PlacementStatus/Store/PlacementStatus.selector';
import { selectFilters } from '@AMIEWEB/Common/Grid/GridStateManagement/GridState.selectors';

export const NotificationElement = props => {
  const { placementId, channel, manualLog, gridKey } = props;
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const notificationData = useSelector(notificationSelection);
  const dataSource = useSelector(gridKey === 'recruitment' ? selectDisplayGridData : selectPlacementGridData);
  const filterChips = useSelector(selectFilterChips);
  const filters = useSelector(selectFilters);

  const enableEmailCommunication = useEnableEmailCommunication();
  const enableVoiceCommunication = useEnableVoiceCommunication();

  const handleNotificationData = () => {
    const data = dataSource.find(item => item.placementId === placementId);

    const filterChipData = filterChips;
    const candidateNames = gridKey === 'recruitment' ? data?.candidate?.split(' ') : data?.candidateName?.split(' ');
    const substitutions: IToken[] = [];

    substitutions.push({
      name: 'Signature',
      input: '{{signature}}',
    });

    substitutions.push({
      name: 'First Name',
      input: '{{first_name}}',
      value: candidateNames?.length > 1 ? candidateNames[0] : '',
    });

    substitutions.push({
      name: 'Last Name',
      input: '{{last_name}}',
      value: candidateNames?.length >= 2 ? candidateNames[candidateNames?.length - 1] : '',
    });

    substitutions.push({
      name: 'Skillset',
      input: '{{skillset}}',
      value: data?.skillSet,
    });

    substitutions.push({
      name: 'Brand',
      input: '{{brand}}',
      value: data?.brand,
    });

    substitutions.push({
      name: 'Email Address',
      input: '{{email_address}}',
      value: data?.candidateEmail,
    });

    substitutions.push({
      name: 'Phone Number',
      input: '{{phone_number}}',
      value: data?.candidatePhone,
    });

    substitutions.push({
      name: 'Recruiter',
      input: '{{recruiter}}',
      value: data?.recruiterName || filterChipData?.placementRecruiter[0]?.label || props?.recruiter,
    });

    substitutions.push({
      name: 'Account Manager',
      input: '{{account_manager}}',
      value: data?.accountManagerName || data?.accountManager,
    });
    substitutions.push({
      name: 'Credential Analyst',
      input: '{{credential_analyst}}',
      value: data?.credentialingAnalystName || data?.credentialingAnalyst,
    });

    substitutions.push({
      name: 'Facility',
      input: '{{facility}}',
      value: data?.facilityName,
    });

    let communicationData = {};
    const sender = {
      name: Concatenate([user.userInfo?.firstName || '', user.userInfo?.lastName || ''], ' '),
      email: user.userInfo?.email,
      userId: user.userInfo?.employeeId,
      senderId: user.userInfo?.employeeId,
      NtUserName: user.userInfo?.ntUserName,
    };
    const candidate = {
      name: gridKey === 'recruitment' ? data?.candidate : data?.candidateName,
      email: data.candidateEmail,
      phoneNumber: data.candidatePhone,
      contactId: data.candidateId,
      ContactType: 'candidate',
      brandId: data?.brandId,
    };

    const associatedRecord = [
      { name: 'travelerId', value: data.candidateId },
      { name: 'placementId', value: data.placementId },
      { name: 'brandId', value: data.brandId },
      { name: 'facilityId', value: data.facilityId },
      {
        name: 'recruiterId',
        value: filters?.filters?.placementRecruiter?.[0]?.value || props?.recruiterId,
      },
      {
        name: 'recruiter',
        value: data?.recruiterName || filterChipData?.placementRecruiter?.[0]?.value?.name || props?.recruiter,
      },
    ];

    if (data?.orderId) {
      const obj = { name: 'orderId', value: data.orderId };
      associatedRecord.push(obj);
    }

    communicationData = {
      manualLog: manualLog,
      channel: channel,
      associatedRecords: associatedRecord,
      sender: sender,
      userRole: Concatenate(user?.userInfo?.roles || [], ','),
      tos: [candidate],
      formName: FormName[FormName.Traveler],
      reason: CallPurpose[CallPurpose.Prospecting],
      brand: data.brand,
      brandId: data.brandId?.toString(),
      useType: UseType.Recruitment,
      sendType: SendType[SendType.one_to_one],
    };
    switch (channel) {
      case ChannelType.voice:
        if (!notificationData.voice.open && notificationData.voice.minimized) {
          dispatch(
            notificationDataActions.setVoiceInteraction({
              open: true,
              minimized: false,
              showDisposition: true,
              displayAddToSummary: true,
            }),
          );
          if (notificationData.voice.data.tos.find(Boolean).contactId !== candidate.contactId) {
            dispatch(notificationDataActions.setSnackBarData({ channel: 'voice', manual: false, changeWarning: true }));
          }
        } else {
          dispatch(
            notificationDataActions.setVoiceData({
              data: communicationData,
              open: true,
              minimized: false,
              displayAddToSummary: true,
            }),
          );
        }

        break;

      case ChannelType.email:
        communicationData['bccs'] = [sender];
        communicationData['substitutions'] = substitutions;
        communicationData['body'] = '<br/><br/> {{signature}}';
        if (!notificationData.email.open && notificationData.email.minimized) {
          dispatch(
            notificationDataActions.setEmailInteraction({
              open: true,
              minimized: false,
            }),
          );
          dispatch(notificationDataActions.setSnackBarData({ channel: 'email', manual: false, changeWarning: true }));
        } else {
          dispatch(
            notificationDataActions.setNotificationData({
              ...notificationData,

              email: {
                data: {
                  ...EmailDefaultValues(),
                  ...communicationData,
                },
                open: true,
                minimized: false,
              },
              snackbarData: null,
            }),
          );
        }
        break;
    }
  };
  const PhoneElement = () => (
    <div
      onClick={() => !notificationData.voice?.open && handleNotificationData()}
      onKeyPress={() => !notificationData.voice?.open && handleNotificationData()}
      role="button"
      tabIndex={-1}
      style={{
        display: 'flex',
        justifyContent: 'center',
        cursor: !notificationData.voice?.open ? 'pointer' : 'content-menu',
      }}
    >
      <img src={Call} alt={''} />
    </div>
  );

  const EmailElement = () => (
    <div
      onClick={() => !notificationData?.email?.open && handleNotificationData()}
      onKeyPress={() => !notificationData?.email?.open && handleNotificationData()}
      role="button"
      tabIndex={-1}
      style={{
        display: 'flex',
        justifyContent: 'center',
        cursor: !notificationData?.email?.open ? 'pointer' : 'content-menu',
      }}
    >
      <img src={email} alt={''} />
    </div>
  );

  return channel === ChannelType.voice ? (
    !manualLog ? (
      enableVoiceCommunication ? (
        <PhoneElement />
      ) : (
        <img src={PhoneDisabled} alt="PhoneDisabled" />
      )
    ) : (
      <AddIcCallIcon
        onClick={() => !notificationData?.voice?.open && handleNotificationData()}
        onKeyPress={() => !notificationData?.voice?.open && handleNotificationData()}
        role="button"
        tabIndex={-1}
        color="primary"
        style={{
          display: 'flex',
          justifyContent: 'center',
          cursor: !notificationData?.voice?.open ? 'pointer' : 'context-menu',
        }}
        content-menu
      />
    )
  ) : channel === ChannelType.email ? (
    enableEmailCommunication ? (
      <EmailElement />
    ) : (
      <img src={EmailDisabled} alt="EmailDisabled" />
    )
  ) : null;
};
