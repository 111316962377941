import { put, call, takeLatest, takeEvery, select, all } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { manuallyDecrementPromiseCounter, manuallyIncrementPromiseCounter, trackPromise } from 'react-promise-tracker';
import { placementDocumentsActions } from './slice';
import { httpSuccess } from 'app/services/serviceHelpers';
import { missingField } from 'app/constants';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { PlacementService } from 'app/services/PlacementServices/placement-service';
import { getContext } from 'redux-saga-test-plan/matchers';
import _ from 'lodash';
import {
  ClientConfirmationSteps,
  DocStatus,
  IPlacementClientConfirmationDoc,
  IPlacementSubmitPacket,
  PlacementDocumentFileNamePrefix,
  SubmitPacketSteps,
} from 'store/redux-store/placement-documents/types';
import { convertToFormat, sortDates } from 'app/helpers/dateHelper';
import { base64DocumentToURL, extractMimeType } from 'app/helpers/fileHelper';
import { SharedService } from 'app/services/SharedServices/shared-service';
import i18next from 'i18next';
import { selectUser } from 'oidc/user.selectors';
import { Concatenate } from 'utils/string/string';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { selectPlacementDocuments } from './selector';

const TrackGetPlacementDocuments = (fn, ...args) => trackPromise(fn(...args), 'get-placement-documents');
const TrackGetSubmitPackets = (fn, ...args) => trackPromise(fn(...args), 'get-placement-submit-packet-documents');
const TrackDeleteDraftWrapper = (fn, ...args) => trackPromise(fn(...args), 'delete-placement-draft-template');
const TrackDownloadSubmitPackets = (fn, ...args) =>
  trackPromise(fn(...args), 'download-placement-submit-packet-documents');
const TrackGetClientConfirmationDocs = (fn, ...args) =>
  trackPromise(fn(...args), 'get-placement-client-confirmation-documents');
const TrackUpdateClientConfirmationDocStatus = (fn, ...args) =>
  trackPromise(fn(...args), 'update-confirmation-doc-status-to-sent');
const TrackUpdateCredentialingDocStatus = (fn, ...args) =>
  trackPromise(fn(...args), 'update-credentialing-doc-status-to-sent');

export function* requestPlacementDocuments(action: PayloadAction<{ placementId: number }>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(
      TrackGetPlacementDocuments,
      placementService.getPlacementDocuments,
      action.payload.placementId,
    );
    if (httpSuccess(response?.status)) {
      yield put(
        placementDocumentsActions.setPlacementDocuments(
          response.data.map((item, index) => ({
            ...item,
            id: index,
            document: item.document || missingField,
            /** prop to be used for fetching doc */
            documentType: item.documentType,
            documentId: item.id || missingField,
            documentImageId: item.documentImageId ?? 0,
            version: item.version || missingField,
            status: item.status || missingField,
            sent:
              item.sent && item?.emailLogs && item?.emailLogs?.length > 0
                ? convertToFormat(item?.emailLogs?.[0]?.publishedOn, 'MM/DD/YYYY hh:mm A', true)
                : item.sent
                ? convertToFormat(item.sent, 'MM/DD/YYYY hh:mm A', true)
                : missingField,
            emailLogs: item?.emailLogs || [],
            signed: item.signed ? convertToFormat(item.signed, 'MM/DD/YYYY hh:mm A', true) : missingField,
            template: item.template || missingField,
            source: item.source || missingField,
            lastUpdated: item.lastUpdated
              ? convertToFormat(item.lastUpdated, 'MM/DD/YYYY hh:mm A', true)
              : missingField,
          })),
        ),
      );
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestPlacementDocuments',
        area: 'src/store/redux-store/placement-documents/saga.ts',
      },
    });
  }
}

export function* requestClientConfirmationDocuments(action: PayloadAction<{ placementId: number }>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(
      TrackGetClientConfirmationDocs,
      placementService.getClientConfirmationDocs,
      action.payload.placementId,
    );
    if (httpSuccess(response?.status)) {
      const { draftDocs: _draftDocs, nonDraftDocs: _nonDraftDocs } = (
        (response.data ?? []) as IPlacementClientConfirmationDoc[]
      )
        .sort(({ updatedOn: a }, { updatedOn: b }) => sortDates(a, b, 'desc'))
        .reduce(
          ({ draftDocs, nonDraftDocs }, x) => {
            x.status === DocStatus.Draft ? draftDocs.push(x) : nonDraftDocs.push(x);
            return { draftDocs, nonDraftDocs };
          },
          { draftDocs: [], nonDraftDocs: [] },
        );

      const sortedDocs = [..._draftDocs, ..._nonDraftDocs];
      yield put(placementDocumentsActions.setClientConfirmationDocs(sortedDocs));
      if (!!sortedDocs.length) yield put(placementDocumentsActions.setSelectedClientConfirmationDoc(sortedDocs[0]));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestClientConfirmationDocuments',
        area: 'src/store/redux-store/placement-documents/saga.ts',
      },
    });
  }
}

export function* requestSubmitPackets(action: PayloadAction<{ placementId: number }>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const response = yield call(
      TrackGetSubmitPackets,
      placementService.getSubmissionsPackets,
      action.payload.placementId,
    );
    if (httpSuccess(response?.status)) {
      const packets = ((response.data ?? []) as IPlacementSubmitPacket[]).sort(
        ({ createdDate: a }, { createdDate: b }) => sortDates(a, b, 'desc'),
      );
      yield put(placementDocumentsActions.setSubmitPackets(packets));
      if (!!packets.length) yield put(placementDocumentsActions.setSelectedPacket(packets[0]));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestSubmitPackets',
        area: 'src/store/redux-store/placement-documents/saga.ts',
      },
    });
  }
}

export function* deleteDraftTemplate(action: PayloadAction<{ placementId: number }>) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const {
      userInfo: { employeeId, firstName, lastName },
    } = yield select(selectUser);

    const response = yield call(TrackDeleteDraftWrapper, placementService.deleteClientConfirmationTemplateDraft, {
      placementId: action.payload.placementId,
      userId: employeeId,
      userName: Concatenate([firstName, lastName], ' '),
    });
    if (httpSuccess(response?.status)) {
      yield put(placementDocumentsActions.setSelectedClientConfirmationDoc(undefined));
      yield call(requestClientConfirmationDocuments, action);
    } else
      yield put(
        placementDocumentsActions.setMiscellaneousField({
          key: 'errorProps',
          data: {
            message: i18next.t('placement.profile.clientConfirmation.setMiscellaneous.deleteErrorMessage'),
            severity: 'error',
            error: true,
          },
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'deleteDraftTemplate',
        area: 'src/store/redux-store/placement-documents/saga.ts',
      },
    });
  }
}

export function* downloadSubmitPacket(action: PayloadAction<{ docId: string; avoidStepUpdate?: boolean }>) {
  try {
    manuallyIncrementPromiseCounter('download-placement-submit-packet-documents');
    const sharedService: SharedService = yield getContext('sharedService');
    const response = yield call(TrackDownloadSubmitPackets, sharedService.getPlacementDocument, action.payload.docId);

    if (httpSuccess(response?.status)) {
      const { data: document } = response;
      const fileType = extractMimeType(document.fileName);
      let fileName = document?.fileName?.includes('AMN') //Todo - Once PacketGen Team updated filename will remove this validation
        ? document?.fileName
        : PlacementDocumentFileNamePrefix.submission +
          _.replace(document?.fileName, '.pdf', `_${document?.placementId}.pdf`);
      if (fileType) {
        base64DocumentToURL(document?.filebytes, {
          fileType,
          fileName: fileName,
          download: true,
        });
        if (!action.payload?.avoidStepUpdate)
          yield put(
            placementDocumentsActions.setMiscellaneousField({
              key: 'currentSubmitPacketStep',
              data: SubmitPacketSteps.download,
            }),
          );
        yield put(
          placementDocumentsActions.setMiscellaneousField({
            key: 'errorProps',
            data: undefined,
          }),
        );
      }
    } else
      yield put(
        placementDocumentsActions.setMiscellaneousField({
          key: 'errorProps',
          data: {
            message: i18next.t('placement.profile.clientConfirmation.setMiscellaneous.downloadErrormessage'),
            severity: i18next.t('placement.profile.clientConfirmation.setMiscellaneous.error'),
            error: true,
          },
        }),
      );
  } catch (error) {
    yield put(
      placementDocumentsActions.setMiscellaneousField({
        key: 'errorProps',
        data: {
          message: i18next.t('placement.profile.clientConfirmation.setMiscellaneous.downloadErrormessage'),
          severity: 'error',
          error: true,
        },
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'downloadSubmitPacket',
        area: 'src/store/redux-store/placement-documents/saga.ts',
      },
    });
  } finally {
    manuallyDecrementPromiseCounter('download-placement-submit-packet-documents');
  }
}

export function* downloadConfirmationDoc(
  action: PayloadAction<{ docId: string; docStatus: DocStatus; source: 'Classic' | 'Web' }>,
) {
  try {
    manuallyIncrementPromiseCounter('download-placement-client-confirmation-documents');
    const sharedService: SharedService = yield getContext('sharedService');
    const response =
      action.payload.source === 'Web'
        ? yield call(sharedService.getPlacementDocument, action.payload.docId)
        : yield call(sharedService.getPlacementClassicDocument, 'confirmation', action.payload.docId);
    if (httpSuccess(response?.status)) {
      const { data: document } = response;
      base64DocumentToURL(action.payload.source === 'Web' ? document.filebytes : document.data, {
        fileType: 'application/pdf',
        fileName: document.fileName,
        download: true,
      });

      yield put(
        placementDocumentsActions.setMiscellaneousField({
          key: 'errorProps',
          data: undefined,
        }),
      );
      if (action.payload.docStatus === DocStatus.NotSent)
        yield put(
          placementDocumentsActions.setMiscellaneousField({
            key: 'currentClientConfirmationStep',
            data: ClientConfirmationSteps.download,
          }),
        );
    } else
      yield put(
        placementDocumentsActions.setMiscellaneousField({
          key: 'errorProps',
          data: {
            message: i18next.t('placement.profile.clientConfirmation.setMiscellaneous.downloadErrormessage'),
            severity: 'error',
            error: true,
          },
        }),
      );
  } catch (error) {
    yield put(
      placementDocumentsActions.setMiscellaneousField({
        key: 'errorProps',
        data: {
          message: i18next.t('placement.profile.clientConfirmation.setMiscellaneous.downloadErrormessage'),
          severity: 'error',
          error: true,
        },
      }),
    );
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'downloadConfirmationDoc',
        area: 'src/store/redux-store/placement-documents/saga.ts',
      },
    });
  } finally {
    manuallyDecrementPromiseCounter('download-placement-client-confirmation-documents');
  }
}

export function* updateClientConfirmationDocStatus(
  action: PayloadAction<{
    placementId: number;
    documentId: string;
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const {
      userInfo: { employeeId: userId, firstName, lastName },
    } = yield select(selectUser);

    const payload = {
      ...action.payload,
      userId,
      userName: Concatenate([firstName, lastName], ' '),
    };

    const response = yield call(
      TrackUpdateClientConfirmationDocStatus,
      placementService.updateClientConfirmationDocStatus,
      payload,
    );

    if (httpSuccess(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('placement.profile.clientConfirmation.setMiscellaneous.docStatusUpdateMessage'),
          severity: 'success',
        }),
      );
      yield put(placementDocumentsActions.resetClientConfirmationDocs());

      const queryParams = new URLSearchParams(window.location.search);
      /** Code: Update docs */
      yield all([
        call(requestClientConfirmationDocuments, {
          type: placementDocumentsActions.getClientConfirmationDocs.type,
          payload: { placementId: action.payload.placementId },
        }),
        parseInt(queryParams.get('tab')) === 2
          ? call(requestPlacementDocuments, {
              type: placementDocumentsActions.getPlacementDocuments.type,
              payload: { placementId: action.payload.placementId },
            })
          : undefined,
      ]);

      /** Code: Update to current doc selection */
      const { clientConfirmationDocs: updatedDocs } = yield select(selectPlacementDocuments);
      yield put(
        placementDocumentsActions.setSelectedClientConfirmationDoc(
          updatedDocs.find(x => x.documentId === action.payload.documentId),
        ),
      );
    } else
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('placement.profile.clientConfirmation.setMiscellaneous.docStatusUpdateErrorMessage'),
          severity: 'error',
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateClientConfirmationDocStatus',
        area: 'src/store/redux-store/placement-documents/saga.ts',
      },
    });
  }
}

export function* updateCredentialingDocStatus(
  action: PayloadAction<{
    placementId: number;
    documentId: string;
  }>,
) {
  try {
    const placementService: PlacementService = yield getContext('placementService');
    const {
      userInfo: { employeeId: userId, firstName, lastName },
    } = yield select(selectUser);

    const payload = {
      ...action.payload,
      userId,
      userName: Concatenate([firstName, lastName], ' '),
    };

    const response = yield call(
      TrackUpdateCredentialingDocStatus,
      placementService.updateCredentialingDocStatus,
      payload,
    );

    if (httpSuccess(response.status)) {
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('placement.profile.clientConfirmation.setMiscellaneous.docStatusUpdateMessage'),
          severity: 'success',
        }),
      );
      const queryParams = new URLSearchParams(window.location.search);
      /** Code: Update docs */
      yield all([
        parseInt(queryParams.get('tab')) === 2
          ? call(requestPlacementDocuments, {
              type: placementDocumentsActions.getPlacementDocuments.type,
              payload: { placementId: action.payload.placementId },
            })
          : undefined,
      ]);
    } else
      yield put(
        globalActions.setSnackBar({
          message: i18next.t('placement.profile.clientConfirmation.setMiscellaneous.docStatusUpdateErrorMessage'),
          severity: 'error',
        }),
      );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'updateCredentialingDocStatus',
        area: 'src/store/redux-store/placement-documents/saga.ts',
      },
    });
  }
}

export function* PlacementDocumentsSaga() {
  yield takeLatest(placementDocumentsActions.getPlacementDocuments.type, requestPlacementDocuments);
  yield takeLatest(placementDocumentsActions.getPlacementSubmitPackets.type, requestSubmitPackets);
  yield takeLatest(placementDocumentsActions.getClientConfirmationDocs.type, requestClientConfirmationDocuments);
  yield takeLatest(placementDocumentsActions.deleteDraftTemplate.type, deleteDraftTemplate);
  yield takeLatest(placementDocumentsActions.updateClientConfirmationDocStatus.type, updateClientConfirmationDocStatus);
  yield takeLatest(placementDocumentsActions.updateCredentialingDocStatus.type, updateCredentialingDocStatus);
  /** takeEvery --> Allows user to download multiple packets */
  yield takeEvery(placementDocumentsActions.downloadSubmitPacket.type, downloadSubmitPacket);
  yield takeEvery(placementDocumentsActions.downloadConfirmationDoc.type, downloadConfirmationDoc);
}
