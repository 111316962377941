import React, { useState, useEffect } from 'react';
import { Grid, IconButton, Typography } from 'amn-ui-core';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import { withReadOnly } from 'oidc/withReadOnly';
import EditIcon from 'app/assets/images/Credentialing/PersonalInfo/editIcon.svg';
import { SubTitle } from '../styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { candidateDetailsSelection } from '../../../../Profile/CandidateDetails.selector';
import ManageGender from './ManageGender';
import { usePromiseTracker } from 'react-promise-tracker';
import { APIStatus } from 'app/enums/ApiStatus';
import { ITypeAheadOption } from '../../../PreferencesTab/CustomComponents/ControlledTypeAhead';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';

/**
 * Component to handle gender dropdown values under profile tab
 */

const GenderOptionsTypeAhead: any = [
  {
    label: 'Male',
    object: {
      ID: 0,
      Description: 'Male',
    },
  },
  {
    label: 'Female',
    object: {
      ID: 1,
      Description: 'Female',
    },
  },
  {
    label: 'Non-Binary',
    object: {
      ID: 2,
      Description: 'Non-Binary',
    },
  },
  {
    label: 'Decline To State',
    object: {
      ID: 3,
      Description: 'Decline To State',
    },
  },
];

const Gender = props => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [saveStatus, setSaveStatus] = useState<any>('');
  const [isValid, setIsValid] = useState<boolean>(true);
  const [gender, setGender] = useState<any>(
    GenderOptionsTypeAhead.find(
      item =>
        item?.label === candidateDetails?.gender || item?.label?.replace(/-|\s/g, '') === candidateDetails?.gender,
    )?.label,
  );
  const genderOptions = GenderOptionsTypeAhead.filter(item => item.object.ID !== 3);
  const { promiseInProgress: genderPostRequestSuccess } = usePromiseTracker({ area: 'gender', delay: 0 });

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (genderPostRequestSuccess) {
      setSaveStatus(APIStatus.Completed);
      setTimeout(() => {
        setSaveStatus('');
        setOpen(false);
      }, 5000);
    }
  }, [genderPostRequestSuccess]);

  const handleSave = (selectedValue: ITypeAheadOption) => {
    if (!selectedValue) {
      setIsValid(false);
    } else {
      dispatch(candidateDetailActions.postGender(selectedValue));
      setGender(selectedValue?.object?.Description);
    }
  };
  const handleClose = () => {
    setIsValid(true);
    setOpen(false);
  };

  return (
    <Grid item container direction="column">
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <SubTitle data-testid="gender-title">{t('candidate.personalInfoCard.gender')}</SubTitle>
        </Grid>
        {!open && (
          <Grid item style={{ width: 32 }}>
            {withReadOnly(IconButton)({
              'data-testid': 'edit-gender-btn',
              onClick: () => setOpen(true),
              color: 'secondary',
              size: 'medium',
              children: <ModeEditOutlinedIcon />,
            })}
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Grid item>
          {!open ? (
            <Typography>{gender ? gender : '--'}</Typography>
          ) : (
            <ManageGender
              open={open}
              width={'150px'}
              options={genderOptions}
              handleSave={handleSave}
              saveStatus={saveStatus}
              selected={gender}
              handleClose={handleClose}
              required={!isValid}
              clearError={() => setIsValid(true)}
              saveBtnId="save-gender-profile-tab-btn"
              closeBtnId="close-gender-profile-tab-btn"
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Gender;
