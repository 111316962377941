import { FormInputDropdown } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/Controls/FormInputDropdown';
import { FormInputText } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/Controls/FormInputText';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import countries from 'app/assets/jsons/Country.json';
import states from 'app/assets/jsons/State.json';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { useSelector } from 'react-redux';
import { selectClientContactDetails } from '../store/ClientContact.selector';
import { theme } from 'styles/global-styles';

const useStyles = makeStyles()(() => ({
  root: {
    padding: '0px 10px 10px',
    '& .MuiGrid-root': {
      margin: 0,
      width: 'auto',
    },
  },
  container: {
    width: '100%',
    boxShadow: 'none',
    border: `1px solid ${theme.palette.framework.system.silverTwo}`,
    borderRadius: '4px',
    marginBottom: '14px',
  },
  divider: {
    margin: '10px 0px 0px 0px',
  },
  child: {
    overflow: 'hidden',
    fontSize: '14px',
  },
  citySession: {
    margin: '0px !important',
    padding: '0px !important',
    '& .MuiGrid-root > .MuiGrid-item': {
      padding: 0,
      marginTop: '8px',
    },
  },
}));

export const AddressFields = props => {
  const { disabled, formMethods: controls, facilityLocation, editMode } = props;
  const { setValue, watch } = controls;
  const { classes } = useStyles();
  const { t } = useTranslation();

  const address = useSelector(selectClientContactDetails);

  const [countryValue, setCountryValue] = useState<any>();
  const [stateList, setStateList] = useState([]);
  const [stateValue, setStateValue] = useState<any>();
  const [cityLabel, setCityLabel] = useState(address?.country?.toLowerCase() === 'usa' ? 'City' : 'Province');
  const [zipLabel, setZipLabel] = useState(
    address?.country?.toLowerCase() === 'usa' ? 'Zip/Postal Code' : 'Postal Code',
  );
  const [address1Value, setAddress1Value] = useState<any>();
  const [cityValue, setCityValue] = useState<any>();
  const [zipValue, setzipValue] = useState<any>();
  const [address2Value, setAddress2Value] = useState<any>();
  const [shrink, setShrink] = useState<boolean>(false);
  const [error, setError] = useState<boolean>();

  const country = watch('country');
  const state = watch('state');

  const updateStateOptions = (countryDescription: string) => {
    const selectedCountry = countries?.countries.find(e => e.Description === countryDescription);
    const updatedStateOptions = states?.states.filter(e => e.CountryID === selectedCountry?.ID);
    const stateLIstValue = updatedStateOptions[0]?.Description || null;
    if (countryDescription == 'USA' || countryDescription == 'Canada') {
      setValue(`state`, stateLIstValue, { shouldDirty: true });
    }
    setStateList(updatedStateOptions);
    setShrink(true);
  };

  const handleCountryChange = event => {
    const selectedCountry = event.target.value;
    const isValidCountry = countries?.countries.some(country => country.Description === selectedCountry);

    if (isValidCountry) {
      setError(false);
      setValue('country', selectedCountry, { shouldDirty: true });
      setCountryValue(selectedCountry);
      updateLabels(selectedCountry);
      updateStateOptions(selectedCountry);
    } else {
      setError(true);
    }
  };

  const updateLabels = val => {
    if (val?.toLowerCase() === 'usa') {
      setCityLabel('City');
      setZipLabel('Zip/Postal Code');
    } else {
      setCityLabel('Province');
      setZipLabel('Postal Code');
    }
  };

  const handleA1Change = event => {
    setAddress1Value(event.target.value);
  };
  const handleA2Change = event => {
    setAddress2Value(event.target.value);
  };
  const handleCityChange = event => {
    setCityValue(event.target.value);
  };
  const handleZipChange = event => {
    if ((event.target.value.length <= 6 && /^[a-zA-Z0-9]+$/.test(event.target.value)) || event.target.value === '') {
      setzipValue(event.target.value);
    }
  };
  const handleStateChange = event => {
    setValue(`state`, event.target.value, { shouldDirty: true });
    setStateValue(event.target.value);
  };

  useEffect(() => {
    if (country && country !== countryValue) {
      setCountryValue(country);
      setValue('country', country, { shouldDirty: true });
      setError(countries?.countries.every(c => c.Description !== country));
      updateStateOptions(country);
    }

    if (state && state !== stateValue) {
      setStateValue(state);
      setValue('state', state, { shouldDirty: true });
    }
    updateLabels(country);
  }, [country, state]);

  useEffect(() => {
    if (!editMode) setStateValue(states.states.find(e => e.ID === facilityLocation?.state)?.Description);
  }, [facilityLocation]);

  return (
    <Grid item>
      <Grid container direction="column" spacing={2} style={{ width: '100%', padding: '10px 0px 10px 0px' }}>
        {/* Country Dropdown */}
        <Grid item xs={4} width={'20vw'} height={48}>
          <FormInputDropdown
            label={t('address.country')}
            name={`country`}
            require={false}
            options={countries?.countries}
            handleChange={handleCountryChange}
            inputValue={countryValue || ''}
            disabled={disabled}
            readOnly={disabled}
            search
            hasCountryField
            isError={error}
          ></FormInputDropdown>
        </Grid>
        <Grid item className={classes.child} width={'100%'}>
          <FormInputText
            name={`address1`}
            label={t('address.address1')}
            inputValue={address1Value}
            handleChange={handleA1Change}
            require={false}
            isCheckBox={true}
            disabled={disabled}
            readOnly={disabled}
            maxLength={200}
          ></FormInputText>
        </Grid>
        <Grid item className={classes.child} width={'100%'}>
          <FormInputText
            name={`address2`}
            label={t('address.address2')}
            inputValue={address2Value}
            handleChange={handleA2Change}
            require={false}
            isCheckBox={true}
            disabled={disabled}
            readOnly={disabled}
            maxLength={200}
          ></FormInputText>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={4}
          className={classes.citySession}
          width={'100%'}
        >
          <Grid item className={classes.child} xs={4} padding={'14px 0px 0px 12px !important'}>
            <FormInputText
              name={`city`}
              label={`${cityLabel}`}
              inputValue={cityValue}
              handleChange={handleCityChange}
              require={false}
              isCheckBox={true}
              disabled={disabled}
              readOnly={disabled}
              maxLength={50}
            ></FormInputText>
          </Grid>
          <Grid item className={classes.child} xs={4} padding={'14px 0px 0px 24px !important'}>
            {country === 'USA' || country === 'Canada' ? (
              <FormInputDropdown
                name={`state`}
                label={t('address.state')}
                require={false}
                options={stateList ?? []}
                handleChange={handleStateChange}
                inputValue={stateValue}
                disabled={disabled}
                readOnly={disabled}
                shrink={shrink}
              ></FormInputDropdown>
            ) : (
              <FormInputText
                name={`state`}
                label={`State`}
                inputValue={stateValue}
                handleChange={handleStateChange}
                require={false}
                isCheckBox={true}
                disabled={disabled}
                readOnly={disabled}
              ></FormInputText>
            )}
          </Grid>
          <Grid item className={classes.child} xs={4} padding={'14px 0px 0px 24px !important'}>
            <FormInputText
              name={`zip`}
              label={zipLabel}
              inputValue={zipValue}
              handleChange={handleZipChange}
              require={false}
              maxLength={6}
              isCheckBox={true}
              disabled={disabled}
              readOnly={disabled}
            ></FormInputText>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddressFields;
