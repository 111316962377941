import { baseAxiosInstance } from 'utils/BaseApi';
import { addContainFlag, generateExpandCollapseArray, getUnique } from 'app/helpers/arrayHelpers';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { CancelToken } from 'axios';
import { isNullOrUndefined, separateCountryCodeAndNumber } from 'app/helpers/objectHelpers';
import { httpSuccess } from '../serviceHelpers';
import { CacheType, cacheResponse, getCachedResponse } from 'utils/networkCache';
import { ICandidateAggregateSearch } from 'app/ComponentLibrary/Filter/CandidateSearch';
import { IFacilityAggregateSearch } from 'app/ComponentLibrary/Filter/FacilitySearch/Index';

const sharedBase = globalThis?.app?.env?.REACT_APP_TRACK_CHANGE_SERVICE_BASE_URL;

export async function getChangeHistory(data: any) {
  try {
    const postData = {
      entityId: data.entityId.toString(),
      entityGUID: data.entityGUID,
      entity: 'candidate',
      pageSize: 0,
      pageNumber: 0,
      sortColumn: 'string',
      sortOrder: 'string',
    };
    const response = await baseAxiosInstance.post(`${sharedBase}/change-tracker`, postData);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getChangeHistory',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getLocationsByKeyword(keyword: string) {
  try {
    const response = await baseAxiosInstance.get(`${sharedBase}/locations/search?searchString=${keyword}`);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getLocationsByKeyword',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getUserInfo() {
  try {
    const response = await baseAxiosInstance.get(`${sharedBase}/users/info`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getUserInfo',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getAMIECategorySearch(body, cancelToken) {
  try {
    const response = await baseAxiosInstance.post(`${sharedBase}/search`, body, { cancelToken });
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getAMIECategorySearch',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getSearchFilterOptions(keyword: string, cacheControl: boolean = true) {
  try {
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, `${sharedBase}/search/lookups/${keyword}`, {
        returnDataOnly: true,
      });
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(`${sharedBase}/search/lookups/${keyword}`);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, `${sharedBase}/search/lookups/${keyword}`, response);
    }
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getSearchFilterOptions',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getPlacementStatusTreeOptions() {
  try {
    const cachedData = await getCachedResponse(
      CacheType.searchOptions,
      `${sharedBase}/search/placement-status/lookup`,
      { returnDataOnly: true },
    );
    if (!isNullOrUndefined(cachedData)) {
      return cachedData;
    }
    const response = await baseAxiosInstance.get(`${sharedBase}/search/placement-status/lookup`);
    cacheResponse(CacheType.searchOptions, `${sharedBase}/search/placement-status/lookup`, response);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getPlacementStatusTreeOptions',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getCandidateTimeOffRequests(candidateId) {
  try {
    const response = await baseAxiosInstance.get(
      `${sharedBase}/candidate-preference/${candidateId}?preferenceName=timeOffRequests`,
    );
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateTimeOffRequests',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getSearchLookups(lookup, keyword) {
  try {
    const url = `${sharedBase}/search/search-lookups/${lookup}${keyword ? `?searchString=${encodeURIComponent(keyword).replace(/'/g, '%27')}` : ''
      }`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getSearchLookups',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
    return [];
  }
}

export async function getEthnicityLookups(cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/search-lookups/ethnicity`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url, {
        returnDataOnly: true,
      });
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response?.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getEthnicityLookups',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
    return [];
  }
}

export async function getCitiesByKeyword(keyword) {
  try {
    //To Fix City Filter Issue Removed : column=CITY
    const url = `${sharedBase}/locations/search?${keyword ? `&searchString=${keyword}` : ''}`;
    const response = await baseAxiosInstance.get(url);
    return getUnique(
      response.data.filter(x => x.city),
      'city',
    ).reduce(
      (resultArray, item) => [
        ...resultArray,
        {
          value: item.city,
          name: item.city,
        },
      ],
      [],
    );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCitiesByKeyword',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
    return [];
  }
}

export async function getZipByKeyword(keyword: string) {
  try {
    const url = `${sharedBase}/locations/search?${keyword ? `&searchString=${keyword}` : ''}`;
    const response = await baseAxiosInstance.get(url);
    return getUnique(
      response.data.filter(x => x.zip),
      'zip',
    ).reduce(
      (resultArray, item) => [
        ...resultArray,
        {
          key: item.zip,
          value: {
            latitude: item.latitude,
            longitude: item.longitude,
          },
          name: item.zip,
        },
      ],
      [],
    );
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getZipByKeyword',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
    return [];
  }
}

export async function getCandidatePreferenceByKey(candidateId, preferenceKey) {
  try {
    const url = `${sharedBase}/candidate-preference/${candidateId}${preferenceKey ? `?preferenceName=${preferenceKey}` : ''
      }`;
    const response = await baseAxiosInstance.get(url);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidatePreferenceByKey',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

function parseUserPreferenceValue(userPreference: { id: string; name: string; value: string }) {
  return { id: userPreference.id, name: userPreference.name, value: JSON.parse(userPreference.value) };
}

function stringifyUserPreferenceValue(userPreference: { id: any; name: string; value: any }) {
  return { id: userPreference.id, name: userPreference.name, value: JSON.stringify(userPreference.value) };
}

export async function getUserPreferences() {
  try {
    const response = await baseAxiosInstance.get(`${sharedBase}/users/preferences`);
    const data = response.data.map(parseUserPreferenceValue);
    response.data = data;
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getUserPreferences',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function saveUserPreferences(data: any, cancelToken: CancelToken) {
  try {
    const response = await baseAxiosInstance.post(
      `${sharedBase}/users/preferences`,
      data.map(stringifyUserPreferenceValue),
      { cancelToken },
    );
    return response.data?.map(parseUserPreferenceValue);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'saveUserPreferences',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getMajorList(cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/lookups/majors`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url);
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getMajorList',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getStatesList(cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/lookups/states`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url);
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getStatesList',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getDegreeList(cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/lookups/degrees`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url);
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getDegreeList',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getTravelerCompanyList(cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/lookups/travelcompany`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url);
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getTravelerCompanyList',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getDisciplineSpecialtyData() {
  try {
    const cachedData = await getCachedResponse(
      CacheType.searchOptions,
      `${sharedBase}/ConfigInfo/disciplinesSpecialtiesSubSpecialtiesList`,
    );
    if (!isNullOrUndefined(cachedData)) {
      return cachedData;
    }
    const result = await baseAxiosInstance.get(`${sharedBase}/ConfigInfo/disciplinesSpecialtiesSubSpecialtiesList`);
    cacheResponse(CacheType.searchOptions, `${sharedBase}/ConfigInfo/disciplinesSpecialtiesSubSpecialtiesList`, result);

    return result;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getDisciplineSpecialtyData',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getCitiesAndZipsByStates(object: {}) {
  try {
    const response = await baseAxiosInstance.post(`${sharedBase}/locations/list/zip`, object);
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCitiesAndZipsByStates',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getInterviewReason(cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/lookups/interviewreason`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url);
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getInterviewReason',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export const getPlacementDocumentFiles = async (docId: string) => {
  try {
    const response = await baseAxiosInstance.post(`${sharedBase}/placement/document/download`, { docId });
    return response;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getInterviewReason',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
    return err?.response;
  }
};

export async function getHearsayCertifications(cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/lookups/HearsayCertification`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url);
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getHearsayCertifications',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getLicenseTypes(cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/lookups/licenseTypes`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url);
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getLicenseTypes',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getAuditCategory(auditTypeId: number, cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/audit-category/lookup?auditTypeId=${auditTypeId}`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url);
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getAuditCategory',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getAuditSubCategory(auditCategoryId: number, cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/audit-subcategory/lookup?auditCategoryId=${auditCategoryId}`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url);
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getAuditSubCategory',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getDescriptionsByKeyword(facilityTypeId, cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/facility-description/lookup?facilityTypeId=${facilityTypeId}`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url);
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response);
    }
    return response.data;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getDescriptionsByKeyword',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getOrderType(cacheControl: boolean = true) {
  try {
    const url = `${sharedBase}/search/lookups/ordertypes`;
    if (cacheControl) {
      const cachedData = await getCachedResponse(CacheType.searchOptions, url, {
        returnDataOnly: true,
      });
      if (!isNullOrUndefined(cachedData)) {
        return cachedData;
      }
    }
    const response = await baseAxiosInstance.get(url);
    if (cacheControl && httpSuccess(response.status)) {
      cacheResponse(CacheType.searchOptions, url, response.data);
    }
    return response.data;
  } catch (err) {
    trackException({
      exception: err,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getOrderType',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getCandidateSearchLookups(params: ICandidateAggregateSearch, signal?: AbortSignal) {
  try {
    if (params.phoneNumber) {
      const codeAndPhone = separateCountryCodeAndNumber(params.phoneNumber);
      if (codeAndPhone) {
        params.phoneNumber = codeAndPhone?.number;
        params['countryDialingCode'] = codeAndPhone.countryCode;
      }
    }

    const response = await baseAxiosInstance.post(`${sharedBase}/search/search-lookups-candidate`, params, { signal });
    return response.data;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCandidateSearchLookups',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getFacilitySearchLookups(params: IFacilityAggregateSearch, signal?: AbortSignal) {
  try {
    const url = `${sharedBase}/search/search-lookups/facilitysearch` +
      (params?.facilityName || params?.fid
        ? `?${params?.facilityName ? `searchString=${params.facilityName}` : 'searchString='}` +
        (params?.fid ? `&lookupId=${params.fid}` : '')
        : '');
    const response = await baseAxiosInstance.get(url, { signal });
    return response.data;

  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getFacilitySearchLookups',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getIssuingBodiesLookupValues() {
  try {
    const response = await baseAxiosInstance.get(`${sharedBase}/search/issuing-bodies`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getIssuingBodiesLookupValues',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getLicensesLookupValues(stateAbbr: string) {
  try {
    const response = await baseAxiosInstance.get(`${sharedBase}/pick-list/licenses?state=${stateAbbr}`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getLicensesLookupValues',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function getCompactStatusLookupValues() {
  try {
    const response = await baseAxiosInstance.get(`${sharedBase}/search/lookups/stateLevels`);
    return response;
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'getCompactStatusLookupValues',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}

export async function fetchDisciplineAndSpecialty(keyword: string = '', signal?: AbortSignal, avoidSuffix = false) {
  try {
    const response = await baseAxiosInstance.get(`${sharedBase}/search/search-lookups-skillset?query=${keyword}`, {
      signal,
    });
    if (httpSuccess(response.status)) {
      const nodesWithChildren = addContainFlag(response.data, keyword);
      return { treeStructure: response.data, nodesWithChildren: generateExpandCollapseArray(nodesWithChildren) };
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.APIResponseError,
        functionName: 'fetchDisciplineAndSpecialtyI',
        area: 'src/app/services/SharedServices/SharedServices.ts',
      },
    });
  }
}
