export enum ExceptionType {
  APIRequestError = 'API Request Error',
  APIResponseError = 'API Response Error',
  CommonUIError = 'Common UI Error',
  CommonSAGAError = 'Common SAGA Error',
}

export enum FilterType {
  numeric = 'number',
  string = 'string',
}

export enum SignalREventType {
  Inbox = 'Inbox',
  OrderMatch = 'Order Match',
  Task = 'Task',
  CueNote = 'Cue Note',
  CoverageInboundSMS = 'CoverageInboundSMS',
  CoverageOutboundSMS = 'CoverageOutboundSMS',
  EmailDelivery = 'Email Delivery',
}

export enum AndOrOperator {
  And = '&',
  Or = '|',
}

export enum AndOrText {
  And = 'AND',
  Or = 'OR',
}

export enum dateAbbreviationText {
  Day = 'DD',
  Month = 'MO',
  Year = 'YR',
}

export enum AndOrOperatorSymbolToText {
  '&' = 'AND',
  '|' = 'OR',
}

export enum SkillSetCollections {
  Licenses = 'Licenses',
  Certifications = 'Certifications',
  TravelExperience = 'TravelExperience',
}
export enum CollectionType {
  LicensesIndex = 0,
  CertificationsIndex = 1,
  TravelExperienceIndex = 2,
}

export enum TravelExperienceSections {
  NoOfAssignments = 'NUMBER OF ASSIGNMENTS',
  YearsOfExperience = 'YEARS OF EXPERIENCE',
}

export enum TravelExperienceSectionIndex {
  NoOfAssignments = 1,
  YearsOfExperience = 2,
}

export enum AddressEnum {
  PermanentAddress = 1,
  TaxAddress = 4
}

export enum FacilityDetails {
  facilityId = "facilityId",
  facilityName = "facilityName",
  fid = "fid",
}