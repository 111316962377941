import { RatingValue } from './enums';

const CertificationType = 'CERTIFICATIONS';
const EMRType = 'EMR Conversion';
const Specify = 'specify';
const EmrConversionTypeId = 18;
const HeadingType = 1;
const IgnoreHeadingType = 2;
const Heading3 = 3;
const Heading4 = 4;
const ConversionHeading = 2;
const NineCheckboxType = 11;
const DateAndTextType = [9, 10];
const TextandYear = [14, 20];
const YearType = 14;
const TextAndYearType = 20;
const TextType = 8;
const AgeSpecificSubType = 'Please check the boxes below for each age group';
const ExperienceAgeGroups = 'Experience with age groups';
const excludeTypeIds = [IgnoreHeadingType, HeadingType, 13, 17, 4];
const excludeHTMLFile = 'text/html';
const RatingConfiguration = [
  {
    id: RatingValue.No_Experience,
    color: ['#4ABAEB', '#E3E3E3', '#E3E3E3', '#E3E3E3'],
  },
  {
    id: RatingValue.Intermittent_Experience,
    color: ['#4ABAEB', '#3F9AD9', '#E3E3E3', '#E3E3E3'],
  },
  {
    id: RatingValue.Proficient,
    color: ['#4ABAEB', '#3F9AD9', '#306FB9', '#E3E3E3'],
  },
  {
    id: RatingValue.Expert_Level,
    color: ['#4ABAEB', '#3F9AD9', '#306FB9', '#1B3F69'],
  },
];

const NineCheckboxConfiguration = [
  { key: 'A', value: 1 },
  { key: 'B', value: 2 },
  { key: 'C', value: 4 },
  { key: 'D', value: 8 },
  { key: 'E', value: 16 },
  { key: 'F', value: 32 },
  { key: 'G', value: 64 },
  { key: 'H', value: 128 },
  { key: 'I', value: 256 }
];

export {
  CertificationType,
  EMRType,
  Specify,
  EmrConversionTypeId,
  HeadingType,
  IgnoreHeadingType,
  excludeTypeIds,
  ConversionHeading,
  RatingConfiguration,
  excludeHTMLFile,
  DateAndTextType,
  TextandYear,
  YearType,
  TextAndYearType,
  TextType,
  Heading3,
  Heading4,
  NineCheckboxType,
  NineCheckboxConfiguration,
  AgeSpecificSubType,
  ExperienceAgeGroups
};
