import { makeStyles } from 'tss-react/mui';

export const useSubStyles = makeStyles()(theme => ({
    headerContainer: {
        minWidth: 250,
        padding: '0px 14px 5px 14px',
        maxWidth: '541px',
    },
    textFieldFid: {
        color: theme.palette.framework.system.violet,
        width: '90px',
        height: '36px',
    },
    textFieldFilterName: {
        color:  theme.palette.framework.system.violet,
        width: '410px',
        height: '36px',
    },
    focusedLabel: {
        fontSize: '16px !important', 
    },
    label: {
        left: '-6px !important',
        fontSize: '12px !important',
    },
}));