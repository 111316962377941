import React from 'react';
import { XGridDefaultColumn } from '@AMIEWEB/Common/XGrid/cells/XGridDefaults';
import { GridColumns } from '@mui/x-data-grid';
import { XGridToolTipCell } from '@AMIEWEB/Common/XGrid/cells/XGridToolTipCell';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { XGridBlueCell } from '@AMIEWEB/Common/XGrid/cells/XGridBlueCell';
import { XGridIconCell } from 'app/components/Common/XGrid/cells/XGridIconCell';
import { Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { XGridOverflowCell } from '@AMIEWEB/Common/XGrid/cells/XGridOverflowCell';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import InitiateEditModal from './InitiateEditModal';
import { ChannelType } from '@AMIEWEB/Notification/Constants';
import _isArray from 'lodash/isArray';
import { ContactsGridTooltip } from '../Helpers/ContactsGridTooltip';

export const contactColumns = (t, onStarIcon, classes): GridColumns => {
  return [
    {
      ...XGridDefaultColumn,
      field: 'starIcon',
      headerName: '',
      flex: 0,
      width: 50,
      renderCell: param => {
        return XGridIconCell({
          icon: (
            <StarBorderIcon
              sx={theme => ({
                color: param.row.isPrimary
                  ? theme.palette.components.icons.secondary.backgroundColor
                  : theme.palette.framework.system.lightGray,
                cursor: 'pointer',
              })}
            />
          ),
          align: 'center',
          onClick: onStarIcon,
        })(param.row);
      },
    },
    {
      ...XGridDefaultColumn,
      field: 'firstName',
      headerName: 'First Name',
      flex: 0,
      width: 150,
      renderCell: XGridOverflowCell(InitiateEditModal)(InitiateEditModal),
    },
    {
      ...XGridDefaultColumn,
      field: 'lastName',
      headerName: 'Last Name',
      flex: 1,
      width: 150,
      renderCell: XGridOverflowCell(InitiateEditModal)(InitiateEditModal),
    },
    {
      ...XGridDefaultColumn,
      field: 'prefix',
      headerName: 'Prefix',
      flex: 0,
      width: 50,
    },
    {
      ...XGridDefaultColumn,
      field: 'goesBy',
      headerName: 'Goes by',
      flex: 1,
      width: 85,
    },
    {
      ...XGridDefaultColumn,
      field: 'email',
      headerName: 'Email',
      flex: 0,
      width: 250,
      // @ts-ignore
      renderCell: XGridOverflowCell()(params =>
        params.formattedValue !== missingField ? (
          <Typography
            sx={theme => ({
              textDecoration: 'underline',
              color: theme.palette.system.primary,
              fontSize: '14px',
              cursor: 'pointer',
            })}
          >
            {params.row.email}
          </Typography>
        ) : (
          missingField
        ),
      ),
    },
    {
      ...XGridDefaultColumn,
      field: 'phoneNumbers',
      headerName: 'Phone',
      flex: 0,
      width: 100,
      valueFormatter: param =>
        param.value.length === 0
          ? missingField
          : _isArray(param.value) &&
            param.value
              .filter(x => x.phoneNumber !== null && x.phoneNumber !== undefined && x.phoneNumber.length > 0)
              .map(obj => `${obj.phoneType}: ${obj.phoneNumber}`)
              .join(', '),
      renderCell: params => (
        <div className={classes.tooltipLayout}>
          <ContactsGridTooltip contact={params.row} value={params.value} type={ChannelType.voice} />
        </div>
      ),
    },
    {
      ...XGridDefaultColumn,
      field: 'title',
      headerName: 'Title',
      flex: 0,
      width: 100,
    },
    {
      ...XGridDefaultColumn,
      field: 'contactPreference',
      headerName: 'Contact Preferences',
      flex: 0,
      width: 100,
    },
    {
      ...XGridDefaultColumn,
      field: 'doNotCallIn',
      headerName: 'Do Not Call',
      flex: 0,
      width: 80,
      renderCell: XGridOverflowCell()(params =>
        !isNullOrUndefined(params.row.doNotCallIn) ? (params.row.doNotCallIn ? t('Yes') : t('No')) : missingField,
      ),
    },
    {
      ...XGridDefaultColumn,
      field: 'address1',
      headerName: 'Address 1',
      flex: 0,
      width: 150,
    },
    {
      ...XGridDefaultColumn,
      field: 'address2',
      headerName: 'Address 2',
      flex: 0,
      width: 150,
    },
    {
      ...XGridDefaultColumn,
      field: 'city',
      headerName: 'City',
      flex: 0,
      width: 100,
    },
    {
      ...XGridDefaultColumn,
      field: 'state',
      headerName: 'State',
      flex: 0,
      width: 75,
    },
    {
      ...XGridDefaultColumn,
      field: 'zip',
      headerName: 'Zip',
      flex: 0,
      width: 75,
    },
    {
      ...XGridDefaultColumn,
      field: 'country',
      headerName: 'Country',
      flex: 0,
      width: 75,
    },
    {
      ...XGridDefaultColumn,
      field: 'status',
      headerName: 'Status',
      flex: 0,
      width: 100,
    },
    {
      ...XGridDefaultColumn,
      field: 'notes',
      headerName: 'Notes',
      flex: 0,
      width: 150,
    },
    {
      ...XGridDefaultColumn,
      field: 'actions',
      headerName: 'Actions',
      flex: 0,
      width: 75,
      renderCell: params =>
        XGridToolTipCell({
          tooltipContent: (param: GridRenderCellParams) => params.row.actionsSortField,
          value: (param: GridRenderCellParams) =>
            params.formattedValue !== missingField ? (
              <Typography sx={theme => ({ color: theme.palette.system.primary, fontSize: '14px', fontWeight: '400' })}>
                {params.row.actions}
              </Typography>
            ) : (
              missingField
            ),
          disabled: param => params.formattedValue === missingField,
          tooltipProps: {
            placement: 'bottom-end',
            tooltipContent: undefined,
          },
        })(XGridBlueCell),
    },
    {
      ...XGridDefaultColumn,
      field: 'units',
      headerName: 'Units',
      flex: 0,
      width: 55,
      renderCell: params =>
        XGridToolTipCell({
          tooltipContent: (param: GridRenderCellParams) => params.row.unitAttributes.tooltipContent,
          value: (param: GridRenderCellParams) =>
            params.formattedValue !== missingField ? (
              <Typography sx={theme => ({ color: theme.palette.system.primary, fontSize: '14px', fontWeight: '400' })}>
                {params.row.units}
              </Typography>
            ) : (
              missingField
            ),
          disabled: param => params.formattedValue === missingField,
          tooltipProps: {
            placement: 'bottom-end',
            tooltipContent: undefined,
          },
        })(XGridBlueCell),
    },
  ];
};
