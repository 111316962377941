import { ChipsContainer } from 'app/ComponentLibrary/Filter/ChipsContainer';
import React, { createRef, useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { determineChipSelected, generateFieldChips } from '../AmWorkDesk/PlacementsDesk/filterHelper';
import { selectFilters } from '../Common/Grid/GridStateManagement/GridState.selectors';
import { generateChips } from './helper';
import { PlacementSearchFilterName } from './Placement/SearchUtils';
import _, { cloneDeep } from 'lodash';
import { calculateParentPath, findBottomNode, injectItem, totalCountNodes } from '../Common/TreeView/TreeViewHelpers';
import { useTranslation } from 'react-i18next';
import { Collapse, Grid } from 'amn-ui-core';
import { SearchPageIcons } from 'app/ComponentLibrary/Filter/SearchPageIcons';
import { makeStyles } from 'tss-react/mui';
import { orderSearchFilterName } from './Order/SearchUtils';
import { facilitySearchFilterName } from './Facility/SearchUtils';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import { instanceOfAggregateCandidate } from 'app/ComponentLibrary/Filter/CandidateSearch';
import { candidateSearchFilterName } from './Candidate/SearchUtils';
import { isNotNullOrUndefined } from '@microsoft/applicationinsights-core-js';
import { instanceOfCandidateSkillSetFilter } from 'app/ComponentLibrary/Filter/CandidateSkillset';
import { instanceOfAggregateFacility } from 'app/ComponentLibrary/Filter/FacilitySearch/Index';

const useStyles = makeStyles()({
  fillterCollapse: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export const getFilteredSkillSets = (values, chip) => {
  //check if parent exists
  //if exists check if more than one children exists
  //if more children exists remove the chip item only.
  //if only one exists remove the chip

  const chipValue = chip?.value[0]?.children[0];
  if (chipValue) {
    let chiplist = cloneDeep(values);
    const indexes = getIndexes(values, chipValue);
    let grandParent = null;
    let parent = null;
    let child = null;
    if (indexes) {
      if (isNotNullOrUndefined(indexes.grandParentIndex)) {
        grandParent = chiplist[indexes.grandParentIndex];
        if (isNotNullOrUndefined(indexes.parentIndex)) {
          parent = grandParent.children[indexes.parentIndex];
          if (isNotNullOrUndefined(indexes.childIndex)) {
            child = parent.children[indexes.childIndex];
            if (parent?.children?.length > 1) {
              parent.children = parent?.children?.filter(c => c.value !== child.value);
            } else {
              parent.children = [];
              if (grandParent?.children?.length > 1) {
                grandParent.children = grandParent?.children?.filter(c => c.value !== parent.value);
              } else {
                grandParent.children = [];
                if (chiplist?.length > 1) {
                  chiplist = chiplist?.filter(c => c.value !== grandParent?.value);
                } else {
                  chiplist = [];
                }
              }
            }
          } else {
            if (grandParent?.children?.length > 1) {
              grandParent.children = grandParent?.children?.filter(c => c.value !== parent.value);
            } else {
              grandParent.children = [];
              if (chiplist?.length > 1) {
                chiplist = chiplist?.filter(c => c.value !== grandParent?.value);
              } else {
                chiplist = [];
              }
            }
          }
        } else {
          if (chiplist?.length > 1) {
            chiplist = chiplist?.filter(c => c.value !== grandParent?.value);
          } else {
            chiplist = [];
          }
        }
      }
    }

    return chiplist;
  } else {
    return values;
  }
};

export const getIndexes = (values, chipValue) => {
  const grandParentArray = values;
  let parentNodeFound = false;
  let childNodeFound = false;

  let grandParentIndex: number | null = null;
  let parentIndex: number | null = null;
  let childIndex: number | null = null;

  for (let i = 0; i < grandParentArray?.length; i++) {
    const grandParent = grandParentArray[i];
    grandParentIndex = i;
    if (grandParent?.value === chipValue?.value) {
      parentIndex = null;
      childIndex = null;
      break;
    }
    const parentArray = grandParent?.children;
    parentIndex = null;
    if (parentArray && parentArray?.length > 0) {
      for (let j = 0; j < parentArray?.length; j++) {
        const parent = parentArray[j];
        parentIndex = j;
        if (parent?.value === chipValue?.value) {
          parentNodeFound = true;
          childIndex = null;
          break;
        }
        const childArray = parent?.children;
        childIndex = null;
        if (childArray && childArray?.length > 0) {
          for (let k = 0; k < childArray?.length; k++) {
            const child = childArray[k];
            childIndex = k;
            if (child.value === chipValue?.value) {
              childNodeFound = true;
              break;
            }
          }
        }
        if (childNodeFound) {
          parentNodeFound = true;
          break;
        }
      }
    }
    if (parentNodeFound) {
      break;
    }
  }

  return {
    grandParentIndex: grandParentIndex,
    parentIndex: parentIndex,
    childIndex: childIndex,
  };
};

export const deleteAdditionalSkillSetFilters = (key: string, chip, skillsets) => {
  const allowDelete =
    key === chip?.value?.key || skillsets === 0 || (key === 'operation' && skillsets <= 1) ? true : false;
  return allowDelete;
};

export const SearchResultsChips = props => {
  const { classes } = useStyles();
  const { category, collapsable = true } = props;
  const [chips, setChips] = useState({});
  const [showChipsCollapseBtn, setShowChipsCollapseBtn] = useState(false);
  const hiddenSubmitButton = createRef<HTMLButtonElement>();
  const { setValue, getValues } = useFormContext();
  const { t } = useTranslation();
  const filterObject = useSelector(selectFilters);
  const [deleteMultipleChips, setDeleteMultipleChips] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  const handleChange = () => {
    setChecked(prev => !prev);
  };

  const getNewSelection = (key, chip) => ({
    ...filterObject?.selectedChipFilters,
    [key]: determineChipSelected(
      chip,
      filterObject?.selectedChipFilters ? filterObject?.selectedChipFilters[key] : undefined,
    ),
  });

  const handleChipDelete = (key, chip) => {
    const fieldValues = getValues(key) ?? getValues(chip.value);
    let newFieldValues: any = null;
    if (instanceOfAggregateCandidate(fieldValues)) {
      newFieldValues = {
        ...fieldValues,
        [chip.value.key]: !Array.isArray(fieldValues[chip.value.key])
          ? null
          : fieldValues[chip.value.key].filter(x => x.value !== chip.value.value.value),
      };
    }
    else if (instanceOfAggregateFacility(fieldValues)) {
      newFieldValues = {
        ...fieldValues,
        [chip.value.key]: !Array.isArray(fieldValues[chip.value.key])
          ? null
          : fieldValues[chip.value.key].filter(x => x.value !== chip.value.value.value),
      };
    }
    else if (key === PlacementSearchFilterName.status) {
      newFieldValues = injectItem(
        _.cloneDeep(fieldValues),
        calculateParentPath(chip.value),
        findBottomNode(chip.value),
        true,
        'value',
        'name',
        'userChecked',
      );
    } else if (key === candidateSearchFilterName.candidateSkillSet) {
      const skillsets = getFilteredSkillSets(fieldValues?.skillsets, chip);
      const skillsetsCount = totalCountNodes({
        isVerifiedOnly: false,
        operation: null,
        allowableMismatch: false,
        skillsets: skillsets,
        isExactMatch: false,
      });
      newFieldValues = {
        isVerifiedOnly: deleteAdditionalSkillSetFilters('isVerifiedOnly', chip, skillsetsCount)
          ? false
          : fieldValues?.isVerifiedOnly,
        operation: deleteAdditionalSkillSetFilters('operation', chip, skillsetsCount) ? '' : fieldValues?.operation,
        allowableMismatch: deleteAdditionalSkillSetFilters('allowableMismatch', chip, skillsetsCount)
          ? false
          : fieldValues?.allowableMismatch,
          isExactMatch: deleteAdditionalSkillSetFilters('isExactMatch', chip, skillsetsCount)
          ? false
          : fieldValues?.isExactMatch,
        skillsets: skillsets,
      };
    } else if (category === SearchType.order && key === orderSearchFilterName.state) {
      const compactOptIndex = fieldValues.findIndex(
        item => item.isCompactOpt && item.value.some(s => s.value === chip.value.value),
      );
      newFieldValues = fieldValues.filter(item => item.value !== chip.value.value);
      if (compactOptIndex !== -1) newFieldValues.splice(compactOptIndex, 1);
    } else if (fieldValues && fieldValues.type && fieldValues.type === 'NestedFilter') {
      const data = generateFieldChips(fieldValues);
      const index = data.findIndex(x => x.label === chip.label);
      newFieldValues = {
        ...fieldValues,
        value: fieldValues.value.filter((item, id) => index !== id),
      };
    } else {
      if (
        fieldValues &&
        Object.hasOwn(fieldValues, 'level') &&
        Object.hasOwn(fieldValues, 'value') &&
        fieldValues?.isLevelSelected === false
      ) {
        let field = cloneDeep(fieldValues);
        field = {
          ...fieldValues,
          value: fieldValues?.value?.filter(item => item !== chip?.value),
        };
        newFieldValues = field;
      } else
      /**Check to avoid click on deletion of valued fields/chips */
        newFieldValues = Array.isArray(fieldValues)
          ? fieldValues.filter(item => item.value !== chip.value.value ?? chip?.value)
          : null;

      if (category === SearchType.facility && key === facilitySearchFilterName.requirementType) {
        if (!newFieldValues || newFieldValues?.length <= 0) {
          setValue(facilitySearchFilterName.includeRequirementTypeUnits, false);
        } else if (filterObject?.filters && filterObject?.filters?.includeRequirementTypeUnits) {
          setValue(facilitySearchFilterName.includeRequirementTypeUnits, true);
        }
      }
    }
    setValue(key, newFieldValues, { shouldDirty: true });
    if (chip.isSelected || props.disableFormSubmit) {
      const newSelections = getNewSelection(key, chip);
      props.onApplyFilter(
        {
          ...getValues(),
          [key]: Array.isArray(fieldValues)
            ? [...(newFieldValues || [])]
            : (fieldValues.type && fieldValues.type === 'NestedFilter') ||
              instanceOfAggregateCandidate(newFieldValues) ||
              instanceOfAggregateFacility(newFieldValues) ||
              instanceOfCandidateSkillSetFilter(newFieldValues)
              ? newFieldValues
              : null,
        },
        newSelections,
      );
    } else hiddenSubmitButton.current?.click();

    if (key == 'zipCode') {
      setDeleteMultipleChips(true);
    }
  };

  /** Restricted to call onApplyfilter directly -
   * observes not to remove existing filter and chips */
  const handleChipClick = (key, chip) => {
    const fieldValues = cloneDeep(getValues(key));
    /**Check to avoid click on single valued fields/chips */
    if (!Array.isArray(fieldValues)) {
      if (!instanceOfAggregateCandidate(fieldValues) && !instanceOfCandidateSkillSetFilter(fieldValues) && !instanceOfAggregateFacility(fieldValues)) return;
      else if (
        chip?.value?.key &&
        !Array.isArray(fieldValues[chip.value.key]) &&
        !instanceOfAggregateCandidate(fieldValues) &&
        !instanceOfAggregateFacility(fieldValues)
      )
        return;
    }
    /**
     * Below functionalty are limited to type ISelection
     * Need to be updated for dates and single selection
     */
    const newSelections = getNewSelection(key, chip);

    setChips(generateChips({ ...getValues() }, t, category, false, newSelections));
    props.onApplyFilter({ ...getValues() }, newSelections);
  };

  useEffect(() => {
    setChips(
      filterObject?.filters
        ? generateChips(filterObject?.filters, t, category, props.hideDelete, filterObject?.selectedChipFilters)
        : {},
    );

    if (
      category === SearchType.facility &&
      filterObject?.filters &&
      !filterObject?.filters?.includeRequirementTypeUnits
    ) {
      const requirementTypeIds = filterObject?.filters?.requirementTypeIds;
      setValue(facilitySearchFilterName.requirementType, []);

      setTimeout(() => {
        setValue(facilitySearchFilterName.requirementType, requirementTypeIds);
        setValue(facilitySearchFilterName.includeRequirementTypeUnits, false);
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterObject?.filters]);

  useEffect(() => {
    const chipsData = Object.keys(chips).some(key => {
      const value = chips[key];
      if (Array.isArray(value) && value.length > 0) {
        return true;
      }
      if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
        return true;
      }
      return false;
    });
    setShowChipsCollapseBtn(chipsData);
  }, [chips]);

  useEffect(() => {
    if (deleteMultipleChips) {
      const chip = { label: filterObject?.filters.radius, enabledelete: true, value: filterObject?.filters.radius };
      handleChipDelete('radius', chip);
      setDeleteMultipleChips(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteMultipleChips]);

  return (
    <>
      {showChipsCollapseBtn && collapsable ? (
        <Grid container spacing={2}>
          <Grid item xs={11}>
            <Collapse in={checked} collapsedSize={38}>
              <div ref={divRef}>
                <ChipsContainer
                  chips={chips}
                  onDelete={(field, chip, index) => handleChipDelete(field, chip)}
                  onClick={(field, chip, index) => handleChipClick(field, chip)}
                  isCollapse={!checked}
                />
              </div>
              <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
            </Collapse>
          </Grid>
          <Grid item xs={1} className={classes.fillterCollapse}>
            {(divRef?.current?.clientHeight ?? 0) > 38 && (
              <SearchPageIcons KeyboardArrowIcon onAdvancedChipsClick={() => handleChange()} checked={checked} />
            )}
          </Grid>
        </Grid>
      ) : (
        <>
          <ChipsContainer
            chips={chips}
            onDelete={(field, chip, index) => handleChipDelete(field, chip)}
            onClick={(field, chip, index) => handleChipClick(field, chip)}
          />
          <button ref={hiddenSubmitButton} style={{ display: 'none' }} type="submit" />
        </>
      )}
    </>
  );
};
