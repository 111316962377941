import { Box, Typography } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { ICallOutcome, IDirection } from '@AMIEWEB/Notification/Constants';
import { IDisposition } from 'app/models/Notification/Notification';

const useStyles = makeStyles<{
  outcomeValue: ICallOutcome;
  direction: IDirection;
  disposition: ICallOutcome;
}>()((theme, { outcomeValue, direction, disposition }) => ({
  box: {
    borderRadius: '10px',
    width: '100%',
    height: '12vh',
    backgroundColor: theme.palette.components.box.container.backgroundColor,
    margin: '6px 0px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: direction === IDirection.Inbound ? 'content-menu' : 'pointer',
    border:
      outcomeValue === disposition
        ? `${theme.palette.framework.system.navyBlue} 2px solid`
        : `${theme.palette.framework.system.lightGray2} 1px solid`,
    background:
      direction === IDirection.Inbound && outcomeValue !== ICallOutcome.SpokeToCandidate
        ? theme.palette.framework.system.platinum
        : theme.palette.framework.system.white,
  },
  gridIcon: {
    display: 'flex',
    margin: '0.5%',
    color:
      outcomeValue === disposition ? theme.palette.framework.system.navyBlue : theme.palette.framework.system.coolGray,
    width: '100%',
  },
  invertItemColor: {
    filter: `invert(31%) sepia(70%) saturate(1672%) hue-rotate(181deg) brightness(80%) contrast(80%)`,
  },
  gridText: {
    display: 'flex',
    margin: '8% 6% 0 6%',
    width: '100%',
    minHeight: '40px',
    alignItems: 'center',
  },
  outcomeText: {
    textAlign: 'center',
    fontWeight: 500,
    font: 'normal normal medium Roboto',
    letterSpacing: '0px',
    opacity: '1',
    color:
      outcomeValue === disposition ? theme.palette.framework.system.navyBlue : theme.palette.framework.system.coolGray,
    fontSize: '12px',
  },
}));

interface IOutcomeProps {
  outcomeIcon: JSX.Element;
  outcomeName: string;
  outcomeValue: ICallOutcome;
  disposition: IDisposition;
  outcomeHandler: (outcomeValue: ICallOutcome) => void;
}

const Outcome: React.FC<IOutcomeProps> = (props: IOutcomeProps) => {
  const { outcomeIcon, outcomeName, outcomeValue, disposition, outcomeHandler } = props;
  const { classes } = useStyles({
    outcomeValue,
    direction: disposition.direction,
    disposition: disposition.disposition,
  });

  return (
    <React.Fragment>
      <Box
        boxShadow={3}
        className={classes.box}
        onClick={(event) => (disposition.direction !== IDirection.Inbound ? outcomeHandler(outcomeValue) : event?.stopPropagation())}
      >
        <LayoutGrid>
          <LayoutGridItem
            item
            justifyContent="center"
            className={`${classes.gridIcon} ${outcomeValue === disposition.disposition ? classes.invertItemColor : ''}`}
          >
            {outcomeIcon}
          </LayoutGridItem>
          <LayoutGridItem item justifyContent="center" className={classes.gridText}>
            <Typography className={classes.outcomeText}>{outcomeName}</Typography>
          </LayoutGridItem>
        </LayoutGrid>
      </Box>
    </React.Fragment>
  );
};

export default Outcome;
