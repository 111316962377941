import { IProgressStack } from 'app/ComponentLibrary/PageProgress';
import { IElementAccessor } from 'app/components/Common/EditPage/PageActions';
import { editableFields } from 'app/components/Placement/PlacementDetails/editUtils';
import { ICredentials } from '../Credentialing/Credentials';
import { IPlacementChangeHistory } from './PlacementChangeHistory';
import { IGroupedSkillset as ISkillsetTree } from '../../components/Common/SkillsetTree/SkillsetTree';
import { ITravelTimePrference, IGender, IDepartureAirport } from './PlacementSummary';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { IPlacementTimestampResponse } from '../Placement';
import { DeepMap } from 'react-hook-form';
import { ExtEditTarget } from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/Statuses/Extensions/ExtensionEditIcon';
import { ICredOneDetails } from './CredOneCompliance';
import { ITypeAheadOption } from '../Orders/OrderDetails';
import { ITimeOff } from '@AMIEWEB/Common/TimeOffRequest';

export interface IPlacementUpdatableFields {
  transportationChosen?: { transportationId: number | null; transportation: string | null };
  requestedShift: string | null;
  bestTimeToCall: string | null;
  travelsWithPartner: boolean;
  travelPartner: string | null;
  placementDate: {
    startDate?: string | null;
    endDate?: string | null;
  };
  placementStartDate?: string | null;
  placementEndDate?: string | null;
  availabilityDate?: string | null;
  sellingPoints: string | null;
  requestedTimeOff: {
    isRequestedTimeOffMakeUp: boolean;
    requestedTimeOff: [];
    requestedTimeOffMode: number;
    requestedTimeOffNote: string;
  };
  travelTimePreferenceChosen?: ITravelTimePrference;
  gender?: IGender;
  departureAirport?: IDepartureAirport;
  arrivalDate?: string;
  arrivalTime?: string;
  ssn?: string;
  dob?: string | null;
  placementShift?: ITypeAheadOption | null;
  vmsReqNo: string | null;
  contractHours: string | null;
  numberOfWeeks: number | null;
}

export interface IPlacementUpdateAllRequest {
  placementId: number;
  // react-hook-form dirtyFields
  dirtyFields: DeepMap<IPlacementUpdatableFields, true>;
  // react-hook-form state
  updates: any;
  // if conflict validation needs to run and where it originated from
  validateConflict: {
    source: IPlacementStatusSource;
  };
  requirementCheckFlagEnabled?: boolean;
}

export interface IPlacementStatusUpdateRequest {
  placementStatus: IPlacementStatus;
  candidateId: number;
  brandId: string | number;
  placementUpdate: IPlacementUpdateAllRequest;
}

export interface IPlacementUpdate extends IPlacementUpdatableFields {
  lastUpdatedAt: string;
  lastUpdatedBy: string;
  vmsLastUpdated: string;
  vmsLastUpdatedBy: string;
  summaryRequestedTimeOff?: any;
}

export interface IPlacementSupervision {
  supervision?: string;
  placementId?: number;
  startDate?: string;
  endDate?: string;
  placementSupervisingID?: number;
}

export enum OrderTypeIds {
  Supervisor = 6,
  Tele_Supervisor = 9,
  FEMA = 11,
}

export interface IPlacementTag {
  category: string;
  isClassicTag: boolean;
  label: string;
  value: string;
}

export interface IPlacementDetails {
  placementOrderType: PlacementOrderType | null;
  timestamp?: IPlacementTimestampResponse;
  header: IPlacementHeader;
  progress: IPlacementProgress;
  quickGlance: IQuickGlance;
  candidateRecords: IPlacementCandidateRecords;
  changeHistory: IPlacementChangeHistory[];
  contacts: IPlacementKeyContacts & { flattenedContacts };
  modalData: { [key: string]: any };
  updateStatus: any;
  placementStatus: any;
  requiredDataLoadStatus?: boolean;
  landsGtgDetails?: ILandsGtgDetails;
  avoidPlacementParentForm?: boolean;
  isLandsCancel?: boolean;
  passportCredentials: IPassportCredential[] | null;
  isLicenseSelect?: number | null;
  isLicenseSelectRedio?: boolean | null;
  landsGtgCompletionStatus?: { landsStatus: boolean; gtgStatus: boolean };
  resetForm?: boolean;
  disciplineData?: any;
  licensesList?: any[];
  passportChecklist?: any[];
  extTimeOffPayerOpts?: any[];
  extDirectHireOpts?: DirectHireOption[];
  selectedDirectHireDetail: DirectHireDetails;
  credOneDetails?: ICredOneDetails;
  drivingDistanceMapLink?: string;
  editor: {
    keyInternalContacts: IKeyInternalContacts;
  };
  placementModificationTabRendered: Boolean;
  placementLinkedSupervision?: IPlacementSupervision[];
  placementLinkedSupervisee?: IPlacementSupervision[];
  placementLinkModalOpen?: boolean;
  placementLinkDetails?: any[];
  placementLink?: any;
  placementTags?: IPlacementTag[];
  editPlacementRegion: IPlacementRegionEdit;
  specialitiesData?: any;
  subSpecialitiesData?: any;
  complianceStatusProgress: {
    notes: string;
    originalNotesList: Array<ComplianceStatusProgressNotesDetails>;
    notesList: Array<ComplianceStatusProgressNotesDetails>;
    openDrawer: boolean;
    fullScreen: boolean;
    expanded: boolean;
    cancelled: boolean;
    isEditNotes: boolean;
    editableNoteDetails: ComplianceStatusProgressNotesDetails;
    isClearForm: boolean;
    complianceStateProgressNotesHeader: ComplianceStateProgressNotesHeader;
  };
  selectAllInternalContacts?: boolean;
  isUpdatedOrderId: boolean;
  focusedHeight?: number;
  isContactsExpanded?: { isInternal: boolean; isClient: boolean };
}

export interface IKeyInternalContacts {
  recruiter: IContactEditor;
  accountManager: IContactEditor;
  regionalDirector: IContactEditor;
  credentialingAnalyst: IContactEditor;
  clientContractSpecialist: IContactEditor;
  clinicalManager: IContactEditor;
}

export interface ComplianceStateProgressNotesHeader {
  license: Array<string>;
  certificates: Array<string>;
  docDealine: string;
  comments: string;
  saveStatus: number | null;
}

export interface ComplianceStatusProgressNotesDetails {
  lastUpdatedDate: string;
  notes: string;
  userName: string;
}

export interface IPlacementRegionEdit {
  id?: number;
  name?: string;
  error?: { message: string };
  helperText?: string;
}

export interface IContactEditor {
  value: any;
  error: { message: string } | null;
  isDirty: Boolean;
  helperText?: string;
  checked?: boolean;
}

export enum PlacementOrderType {
  Default = 'Default',
  StrikeOrderInHsg = 'StrikeOrderInHsg',
  Strike = 'Strike',
  Travel = 'Travel',
}

/** Function to derive corresponding value of enum assigned in Placement Service */
export const getPlacementOrderTypeValue = (key: PlacementOrderType | null) =>
  customSwitch({
    [PlacementOrderType.StrikeOrderInHsg]: 1,
  })(0)<PlacementOrderType | null>(key);

export const getPlacementOrderType = (key: PlacementOrderType | null) =>
  customSwitch({
    0: PlacementOrderType.Default,
    1: PlacementOrderType.StrikeOrderInHsg,
  })(null)<PlacementOrderType | null>(key);

export interface IPlacementProgress {
  placementId: number;
  activityStatusId: number;
  activityTypeId: number;
  activityStatus: string;
  categoryProgress: IProgressStack[];
  subcategoryProgress: IProgressStack[];
}

export interface ILandsGtgDetails {
  placementId: number;
  notRequired: boolean;
  notRequiredReasonId: number | null;
  notRequiredReason: string | null;
  licenseNumber: string | null;
  licenseActive: boolean;
  hitsOnLicenseMatchAudit: boolean;
  auditScreenedWithClient: boolean;
  statesInNursys: boolean;
  statesInNursysReason: string | null;
  nursysMatchOnFile: boolean;
  licenseIssuedAfterGraduation: boolean;
  checkedDob: boolean;
  expirationDate: string | null;
  typeId: number | null;
  levelId: number | null;
  discMatchAssign: boolean;
  lastUpdated: string | null;
  lastUpdatedByUserId: number;
  lastUpdatedByName: {
    first: string | null;
    last: string | null;
  };
  timestamp: string | null;
  cabreezeAct: string | null;
  confirmCaLicense: boolean | null;
  confirmCaLicenseReason: string | null;
  isLandsComplete: boolean | null;
  gtgNotRequired: boolean | null;
  gtgNotRequiredReasonId: number | null;
  gtgNotRequiredReason: string | null;
  crimCheckDate: string | null;
  i9Uploaded: boolean | null;
  supportDocListId: number | null;
  supportDocUploaded: boolean | null;
  namesNumbersMatchI9: boolean | null;
  eVerifyDate: string | null;
  notForeignTraveler: boolean | null;
  foreignTravelerFUDate: string | null;
  foreignTravelerEvent: boolean | null;
  checkState: boolean | null;
  checkStateReason: string | null;
  checkStateExceptId: number | null;
  missExpEvents: boolean | null;
  noExpAssign: boolean | null;
  packetSentDate: string | null;
  packetNotRequired: boolean | null;
  gtgLastUpdated: string | null;
  gtgLastUpdatedByUserId: number;
  gtgLastUpdatedByName: {
    first: string | null;
    last: string | null;
  };
  isgtgComplete: boolean | null;
  i9FinalAuditCompleted: boolean | null;
  i9eVerifyCode: boolean | null;
  allCertificationsVerified: boolean | null;
  licenseStateProvinceId: string | null;
  licenseStateProvice: null;
  licenseReviewTeamClearedHits: null;
  isAlliedClinician: boolean | null;
  nursysLastnameMatchesLicense: boolean | null;
  nursysLastnameNotMatchesLicenseReason: string | null;
  isProofOfResidencyOnFileForCompact: boolean | null;
  nursysMatchOnFileReason: string | null;
  currentEmployeeId: number;
}

export interface ILandsGtgDetailsPost {
  placementId: number;
  notRequired: boolean;
  notRequiredReasonId: number | null;
  notRequiredReason: string | null;
  licenseNumber: string | null;
  licenseActive: boolean;
  hitsOnLicenseMatchAudit: boolean;
  auditScreenedWithClient: boolean;
  statesInNursys: boolean;
  statesInNursysReason: string | null;
  nursysMatchOnFile: boolean;
  licenseIssuedAfterGraduation: boolean;
  checkedDob: boolean;
  expirationDate: string | null;
  typeId: number | null;
  levelId: number | null;
  discMatchAssign: boolean;
  lastUpdated: string | null;
  lastUpdatedByUserId: number;
  lastUpdatedByName: {
    first: string | null;
    last: string | null;
  };
  timestamp: string | null;
  cabreezeAct: string | null;
  confirmCaLicense: boolean | null;
  confirmCaLicenseReason: string | null;
  isLandsComplete: boolean | null;
  gtgNotRequired: boolean | null;
  gtgNotRequiredReasonId: number | null;
  gtgNotRequiredReason: string | null;
  crimCheckDate: string | null;
  i9Uploaded: boolean | null;
  supportDocListId: number | null;
  supportDocUploaded: boolean | null;
  namesNumbersMatchI9: boolean | null;
  eVerifyDate: string | null;
  notForeignTraveler: boolean | null;
  foreignTravelerFUDate: string | null;
  foreignTravelerEvent: boolean | null;
  checkState: boolean | null;
  checkStateReason: string | null;
  checkStateExceptId: number | null;
  missExpEvents: boolean | null;
  noExpAssign: boolean | null;
  packetSentDate: string | null;
  packetNotRequired: boolean | null;
  gtgLastUpdated: string | null;
  gtgLastUpdatedByUserId: number;
  gtgLastUpdatedByName: {
    first: string | null;
    last: string | null;
  };
  isgtgComplete: boolean | null;
  i9FinalAuditCompleted: boolean | null;
  i9eVerifyCode: boolean | null;
  allCertificationsVerified: boolean | null;
  licenseStateProvinceId: string | null;
  licenseStateProvice: string | null;
  licenseReviewTeamClearedHits: null;
  isAlliedClinician: boolean | null;
  nursysLastnameMatchesLicense: boolean | null;
  nursysLastnameNotMatchesLicenseReason: string | null;
  isProofOfResidencyOnFileForCompact: boolean | null;
  nursysMatchOnFileReason: string | null;
  currentEmployeeId: number;
  licenseRequirementTypeId: number | null;
  amnDivisionSelected: string | null;
  oldLicenceName: string;
  newLicenceName: string;
  checkStateExceptOldName: string;
  checkStateExceptNewName: string;
  supportDocListOldName: string;
  supportDocListNewName: string;
}

export interface IPassportCredential {
  category: string;
  categoryId: number;
  comments: string | null;
  completeDate: Date | null;
  description: string | null;
  documents: ICredentialDocument[];
  exceptionWaiveId: number | null;
  exceptionWaiveType: string | null;
  expirationDate: Date | null;
  id: number | null;
  instructions: string | null;
  isCompliant: boolean | null;
  reason: string | null;
  reasonId: number | null;
  requirementGroupNameId: number | null;
  requirementTypeId: number | null;
  status: string | null;
  statusId: number | null;
  typeId: number | null;
}

export interface ICredentialDocument {
  credentialDocument: IFileData;
  credentialId: number | null;
  documentType: string | null;
  id: string | null;
  placementId: number | null;
  title: string | null;
  userId: string | null;
}

export interface IFileData {
  file: string | null;
  url: string | null;
  date: string | null;
}

export interface IPlacementPageAttributes {
  editableFieldStatus: {
    requestedShift: IEditableStatus;
    bestTimeToCall: IEditableStatus;
    vmsReqNumber: IEditableStatus;
    contractHours: IEditableStatus;
    travelsWithPartner: IEditableStatus;
    travelPartner: IEditableStatus;
    placementDate: IEditableStatus;
    availabilityDate: IEditableStatus;
    sellingPoints: IEditableStatus;
    requestedTimeOff: IEditableStatus;
    ssn: IEditableStatus;
    dob: IEditableStatus;
    arrivalDate: IEditableStatus;
    arrivalTime: IEditableStatus;
    placementShift: IEditableStatus;
  };
  editOrderStack: editableFields[];
  accessibleItems: IElementAccessor[];
  glanceDrawerState: boolean;
  currentFocus: string | null;
  popperOpen: boolean;
  extensionModalProps: {
    open: boolean;
    title?: string;
    isNewExtension?: boolean;
    extensionStatusId?: number;
    extensionStatus?: string;
    extensionOverride: number;
    extContainerIndex?: number;
    currentTargetField?: ExtEditTarget | null;
  };
  preExtensionModalProps: {
    open: boolean;
    title?: string;
    extensionStatusId?: number;
    extensionStatus?: string;
    isNewExtension?: boolean;
  };
  miscDialogProps?: { key: MiscPlacementDialogType; data?: any };
}

export enum MiscPlacementDialogType {
  on_assignment_confirmation_cancellation = 1,
  generate_candidate_contract,
}

export interface IEditableStatus {
  disabled: boolean;
}

export interface IPlacementHeader {
  placementId: number;
  activityStatusId: number;
  availabilityDate?: string | null;
  accountManagerId: number;
  placementDate: {
    startDate?: string | null;
    endDate?: string | null;
  };
  placementStartDate?: string | null;
  placementEndDate?: string | null;
  placementUnitId: number;
  placementUnit: string;
  orderId: number;
  orderTypeId?: number;
  brandId: number;
  brandName: string;
  facility: PlacementFacilityHeader;
  technologyVendorId: number;
  technologyVendorName: string;
  candidate: PlacementCandidateHeader;
  candidateLastContactedOn: string;
  candidatePrimaryEmail: string;
  candidateBestTimeToCall?: string | null;
  skillsets: IGroupedSkillset[];
  lastUpdatedAt: string;
  lastUpdatedBy: string;
  complianceNotes: string;
  isFinalizedByQS?: boolean;
  isArchived: boolean;
  isSsnKeyValid?: boolean;
  supervisorRequired?: boolean;
  candidateLicenseNumber?: string | null;
  locationRegionId?: number;
  locationRegionName?: string;
  vmsReqDetails?: IVmsReqDetails;
  contractHours?: string | null;
  screen?: string;
  maxFilesSent?: boolean;
  numberOfWeeks: number | null;
  recruiterFirstName?: string;
  recruiterLastName?: string;
  recruiterId?: number;
}
export interface IVmsReqDetails {
  vmsReqNo?: string;
  lastUpdated?: string;
  lastUpdatedBy?: string;
}

export type IPlacementStatusChangeSource = 'whatsNext' | 'statusDropDown' | 'travelConfirmed' | null;

export interface PlacementStatus {
  // available statuses to transition to next
  availablePlacementStatus: IPlacementStatus[];
  // current status of the placement (note that activityTypeId is not included when initially loaded)
  // If needed, add it to the placement header API
  currentPlacementStatus?: IPlacementStatus;
  // Next status selected from What's Next container or Status Dropdown
  selectedStatus?: IPlacementStatus;
  // success: the placement status was updated successfully
  // supportAPIFailure: placement header and progress bar may have failed after status update
  updatePlacementStatusResponse: IUpdatePlacementStatusResponse | null;
  // failed requirement messaging is kept separate to account for async calls between status and requirements
  // key is activityTypeId:activityStatusId
  failedRequirements?: IPlacementStatusRequirement;
  // key is activityTypeId:activityStatusId
  warningRequirements?: IPlacementStatusRequirement;
  // source of where the status change request came from
  statusChangeSource?: IPlacementStatusChangeSource;
  // data related to placement extension
  extensionDetails?: IExtensionDetails;
  isModificationError?: boolean;
}

export interface IUpdatePlacementStatusResponse {
  success?: boolean;
  successType?: IPlacementStatusSuccessType;
  source?: IPlacementStatusSource;
  supportAPIFailure?: boolean;
  props?: { [key: string]: any };
  errorMessage?: string;
}

export enum IPlacementStatusSuccessType {
  placementUpdate,
  placementConflict,
  placementStatusUpdateStepsError,
  bookingToNegativeStatusError,
  onAssignmentToNegativeStatusEndDateError,
  onAssignmentToNegativeStatusStartDateError,
  missingStartDateEndDateOrNumberOfWeeks,
  numberofWeeksMismatch,
  BookedStatusPermOrderError,
  shiftError,
  placementVerificationScreenError,
  confirmationStatusError,
  contractStatusError,
  payCycleError,
  billRateError,
  housingTypeError,
  payRateError,
  travelAllowanceError,
  missingFurnitureHousingInsurancePayRateBillRateError,
  optionsScreenError,
  checklistsError,
  onAssignmentStartDateError,
}

export enum IPlacementStatusSource {
  statusConfirmationDialog,
  pageActions,
}

export interface IPlacementStatusRequirement {
  [typeStatusID: string]: IPlacementStatusRequirementMessage[];
}

export interface IPlacementStatusRequirementMessage {
  id: IPlacementRequirementKey;
  message?: string;
  tooltip?: IPlacementStatusRequirementTooltip;
}

export enum IOperation {
  AND = '&',
  OR = '|',
}

export enum IPlacementRequirementKey {
  // PLACEMENT DETAILS
  // Submission - AMN
  amnSkillset = '2001', // Number relates to ruleID in PlacementReqMessages.json
  amnCandidateStatus = '1004', // APi - Status
  amnAudit = '1000', //APi - Audit
  amnEducation = '1018', // APi - Education
  amnWorkHistory = '1028', // APi - WorkHistory
  amnSkillsChecklist = '1027', // APi - SkillsChecklist
  amnUsRightToWork = '1017', // APi - RightToWork
  amnSuspension = '1021', // APi - Suspension
  amnVerifiedSkillset = '2010',
  orderReferences = '1001', // APi - References

  // Submission - Order
  orderSkillset = '1022', // APi - Skillsets
  amnExperience = '1019', // APi - Experience
  orderMaxFilesSent = '1012', // APi - MaxFilesSent
  orderShiftOffered = '1025', // APi - ShiftOffered
  orderLicense = '1011', // APi - License
  orderCertifications = '1010', // APi - Certification
  orderRadius = '1013', // APi - MileRadius
  orderCovid = '1005', // APi - Covid
  availableStartDate = '1002', //APi - AvailableDate
  placementDate = '1024', // APi - PlacementDate
  orderDOB = '1032', // API - order-DOB
  orderSsn = '1033', // API - order-SSN
  arbitrationAgreement = '1034', // API - order-SSN
  orderWorkHistory = '1035', // APi - OrderWorkHistory
  orderExperience = '1036', // API - Order Experience

  // Interview requirements
  virtualInterview = '2048',

  // Offer confirmations
  datesConfirmed = '2023',
  travelConfirmed = '2024',
  dnsCleared = '2039',
  // Offer transportation
  transportation = '2025',
  ssn = '2026',
  dob = '2027',
  travelTimePreference = '2028',
  gender = '2029',
  departureAirport = '2030',
  departureItinerary = '2031',

  arrivalDate = '2045',
  arrivalTime = '2046',

  // Booking confirmations
  arrived = '2032',
  directDeposit = '2033',
  wisely = '2034',
  // Booking credentialing
  gtgComplete = '2035',
  finalizedByQS = '2036',

  // Booking Requirements - Non strike
  inBookedStatus = '2039',
  userRole = '2041',
  pendingModification = '2043',

  // WaitingGoodToGo Requirements - Non strike
  wgtgClientConfirmation = '2044',
  wgtgCandidateContract = '2045',
  wgtgUserRole = '2046',

  recruiterAssigned = '1016', // APi - RecruiterAssigned
  facility = '1007', // APi - Facility
  facilityStatus = '1009', // APi - FacilityStatus
  facilityDivision = '1008', // APi - FacilityDivision
  positions = '1015', // APi - Positions
  discipline = '1006', // APi - Discipline
  orderType = '1014', // APi - OrderType
  brand = '1003', // APi - Brand

  bookingClientConfirmation = '2037',
  bookingCandidateContract = '2038',

  // CREATE PLACEMENT
  createPlacement = '2037',
  networkError = '2038',

  // Extension Time Off
  // Does not come from API. Number Determined based on previous key number used
  extensionTimeOff = '2040',
}

export interface IPlacementStatusRequirementTooltip {
  message: string;
  supportingList?: any[];
  tooltipType?: EPLacementStatusRequirementTooltipType;
}

export enum EPLacementStatusRequirementTooltipType {
  message,
  messageWithSupportList,
}

export enum WhatsNextIcon {
  GreenChecked = 'green-checked',
  BlueArrow = 'blue-arrow',
  null = null,
}

export interface IPlacementStatus {
  activityTypeId: number;
  activityStatusId: number;
  activityStatus: string;
  // Alternative label for activityStatus.
  // Priority will be alt activityStatusAlt (if exists), then activityStatus.
  activityStatusAlt?: string;
  whatsNextIcon?: WhatsNextIcon;
  data?: any;
}
export interface IExtensionDetails {
  extensionStatusId: number | null;
  extensionStatus: string;
  availableExtensionStatus: IAvailableExtensionStatus[];
  isPlacementExtendable: boolean;
  endDateTravelerOption: IEndDateTravelerOption;
}

export interface IEndDateTravelerOption {
  endDate: string;
  effectiveDate: string;
  approvalStatusId: number;
  approvalStatus: string;
}

export interface IAvailableExtensionStatus {
  extensionStatusId: number;
  extensionStatus: string;
  isPositive: boolean;
}

export interface IQuickGlance {
  audits: IGlanceResponse;
  fileOutAudit: IGlanceResponse;
  licenseIssue: IGlanceResponse;
  mfs: IGlanceResponse;
  screen: IGlanceResponse | null;
  references: IGlanceResponse;
  skillsChecklist: IGlanceResponse;
}
export enum GlanceDataStatus {
  yes = 'Yes',
  no = 'No',
  neutral = 'Neutral',
}
export interface IGlanceResponse {
  status: GlanceDataStatus;
  value?: any;
}
export interface PlacementFacilityHeader {
  id: number;
  name: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  state: string;
  postalCode: string;
  amnDivisionType: string | null;
  amnDivisionTypeId: number | null;
}

export interface PlacementCandidateHeader {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  goesByName: string;
  ssn: {
    ssn: string;
    timestamp: string;
  };
  dob?: {
    dob: string | null;
    timestamp: string;
  };
  phoneNumber: PlacementCandidatePhoneNumber[];
  address: Address;
  addressTypeId: number | null;
  addressType: string | null;
}

export interface Address {
  address1: string;
  address2?: string | null;
  city: string;
  stateProvinceID: string;
  state: string;
  country: string;
  zip: string;
  addressType: string;
  addressTypeId: number;
}

export interface PlacementCandidatePhoneNumber {
  phoneTypeDescription: string;
  phoneTypeId: number;
  phoneNumber: string;
  countryDialingCode: string;
}

export interface IGroupedSkillset {
  disciplineAbbr: string;
  specialties: IGroupedSpecialties[];
}
export interface IGroupedSpecialties {
  specialtyAbbr: string;
  specialty: string;
}

export interface IPlacementContacts {
  contacts: IPlacementKeyContacts;
}

export interface IPlacementKeyContacts {
  placementId: number;
  facility: PlacementFacilityContacts;
  recruiter: Employee;
  regionalDirector: Employee;
  accountManager: Employee;
  credentialingAnalyst: Employee;
  clinicalManager: Employee;
  clientContractSpecialist: Employee;
  facilityContacts: FacilityContact[];
}

export interface Employee {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface FacilityContact {
  id: string;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  actions: FacilityContactAction[];
  doNotCall: number;
  phoneNumber: FacilityContactPhoneNumber[];
  statusId: number;
}

export interface PlacementFacilityContacts extends PlacementFacilityHeader {
  address1: string;
  address2: string;
  phoneNumber: string;
  country: string;
}

export interface FacilityContactAction {
  description: string;
}

export interface FacilityContactPhoneNumber {
  phoneTypeID?: number;
  phoneTypeDescription: string;
  phoneNumber: string;
  countryDialingCode: string;
}

//////////////FileOutTab Start/////////////////
export interface IPlacementCandidateRecords {
  candidatePlacementCounts: CandidatePlacementCounts;
  isCandidatePlacementCountsLoaded?: boolean | false;
  candidateSellingPointCounts: CandidateSellingPointCounts;
  isCandidateSellingPointCountsLoaded?: boolean | false;
  strikeStatus: StrikeStatus;
  comparisons: IPlacementComparisons;
  credentialingCertifications: ICredentials[];
}

export interface IModalData {
  submittalsInfo: IPlacementDetailCandidatePlacement[];
  interviewsInfo: IPlacementDetailCandidatePlacement[];
  offerAcceptsInfo: IPlacementDetailCandidatePlacement[];
  sameMspPlacements: IPlacementDetailCandidatePlacement[];
  amnAssignmentsCompleteInfo: IPlacementDetailCandidatePlacement[];
}

export interface CandidatePlacementCounts {
  submittals: number;
  interviews: number;
  offersAccepts: number;
  hasMspClient: boolean;
  sameMspPlacements: number;
}

export interface IRecordCounts {
  id: CandidatePlacementCategory;
  count: number;
}

export enum CandidatePlacementCategory {
  submittals = 1,
  interviews = 2,
  offersaccepts = 3,
  samemspplacements = 4,
  amnassignmentscomplete = 5,
}

export interface IPlacementDetailCandidatePlacement {
  candidateId: number;
  candidateFirstName: string;
  candidateLastName: string;
  placementId: number;
  placementStatusId: number;
  placementStatus: string;
  brandId: number;
  brand: string;
  facilityID: number;
  facility: string;
  city: string;
  recruiterFirstName: string;
  recruiterLastName: string;
  accountManagerFirstName: string;
  accountManagerLastName: string;
  statusChangeDate: string;
}

export interface CandidateSellingPointCounts {
  currentAmnTraveler: boolean;
  amnAssignmentsComplete: any;
  travelsWithPartner: boolean;
  travelsWithPartnerName: string | null; // Added null for unit test
  returnToFacility: boolean;
  returnToFacilityEndOfAssignment: string;
  returnToFacilityAssignmentLength: string;
  returnToFacilityCount: number;
  hasMspClient: boolean;
  returnToMsp: boolean;
  returnToMspEndOfAssignment: string;
  returnToMspAssignmentLength: string;
  returnToMspCount: number;
  placementSellingPoints: string | null; // Added null for unit test
}

export interface StrikeStatus {
  eventManagementStatusId: number;
  eventManagementStatus: string;
  schedulingStatusId: number;
  schedulingStatus: string;
}

export interface PlacementDetailsCue {
  firstName: string;
  lastName: string;
  text: string;
  date: string;
  statusAge: StatusAge;
}

export interface StatusAge {
  minutes: number;
  hours: number;
  days: number;
  totalMinutes: number;
  ageString: string;
}

export interface PlacementReference {
  referenceIcon: number;
  facilityName: string;
  completedDate: string;
  evaluatorTitle: string;
  message: string;
}

export interface PlacementSkillCheckList {
  checklistIcon: number;
  checklistName: string;
  completedDate: string;
  message: string;
}

export interface QualificationsAndNotes {
  requiredQualifications: string;
  preferredQualifications: string;
  internalNotes: string;
}

export interface WorkHistory {
  workHistoryIcon: number;
  workHistoryLastUpdatedDate: string;
  lastUpdateIcon: number;
  message: string;
  dateMessage: string;
}
//////////////FileOutTab end/////////////////

//////////////Comparisons start/////////////
export interface IPlacementComparisons {
  candidate: ICandidateComparisonData;
}

export interface IOrderComparisonData {
  yearsOfExperience: number;
  startDate: string | null;
  endDate: string | null;
  expectedHours: number;
  contractGWW: boolean;
  orderGWW: number;
  licenseState: string;
  referencesRequired: number;
  referencesToRfo: number;
  radiusMile: number;
  orderShifts: string[];
  skillsets: ISkillsetTree[];
}

export interface ICandidateComparisonData {
  candidateId: number;
  licenses: any;
  skillsets: ISkillsetTree[];
  requestedShifts?: string | null;
  availabilityDate: string | null;
  requestedTimeOff: {
    isRequestedTimeOffMakeUp: boolean;
    requestedTimeOff: [];
    requestedTimeOffMode: number;
    requestedTimeOffNote: string;
  };
  totalYearsOfExp: number;
  totalMonthsOfExp: number;
}

export interface IExperience {
  disciplineAbbr: string;
  specialtyAbbr: string;
  totalYearsOfExperience: number;
  totalMonthsOfExperience: number;
  lastThreeYearsYearsOfExperience: number;
  lastThreeYearsMonthsOfExperience: number;
}

export interface ILicense {
  requirementType: string;
  type: string;
  level: string;
  state: string;
  expirationDate: string;
  licenseNumber: string;
  hearsay: boolean;
  verified: boolean;
  isExpired: boolean;
}

export interface ICertification {
  certification: string;
  expirationDate: string;
  issuedBy: string;
  isExpired: boolean;
}

export interface IEducation {
  graduationMonth: number | null;
  graduationYear: number | null;
  major: string;
  degree: string;
}

export interface ICredentialCertification {
  count: number | null;
}

export interface ISkillSet {
  discipline: string;
  specialties: { specialty: string; subSpecialties: string[] }[];
}

export interface IDirections {
  radius: number;
  errorMessage: string;
}

export interface ITimestampRequest {
  id: number | string;
  timestamp?: string;
}
export interface IPlacementLink {
  supervisorPlacementId?: number;
  superviseePlacementId?: number;
}

export interface IPlacementTagsUpdate {
  placementId: number;
  updatedBy: number;
  placementTags: IPlacementTag[];
  arbitrationAgreementFlagEnabled?: boolean;
}

export interface IPlacementSubmissionPacketDetail {
  type: number;
  submissionError: string;
  isAutomated: boolean;
  activityTypeId: number;
  activityStatusId: number;
}

export enum EPlacementSubmissionPacketType {
  Automated = 'A',
  Manual = 'M',
  Unknown = 'U',
}

export enum EPlacementExternalSystems {
  Type = 2,
}

export interface DirectHireOption {
  id: string;
  description: string;
}

export interface DirectHireDetails {
  directHireOption: DirectHireOption | null;
  directHireLastUpdated: string | null;
  directHireLastUpdatedBy: string;
}

export interface DirectHireDetailsPayload {
  directHireOptionId: string | null;
  directHireTypeDescription: string | null;
  directHireLastUpdated: string | null;
  directHireLastUpdatedBy: string;
}
