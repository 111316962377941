import { selectWfctoRfcAutomationStatus } from '@AMIEWEB/Order/OrderDetails/OrderPreferences/store/OrderPreference.selector';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';
import { useSelector } from 'react-redux';
import {
  selectIsWfcPlacement,
  selectPlacementDivisionType,
  selectIsPassportPlacement,
} from 'store/redux-store/placement-summary/consolidatedStoreSelectors';

export const useIsPassportAutomationEnabled = () => {
  const isPassportPlacement = useSelector(selectIsPassportPlacement);
  const placementDivisionType = useSelector(selectPlacementDivisionType);
  const isWfcPlacement = useSelector(selectIsWfcPlacement);
  const isAutomationOn = useSelector(selectWfctoRfcAutomationStatus);

  return (
    isAutomationOn &&
    (placementDivisionType === AMNDivisionType.allied || placementDivisionType === AMNDivisionType.amnNurse) &&
    isPassportPlacement &&
    isWfcPlacement
  );
};
