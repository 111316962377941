import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { TextField, Typography } from 'amn-ui-core';
import { formInputs } from 'app/models/ClientContact/ClientContact';
import { useSelector } from 'react-redux';
import { selectClientContactDetails, selectIsClientContactEditModalOpen } from '../store/ClientContact.selector';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { emailFieldRequiredCheck, isValidClientContactEmail } from 'app/components/Facility/FacilityDetails/helper';
import { selectFacilityDetail } from '@AMIEWEB/Facility/FacilityStore/Facility.selector';
import { FacilityResponse } from 'app/models/Facility/FacilityDetails';
import { IOptionsList } from 'app/models/Tasks/Tasks';

export const CCEmail = formMethods => {
  const { t } = useTranslation();
  const {
    formMethods: controls,
    setIsInValidEmail,
    isInValidEmail,
    disabled,
    actionValues,
    setEmailRequired,
    hasEmailRequired,
  } = formMethods;

  const selectedClientContactDetails = useSelector(selectClientContactDetails);
  const facilityDetail = useSelector(selectFacilityDetail);
  const selectIsContactEditModelOpen = useSelector(selectIsClientContactEditModalOpen);
  const {
    control,
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { isDirty, errors },
  } = controls;

  const hasEmail = watch('email');

  const handleEmailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    setError: any,
    clearErrors: any,
    setIsInValidEmail: (isInValidEmail: boolean) => void,
    setEmailRequired: (hasEmailRequired: boolean) => void,
    facilityDetail: FacilityResponse,
    actionValues: IOptionsList[],
  ) => {
    const emailValue = e.target?.value;
    if (isValidClientContactEmail(emailValue)) {
      clearErrors(formInputs.email);
      setIsInValidEmail(false);
      setEmailRequired(false);
    } else {
      setIsInValidEmail(true);
      setError(formInputs.email, {
        type: 'invalidEmail',
        message: 'Invalid email address',
      });
    }
    if (emailValue === '') {
      const isEmailRequired = emailFieldRequiredCheck(
        facilityDetail,
        actionValues,
        selectIsContactEditModelOpen,
        selectedClientContactDetails,
      );
      setEmailRequired(isEmailRequired);
      if (isEmailRequired) {
        setError(formInputs.email, {
          type: 'required',
          message: 'Required',
        });
      } else {
        clearErrors(formInputs.email);
      }
    }
  };

  useEffect(() => {
    if (selectedClientContactDetails?.contactId)
      setValue('email', selectedClientContactDetails?.email ? selectedClientContactDetails?.email : '', {
        shouldDirty: false,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails?.contactId]);

  useEffect(() => {
    if (hasEmailRequired && isInValidEmail) {
      setError(formInputs.email, {
        type: 'invalidEmail',
        message: 'Invalid email address',
      });
    } else {
      clearErrors(formInputs.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInValidEmail]);

  useEffect(() => {
    const isEmailRequired = emailFieldRequiredCheck(
      facilityDetail,
      actionValues,
      selectIsContactEditModelOpen,
      selectedClientContactDetails,
    );
    if (!hasEmail) {
      setEmailRequired(isEmailRequired);
      if (!isEmailRequired) {
        clearErrors(formInputs.email);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    facilityDetail?.confirmationDeliveryMethodId,
    facilityDetail?.evaluationDeliveryMethodId,
    facilityDetail?.fileDeliveryMethodId,
    actionValues,
  ]);

  return (
    <>
      <LayoutGrid container direction="column" marginTop={'0px'}>
        <LayoutGrid container direction="row" justifyContent="space-between">
          <LayoutGridItem item paddingLeft={'24px !important'}>
            <Typography variant="subtitle1" gutterBottom component="div" style={{ fontWeight: 500 }}>
              {t('facility.clientContacts.email')}
            </Typography>
          </LayoutGridItem>
        </LayoutGrid>
      </LayoutGrid>
      <Controller
        control={control}
        name={'email'}
        rules={{
          required: hasEmailRequired ? t('required').toString() : false,
        }}
        render={({ ref, onChange, value, ...rest }) => (
          <TextField
            variant="filled"
            color="primary"
            id="email"
            label={t('clientContactModal.labels.email')}
            inputProps={{ maxLength: 101 }}
            InputLabelProps={{ shrink: !!value }}
            disabled={disabled}
            autoComplete="off"
            value={value}
            {...rest}
            error={
              errors?.email ? true : isDirty && errors?.email ? true : isInValidEmail || (hasEmailRequired && !value)
            }
            onChange={e => {
              onChange(e);
              handleEmailChange(
                e,
                setError,
                clearErrors,
                setIsInValidEmail,
                setEmailRequired,
                facilityDetail,
                actionValues,
              );
            }}
            onBlur={e => {
              if (e?.target?.value === '') {
                onChange(null);
              }
            }}
            helperText={
              hasEmailRequired && !value && actionValues?.length > 0
                ? t('notification.clientContact.emailRequired')
                : value !== '' && (isInValidEmail || errors?.email || (isDirty && errors?.email))
                ? t('notification.clientContact.emailInvalid')
                : null
            }
          />
        )}
      />
    </>
  );
};
