import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { contactPreferenceChoice } from 'app/models/ClientContact/ClientContact';
import { FormInputDropdown } from 'app/components/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/Controls/FormInputDropdown';
import { formInputs } from 'app/models/ClientContact/ClientContact';
import { selectClientContactDetails, selectclientContactPhoneNumber } from '../store/ClientContact.selector';
import { useSelector } from 'react-redux';

export const ContactPreference = props => {
  const { t } = useTranslation();
  const [contactSelection, setContactSelection] = useState(null);
  const [isChecked, setChecked] = useState(false);
  const { formMethods: controls, doNotCallIsChecked, setDisableCheck, disabled } = props;
  const selectedClientContactDetails = useSelector(selectClientContactDetails);
  const clientContactPhoneNumbers = useSelector(selectclientContactPhoneNumber);
  const [hasPreferenceRequired, setPreferenceRequired] = useState<boolean>(false);

  const {
    control,
    clearErrors,
    setValue,
    setError,
    formState: { isDirty, errors },
  } = controls;

  const contactPreferenceOptions = Object.values(
    contactPreferenceChoice.filter(pref => pref.Description !== 'Do not call contact'),
  );
  const handleContactPreferenceChange = event => {
    if (event?.target?.value === undefined) {
      setDisableCheck(false);
      setChecked(false);
      setContactSelection(null);
      setValue('contactPreference', undefined, { shouldDirty: true });
      setValue('contactPreferenceTypeId', null, { shouldDirty: false });
    } else {
      setDisableCheck(true);
      setChecked(true);
      setContactSelection(event?.target?.value);
      setValue('contactPreference', event?.target?.value, { shouldDirty: true });
      setValue(
        'contactPreferenceTypeId',
        contactPreferenceChoice.find(pref => pref.Description === event?.target?.value).Id,
        { shouldDirty: true },
      );
      clearErrors(formInputs.contactPreference);
      clearErrors(formInputs.contactPreferenceTypeId);
    }
  };

  useEffect(() => {
    if (doNotCallIsChecked) {
      setContactSelection('Do not call contact');
      if (selectedClientContactDetails && selectedClientContactDetails?.contactPreferenceTypeId === 3) {
        setValue('contactPreference', 'Do not call contact', { shouldDirty: false });
      } else {
        setValue('contactPreference', 'Do not call contact', { shouldDirty: true });
      }
      setValue(
        'contactPreferenceTypeId',
        contactPreferenceChoice.find(pref => pref.Description === 'Do not call contact').Id,
        { shouldDirty: true },
      );
      clearErrors(formInputs.contactPreference);
    } else {
      setContactSelection(null);
      setValue('contactPreference', undefined, { shouldDirty: false });
      setValue('contactPreferenceTypeId', null, { shouldDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doNotCallIsChecked]);

  useEffect(() => {
    if (selectedClientContactDetails && selectedClientContactDetails?.contactPreferenceTypeId) {
      const contactPreferenceValue = contactPreferenceChoice.find(
        pref => pref?.Id === selectedClientContactDetails?.contactPreferenceTypeId,
      )?.Description;
      setValue('contactPreference', contactPreferenceValue, { shouldDirty: false });
      setContactSelection(contactPreferenceValue);
      setValue('contactPreferenceTypeId', selectedClientContactDetails?.contactPreferenceTypeId, {
        shouldDirty: false,
      });
      clearErrors(formInputs.contactPreference);
      clearErrors(formInputs.contactPreferenceTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails?.contactId]);

  //TODO: Component need refactoring to handle the form values and errors in a better way @kevin.george@neudesic.com, @kevin.george@neudesic.com
  useEffect(() => {
    const softDelete = clientContactPhoneNumbers?.every(item => item.softDelete === true);
    const hasPhoneNumbers = Boolean(
      selectedClientContactDetails?.phoneNumbers?.length || clientContactPhoneNumbers?.length,
    );
    if (hasPhoneNumbers) {
      if (softDelete) {
        setPreferenceRequired(false);
        clearErrors(formInputs.contactPreference);
      } else {
        !contactSelection &&
          setError(formInputs.contactPreference, {
            type: 'Required',
            message: 'Required',
          });
        setPreferenceRequired(true);
      }
    }
  }, [
    clientContactPhoneNumbers,
    selectedClientContactDetails?.phoneNumbers,
    clearErrors,
    formInputs.contactPreference,
  ]);

  return (
    <Controller
      control={control}
      name={'contactPreferenceTypeId'}
      rules={{
        required: hasPreferenceRequired ? t('required').toString() : false,
      }}
      render={({ ref, onChange, ...rest }) => (
        <FormInputDropdown
          isContactPreference
          readOnly={doNotCallIsChecked || disabled}
          disabled={disabled}
          name={'contactPreference'}
          label={t('clientContactModal.contactPreference')}
          options={contactPreferenceOptions}
          handleChange={e => handleContactPreferenceChange(e)}
          inputValue={contactSelection}
          standard={true}
          require={hasPreferenceRequired && !contactSelection && !doNotCallIsChecked}
          clearButton={contactSelection !== undefined}
          isCheckBox={isChecked}
          isError={
            (hasPreferenceRequired && !doNotCallIsChecked && errors?.contactPreference) ||
            (isDirty && errors?.contactPreference)
          }
        />
      )}
    />
  );
};
