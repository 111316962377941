import { Button, Grid } from 'amn-ui-core';
import { IBannerAction } from 'app/models/Global/Global';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

export const BannerActions = ({
  action,
  justifyContent,
}: {
  action: IBannerAction;
  justifyContent?: 'flex-start' | 'flex-end';
}) => {
  return (
    <Grid container justifyContent={justifyContent}>
      {action === IBannerAction.refreshPage && (
        <Grid item>
          <RefreshButton />
        </Grid>
      )}
    </Grid>
  );
};

const RefreshButton = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const onClick = () => {
    history.go(0);
  };
  return (
    <Button disableElevation color="primary" variant="contained" onClick={onClick}>
      {t('Refresh')}
    </Button>
  );
};
