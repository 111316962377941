export interface IGlobal {
  isProfileOpen: boolean;
  sessionLoaded?: boolean;
  banner: IBanner;
  isEmailSignatureExpanded: boolean;
  emailSignature: any[];
  notificationPreference: any[];
  candidateDrawerData: {
    open: boolean;
    candidate: ICandidate;
  };
  facilityDrawerData: {
    open: boolean;
    facility: IFacility;
  };
  orderDrawerData: {
    open: boolean;
    order: IOrder;
  };
  detailsPageDrawer: {
    open: boolean;
  };
  smsIconDrawer: {
    open: boolean;
  };
  candidateProfileDrawer: {
    open: boolean;
  };
  activeStep: number;
  deletedAttachments: String[];
  catastrophicDialog: ICatastrophicDialog;
  failedServices: IFailedService[];
  globalSnackbar: GlobalSnackbar;
}

export interface GlobalSnackbar {
  singleMessage?: IGlobalSnackBar[];
  messageQueues?: IGlobalSnackBar[];
}
export interface IGlobalSnackBar {
  id?: string | number;
  message: string;
  severity: ISnackBarSeverity;
  variant?: ISnackBarVariant;
  disableIcon?: boolean;
  onSnackClose?: Function;
  actions?: GenericSnackbarActions[];
  isDialogMode?: boolean;
  className?: string;
  clickAwayProps?: any;
  position?: ISnackBarPosition;
  iconColor?: string;
  portalRefId?: string;
}

export interface GenericSnackbarActions {
  closeSnackbar?: boolean;
  action?: () => void;
  className?: string;
  text: string | JSX.Element;
  closeAction?: boolean;
}

export interface ICandidate {
  candidateId: number;
  brandId: number;
}
export interface IFacility {
  facilityId: number;
  hideNavigation?: boolean;
}

export interface IOrder {
  orderId: string;
}

export interface ISnackBar {
  message?: string;
  severity?: ISnackBarSeverity;
}

export type ISnackBarSeverity = 'error' | 'success' | 'info' | 'warning';
export type ISnackBarVariant = 'dark' | 'light';
export type ISnackBarPosition = 'top' | 'bottom';

export interface IBanner {
  open?: boolean;
  message?: string | null;
  severity?: 'warning' | 'error';
  justify?: 'center' | 'flex-start' | 'flex-end';
  justifyActionContent?: 'flex-start' | 'flex-end';
  action?: IBannerAction;
}

export enum IBannerAction {
  refreshPage,
}

export interface ICatastrophicDialog {
  open?: boolean;
}

export interface IFailedService {
  type: string;
  payload: any;
}
