import { PayloadAction } from '@reduxjs/toolkit';
import { DistributionContact } from 'app/models/Orders/OrderPreference';
import {
  checkAutomationEditStatusByStage,
  getActivePlacementCounts,
  getOrderAutomationStatus,
  getOrderAutomationPlacementsByStatus,
  postDistributionList,
} from 'app/services/OrderServices/OrderServices';
import { trackPromise } from 'react-promise-tracker';
import { call, getContext, put, select, takeLatest } from 'redux-saga/effects';

import { orderPreferenceAlert } from '../OrderPreferenceAlerts';
import {
  IAutomationStatusUpdateRequest,
  IOrderAutomationRequest,
  IOrderPreferenceRequest,
  IUpdateSubmissionPreferenceRequest,
  orderPreferenceActions,
} from './OrderPreference.redux';
import { makeIdHash } from 'app/helpers/stringHelper';
import { httpSuccess } from 'app/services/serviceHelpers';
import { placementSubStatuses } from 'app/components/RecruiterWorkDesk/MyPlacementsDesk/utils';
import { getReadyToSubmitPlacementIds } from 'app/services/PlacementServices/PlacementServices';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { trackException } from 'app-insights/appInsightsTracking';
import { ExceptionType } from 'app/enums/Common';
import { convertToFormat } from 'app/helpers/dateHelper';
import { OrderService } from 'app/services/OrderServices/order-service';
import { selectSubmissionPreferences } from './OrderPreference.selector';
import { FacilityService } from 'app/services/FacilityServices/facility-service';

const TrackActivePlacementCounts = (fn, ...args) =>
  trackPromise(fn(...args), 'order-preference-active-placementcounts');
const TrackGetAutomationStatusByStage = (fn, ...args) =>
  trackPromise(
    fn(...args),
    !!args[args.length - 1]
      ? `order-preference-get-automation-status-by-stage-${!!args[args.length - 1]}`
      : 'order-preference-get-automation-status-by-stage',
  );
const TrackUpdateAutomationStatusByStage = (fn, ...args) =>
  trackPromise(fn(...args), 'order-preference-update-automation-status-by-stage');
const TrackCheckAutomationEditStatusByStage = (fn, ...args) =>
  trackPromise(fn(...args), 'order-preference-checkAutomation-edit-status-by-stage');
const TrackGetDistributionList = (fn, ...args) => trackPromise(fn(...args), 'order-preference-get-distribution-list');
const TrackRequestExistingRFOs = (fn, ...args) => trackPromise(fn(...args), 'order-preference-get-existing-rfos');
const TrackRequestReadyToSubmitRfoPIds = (fn, ...args) =>
  trackPromise(fn(...args), 'order-preference-get-ready-to-submit-rfo-pids');
const TrackUpdateOrderPreference = (fn, ...args) => trackPromise(fn(...args), `update-order-preferences-automation`);
const TrackSendToClientContacts = (fn, ...args) => trackPromise(fn(...args), 'send-to-client-contacts');

export function* requestActivePlacementCounts(action: PayloadAction<IOrderPreferenceRequest>) {
  try {
    yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: true }));
    const response = yield call(TrackActivePlacementCounts, getActivePlacementCounts, action.payload.orderId);
    if (response) {
      yield put(orderPreferenceActions.setActivePlacementCounts({ ...response }));
    }
    yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestActivePlacementCounts',
        area: 'src/app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga.ts',
      },
    });
    yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  }
}

export function* requestAutomationStatusByStage(action: PayloadAction<IOrderAutomationRequest>) {
  try {
    if (!isNullOrUndefined(action.payload.orderId)) {
      yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: true }));
      const response = yield call(TrackGetAutomationStatusByStage, getOrderAutomationStatus, action.payload);
      if (response) {
        yield put(
          orderPreferenceActions.setAutomationPreferenceData({
            submissionPreferences: response,
          }),
        );
      }
      yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestAutomationStatusByStage',
        area: 'src/app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga.ts',
      },
    });
    yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  }
}

function* saveAutomationStatus(action: PayloadAction<IAutomationStatusUpdateRequest>) {
  try {
    /** rest should only include the placement stages - lest logic would break below */
    const { orderId, ...rest } = action.payload;
    yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: true }));
    const orderService: OrderService = yield getContext('orderService');
    const response = yield call(TrackUpdateAutomationStatusByStage, orderService.updateOrderAutomationConfig, {
      ...action.payload,
    });
    if (httpSuccess(response.status)) {
      yield call(requestAutomationStatusByStage, {
        payload: {
          orderId: orderId,
        },
        type: orderPreferenceActions.getAutomationStatusByStage.type,
      });
      yield put(
        orderPreferenceActions.setAlert(
          action.payload.rfoToSent
            ? orderPreferenceAlert.automationOnSuccess
            : orderPreferenceAlert.automationOffSuccess,
        ),
      );
      yield put(orderPreferenceActions.setAutomationStatusInProgress(null));
    } else {
      yield put(orderPreferenceActions.setAlert(orderPreferenceAlert.automationFailed));
    }

    yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  } catch (error) {
    yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
    yield put(orderPreferenceActions.setAlert(orderPreferenceAlert.automationFailed));
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveAutomationStatus',
        area: 'src/app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga.ts',
      },
    });
  }
}

function* requestAutomationEditStatusByStage(action: PayloadAction<IOrderAutomationRequest>) {
  try {
    yield call(TrackCheckAutomationEditStatusByStage, checkAutomationEditStatusByStage, action.payload);
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestAutomationEditStatusByStage',
        area: 'src/app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga.ts',
      },
    });
  }
}

function* saveDistributionList(
  action: PayloadAction<{ orderId: number; contacts: DistributionContact[]; onSuccess?: Function; onError?: Function }>,
) {
  try {
    const response = yield call(TrackGetDistributionList, postDistributionList, {
      id: makeIdHash(20),
      orderId: action.payload.orderId,
      contacts: action.payload.contacts.map(item => {
        return {
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          phoneNumber: item.phoneNumber,
          userId: item.userId,
          modifiedBy: item.modifiedBy,
          modifiedDate: convertToFormat(item.modifiedDate, 'YYYY-MM-DDTHH:mm:ss.SSS'),
        };
      }),
    });
    if (httpSuccess(response.status)) {
      const { submission: status } = response.data;
      yield put(orderPreferenceActions.setDistributionList(action.payload.contacts));
      if (!status) {
        yield call(requestAutomationStatusByStage, {
          payload: {
            orderId: action.payload.orderId,
          },
          type: orderPreferenceActions.getAutomationStatusByStage.type,
        });
      }
      yield put(orderPreferenceActions.setAlert(orderPreferenceAlert.distributionUpdationSuccess));
      action.payload.onSuccess?.();
    } else {
      yield put(orderPreferenceActions.setAlert(orderPreferenceAlert.distributionUpdationFailed));
    }
  } catch (error) {
    action.payload.onError?.();
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveDistributionList',
        area: 'src/app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga.ts',
      },
    });
  }
}

function* requestExistingRFOs(action: PayloadAction<IOrderPreferenceRequest>) {
  try {
    const { isStrike } = yield select(selectSubmissionPreferences);
    const response = yield call(
      TrackRequestExistingRFOs,
      getOrderAutomationPlacementsByStatus,
      action.payload.orderId,
      isStrike ? placementSubStatuses.wfc : placementSubStatuses.rfo,
    );
    if (httpSuccess(response.status)) yield put(orderPreferenceActions.setExistingRFOs([...response.data]));
    else yield put(globalActions.setSnackBar({ message: 'Failed to load placements', severity: 'error' }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestExistingRFOs',
        area: 'src/app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga.ts',
      },
    });
  }
}

function* requestReadyToSubmitRfoPlacementIds(action: PayloadAction<IOrderPreferenceRequest>) {
  try {
    const { isStrike } = yield select(selectSubmissionPreferences);
    const response = yield call(
      TrackRequestReadyToSubmitRfoPIds,
      getReadyToSubmitPlacementIds,
      action.payload.orderId,
      isStrike ? placementSubStatuses.wfc : placementSubStatuses.rfo,
      action?.payload?.isWfcToRfoChecked ?? false
    );
    if (httpSuccess(response.status))
      yield put(orderPreferenceActions.setReadyToSubmitRfoPlacementIds([...response.data]));
    else yield put(globalActions.setSnackBar({ message: 'Failed to validate placements', severity: 'error' }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestReadyToSubmitRfoPlacementIds',
        area: 'src/app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga.ts',
      },
    });
  }
}

function* saveAutomationPreferences(
  action: PayloadAction<{
    data: IUpdateSubmissionPreferenceRequest;
  }>,
) {
  yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: true }));
  try {
    const orderService: OrderService = yield getContext('orderService');
    const response = yield call(
      TrackUpdateOrderPreference,
      orderService.updateOrderAutomationConfig,
      action.payload.data,
    );
    if (httpSuccess(response.status)) {
      yield call(requestAutomationStatusByStage, {
        payload: {
          orderId: action.payload.data.orderId,
        },
        type: orderPreferenceActions.getAutomationStatusByStage.type,
      });
      yield put(
        globalActions.setSnackBar({
          severity: 'success',
          message: 'Preferences were saved successfully.',
        }),
      );
    } else
      yield put(
        globalActions.setSnackBar({
          severity: 'error',
          message: 'Error in saving Preferences. Please try again.',
        }),
      );
    yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'saveAutomationPreferences',
        area: 'src/app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga.ts',
      },
    });
    yield put(orderPreferenceActions.setUpdateAutomationApiProgressStatus({ status: false }));
  }
}

function* requestClientContacts(action: PayloadAction<{ facilityId: number; unitId: number }>) {
  try {
    const facilityService: FacilityService = yield getContext('facilityService');
    const response = yield call(
      TrackSendToClientContacts,
      facilityService.getFacilityUnitContacts,
      action.payload.facilityId,
      action.payload.unitId,
    );
    if (httpSuccess(response.status)) {
      const facilityContacts = response?.data?.filter(item =>
        item?.actions?.some((ac: { description: string }) => ac.description === 'Send file to'),
      );
      yield put(orderPreferenceActions.setClientContacts([...facilityContacts]));
    }
  } catch (error) {
    trackException({
      exception: error,
      properties: {
        name: ExceptionType.CommonSAGAError,
        functionName: 'requestClientContacts',
        area: 'src/app/components/Order/OrderDetails/OrderPreferences/store/OrderPreference.saga.ts',
      },
    });
  }
}

export function* orderPreferenceSaga() {
  yield takeLatest(orderPreferenceActions.getActivePlacementCounts.type, requestActivePlacementCounts);
  yield takeLatest(orderPreferenceActions.getAutomationStatusByStage.type, requestAutomationStatusByStage);
  yield takeLatest(orderPreferenceActions.updateAutomationStatusByStage.type, saveAutomationStatus);
  yield takeLatest(orderPreferenceActions.checkAutomationEditStatusByStage.type, requestAutomationEditStatusByStage);
  yield takeLatest(orderPreferenceActions.saveDistributionList.type, saveDistributionList);
  yield takeLatest(orderPreferenceActions.getExistingRFOs.type, requestExistingRFOs);
  yield takeLatest(orderPreferenceActions.getReadyToSubmitRfoPlacementIds.type, requestReadyToSubmitRfoPlacementIds);
  yield takeLatest(orderPreferenceActions.updateOrderPreferences.type, saveAutomationPreferences);
  yield takeLatest(orderPreferenceActions.getClientContacts.type, requestClientContacts);
}
