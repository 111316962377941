import React from 'react';
import { TravelTimePreference } from './TravelTimePreference';
import { TransportationDateOfBirth } from './TransportationDateOfBirth';
import { Gender } from './Gender';
import { TransportationSSN } from './TransportationSSN';
import { DepartureAirport } from './DepartureAirport';
import { Itinerary } from './Itinerary';
import { TransportationsHeader } from './TransportationHeader';
import { TransportationTable } from './TransportationTable';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  selectEditableRequirementTypes,
  selectOfferInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import { TransportationArrivalDate } from './TransportationArrivalDate';
import { TransportationArrivalTime } from './TransportationArrivalTime';

export enum TransportationTypes {
  UNKNOWN = 0,
  DRIVE,
  FLY,
}

export const TransportationWrapper = () => {
  const { requirementFilter } = useSelector(selectOfferInfo);
  const { watch } = useFormContext();
  const transportationChosen = watch('transportationChosen');
  const {
    ssn,
    dob,
    gender,
    departureAirport,
    travelTimePreference,
    transportation,
    departureItineraryLink,
    arrivalDate,
    arrivalTime,
  } = useSelector(selectEditableRequirementTypes);

  const { display: displayTransportationField } = useRequirementFilter(
    'offer-transportation',
    requirementFilter,
    transportation?.requirement,
  );
  const { display: displayTimeField } = useRequirementFilter(
    'offer-transportation',
    requirementFilter,
    travelTimePreference?.requirement,
  );

  const { display: displayDobField } = useRequirementFilter(
    'offer-transportation',
    requirementFilter,
    dob?.requirement,
  );
  const { display: displayGenderField } = useRequirementFilter(
    'offer-transportation',
    requirementFilter,
    gender?.requirement,
  );
  const { display: displaySsnField } = useRequirementFilter(
    'offer-transportation',
    requirementFilter,
    ssn?.requirement,
  );
  const { display: displayAirportField } = useRequirementFilter(
    'offer-transportation',
    requirementFilter,
    departureAirport?.requirement,
  );
  const { display: displayIteneraryField } = useRequirementFilter(
    'offer-transportation',
    requirementFilter,
    departureItineraryLink?.requirement,
  );

  const { display: displayArrivalDateField } = useRequirementFilter(
    'offer-transportation',
    requirementFilter,
    arrivalDate?.requirement,
  );

  const { display: displayArrivalTimeField } = useRequirementFilter(
    'offer-transportation',
    requirementFilter,
    arrivalTime?.requirement,
  );

  return (
    <TransportationTable
      headerComponent={displayTransportationField ? <TransportationsHeader /> : null}
      id="placement-offer-transportation-table"
    >
      {transportationChosen?.transportation === 'Drive' ? (
        <>
          {displayArrivalDateField && <TransportationArrivalDate />}
          {displayArrivalTimeField && <TransportationArrivalTime />}
        </>
      ) : (
        <>{displayArrivalDateField && <TransportationArrivalDate />}</>
      )}
      {transportationChosen?.transportation !== 'Drive' && (
        <>
          {displayTimeField && <TravelTimePreference />}
          {displayAirportField && <DepartureAirport />}
          {displayDobField && <TransportationDateOfBirth />}
          {displayGenderField && <Gender />}
          {displaySsnField && <TransportationSSN />}
          {displayIteneraryField && <Itinerary />}
        </>
      )}
    </TransportationTable>
  );
};
