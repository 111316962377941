import { useDecision } from '@optimizely/react-sdk';
import { trackEvent, trackPageView } from 'app-insights/appInsightsTracking';
import { withRouteParams } from 'app/components/Common/HighOrderComponents/withRouteParams';
import { ff_global_search_title } from 'app/constants/FeatureFlags/Global/keys';
import { isNullOrUndefined } from 'app/helpers/objectHelpers';
import { SearchType } from 'app/models/GlobalSearch/GlobalSearch';
import i18next from 'i18next';
import { selectUser } from 'oidc/user.selectors';
import { isAccountManagement, isCredentialing, isRecruitment } from 'oidc/userRoles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useDocumentTitle from 'utils/customHooks/useDocumentTitle';
import { customSwitch } from 'utils/customSwitchCase/customSwitch';
import { Concatenate, isNum } from 'utils/string/string';
import { CandidateSearchFilters } from './Candidate/CandidateSearchFilters';
import { candidateSearchFilterName } from './Candidate/SearchUtils';
import { FacilitySearchFilters } from './Facility/FacilitySearchFilters';
import { facilitySearchFilterName } from './Facility/SearchUtils';
import { globalSearchTitle, searchTitle } from './helper';
import { MFSOpts, PositionOpts } from './Order/FilterUtils';
import { OrderSearchFilters } from './Order/OrderSearchFilters';
import { orderSearchFilterName } from './Order/SearchUtils';
import { archiveOpts } from './Placement/FilterUtils';
import { PlacementSearchFilter } from './Placement/PlacementSearchFilter';
import { PlacementSearchFilterName } from './Placement/SearchUtils';
import { SearchResultsPage } from './SearchResultsPage';
import { initialCandidateAggregateSearch } from 'app/ComponentLibrary/Filter/CandidateSearch';
import { initialCandidateSkillSetFilter } from 'app/ComponentLibrary/Filter/CandidateSkillset';
import { initialFacilityAggregateSearch } from 'app/ComponentLibrary/Filter/FacilitySearch/Index';

const SearchResults = ({ category, searchString, Filters, searchTitleFlagEnabled, ...props }) =>
  withRouteParams(SearchResultsPage)({
    title:
      searchTitleFlagEnabled && !!searchString
        ? i18next.t(`search.globalSearch.${category}.searchTitle`, { value: searchString })
        : i18next.t(`search.globalSearch.${category}.title`),
    gridTitleTranslationKey: `search.globalSearch.${category}.gridTitle`,
    Filters,
    searchString,
    ...props,
  });

export const GRID_TAG = 'GlobalSearchGrid';

export const chooseDefaultFilterValues = (category, searchString, userInfo) => {
  return customSwitch({
    [SearchType.candidate]: {
      [candidateSearchFilterName.recruiter]:
        isNullOrUndefined(searchString) && userInfo?.employeeId && isRecruitment(userInfo.roles || [])
          ? [
              {
                id: 0,
                name: Concatenate([userInfo.firstName || '', userInfo.lastName || ''], ' '),
                value: userInfo.employeeId.toString(),
              },
            ]
          : [],
      [candidateSearchFilterName.candidate]: [],
      [candidateSearchFilterName.candidateAgggreggate]: initialCandidateAggregateSearch,
      [candidateSearchFilterName.candidateSkillSet]: initialCandidateSkillSetFilter,
      [candidateSearchFilterName.cid]: [],
      [candidateSearchFilterName.brand]: [],
      [candidateSearchFilterName.candidateStatus]: [],
      [candidateSearchFilterName.discipline]: [],
      [candidateSearchFilterName.specialty]: [],
      [candidateSearchFilterName.availableToStart]: null,
      [candidateSearchFilterName.lastApplicationDate]: null,
      [candidateSearchFilterName.engagementDate]: null,
      [candidateSearchFilterName.nonContacted]: null,
      [candidateSearchFilterName.callBackDate]: null,
      [candidateSearchFilterName.lastPlacementEndDate]: null,
      [candidateSearchFilterName.state]: [],
      [candidateSearchFilterName.city]: [],
      [candidateSearchFilterName.zip]: null,
      [candidateSearchFilterName.radius]: null,
      [candidateSearchFilterName.virtualInterview]: null,
      [candidateSearchFilterName.covidVaxStatus]: [],
      [candidateSearchFilterName.placementBeenOnAssignment]: null,
      [candidateSearchFilterName.supervisor]: null,
      [candidateSearchFilterName.supervisorSkillsetRejectionReason]: null,
      [candidateSearchFilterName.supervisorSkillsetStatus]: null,
      [candidateSearchFilterName.license]: null,
      [candidateSearchFilterName.certification]: null,
      [candidateSearchFilterName.workHistory]: null,

      [candidateSearchFilterName.activePlacements]: isNullOrUndefined(searchString)
        ? {
            id: 0,
            name: 'Exclude',
            value: {
              include: false,
            },
          }
        : null,
    },
    [SearchType.order]: {
      [orderSearchFilterName.division]: [],
      [orderSearchFilterName.businessRelationship]: [],
      [orderSearchFilterName.facilityStatus]: isNullOrUndefined(searchString)
        ? [
            {
              id: 0,
              value: '1',
              name: 'Active',
            },
            {
              id: 5,
              value: '3',
              name: 'Prospect',
            },
            {
              id: 4,
              value: '6',
              name: 'Pending',
            },
          ]
        : [],
      [orderSearchFilterName.orderType]: [],
      [orderSearchFilterName.skillsetFilter]: [],
      [orderSearchFilterName.discipline]: [],
      [orderSearchFilterName.specialty]: [],
      [orderSearchFilterName.subSpecialty]: [],
      [orderSearchFilterName.state]: [],
      [orderSearchFilterName.city]: [],
      [orderSearchFilterName.zip]: null,
      [orderSearchFilterName.radius]: null,
      [orderSearchFilterName.facility]: [],
      [orderSearchFilterName.mspClient]: [],
      [orderSearchFilterName.contractAffiliates]: [],
      [orderSearchFilterName.orderAgeDateRange]: null,
      [orderSearchFilterName.orderEndDateRange]: null,
      [orderSearchFilterName.orderBillRate]: null,
      [orderSearchFilterName.paysGww]: null,
      [orderSearchFilterName.guaranteedHours]: null,
      [orderSearchFilterName.expectedHours]: null,
      [orderSearchFilterName.reason]: [],
      [orderSearchFilterName.education]: [],
      [orderSearchFilterName.accountManager]:
        isNullOrUndefined(searchString) && userInfo?.employeeId && isAccountManagement(userInfo.roles || [])
          ? [
              {
                id: 0,
                name: Concatenate([userInfo.firstName || '', userInfo.lastName || ''], ' '),
                value: userInfo.employeeId.toString(),
              },
            ]
          : [],
      [orderSearchFilterName.orderPriority]: [],
      [orderSearchFilterName.shift]: [],
      [orderSearchFilterName.weeklyGrossPay]: null,
      [orderSearchFilterName.startDateRange]: null,
      [orderSearchFilterName.mfs]: isNullOrUndefined(searchString) ? MFSOpts[1] : null,
      [orderSearchFilterName.positions]: isNullOrUndefined(searchString) ? PositionOpts[1] : null,
      [orderSearchFilterName.vaccinated]: null,
      [orderSearchFilterName.orderIds]: [],
    },
    [SearchType.facility]: {
      [facilitySearchFilterName.facilityStatus]: isNullOrUndefined(searchString)
        ? [
            {
              id: 0,
              value: '1',
              name: 'Active',
            },
            {
              id: 5,
              value: '3',
              name: 'Prospect',
            },
            {
              id: 4,
              value: '6',
              name: 'Pending',
            },
          ]
        : [],
      [facilitySearchFilterName.division]: [],
      [facilitySearchFilterName.state]: [],
      [facilitySearchFilterName.city]: [],
      [facilitySearchFilterName.facility]: [],
      [facilitySearchFilterName.facilityAgggreggate]: initialFacilityAggregateSearch,
      [facilitySearchFilterName.accountManager]: [],
      [facilitySearchFilterName.positions]: isNullOrUndefined(searchString) ? PositionOpts[1] : null,
      [facilitySearchFilterName.credentialingAnalyst]: [],
      [facilitySearchFilterName.clinicalManager]: [],
      [facilitySearchFilterName.contractAffiliateName]: [],
      [facilitySearchFilterName.contractAffiliateId]: [],
      [facilitySearchFilterName.regionalDirector]: [],
      [facilitySearchFilterName.clientContractAdmin]: [],
      [facilitySearchFilterName.clientContractSpecialist]: [],
      [facilitySearchFilterName.hospitalSetting]: [],
      [facilitySearchFilterName.requirementType]: [],
      [facilitySearchFilterName.facilityType]: [],
      [facilitySearchFilterName.description]: [],
      [facilitySearchFilterName.staffingVendor]: [],
      [facilitySearchFilterName.healthSystemID]: [],
      [facilitySearchFilterName.healthSystem]: [],
      [facilitySearchFilterName.techVendor]: [],
      [facilitySearchFilterName.mspClient]: [],
    },
    [SearchType.placement]: {
      [PlacementSearchFilterName.recruiter]:
        isNullOrUndefined(searchString) && userInfo?.employeeId && isRecruitment(userInfo.roles || [])
          ? [
              {
                id: 0,
                name: Concatenate([userInfo.firstName || '', userInfo.lastName || ''], ' '),
                value: userInfo.employeeId.toString(),
              },
            ]
          : [],
      [PlacementSearchFilterName.accountManager]:
        isNullOrUndefined(searchString) && userInfo?.employeeId && isAccountManagement(userInfo.roles || [])
          ? [
              {
                id: 0,
                name: Concatenate([userInfo.firstName || '', userInfo.lastName || ''], ' '),
                value: userInfo.employeeId.toString(),
              },
            ]
          : [],
      [PlacementSearchFilterName.credentialingAnalyst]:
        isNullOrUndefined(searchString) && userInfo?.employeeId && isCredentialing(userInfo.roles || [])
          ? [
              {
                id: 0,
                name: Concatenate([userInfo.firstName || '', userInfo.lastName || ''], ' '),
                value: userInfo.employeeId.toString(),
              },
            ]
          : [],
      [PlacementSearchFilterName.placementClinicalContractSpecialist]: [],
      [PlacementSearchFilterName.placementClinicalManager]: [],
      [PlacementSearchFilterName.archiveStatus]: isNullOrUndefined(searchString) ? archiveOpts[0] : null,
      [PlacementSearchFilterName.status]: [],
      [PlacementSearchFilterName.extensionStatus]: [],
      [PlacementSearchFilterName.amnDivision]: [],
      [PlacementSearchFilterName.brand]: [],
      [PlacementSearchFilterName.candidate]: [],
      [PlacementSearchFilterName.cid]: [],
      [PlacementSearchFilterName.oid]: [],
      [PlacementSearchFilterName.pid]: [],
      [PlacementSearchFilterName.facility]: [],
      [PlacementSearchFilterName.facilityID]: [],
      [PlacementSearchFilterName.unit]: [],
      [PlacementSearchFilterName.submission]: [],
      [PlacementSearchFilterName.contractAffiliate]: [],
      [PlacementSearchFilterName.orderType]: [],
      [PlacementSearchFilterName.screen]: [],
      [PlacementSearchFilterName.city]: [],
      [PlacementSearchFilterName.state]: [],
      [PlacementSearchFilterName.mspClient]: [],
      [PlacementSearchFilterName.discipline]: [],
      [PlacementSearchFilterName.specialty]: [],
      [PlacementSearchFilterName.availableStartDate]: null,
      [PlacementSearchFilterName.startDate]: null,
      [PlacementSearchFilterName.endDate]: null,
      [PlacementSearchFilterName.placementCuedTo]: [],
      [PlacementSearchFilterName.contractAffiliateIds]: [],
      [PlacementSearchFilterName.staffingVendor]: [],
      [PlacementSearchFilterName.arbitrationStatus]: [],
      [PlacementSearchFilterName.region]: [],
      [PlacementSearchFilterName.nonContacted]: null,
    },
  })({})(category);
};

export const globalSearchFilters =(category, absoluteDefaultFilter) => {
  return customSwitch({
    [SearchType.candidate]: () => <CandidateSearchFilters defaultFilterValues={absoluteDefaultFilter} />,
    [SearchType.order]: () => <OrderSearchFilters defaultFilterValues={absoluteDefaultFilter} />,
    [SearchType.placement]: () => <PlacementSearchFilter defaultFilterValues={absoluteDefaultFilter} />,
    [SearchType.facility]: () => <FacilitySearchFilters defaultFilterValues={absoluteDefaultFilter} />,
  })(null)(category);
}

export const SearchResultsWrapper = () => {
  const { category, searchString } = useParams<{ category: string; searchString: string | undefined }>();
  const [browserTitle, setBrowserTitle] = useState<string | undefined>();
  const { userInfo } = useSelector(selectUser);
  const [searchTitleFlag] = useDecision(ff_global_search_title);

  useEffect(() => {
    if (searchString && searchString !== '#') setBrowserTitle(globalSearchTitle(category));
    else setBrowserTitle(searchTitle(category));
  }, [category, searchString]);

  useDocumentTitle({ title: browserTitle });

  //AppInsight : Volume of searches for specific ID(int).
  useEffect(() => {
    if (isNum(searchString))
      trackEvent({ type: 'event', name: 'Global Search', properties: { category: category, search: searchString } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  useEffect(() => {
    if (browserTitle) trackPageView({ name: browserTitle });
  }, [browserTitle]);

  const absoluteDefaultFilter = chooseDefaultFilterValues(category, searchString, userInfo);

  

  const chooseFilter = React.useMemo(() => {
    return globalSearchFilters(category, absoluteDefaultFilter);
  }, [absoluteDefaultFilter, category]);

  return (
    <>
      {window.location.href.includes(category) && (
        <div key={`global-search-${category}-${searchString}`}>
          <SearchResults
            defaultFilterValues={absoluteDefaultFilter}
            Filters={chooseFilter}
            category={category}
            searchString={searchString}
            gridTag={`${GRID_TAG}-${category}`}
            searchTitleFlagEnabled={searchTitleFlag.enabled}
          />
        </div>
      )}
    </>
  );
};
