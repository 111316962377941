import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { ContactTypePhoneNumber } from './ContactTypePhoneNumber';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import Typography from '@mui/material/Typography';
import { IContactTypeOption, IFormClientPhoneNumber } from 'app/models/ClientContact/ClientContact';
import { theme } from 'styles/global-styles';
import { getDefaultPhoneNumber } from '@AMIEWEB/Facility/FacilityDetails/helper';
import clsx from 'clsx';

const useStyles = makeStyles()(() => ({
  inlineLink: {
    color: theme.palette.framework.system.navyBlue,
    cursor: 'pointer',
  },
  inlineLinkDisabled: {
    color: theme.palette.framework.system.shadeyGrey,
    cursor: 'not-allowed',
  },
  addLink: {
    fontSize: '14px',
    fontWeight: '600',
    paddingLeft: '0.3rem',
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
  },
}));

interface ICCPhoneNumber {
  options: IContactTypeOption[];
}

export const CCPhoneNumber = (props: ICCPhoneNumber) => {
  const { options } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [typeOptions, setTypeOptions] = useState<IContactTypeOption[]>([]);

  const { control } = useFormContext();

  const { fields, append } = useFieldArray({
    control,
    name: `phoneNumbers`,
  });

  const setPhoneTypes = () => {
    let tempTypes = [...options];
    fields?.map(item => {
      tempTypes = tempTypes?.filter(x => x?.ID !== item?.phoneTypeId);
    });
    setTypeOptions(tempTypes);
  };

  useEffect(() => {
    setPhoneTypes();
  }, [fields, options]);

  const addPhone = () => {
    if (typeOptions.length > 0) {
      const newPhoneType = typeOptions[0];
      const item = getDefaultPhoneNumber(newPhoneType?.ID, options);
      append(item);
      setTypeOptions(typeOptions?.filter((item, index) => index !== 0));
    }
  };

  const getTypeOptions = (typeId: number) => {
    const current = options?.find(item => item?.ID === typeId);
    return [...typeOptions, current];
  };

  return (
    <>
      <LayoutGrid container direction="column">
        <LayoutGrid container direction="row" justifyContent="space-between">
          <LayoutGridItem item style={{ paddingLeft: 24 }}>
            <Typography variant="subtitle1" gutterBottom component="div" style={{ fontWeight: 500 }}>
              {t('candidate.profileInformation.phone')}
            </Typography>
          </LayoutGridItem>
          <LayoutGridItem item>
            <span
              className={
                typeOptions.length > 0
                  ? clsx(classes.inlineLink, classes.addLink)
                  : clsx(classes.inlineLinkDisabled, classes.addLink)
              }
              onClick={() => addPhone()}
            >
              {t('candidate.profileSummaryCard.add')}
            </span>
          </LayoutGridItem>
        </LayoutGrid>
        {fields?.map((field: IFormClientPhoneNumber, index: number) => {
          return (
            <ContactTypePhoneNumber
              controllerName={`phoneNumbers[${index}]`}
              options={getTypeOptions(field?.phoneTypeId)}
              key={field?.id}
              index={index}
              phoneNumberItem={field}
              totalItems={fields?.length}
            />
          );
        })}
      </LayoutGrid>
    </>
  );
};
