const placementPrefix = 'placement';
const globalPrefix = 'global';
const facilityPrefix = 'facility';
const marginToolPrefix = 'marginTool';

export const PromiseTrackerKeys = {
  placements: {
    peopleSoftSync: `${placementPrefix}-sync-placement-with-peoplesoft`,
    peopleSoftGetDetails: `${placementPrefix}-get-placement-peoplesoft-status`,
    peopleSoftAwaitResults: `${placementPrefix}-get-placement-peoplesoft-results-delayed`,
    updateAllDetails: `${placementPrefix}-update-placement-details`,
    mergeTokens: `${placementPrefix}-candidate-contract-templates-merge-tokens`,
    generateDraft: `${placementPrefix}-candidate-contract-templates-generate-draft`,
    deleteDraft: `${placementPrefix}-candidate-contract-templates-delete-draft`,
    previewDocument: `${placementPrefix}-client-contract-templates-preview-document`,
    createDocument: `${placementPrefix}-client-contract-templates-create-document`,
    saveDraft: `${placementPrefix}-candidate-contract-templates-save-draft`,
    publishDocument: `${placementPrefix}-client-contract-templates-publish-document`,
    voidDocument: `${placementPrefix}-client-contract-templates-void-document`,
    requestPdfDoc: `${placementPrefix}-candidate-contract-request-pdf-doc`,
    updateOrderId: `update-orderId-placement`,
  },
  global: {
    optimizelyFlags: `${globalPrefix}-optimizely-flags`,
    optimizelyFlagVariation: `${globalPrefix}-optimizely-flag-variation`,
    searchCall: `${globalPrefix}-search-call`,
  },
  facility: {
    facilitiesForAffiliate: `${facilityPrefix}-get-facilities-for-affiliate`,
  },
  marginTool: {
    peopleSoftCallMarginTool: `${marginToolPrefix}-get-people-soft-calculation`,
    gsaCalculateFetchData: `${marginToolPrefix}-get-scenario-gsa-calculate-data`,
    getAddScenarioDetailsFetchData: `${marginToolPrefix}-get-scenario-add-details-data`,
    initialMarginToolsData: `${marginToolPrefix}-get-initial-margin-tools-data`,
    scenarioNavigationData: `${marginToolPrefix}-get-scenario-navigation-tree-data`,
    marginToolTemplate: `${marginToolPrefix}-get-margin-tool-template`,
    fetchplacementHeader: `${marginToolPrefix}-get-margin-tool-placement-header`,
    fetchOrderDetails: `${marginToolPrefix}-get-margin-tool-order-details`,
    fetchPlacementDocs: `${marginToolPrefix}-get-margin-tool-placement-docs`,
    fetchBillRateMod: `${marginToolPrefix}-get-margin-tool-bill-rate-mod`,
    marginToolPreview: `${marginToolPrefix}-get-margin-tool-preview`,
    saveScenario: `${marginToolPrefix}-save-scenario`,
    validateScenario: `${marginToolPrefix}-validate-scenario`,
  },
};
