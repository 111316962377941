import Divider from '@mui/material/Divider';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import { groupBy, toArray } from 'lodash';
import React from 'react';
import { SkillChecklistFields } from './FieldSections/SkillChecklistFields';
import { SkillChecklistHeader } from './FieldSections/SkillChecklistHeader';
import { Acknowledgment, Certification, RatingLegend } from './FieldSections/ManageSkillChecklistSection';
import { NineCheckboxQuestion } from './FieldSections/NineCheckbox';
import { ISkillChecklistSection } from './Config/model';
import { getSectionData } from './Config/helper';
import { CertificationType, EMRType, NineCheckboxType } from './Config/constants';
import { useSelector } from 'react-redux';
import { candidateDetailsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';

export const ViewSkillChecklist = ({ credentialDateCompleted }: { credentialDateCompleted: string }) => {
  const candidateDetails = useSelector(candidateDetailsSelection);
  const viewSkillsChecklistPassport = candidateDetails.viewSkillsChecklistPassport;
  const groupedSkillChecklist = groupBy(viewSkillsChecklistPassport?.questions, 'section');
  const mappedSkillChecklist = toArray(groupedSkillChecklist);

  /**
   * Method to find the skill checklist
   * @param skillChecklistSection - list of skill checklist items
   * @param index - index of each skill checklist section item
   */
  const findSkillChecklistSection = (skillChecklistSection: ISkillChecklistSection[], index: number) => {
    const certificationSection = skillChecklistSection.find(item => item.text === CertificationType);
    const conversionSection = skillChecklistSection.find(item => item.text === EMRType);
    const nineCheckbox = skillChecklistSection?.filter(x => x.typeId === NineCheckboxType);
    const certificateData = getSectionData(skillChecklistSection, certificationSection);
    if (certificateData?.length) {
      return <Certification certificateData={certificateData} key={index} />;
    }

    if (nineCheckbox?.length > 0) {
      return <NineCheckboxQuestion skillChecklistSection={skillChecklistSection} />
    } else {
      return <SkillChecklistFields key={index} skillChecklistFields={skillChecklistSection} />;
    }
    
  };

  return viewSkillsChecklistPassport ? (
    <>
      <Divider />
      <LayoutGrid container>
        <LayoutGridItem container item direction="column">
          <SkillChecklistHeader
            skillChecklistItem={viewSkillsChecklistPassport}
            credentialDateCompleted={credentialDateCompleted}
          />
        </LayoutGridItem>
        <LayoutGridItem container item rowGap={2}>
          {mappedSkillChecklist.map((skillChecklistSection, index) =>
            findSkillChecklistSection(skillChecklistSection, index),
          )}
        </LayoutGridItem>
        <RatingLegend />
        <Divider style={{ width: '98%' }} />
        <Acknowledgment />
      </LayoutGrid>
    </>
  ) : (
    <></>
  );
};
