import { NOTES_CHAR_LIMIT } from '@AMIEWEB/Facility/FacilityDetails/helper';
import { InputAdornment, TextField, Theme } from 'amn-ui-core';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styledComponent from 'styled-components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { makeStyles } from 'tss-react/mui';

export const useNotesStyles = makeStyles()((theme: Theme) => ({
  errorText: {
    margin: '2px',
    paddingLeft: '12px',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: theme.palette.framework.system.coralRed,
  },
  notesRoot: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.framework.system.charcoal,
      backgroundColor: theme.palette.framework.system.whiteSmoke,
    },
    '& .Mui-disabled': {
      backgroundColor: `${theme.palette.framework.system.whiteSmoke} !important`,
    },
  },
  notesAdornment: {
    position: 'absolute',
    top: '0',
    right: '0',
    color: theme.palette.framework.system.errorRed,
  },
  errorInfoIcon: {
    width: '16px',
    height: '16px',
  },
  characterCount: {
    fontSize: '12px',
    justifyContent: 'center',
    color: theme.palette.framework.system.tertiaryGrey,
  },
  countContainer: {
    width: '100%',
    marginLeft: '0px',
  },
}));

const Notes = styledComponent(TextField)`
  width: 100%;
  margin-top: 0px;
`;

interface ICCNotes {
  controllerName: string;
  disabled?: boolean;
  maxRows?: number;
  notesLabel: string;
}

const CCNotes: React.FC<ICCNotes> = props => {
  const { classes } = useNotesStyles();
  const { t } = useTranslation();
  const { controllerName, disabled, maxRows, notesLabel } = props;
  const { control, watch } = useFormContext();
  const [focused, setFocused] = useState<boolean>(false);
  const notes = watch(controllerName) ?? '';

  return (
    <React.Fragment>
      <Controller
        name={controllerName}
        control={control}
        render={({ onChange, value }) => (
          <Notes
            value={value}
            label={notesLabel}
            className={classes.notesRoot}
            disabled={disabled || false}
            onChange={event => {
              onChange(event);
            }}
            multiline
            maxRows={maxRows || 1}
            inputProps={{
              maxLength: NOTES_CHAR_LIMIT,
            }}
            InputProps={{
              onFocus: () => setFocused(true),
              onBlur: () => setFocused(false),
              startAdornment:
                focused && notes?.length >= NOTES_CHAR_LIMIT ? (
                  <InputAdornment position="start" className={classes.notesAdornment}>
                    <InfoOutlinedIcon className={classes.errorInfoIcon} />
                  </InputAdornment>
                ) : (
                  <></>
                ),
            }}
            variant="filled"
            error={focused && notes?.length === NOTES_CHAR_LIMIT}
          />
        )}
      />
      <LayoutGrid container justifyContent="space-between" className={classes.countContainer} xs={12}>
        <LayoutGridItem paddingLeft={'0px !important'}>
          <label className={classes.errorText}>{`${
            focused && notes?.length === NOTES_CHAR_LIMIT ? t('notification.voice.maxCharLimit') : ' '
          }`}</label>
        </LayoutGridItem>
        <LayoutGridItem>
          <label
            className={focused && notes?.length === NOTES_CHAR_LIMIT ? classes.errorText : classes.characterCount}
          >{`${notes?.length}/${NOTES_CHAR_LIMIT}`}</label>
        </LayoutGridItem>
      </LayoutGrid>
    </React.Fragment>
  );
};

export default CCNotes;
