import React from 'react';
import { Divider, Typography } from '@mui/material';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { HeadingType, IgnoreHeadingType, Heading4, Heading3 } from '../Config/constants';
import { getFieldSections, groupByTypeAndIndent } from './helper';

/**
 *  Functional component which renders dynamic field and its values
 */
export const SkillChecklistFields = skillChecklistSection => {
  const skillChecklistFields = skillChecklistSection.skillChecklistFields;
  const heading = skillChecklistFields?.find(item => item.typeId === HeadingType || item.typeId === IgnoreHeadingType);
  const subHeading = skillChecklistFields?.find(item => item?.typeId === Heading3);
  const isHeading4Present = skillChecklistFields?.some(item => item?.typeId === Heading4);
  // Filter to new array that will have hasAnswer as true
  const skillChecklistCollection = skillChecklistFields?.filter(i => i.hasAnswer);
  const firstTypeId4Index = skillChecklistFields?.findIndex(item => item?.typeId === Heading4);
  const filteredData =
    firstTypeId4Index !== -1
      ? skillChecklistFields?.slice(0, firstTypeId4Index)?.filter(i => i?.hasAnswer)
      : [...skillChecklistFields]?.filter(i => i?.hasAnswer);
  const skillChecklistCollection2 = isHeading4Present ? groupByTypeAndIndent(skillChecklistFields) : [];
  // Split the array into two sections by sequence id
  const collectionData = isHeading4Present && filteredData?.length > 0 ? filteredData : skillChecklistCollection;
  const middleIndex = Math.ceil(collectionData.length / 2);
  const firstHalf = collectionData.slice(0, middleIndex);
  const secondHalf =
    collectionData.length === 1
      ? []
      : collectionData.length % 2 === 0
      ? collectionData.slice(-middleIndex)
      : collectionData.slice(-(middleIndex - 1));

  return (
    <LayoutGridItem container item direction="column">
      <LayoutGridItem item>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: '500 !important',
            color: theme => theme.palette.framework.system.charcoal,
            fontSize: '12px !important',
          }}
        >
          {subHeading?.text?.replace(/&amp;/g, '&')}
        </Typography>
      </LayoutGridItem>
      <LayoutGridItem item>
        <Typography variant="subtitle1" sx={{ fontWeight: '500 !important', color: '#1B3F69' }}>
          {heading?.text?.replace(/&amp;/g, '&')}
        </Typography>
      </LayoutGridItem>
      {(!isHeading4Present || (isHeading4Present && filteredData?.length > 0)) && (
        <LayoutGridItem container item direction="row" pb="12px">
          {/* iterate through sub section fields */}
          <LayoutGridItem item xs={5.8}>
            {firstHalf?.map((currentItem, index: number) => getFieldSections({ currentItem, index }))}
          </LayoutGridItem>
          <LayoutGridItem item xs={0.2} mr={'12px'}>
            <Divider orientation="vertical" />
          </LayoutGridItem>
          <LayoutGridItem item xs={5.8}>
            {secondHalf?.map((currentItem, index: number) => getFieldSections({ currentItem, index }))}
          </LayoutGridItem>
        </LayoutGridItem>
      )}
      {isHeading4Present && <SubHeaderSection skillChecklistCollection={skillChecklistCollection2} />}

      <Divider />
    </LayoutGridItem>
  );
};

const SubHeaderSection = ({ skillChecklistCollection }) => {
  const renderedItems = skillChecklistCollection.map((each, index) => {
    if (each?.some(item => Array.isArray(item))) {
      const heading = each?.find(item => item?.typeId === Heading4);
      const subHeadingData = each?.filter(item => item?.typeId !== Heading4 && !Array.isArray(item) && item?.hasAnswer);
      const middleIndex = Math.ceil(subHeadingData?.length / 2);
      const firstHalf = subHeadingData?.slice(0, middleIndex);
      const secondHalf =
        subHeadingData?.length === 1
          ? []
          : subHeadingData?.length % 2 === 0
          ? subHeadingData?.slice(-middleIndex)
          : subHeadingData?.slice(-(middleIndex - 1));
      const subArrays = each?.filter(item => item?.typeId !== 4 && Array.isArray(item));
      return (
        <LayoutGridItem container item direction="column" key={index}>
          <LayoutGridItem item>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: '500 !important',
                color: theme => theme.palette.framework.system.charcoal,
                fontSize: '12px !important',
                marginTop: '5px',
              }}
            >
              {heading?.text?.replace(/&amp;/g, '&')}
            </Typography>
          </LayoutGridItem>
          <LayoutGridItem container item direction="row" pb="12px">
            <LayoutGridItem item xs={5.8}>
              {firstHalf?.map((currentItem, index) => getFieldSections({ currentItem, index }))}
            </LayoutGridItem>
            <LayoutGridItem item xs={0.2} mr={'12px'}>
              <Divider orientation="vertical" />
            </LayoutGridItem>
            <LayoutGridItem item xs={5.8}>
              {secondHalf?.map((currentItem, index) => getFieldSections({ currentItem, index }))}
            </LayoutGridItem>
          </LayoutGridItem>
          {subHeadingData?.length > 0 && <Divider />}
          {subArrays?.map((eachItem, subIndex) => (
            <Subsection data={eachItem} key={subIndex} />
          ))}
        </LayoutGridItem>
      );
    } else {
      return <Subsection data={each} key={index} />;
    }
  });
  return <>{renderedItems}</>;
};

const Subsection = ({ data }) => {
  const heading = data?.find(item => item?.typeId === Heading4);
  const skillChecklistCollection = data?.filter(i => i?.hasAnswer);
  const middleIndex = Math.ceil(skillChecklistCollection?.length / 2);
  const firstHalf = skillChecklistCollection?.slice(0, middleIndex);
  const secondHalf =
    skillChecklistCollection?.length === 1
      ? []
      : skillChecklistCollection?.length % 2 === 0
      ? skillChecklistCollection?.slice(-middleIndex)
      : skillChecklistCollection?.slice(-(middleIndex - 1));
  return (
    <LayoutGridItem container item direction="column">
      <LayoutGridItem item>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: '500 !important',
            color: theme => theme.palette.framework.system.charcoal,
            fontSize: '12px !important',
          }}
        >
          {heading?.text?.replace(/&amp;/g, '&')}
        </Typography>
      </LayoutGridItem>
      <LayoutGridItem container item direction="row" pb="12px">
        <LayoutGridItem item xs={5.8}>
          {firstHalf?.map((currentItem, index) => getFieldSections({ currentItem, index }))}
        </LayoutGridItem>
        <LayoutGridItem item xs={0.2} mr={'12px'}>
          <Divider orientation="vertical" />
        </LayoutGridItem>
        <LayoutGridItem item xs={5.8}>
          {secondHalf?.map((currentItem, index) => getFieldSections({ currentItem, index }))}
        </LayoutGridItem>
      </LayoutGridItem>
      <Divider />
    </LayoutGridItem>
  );
};
