import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILookupOptions, ILookupState, initialState } from './initialState';

const lookupSlice = createSlice({
  name: 'lookup',
  initialState: initialState,
  reducers: {
    setDiscipline(state, action: PayloadAction<ILookupState>) {
      state.discSpecMapping = action.payload.discSpecMapping;
      state.disciplines = action.payload.disciplines;
      state.divisionDisciplines = action.payload.divisionDisciplines;
      state.specialties = action.payload.specialties;
      state.subSpecialties = action.payload.subSpecialties;
      state.brands = action.payload.brands;
      state.countries = action.payload.countries;
      state.degrees = action.payload.degrees;
      state.majors = action.payload.majors;
      state.states = action.payload.states;
      state.raceEthnicityOptions = action.payload.raceEthnicityOptions;
      state.graduationMonth = action.payload.graduationMonth;
      state.graduationYear = action.payload.graduationYear;
      state.interviewReasons = action.payload.interviewReasons;
      state.divisions = action.payload.divisions;
      state.hearsayCertifications = action.payload.hearsayCertifications;
      state.licenseTypes = action.payload.licenseTypes;
      state.auditType = action.payload.auditType;
      state.auditAction = action.payload.auditAction;
      state.auditStatus = action.payload.auditStatus;
      state.auditResponsible = action.payload.auditResponsible;
      state.skillsetRejectionReason = action.payload.skillsetRejectionReason;
      state.maritalStatus = action.payload.maritalStatus;
      state.furnitureExtras = action.payload.furnitureExtras;
      state.keyFacts = action.payload.keyFacts;
      state.typeBed = action.payload.typeBed;
      state.housingType = action.payload.housingType;
    },
    setLookupOptions(state, action: PayloadAction<ILookupOptions>) {
      state.lookUpOptions.divisionOptions = action.payload.divisionOptions;
      state.lookUpOptions.disciplineOptions=action.payload.disciplineOptions;
      state.lookUpOptions.specialtiesOptions = action.payload.specialtiesOptions;
      state.lookUpOptions.subSpecialtiesOptions=action.payload.subSpecialtiesOptions;
      state.lookUpOptions.facilityStatusOptions = action.payload.facilityStatusOptions;
      state.lookUpOptions.orderTypeOptions = action.payload.orderTypeOptions;
      state.lookUpOptions.orderPriorityOptions = action.payload.orderPriorityOptions;
      state.lookUpOptions.compactStateOptions = action.payload.compactStateOptions;
      state.lookUpOptions.shiftListOptions = action.payload.shiftListOptions;
      state.lookUpOptions.accountManagerOptions = action.payload.accountManagerOptions;
      state.lookUpOptions.stateOptions = action.payload.stateOptions;
      state.lookUpOptions.isOptionsLoaded = action.payload.isOptionsLoaded;
    },
    setFacilityLookUpOptions(state, action) {
      state.facilityLookUp = action.payload;
    },
    getAllLookup() {},
    getAllLookupOptions() {},
  },
});

export const { actions: lookupActions, reducer: lookupReducer } = lookupSlice;
