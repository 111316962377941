import { IRequirementRowPadding } from 'app/models/Placement/PlacementSummary';
import React, { useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRequirementFilter } from '../../../Helpers/requirementFilter';
import {
  selectEditableFields,
  selectEditableRequirementTypes,
  selectOfferInfo,
} from '../../../../../../../../../store/redux-store/placement-summary/selectors';
import { ConfirmationCheckbox } from '../../TableComponents/Confirmations/ConfirmationCheckbox';
import { ConfirmationsRow } from '../../TableComponents/Confirmations/ConfirmationsTable';
import { selectPlacementStatus } from 'store/redux-store/placement-status/selectors';
import { TransportationTypes } from '../Transportation/TransportationWrapper';
import { CustomTooltip } from '@AMIEWEB/Common';
import { selectPlacementOrderType } from 'store/redux-store/placement-details/selectors';
import { PlacementOrderType } from 'app/models/Placement/PlacementDetails';

export const DatesConfirmed = ({ hasRequirements }: IRequirementRowPadding) => {
  const { currentPlacementStatus } = useSelector(selectPlacementStatus);
  const { t } = useTranslation();
  const { datesConfirmed: initialValue } = useSelector(selectEditableFields);
  const { requirementFilter } = useSelector(selectOfferInfo);
  const { datesConfirmed } = useSelector(selectEditableRequirementTypes);
  const { control, getValues, setValue } = useFormContext();
  const formData = getValues();
  const [tooltipContent, setTooltipContent] = useState<string>('');
  const selectedTransport = getValues('transportationChosen');
  const placementOrderType = useSelector(selectPlacementOrderType);
  const isStrike = placementOrderType === PlacementOrderType.StrikeOrderInHsg;
  const { display } = useRequirementFilter('offer-confirmation', requirementFilter, datesConfirmed?.requirement);

  const updateDatesConfirmed = () => {
    const updatedDatesConfirmed = getValues('datesConfirmed');
    setValue('datesConfirmed', {
      ...updatedDatesConfirmed,
      checked: false,
    });
  };

  const handleDriveTransportation = () => {
    if (!formData?.arrivalDate || !formData?.arrivalTime) {
      setTooltipContent(t('placement.profile.summary.offer.confirmations.driveTransportationMessage'));
      updateDatesConfirmed();
      return true;
    }
  };

  const handleFlyTransportation = () => {
    if (!formData?.arrivalDate || !formData?.departureAirport) {
      setTooltipContent(t('placement.profile.summary.offer.confirmations.flyTransportationMessage'));
      updateDatesConfirmed();
      return true;
    }
  };

  const handleUnknownTransportation = () => {
    setTooltipContent(t('placement.profile.summary.offer.confirmations.unknownTransportationMessage'));
    updateDatesConfirmed();
    return true;
  };

  const validateDatesConfirmedByTransportation = () => {
    switch (selectedTransport?.transportationId) {
      case TransportationTypes.UNKNOWN:
        return handleUnknownTransportation();
      case TransportationTypes.DRIVE:
        return handleDriveTransportation();
      case TransportationTypes.FLY:
        return handleFlyTransportation();
      default:
        return false;
    }
  };

  /**
   * Method to check if the dates are confirmed disabled by the transportation type only for strike placements
   */
  const isDatesConfirmedDisabled = useMemo(() => {
    const isDisabled = validateDatesConfirmedByTransportation();
    return isDisabled;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formData?.arrivalDate,
    formData?.arrivalTime,
    selectedTransport?.transportationId,
    formData?.departureAirport?.label,
  ]);

  return display ? (
    <Controller
      control={control}
      name="datesConfirmed"
      render={({ ref, ...rest }) => (
        <ConfirmationsRow
          id="placement-offer-confirmations-dates-confirmed"
          lastUpdatedBy={rest?.value?.lastUpdatedBy}
          lastUpdatedDate={rest?.value?.lastUpdatedAt}
          requirement={datesConfirmed?.requirement}
          hasOtherRequirements={hasRequirements}
          controlComponent={
            <CustomTooltip disabled={isStrike ? !isDatesConfirmedDisabled : true} tooltipContent={tooltipContent}>
              <ConfirmationCheckbox
                label={t('placement.profile.summary.offer.confirmations.datesConfirmed')}
                defaultState={initialValue}
                disabled={
                  isStrike
                    ? isDatesConfirmedDisabled
                    : currentPlacementStatus?.activityStatusId >= 15 && currentPlacementStatus?.activityStatusId !== 16
                }
                {...rest}
              />
            </CustomTooltip>
          }
        />
      )}
    />
  ) : null;
};
