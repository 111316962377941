import { Grid, TextField } from 'amn-ui-core';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { useSubStyles } from './FacilitySubSearch.styles';

export interface IFacilitySubSearch {
    facilityName: string;
    fid: string;
}

export const FacilitySubSearch: React.FC<{
    value: IFacilitySubSearch;
    onChange: (state: IFacilitySubSearch) => void;
}> = ({ value, onChange }) => {
    const { classes } = useSubStyles();
    const { t } = useTranslation();

    const handleChange = React.useCallback(
        (searchProps: { [key in keyof IFacilitySubSearch]?: string }) => {
            const filterKey = Object.keys(searchProps);

            searchProps[filterKey[0]] = searchProps[filterKey[0]] ?? null;
            onChange({ ...value, ...searchProps });
        },
        [onChange, value],
    );

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-end"
            spacing={1}
            classes={{
                root: classes.headerContainer,
            }}
        >
            <Grid item>
                <TextField
                    value={value?.facilityName ?? ''}
                    variant="outlined"
                    size="small"
                    className={classes.textFieldFilterName}
                    label={<SearchLabel label={t('search.filter.facilitySearch.facilityName')} />}
                    inputProps={{ maxLength: 225 }}
                    InputLabelProps={{ classes: { root: classes.label, focused: classes.focusedLabel } }}
                    onChange={event => handleChange({ facilityName: event.target?.value })}
                />
            </Grid>
            <Grid item>
                <TextField
                    value={value?.fid ?? ''}
                    variant="outlined"
                    size="small"
                    className={classes.textFieldFid}
                    label={<SearchLabel label={t('search.filter.facilitySearch.fid')} />}
                    InputLabelProps={{ classes: { root: classes.label, focused: classes.focusedLabel } }}
                    onChange={event => {
                        if (/^\d*$/.test(event.target?.value)) { handleChange({ fid: event.target?.value }) }
                    }}
                />
            </Grid>
        </Grid>
    );
};

const SearchLabel = ({ label }) => (
    <>
        <SearchIcon style={{ opacity: 0.6 }} /> <span>{label}</span>
    </>
);
