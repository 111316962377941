import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types/RootState';
import { initialState } from './initialState';

const domain = (state: RootState) => state?.lookup || initialState;

export const lookupSelector = createSelector(domain, state => state);
export const ethnicityOptionsSelector = createSelector(domain, state => state.raceEthnicityOptions);
export const disciplineDataSelector = createSelector(domain, state => state.disciplines);
export const specialtiesSelector = createSelector(domain, state => state.specialties);
export const lookupLoaderSelector = createSelector(domain, state => state.isLoaded);
export const interviewReasonsSelector = createSelector(domain, state => state.interviewReasons);
export const brandsSelector = createSelector(domain, state => state.brands);
export const lookupOptionSelector = createSelector(domain, state => state.lookUpOptions);
export const lookupOptionLoaderSelector = createSelector(domain, state => state.lookUpOptions.isOptionsLoaded);
export const facilityLookUpSelector = createSelector(domain, state => state.facilityLookUp);