import { IAddScenarioRequestPayload } from '@AMIEWEB/MarginTool/Components/PayPackage/AddScenario/model';
import { IGSARequestPayload } from '@AMIEWEB/MarginTool/Components/PayPackage/models/PayPackage';
import { IPeopleSoftMarginDetailsRequest } from '@AMIEWEB/MarginTool/Components/PeopleSoftCalculation/IPeopleSoftCalculation';
import { IMarginToolSave } from '@AMIEWEB/MarginTool/IMarginToolSave';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import {
  IDeleteMarginToolDetailsPayload,
  IDeleteScenarioDetailsPayload,
  IUpdatePayPackageStatusPayload,
} from 'store/redux-store/margin-tool/slices/pay-package-status/pay-package-status.model';
import baseAxiosInstance from 'utils/BaseApi';

export class MarginToolService {
  private sharedBase: string;

  private cancellationToken = axios.CancelToken.source();

  private static currentInstance: MarginToolService;

  config?: AxiosRequestConfig = {};

  private constructor() {
    this.sharedBase = globalThis?.app?.env?.REACT_APP_TRACK_CHANGE_SERVICE_BASE_URL;
  }

  static createInstance(): MarginToolService {
    const activeInstance = new MarginToolService();
    // activeInstance.cancellationToken = axios.CancelToken.source();
    // if (activeInstance.config) activeInstance.config.cancelToken = activeInstance.cancellationToken.token;
    return activeInstance;
  }

  static getInstance(): MarginToolService {
    if (!this.currentInstance) this.currentInstance = this.createInstance();
    return this.currentInstance;
  }

  static cancelActiveRequests() {
    this.currentInstance.cancellationToken.cancel('RequestCancellation');
    // this.currentInstance = null;
  }

  cancelRequests() {
    this.cancellationToken.cancel('RequestCancellation');
    return MarginToolService.getInstance();
  }

  cancelAndRevokeInstance() {
    this.cancellationToken.cancel('RequestCancellation');
    this.cancellationToken = axios.CancelToken.source();
    if (this.config) this.config.cancelToken = this.cancellationToken.token;
  }

  getMarginToolAddScenarioDetailsData = (
    placementId: number,
    bookingPeriodId?: number | null,
    isExtensionFlow: boolean = false,
  ) => {
    return baseAxiosInstance
      .post(`${this.sharedBase}/placement/get-add-scenario-details`, {
        placementId: placementId,
        bookingPeriodId: bookingPeriodId,
        isExtensionFlow: isExtensionFlow,
      })
      .catch((error: AxiosError) => error.response);
  };

  getMarginToolAddExtentionDetailsData = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/placement/${placementId}/margin-add-extension`)
      .catch((error: AxiosError) => error.response);

  getMarginToolDetails = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/placement/${placementId}/margin-details`)
      .catch((error: AxiosError) => error.response);

  getShiftData = () =>
    baseAxiosInstance.get(`${this.sharedBase}/shift/details`).catch((error: AxiosError) => error.response);

  getlistofPayPackageStatus = (status: number, category: number) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/margin/status-list/${status}/${category}`)
      .catch((error: AxiosError) => error.response);

  postAddScenarioModal = (payload: IAddScenarioRequestPayload) =>
    baseAxiosInstance
      .post(`${this.sharedBase}/placement/add-scenario-details`, payload)
      .catch((error: AxiosError) => error.response);

  getNewScenarioDefaultValues = (placementId: number, scenarioId: number) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/placement/${placementId}/scenario/${scenarioId}/basic-calculation-details`)
      .catch((error: AxiosError) => error.response);

  deleteSingleScenario = (payload: IDeleteScenarioDetailsPayload) =>
    baseAxiosInstance
      .post(`${this.sharedBase}/margin/scenario-details/delete`, payload)
      .catch((error: AxiosError) => error.response);

  deleteMarginDetails = (payload: IDeleteMarginToolDetailsPayload) =>
    baseAxiosInstance
      .post(`${this.sharedBase}/margin/margin-details/delete`, payload)
      .catch((error: AxiosError) => error.response);

  getPlacementEmployeesForMargin = (placementId: number) =>
    baseAxiosInstance
      .get(`${this.sharedBase}/margin/placement-employees/${placementId}`)
      .catch((error: AxiosError) => error.response);

  updatePayPackageStatus = (payload: IUpdatePayPackageStatusPayload) =>
    baseAxiosInstance
      .post(`${this.sharedBase}/margin/scenario/update-status`, payload)
      .catch((error: AxiosError) => error.response);

  postMarginToolSave = (payload: IMarginToolSave) =>
    baseAxiosInstance
      .post(`${this.sharedBase}/margin/scenario-details/update`, payload)
      .catch((error: AxiosError) => error.response);

  postMarginToolValidate = (payload: IMarginToolSave) =>
    baseAxiosInstance
      .post(`${this.sharedBase}/margin/scenario-details/verification`, payload)
      .catch((error: AxiosError) => error.response);

  getPicklistData = () =>
    baseAxiosInstance.get(`${this.sharedBase}/margin/picklists`).catch((error: AxiosError) => error.response);

  getGSAcalculatedValues = (payload: IGSARequestPayload) =>
    baseAxiosInstance
      .post(`${this.sharedBase}/margin/margin-gsa-calculate`, payload)
      .catch((error: AxiosError) => error.response);

  upsertScenarioDetails = (payload: IAddScenarioRequestPayload) =>
    baseAxiosInstance
      .post(`${this.sharedBase}/placement/upsert-scenario-details`, payload)
      .catch((error: AxiosError) => error.response);

  getPeopleSoftCalculatedValues = (payload: IPeopleSoftMarginDetailsRequest) =>
    baseAxiosInstance
      .post(`${this.sharedBase}/margin/scenario-calculate`, payload)
      .catch((error: AxiosError) => error.response);
}
