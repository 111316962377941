import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles<{
    isSelected: boolean;
    open: boolean;
    anchorRef: boolean;
    removeActionContainer: boolean;
}>()((theme, { isSelected, open, anchorRef, removeActionContainer }) => ({
    root: {},
    outlineAnchor: {
        borderRadius: 4,
        width: '100%',
        minHeight: '36.7px',
        justifyContent: 'space-between',
        textTransform: 'none',
        color: open ? theme.palette.framework.system.violet : isSelected ? theme.palette.framework.system.doveGray : theme.palette.system.dimGrey2,
        fontSize: 12,
        fontWeight: 400,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.framework.system.white,
        },
        visibility: anchorRef ? 'hidden' : undefined,
        borderColor: 'rgba(0, 0, 0, 0.23)',
        '&:hover': {
            borderColor: 'black',
        },
    },
    paperDropdown: {
        boxShadow: `0px 2px 8px ${theme.palette.framework.system.semiTransparentBlack}`,
        width: "550px",
    },
    popper: {
        marginTop: 4,
        zIndex: 5000,
    },
    topActionContainer: {
        boxShadow: `0px 12px 10px -17px ${theme.palette.framework.system.semiTransparentBlack}`,
        zIndex: 3,
        position: 'relative',
        padding: '0px 16px 0px 16px',
    },
    actionContainer: {
        visibility: removeActionContainer ? 'hidden' : undefined,
        backgroundColor: theme.palette.framework.system.white,
        borderTop: removeActionContainer ? undefined : `1px solid ${theme.palette.framework.system.silver}`,
        padding: removeActionContainer ? 0 : '10px 8px',
        height: removeActionContainer ? 0 : undefined,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
    },
    button: {
        fontSize: '12px',
        fontWeight: 'bold',
        textTransform: 'none',
        '&:hover, &:focus': {
            background: 'none',
        },
    },
    inActive: {
        color: theme.palette.framework.system.tertiaryGrey,
    },
    actionButton: {
        padding: '6px 0px',
    },
    loadingLabel: {
        color: theme.palette.framework.system.tertiaryGrey,
        fontSize: '14px',
        padding: '8px 0px 8px 16px',
    },
}));