import { createAction } from '@reduxjs/toolkit';
import { IPlacementStatus, IPlacementStatusRequirementMessage } from 'app/models/Placement/PlacementDetails';
import { IAuditRequest, IPlacementSummaryRequest } from './slice';

/** Submission - AMN Requirement actions */
const getSkillsetRequirement = createAction<IPlacementSummaryRequest>('GET_SKILLSET_REQIREMENT');
const getStatusAmnRequirement = createAction<IPlacementSummaryRequest>('GET_STATUS_AMN_REQIREMENT');
const getAuditAmnRequirement = createAction<IPlacementSummaryRequest>('GET_AUDIT_AMN_REQIREMENT');
const getEducationAmnRequirement = createAction<IPlacementSummaryRequest>('GET_EDUCATION_AMN_REQIREMENT');
const getExperienceRequirement = createAction<IPlacementSummaryRequest>('GET_EXPERIENCE_REQUIREMENT');
const workHistorySubmissionRequirement = createAction<IPlacementSummaryRequest>(
  'GET_WORK_HISTORY_SUBMISSION_REQIREMENT',
);
const getReferenceAmnRequirement = createAction<IPlacementSummaryRequest>('GET_REFERENCE_AMN_REQIREMENT');
const getSkillSChecklistRequirement = createAction<IPlacementSummaryRequest>('GET_SKILLS_CHECKLIST_AMN_REQIREMENT');
const getApplicationAmnRequirement = createAction<IPlacementSummaryRequest>('GET_APPLICATION_AMN_REQIREMENT');
const getAuditRecords = createAction<IAuditRequest>('GET_AUDIT_DETAILS_AMN_REQUIREMENT');
const getReferenceAndEmploymentVerificationRecords = createAction<IPlacementSummaryRequest>(
  'GET_REF_AND_EMP_VERIFICATION_REQUIREMENT',
);

/** Submission - Order Requirement actions */
const getStatusOrderRequirement = createAction<IPlacementSummaryRequest>('GET_STATUS_ORDER_REQIREMENT');
const getFacilityStatusOrderRequirement = createAction<IPlacementSummaryRequest>(
  'GET_FACILITY_STATUS_ORDER_REQUIREMENT',
);
const getSsnStatusOrderRequirement = createAction<IPlacementSummaryRequest>('GET_SSN_STATUS_ORDER_REQUIREMENT');
const getDOBStatusOrderRequirement = createAction<IPlacementSummaryRequest>('GET_DOB_STATUS_ORDER_REQUIREMENT');
const getDatesOrderRequirement = createAction<IPlacementSummaryRequest>('GET_DATES_ORDER_REQIREMENT');
const getScheduleOrderRequirement = createAction<IPlacementSummaryRequest>('GET_SCHEDULE_ORDER_REQIREMENT');
const getWorkHistoryOrderRequirement = createAction<IPlacementSummaryRequest>('GET_WORK_HISTORY_ORDER_REQIREMENT');
const getReferenceOrderRequirement = createAction<IPlacementSummaryRequest>('GET_REFERENCE_ORDER_REQIREMENT');
const getLicenseOrderRequirement = createAction<IPlacementSummaryRequest>('GET_LICENSE_ORDER_REQIREMENT');
const getCertificationOrderRequirement = createAction<IPlacementSummaryRequest>('GET_CERTIFICATION_ORDER_REQIREMENT');
const getMilesRadiusOrderRequirement = createAction<IPlacementSummaryRequest>('GET_MILES_RADIUS_ORDER_REQIREMENT');
const refreshDrivingDistance = createAction('REFRESH_PLACEMENT_DRIVING_DISTANCE');
const getMiscDetailsOrderRequirement = createAction<IPlacementSummaryRequest>('GET_MICE_DETAILS_ORDER_REQIREMENT');
const getInterviewOrderRequirement = createAction<IPlacementSummaryRequest>('GET_INTERVIEW_ORDER_REQIREMENT');
const getCovidOrderRequirement = createAction<IPlacementSummaryRequest>('GET_COVID_ORDER_REQIREMENT');
const getDobOrderRequirement = createAction<IPlacementSummaryRequest>('GET_DOB_ORDER_REQIREMENT');
const getSsnOrderRequirement = createAction<IPlacementSummaryRequest>('GET_SSN_ORDER_REQIREMENT');
const getDocumentsOrderRequirement = createAction<IPlacementSummaryRequest>('GET_DOCUMENTS_ORDER_REQIREMENT');
const getGroupedSkillSetRequirements = createAction<IPlacementSummaryRequest>('GET_GROUPED_SKILLSET_REQUIREMENTS');

/** Interview - Requirement actions */
const getVirtualInterviewRequirement = createAction<IPlacementSummaryRequest>('GET_INTERVIEW_VI_REQIREMENT');

/** Set failures */
const setFailedRequirement = createAction<{
  nextStatusRequirement: IPlacementStatus[];
  requirement: IPlacementStatusRequirementMessage;
}>('SET_PLACEMENT_FAILED_REQUIREMENT');
/** Remove failures/risks */
const removeAvailableStartDateFailure = createAction('REMOVE_AVAILABLE_START_DATE_FAILURE');

const getOrderInformationRequirement = createAction<IPlacementSummaryRequest>('GET_ORDER_INFORMATION_REQIREMENT');

const getAssignmentInformationRequirement = createAction<IPlacementSummaryRequest>(
  'GET_ASSIGNMENT_INFORMATION_REQUIREMENT',
);

/**Offer Info actions  */
const getTransportationData = createAction<IPlacementSummaryRequest>('GET_OFFER_INFO_TRANSPORTATION_DATA');
const getTransportationPicklists = createAction('GET_TRANSPORTATION_PICKLISTS');
const getOfferConfirmationRequirement = createAction<IPlacementSummaryRequest>('GET_OFFER_CONFIRMATION');
const getOfferTravelDetailsRequirement = createAction<IPlacementSummaryRequest>('GET_OFFER_TRAVEL_DETAILS');

/** Booking info actions  */
const getBookingCredentialingRequirement = createAction<IPlacementSummaryRequest>(
  'GET_BOOKING_CREDENTIALING_REQUIREMENT',
);
const getBookingConfirmationRequirement = createAction<IPlacementSummaryRequest>(
  'GET_BOOKING_CONFIRMATION_REQUIREMENT',
);
const getBookingRequirement = createAction<IPlacementSummaryRequest>('GET_BOOKING_MISC_REQUIREMENTS');
const getBookingApprovedRequirements = createAction<IPlacementSummaryRequest>('GET_BOOKING_APPROVED_MISC_REQUIREMENTS');
const getWaitingGoodToGoRequirements = createAction<IPlacementSummaryRequest>(
  'GET_WAITING_GOOD_TO_GO_MISC_REQUIREMENTS',
);

const getSubmissionPacketDetails = createAction<IPlacementSummaryRequest>('GET_SUBMISSION_PACKET_DETAILS');
const checkForClassicSubmissionPacketsInOkToSend = createAction<IPlacementSummaryRequest>(
  'CHECK_CLASSIC_SUBMISSION_PACKETS_IN_OK_TO_SEND',
);

/** PeopleSoft Sync */
const syncWithPeopleSoftAction = createAction<{ placementId: number }>('SYNC_PLACEMENT_WITH_PEOPLESOFT');
const getPeopleSoftDetails = createAction<{ placementId: number }>('GET_PLACEMENT_PEOPLESOFT_DETAILS');

const getArbitrationAgreementRequirements = createAction<IPlacementSummaryRequest>(
  'GET_ARBITRATION_AGREEMENT_REQUIREMENTS',
);

const getPlacementMarginOrderDetails = createAction<IPlacementSummaryRequest>('GET_PLACEMNET_MARGIN_ORDER_DETAILS');

export const serviceActions = {
  //AMN Requirement Actions
  getSkillsetRequirement,
  getStatusAmnRequirement,
  getAuditAmnRequirement,
  getEducationAmnRequirement,
  getExperienceRequirement,
  workHistorySubmissionRequirement,
  getReferenceAmnRequirement,
  getSkillSChecklistRequirement,
  getApplicationAmnRequirement,
  getAuditRecords,
  getReferenceAndEmploymentVerificationRecords,
  //Order Requirement Actions
  getStatusOrderRequirement,
  getFacilityStatusOrderRequirement,
  getDOBStatusOrderRequirement,
  getSsnStatusOrderRequirement,
  getDatesOrderRequirement,
  getScheduleOrderRequirement,
  getWorkHistoryOrderRequirement,
  getReferenceOrderRequirement,

  getLicenseOrderRequirement,
  getCertificationOrderRequirement,
  /** Driving distance requirement - Start */
  getMilesRadiusOrderRequirement,
  refreshDrivingDistance,
  /** Driving distance requirement - End */
  getMiscDetailsOrderRequirement,
  getInterviewOrderRequirement,
  getCovidOrderRequirement,
  getDobOrderRequirement,
  getSsnOrderRequirement,
  getDocumentsOrderRequirement,
  getAssignmentInformationRequirement,
  // Interview
  getVirtualInterviewRequirement,
  //Order Information Actions
  getOrderInformationRequirement,
  //Remove failures
  removeAvailableStartDateFailure,
  setFailedRequirement,
  // PeopleSoft
  syncWithPeopleSoftAction,
  getPeopleSoftDetails,
  //Offer info actions
  getTransportationData,
  getTransportationPicklists,
  getOfferConfirmationRequirement,
  getOfferTravelDetailsRequirement,
  getBookingConfirmationRequirement,
  getBookingCredentialingRequirement,
  getBookingRequirement,
  getBookingApprovedRequirements,
  getWaitingGoodToGoRequirements,
  getSubmissionPacketDetails,
  getGroupedSkillSetRequirements,
  checkForClassicSubmissionPacketsInOkToSend,
  getArbitrationAgreementRequirements,
  getPlacementMarginOrderDetails,
};
