import React from 'react';
import { Box, Typography } from 'amn-ui-core';
import { missingField } from 'app/constants';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { ISkillChecklistSection } from '../Config/model';
import { TextAndYearType, YearType } from '../Config/constants';

const useTextAndYearStyles = makeStyles()(theme => ({
  dateContainer: {
    justifyContent: 'flex-end',
  },
  textRegion: {
    textAlign: 'left',
    font: 'normal normal normal 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.charcoal,
    opacity: 1,
  },
  questionRegion: {
    textAlign: 'left',
    font: 'normal normal medium 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.dimGray,
    opacity: 1,
  },
  dateRegion: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.charcoal,
    opacity: 1,
  },
}));

const TextAndYears = ({ currentItem, index }: { currentItem: ISkillChecklistSection; index: number }) => {
  const { classes } = useTextAndYearStyles();
  const { t } = useTranslation();

  const getItemText = (currentItem: ISkillChecklistSection) => {
    const text = currentItem?.text?.split('#');
    return <Typography variant="body2">{`${text[0]?.replace(/:/g, '')?.replace(/&amp;/g, '&')}`}</Typography>;
  };

  const getYearValue = (currentItem: ISkillChecklistSection) => {
    if (currentItem?.typeId === YearType && currentItem?.tValue) {
      return currentItem.tValue;
    } else if (currentItem?.typeId === TextAndYearType && currentItem?.iValue) {
      return currentItem.iValue;
    } else {
      return missingField;
    }
  };

  return (
    <LayoutGridItem container item key={index} display="flex" justifyContent={'flex-end'}>
      <LayoutGridItem item xs={5} paddingRight={'8px'} className={classes.questionRegion}>
        {getItemText(currentItem)}
      </LayoutGridItem>
      <LayoutGridItem item xs={5} paddingLeft={'8px'}>
        {currentItem?.typeId == TextAndYearType && currentItem?.hasAnswer && currentItem?.tValue && (
          <Typography variant="body2" className={classes.textRegion}>
            {currentItem.tValue}
          </Typography>
        )}
      </LayoutGridItem>

      <LayoutGridItem item xs={2} className={classes.dateContainer} paddingLeft={'8px'}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
          <Typography variant="body2" className={classes.dateRegion}>
            {getYearValue(currentItem)}
          </Typography>
          <Typography variant="body2" paddingLeft={'5px'} className={classes.dateRegion}>
            {t('years')}
          </Typography>
        </Box>
      </LayoutGridItem>
    </LayoutGridItem>
  );
};

export default TextAndYears;
