import { Expandable } from 'app/components/Common/Expandable/Expandable';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectPlacementSummary } from 'store/redux-store/placement-summary/selectors';
import { Concatenate } from 'utils/string/string';
import { AssignmentTitle } from '../../Titles/AssignmentTitle';
import { Details } from './Details/Details';
import { makeStyles } from 'tss-react/mui';
import { useWatch } from 'react-hook-form';
import moment from 'moment';

const useStyles = makeStyles()({
  datesTitle: {
    fontWeight: 400,
    '@media (max-width: 980px)': {
      fontSize: 12,
    },
  },
});

export const Assignment = () => {
  const { assignmentInformation } = useSelector(selectPlacementSummary);
  const startDateValue = assignmentInformation?.startDate; //useWatch<string>({ name: 'placementStartDate' });
  const endDateValue = assignmentInformation?.endDate; //useWatch<string>({ name: 'placementEndDate' });
  const { t } = useTranslation();
  const { classes } = useStyles();

  const dateString = React.useMemo(() => {
    const str =
      moment(startDateValue)?.isValid() && moment(endDateValue)?.isValid()
        ? Concatenate([moment(startDateValue)?.format('MM/DD/yyyy'), moment(endDateValue)?.format('MM/DD/yyyy')], ' - ')
        : null;
    return <span className={classes.datesTitle}>{` - ${str}`}</span>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateValue, endDateValue]);

  return (
    <Expandable
      id="placement-assignment-container"
      unmountOnExit={false}
      preventForceClose
      expanded={false}
      titleComponent={
        <AssignmentTitle
          title={t('placement.profile.summary.assignment.title')}
          titleAddOns={dateString}
          assignmentDates={{
            startDate: moment(startDateValue)?.isValid() ? moment(startDateValue)?.format('MM/DD/yyyy') : null,
            endDate: moment(endDateValue)?.isValid() ? moment(endDateValue)?.format('MM/DD/yyyy') : null,
          }}
        />
      }
    >
      <Details
        id="placement-details-container"
        expanded={false}
        unmountOnExit={false}
        title={t('placement.profile.summary.assignment.details.title')}
        assignmentInformation={{
          ...assignmentInformation,
          startDate: moment(startDateValue)?.isValid() ? moment(startDateValue)?.format('MM/DD/yyyy') : null,
          endDate: moment(endDateValue)?.isValid() ? moment(endDateValue)?.format('MM/DD/yyyy') : null,
        }}
      />
    </Expandable>
  );
};
