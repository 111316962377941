import { Banner } from '@AMIEWEB/Common/Banner/CustomBanner';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { selectclientContactPhoneNumber } from '../store/ClientContact.selector';
import { useSelector } from 'react-redux';
import { facilityPhoneType } from 'app/models/ClientContact/ClientContact';

export const bannerModalStyles = makeStyles<{ isExpanded: boolean }>()((theme, props) => ({
  fixedBanner: {
    position: 'fixed',
    top: 'auto',
    zIndex: 100,
    backgroundColor: theme.palette.background.paper,
    width: props.isExpanded ? '100%' : '59.9rem',
    marginLeft: '-23px',
  },
}));

export const ClientContactBanner = ({ bannerMessage, setShowBannerMessage, isExpanded }) => {
  const { classes } = bannerModalStyles({ isExpanded: isExpanded });
  const clientContactPhoneNumbers = useSelector(selectclientContactPhoneNumber);

  const phoneType = clientContactPhoneNumbers?.some(
    item => item.phoneType === facilityPhoneType.FAX_1 || item.phoneType === facilityPhoneType.FAX_2,
  );
  if (phoneType) {
    setShowBannerMessage(false);
  }
  return (
    <div className={classes.fixedBanner}>
      <Banner message={bannerMessage} severity="error" />
    </div>
  );
};
