/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import PhoneIconOutline from 'app/assets/images/IconImages/CallIconOutline.svg';
import MailBoxIconOutline from 'app/assets/images/IconImages/MailIconOutline.svg';
import MessageIconOutline from 'app/assets/images/IconImages/MessageIconOutline.svg';
import states from 'app/assets/jsons/State.json';
import { setNotificationData } from 'app/components/Candidate/CandidateProfile/CandidateHeader/NotificationHelper';
import {
  candidateDetailsSelection,
  selecthasActivePlacements,
} from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { gridStateActions } from 'app/components/Common/Grid/GridStateManagement/GridState.redux';
import { ChannelType, ContactType } from 'app/components/Notification/Constants';
import { notificationDataActions } from 'store/redux-store/notification/notification.redux';
import { notificationSelection, selectSidePanelConsents } from 'store/redux-store/notification/notification.selector';
import { CommunicationLogPayload, ICandidateDetails, PlacementInfo } from 'app/models/Candidate/CandidateProfile';
import { PagingData } from 'app/models/PagingData';
import {
  getCandidateById,
  getSSNValueByTravelerId,
  updateHeaderDetails,
} from 'app/services/CandidateServices/CandidateServices';
import { GetUserLogs } from 'app/services/NotificationServices/NotificationService';
import { getActivePlacementsByCandidate } from 'app/services/PlacementServices/PlacementServices';
import { selectUser } from 'oidc/user.selectors';
import { default as React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { appendSessionValue, SessionKey } from 'utils/customHooks/sessionStorage/sessionHelpers';
import { Concatenate } from 'utils/string/string';
import { globalSearchActions } from 'store/redux-store/global-search/slice';
import { selectSearchResults } from 'store/redux-store/global-search/selectors';
import {
  ICandidateDrawerConsent,
  OperationType,
  PanelControls,
  SideDrawer,
} from 'app/components/Common/Drawer/SidePanel';
import { TagsWrapper } from 'app/components/Candidate/CandidateProfile/CandidateHeader/TagsWrapper/TagsWrapper';
import moment from 'moment';
import _ from 'lodash';
import { InlineEditText } from 'app/components/Common/Textfield/InlineEditText';
import { selectUserPreference } from 'oidc/UserDevicePreference/userPreference.selectors';
import { CandidateStatus } from 'app/components/Candidate/CandidateProfile/CandidateHeader/CandidateStatusEditRuleHelper';
import { ContactTab } from 'app/components/Candidate/CandidateProfile/CandidateHeader/ContactTab';
import { missingField } from 'app/constants';
import { ICandidateHeaderDetails } from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/CandidateHeader';
import {
  candidateDetailActions,
  loadUserLogs,
} from '@AMIEWEB/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { navigationProfilesAction } from '../store/NavigationProfiles.redux';
import { defaultDateRange } from '@AMIEWEB/Candidate/CandidateProfile/CandidateHeader/Defaults';
import { isPositiveNumber, decodeBase64String } from 'app/helpers/componentHelpers';
import { getExperienceData } from '@AMIEWEB/Candidate/CandidateProfile/QuickGlanceBar/helper';
import CommunicationStack from '@AMIEWEB/Notification/MultiChannel/CommunicationTooltip/CommunicationStack';
import { calculateExperienceOverlap } from '@AMIEWEB/Candidate/CandidateProfile/Profile/helper';
import EmailStack from '@AMIEWEB/Notification/MultiChannel/CommunicationTooltip/EmailStack';
import { ConsentType } from 'app/models/Notification/Notification';
import { useEnableOneTrustConsents } from 'oidc/CommunicationEnabler';
import { selectCoveredInbox } from 'store/redux-store/user-coverage/selectors';
import { CreatePlacementWrapper } from '@AMIEWEB/Placement/CreatePlacement';
import PhoneWithdrawYellow from 'app/assets/images/phone-withdraw-yellow.svg';
import emailWithdrawYellow from 'app/assets/images/Email-withdraw-yellow.svg';
import smsWithdrawYellow from 'app/assets/images/SMS-withdraw-yellow.svg';
import { selectOrderDetailsUIView } from '@AMIEWEB/Order/Store/Order.selectors';

const useStyles = makeStyles()(theme => ({
  MuiCircularProgressRoot: {
    left: '45%',
    position: 'absolute',
    top: '50vh',
  },
}));

interface ICandidateSidePanelDataProps {
  candidateDetails: ICandidateDetails;
  rowId: string;
}
interface ICurrentCandidateDetails {
  travelerId: number;
  brandId: number;
}

interface ICandidateDrawerPreviewerProps {
  travelerId?: number;
  brandId?: number;
  closeDrawer?: () => void;
  isSearchGrid: boolean;
  isWorkDesk: boolean;
  isDrawer?: boolean;
  hideNavigationArrows?: boolean;
}

enum EditType {
  availableOn = 'availableOn',
  callbackTo = 'callbackTo',
  candidateSummary = 'candidateSummary',
}

export const CandidateDrawerPreviewer = (props: ICandidateDrawerPreviewerProps) => {
  const {
    travelerId = 0,
    brandId = 0,
    isSearchGrid = false,
    isWorkDesk = false,
    isDrawer = true,
    hideNavigationArrows = false,
  } = props;
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const history = useHistory();
  const coveredInbox = useSelector(selectCoveredInbox);
  const user = useSelector(selectUser);
  const notificationData = useSelector(notificationSelection);
  const userPreference = useSelector(selectUserPreference);
  const sidePanelConsents = useSelector(selectSidePanelConsents);
  const enableOneTrustConsents = useEnableOneTrustConsents();
  const selectOrderUiDetails = useSelector(selectOrderDetailsUIView);
  /** Poor Approach - selectedIndex can change since order of data in grid can change extenranlly  */
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);
  const [selectedRowId, setSelectedRowId] = useState<string>(!isSearchGrid ? '0' : '');

  const { drawerData, items } = useSelector(selectSearchResults);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const hasActivePlacements = useSelector(selecthasActivePlacements);
  const [candidateSearchData, setCandidateSearchData] = useState<ICandidateSidePanelDataProps[]>([]);
  const [savedValues, setSavedValues] = useState<{
    availableOn: string | null;
    callbackTo: string | null;
    candidateSummary: string | null;
    timestamp: string | null;
  }>({
    availableOn: null,
    callbackTo: null,
    candidateSummary: null,
    timestamp: null,
  });
  const [availableOnStartValue, setAvailableOnStartValue] = useState<string | null>('');
  const [callbackToValue, setCallbackToValue] = useState<string | null>('');
  const [candidateSummaryValue, setCandidateSummaryValue] = useState<string | null>(null);
  const [saveStatus, setSaveStatus] = useState<number | null>();
  const [clickAvailableOn, setClickAvailableOn] = useState<boolean>(false);
  const [clickCallbackTo, setClickCallbackTo] = useState<boolean>(false);
  const [clickCandidateSummary, setClickCandidateSummary] = useState<boolean>(false);
  const [openCreatePlacementForm, setOpenCreatePlacementForm] = useState<boolean>(false);
  const [isCallbackHidden, setIsCallbackHidden] = useState<boolean>(false);
  const [isAvailablilityHidden, setIsAvailablilityHidden] = useState<boolean>(false);
  const [currentCandidateDetails, setCurrentCandidateDetails] = useState<ICurrentCandidateDetails | {}>({
    travelerId: 0,
    brandId: 0,
  });

  const [consentsData, setConsentsData] = useState<ICandidateDrawerConsent>({
    hasCallConsents: true,
    hasEmailConsents: true,
    hasTextConsents: true,
  });

  useEffect(() => {
    setConsentsData({
      hasCallConsents: hasActivePlacements ? true : sidePanelConsents?.hasCallConsents ?? true,
      hasEmailConsents: hasActivePlacements ? true : sidePanelConsents?.hasEmailConsents ?? true,
      hasTextConsents: hasActivePlacements ? true : sidePanelConsents?.hasTextConcents ?? true,
    });
  }, [sidePanelConsents, hasActivePlacements]);

  useEffect(() => {
    const candidate = candidateSearchData.find(x => x.rowId === selectedRowId)?.candidateDetails;
    if (!candidate?.callBackDate) {
      setIsCallbackHidden(true);
    }
    if (!candidate?.availableOn) {
      setIsAvailablilityHidden(true);
    }
  }, []);

  useEffect(() => {
    const candidate = candidateSearchData.find(x => x.rowId === selectedRowId)?.candidateDetails;
    if (candidate) {
      setCurrentCandidateDetails({
        travelerId: candidate.travelerId,
        brandId: candidate.brandId,
      });
    }
  }, [candidateSearchData]);

  const highlightRow = rowId => {
    dispatch(gridStateActions.setHighlightedRow(rowId));
  };

  const fetchCandidateDetails = async (params: { rowId: string; travelerId: string; brandId: string }) => {
    const existingCandidateIndex = candidateSearchData.findIndex(
      x =>
        x.rowId === params.rowId &&
        x?.candidateDetails?.travelerId === parseInt(params?.travelerId) &&
        x?.candidateDetails?.brandId === parseInt(params?.brandId),
    );
    const communicationLogPayload: CommunicationLogPayload = {
      brandId:
        existingCandidateIndex !== -1
          ? candidateSearchData[existingCandidateIndex].candidateDetails.brandId?.toString()
          : '',
      contactId: params.travelerId.toString(),
      channel: ['Conversation', 'Email', 'Voice'],
    };

    /*Fetching new candidate details*/
    if (existingCandidateIndex === -1) {
      let candidateDetails: ICandidateDetails | {} = {};
      if (isPositiveNumber(params.travelerId) && isPositiveNumber(params.brandId)) {
        const ssnValue = await getSSNValueByTravelerId(params.travelerId);
        await getCandidateById(parseInt(params.travelerId), parseInt(params.brandId))
          .then(async candidateRes => {
            appendSessionValue(
              SessionKey.recentCandidates,
              {
                candidateId: candidateRes.travelerId,
                name: Concatenate([candidateRes.name?.first, candidateRes.name?.middle, candidateRes.name?.last], ' '),
                brandId: candidateRes.brandId,
                brand: candidateRes.brand,
                recruiter: Concatenate([candidateRes.recruiter?.first, candidateRes.recruiter?.last], ' '),
                recruiterId: candidateRes.recruiter.recruiterId,
                applicationDate: candidateRes.applicationDate,
                disciplines: candidateRes.groupedSkillsSets?.reduce((storage, item) => {
                  if (item.disciplineAbbr !== null) storage.push(item.disciplineAbbr);
                  return storage;
                }, []),
              },
              true,
              5,
            );
            communicationLogPayload.brandId = candidateRes.brandId?.toString();
            candidateRes.socialSecurityNumber = ssnValue?.ssn;
            const res = calculateExperienceOverlap(candidateRes?.experience?.employment, candidateRes);

            setSaveStatus(null);
            setAvailableOnStartValue('');
            setCallbackToValue('');
            setCandidateSummaryValue(null);
            setSavedValues({
              availableOn: null,
              callbackTo: null,
              candidateSummary: null,
              timestamp: null,
            });
            const payload = {
              brandId: candidateRes?.brandId?.toString(),
              contactId: candidateRes?.travelerId.toString(),
              channel: ['Conversation', 'Email', 'Voice'],
            };

            dispatch(loadUserLogs({ payload }));

            dispatch(
              candidateDetailActions.getAvailableChannelFilters({
                contactId: candidateRes?.travelerId?.toString(),
                brandId: candidateRes?.brandId?.toString(),
              }),
            );
            // Placements: Active placements
            await getActivePlacementsByCandidate(params.travelerId)
              .then(activePlacementsRes => {
                const pagingData: PagingData<PlacementInfo> = activePlacementsRes;
                candidateRes.activePlacements = pagingData.items;
                dispatch(
                  candidateDetailActions.setActivePlacementForCandidate(
                    !!(candidateRes?.activePlacements && candidateRes?.activePlacements.length > 0),
                  ),
                );
                candidateDetails = { ...candidateDetails, ...candidateRes };
              })
              .catch(err =>
                dispatch(
                  globalActions.setSnackBar({
                    message: t('Failed to Fetch Candidate Active Placements'),
                    severity: 'error',
                  }),
                ),
              );
          })
          .catch(err => {
            dispatch(
              globalActions.setSnackBar({
                message: `Failed to fetch candidate Information`,
                severity: 'error',
              }),
            );
            handleClose();
          });
      }

      const communicationLogResponse = await GetUserLogs(communicationLogPayload).catch(err => {
        dispatch(
          globalActions.setSnackBar({
            message: `Failed to fetch user logs`,
            severity: 'error',
          }),
        );
      });
      if (communicationLogResponse) {
        candidateDetails = candidateDetails && {
          ...candidateDetails,
          communicationLog: communicationLogResponse,
        };
      }

      setCandidateSearchData([{ candidateDetails: candidateDetails as ICandidateDetails, rowId: params.rowId }]);

      dispatch(notificationDataActions.resetFilters(''));
      dispatch(notificationDataActions.setShowFilters(false));
    } else {
      /*Reusing the existing data present in the drawerData*/
      const existingCandidateInfo = [...candidateSearchData][existingCandidateIndex];
      const communicationLogResponse = await GetUserLogs(communicationLogPayload).catch(err => {
        dispatch(
          globalActions.setSnackBar({
            message: `Failed to fetch user logs`,
            severity: 'error',
          }),
        );
      });
      if (communicationLogResponse) {
        existingCandidateInfo.candidateDetails.communicationLog = communicationLogResponse;
      }
      setCandidateSearchData([...candidateSearchData, existingCandidateInfo]);
    }
    setIsLoad(false);
  };

  const handleConsent = (travelerId, brandId) => {
    enableOneTrustConsents &&
      dispatch(
        notificationDataActions.getCandidatesConsentsAction({
          candidateIds: [travelerId],
          brandId: brandId,
          consentType: ConsentType.sidePanels,
        }),
      );
  };

  useEffect(
    () => {
      if (!isSearchGrid && !isWorkDesk && travelerId !== 0 && brandId !== 0) {
        setIsLoad(true);
        fetchCandidateDetails({
          rowId: '0',
          travelerId: `${travelerId}`,
          brandId: `${brandId}`,
        });
        setSelectedIndex(0);
        setSelectedRowId('0');
        handleConsent(`${travelerId}`, `${brandId}`);
      } else {
        if (drawerData?.data?.displayValue) {
          setIsLoad(true);
          if (drawerData?.data?.selectedorder?.row) {
            fetchCandidateDetails({
              rowId: drawerData.data.selectedorder.row.id,
              travelerId: drawerData?.data.selectedorder.row.candidateId,
              brandId: drawerData?.data.selectedorder.row.brandId,
            });
            setSelectedRowId(drawerData?.data.selectedorder.row.id);
            handleConsent(
              `${drawerData?.data.selectedorder.row.candidateId}`,
              `${drawerData?.data.selectedorder.row.brandId}`,
            );
          }

          if (drawerData?.data?.selectedorder?.row?.previewDrawerViewerId) {
            highlightRow(
              `${drawerData?.data?.selectedorder?.row?.previewDrawerViewerId}-${drawerData?.data?.selectedorder?.row?.indexCounter}`,
            );
          }
          const rowIndex = props.isWorkDesk
            ? items.findIndex(item => item.id === drawerData?.data?.selectedorder?.row?.id)
            : drawerData?.data?.selectedIndex;

          dispatch(
            globalSearchActions.setDrawerData({
              ...drawerData,
              data: { ...drawerData.data, selectedIndex: rowIndex },
            }),
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      drawerData?.data?.displayValue,
      drawerData?.data?.selectedorder?.row?.candidateId,
      drawerData?.data?.selectedorder?.row?.brandId,
      props?.travelerId,
      props?.brandId,
    ],
  );

  /** To be Refactored - Selected Index can vary, since order of data can change externally */
  useEffect(() => {
    const rowIndex = props.isWorkDesk
      ? items.findIndex(item => item.id === drawerData?.data?.selectedorder?.row?.id)
      : drawerData?.data?.selectedIndex;
    setSelectedIndex(rowIndex);
  }, [items]);

  useEffect(() => {
    if (drawerData?.data?.selectedorder?.row?.candidateId && drawerData?.data?.selectedorder?.row?.brandId)
      dispatch(
        candidateDetailActions.getTagsList({
          travelerId: drawerData?.data.selectedorder.row.candidateId,
          brandId: drawerData?.data.selectedorder.row.brandId,
        }),
      );
  }, [drawerData?.data?.selectedorder?.row?.candidateId, drawerData?.data?.selectedorder?.row?.brandId]);

  const handleClose = () => {
    if (!isSearchGrid && travelerId !== 0 && brandId !== 0 && props.closeDrawer) {
      props.closeDrawer();
    } else {
      highlightRow('test');
      dispatch(globalSearchActions.setDrawerData({ open: false, data: undefined }));
    }
    if (props?.travelerId && props?.brandId) {
      dispatch(globalActions.resetCandidateDrawerData());
      dispatch(
        notificationDataActions.setFilters({
          channel: [],
          initiatedBy: '',
        }),
      );
    }
  };

  /* Navigate to candidate Page
   * @param travelerId
   * @param brandId
   */
  const navigateToCandidateDetails = () => {
    let candidateId = '';
    let companyId = '';
    if (!isSearchGrid && !isWorkDesk && travelerId !== 0 && brandId !== 0) {
      candidateId = `${travelerId}`;
      companyId = `${brandId}`;
    } else {
      candidateId = drawerData?.data.selectedorder.row.candidateId;
      companyId = drawerData?.data.selectedorder.row.brandId;
    }
    history.push({
      pathname: `/candidate/${candidateId}/${companyId}`,
      state: { navigateBack: true },
    });
    if (isSearchGrid || isWorkDesk) {
      dispatch(navigationProfilesAction.setNavigationFlag({ navigationFromGrid: true }));
      dispatch(navigationProfilesAction.setCurrentRowId({ rowId: drawerData?.data?.selectedorder?.row?.id }));
    }
    handleClose();
    if (props?.travelerId && props?.brandId) {
      dispatch(globalActions.resetCandidateDrawerData());
    }
  };

  const NavigateCandidateSearchDataItem = async direction => {
    if (isLoad) {
      return;
    }
    let index = selectedIndex;
    if (direction) {
      if (index <= items.length - 2) {
        index = index + 1;
      } else {
        index = 0;
      }
    } else {
      if (index > 0) {
        index = index - 1;
      } else {
        index = items.length - 1;
      }
    }
    const newRowId = items[index].id;
    const travelerId = items[index].candidateId;
    const brandId = items[index].brandId;

    setSelectedRowId(newRowId);
    setSelectedIndex(index);
    dispatch(
      globalSearchActions.setDrawerData({
        open: drawerData?.open as boolean,
        data: { ...drawerData?.data, selectedIndex: index, selectedorder: { row: items[index] } },
      }),
    );

    if (drawerData?.data?.selectedorder?.row?.id) {
      highlightRow(`${travelerId}-${brandId}-${index}`);
    }
    setIsLoad(true);
    fetchCandidateDetails({ rowId: `${newRowId}`, travelerId: `${travelerId}`, brandId: `${brandId}` });
  };

  const skills = () => {
    let verifiedSkills: string = '';
    candidateDetails?.verifiedSkills.map(skill => {
      verifiedSkills = Concatenate(
        [verifiedSkills, Concatenate([skill.disciplineAbbr, skill.specialtyAbbr], ', ')],
        ' | ',
      );
    });
    return verifiedSkills;
  };

  const candidateSkills = skillset => {
    const verifiedSkills: string[] = [];
    const verifyDuplicateSkills: Map<string, boolean> = new Map<string, boolean>();
    skillset?.forEach(skill => {
      let res = `${skill.disciplineAbbr}-${skill.specialtyAbbr}`;
      if (skill.subSpecialtyAbbr) res += ', ' + skill.subSpecialtyAbbr;
      if (!verifyDuplicateSkills.has(res)) {
        verifiedSkills.push(res);
        verifyDuplicateSkills.set(res, true);
      }
    });
    return verifiedSkills;
  };

  const handleNotificationData = async (
    channel: ChannelType,
    contactType: ContactType | null,
    manualLog: boolean = false,
  ) => {
    setNotificationData(
      user,
      user.userInfo?.phoneNumber,
      candidateSearchData.find(x => x.rowId === selectedRowId)?.candidateDetails,
      dispatch,
      notificationData,
      channel,
      contactType,
      manualLog,
      skills,
      undefined,
      false,
      coveredInbox,
    );
  };

  const getCandidateStatus = (statusId, statusName) => {
    if ([CandidateStatus.ACTIVE, CandidateStatus.ACTIVE_WITH_AUDIT].includes(statusId)) {
      return {
        title: statusName,
        color: 'rgb(95, 176, 87)',
        background: 'rgba(95, 176, 87, 0.2)',
      };
    } else if ([CandidateStatus.TERMINATE, CandidateStatus.PENDING_WITH_AUDIT].includes(statusId)) {
      return {
        title: statusName,
        color: 'rgb(171,0,0)',
        background: 'rgba(171,0,0, 0.2)',
      };
    } else if (
      [
        CandidateStatus.PENDING,
        CandidateStatus.HOLDING,
        CandidateStatus.APPLICATION,
        CandidateStatus.TERM_HOLD,
      ].includes(statusId)
    ) {
      return {
        title: statusName,
        color: 'rgb(82, 66, 154)',
        background: 'rgba(82, 66, 154, 0.2)',
      };
    } else {
      return {
        title: statusName,
        color: 'rgb(136,136,136)',
        background: 'rgba(136,136,136, 0.2)',
      };
    }
  };

  const handleChangeValue = (property, value) => {
    if (property === EditType.availableOn) {
      setAvailableOnStartValue(value);
      setSaveStatus(null);
    }
    if (property === EditType.callbackTo) {
      setCallbackToValue(value);
      setSaveStatus(null);
    }
    if (property === EditType.candidateSummary) {
      setCandidateSummaryValue(value ? value : '');
      setSaveStatus(null);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      resetFields(candidateSearchData.find(x => x.rowId === selectedRowId)?.candidateDetails);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [savedValues.availableOn, savedValues.callbackTo, savedValues.candidateSummary, savedValues.timestamp]);

  const postUpdatedHeaderDetails = async (candidate: ICandidateDetails, data: ICandidateHeaderDetails) => {
    const result = await updateHeaderDetails(data);
    if (result && result !== '') {
      dispatch(
        candidateDetailActions.setCandidateDetails({
          ...candidate,
          availableOn: data.availabilityDate,
          callBackDate: data.callBackDate,
          candidateSummary: data.notes,
          amieTravelerTimestamp: result.timestamp,
        }),
      );
      setSaveStatus(2);
      setSavedValues({
        availableOn: availableOnStartValue,
        callbackTo: callbackToValue,
        candidateSummary: data.notes,
        timestamp: result.timestamp,
      });
    } else {
      setSaveStatus(3);
    }
  };

  const resetFields = candidate => {
    if (clickAvailableOn || clickCallbackTo || clickCandidateSummary || saveStatus) {
      setAvailableOnStartValue(savedValues?.availableOn || candidate?.availableOn);
      setClickAvailableOn(false);
      setSaveStatus(null);
      setCallbackToValue(savedValues?.callbackTo || candidate?.callBackDate);
      setClickCallbackTo(false);
      setSaveStatus(null);
      setCandidateSummaryValue(
        savedValues?.candidateSummary === ''
          ? missingField
          : savedValues?.candidateSummary || candidate?.candidateSummary,
      );
      setClickCandidateSummary(false);
      setSaveStatus(null);
    }
  };

  const handleSave = async (data?: string) => {
    setSaveStatus(1);
    const candidate = candidateSearchData.find(x => x.rowId === selectedRowId)?.candidateDetails;
    if (availableOnStartValue !== 'Invalid Date' && callbackToValue !== 'Invalid Date') {
      const updatedHeaderDetails: ICandidateHeaderDetails = {
        candidateId: candidate?.travelerId,
        goesBy: candidate?.goesBy,
        recruiterId: candidate?.recruiter?.recruiterId > 0 ? candidate?.recruiter?.recruiterId : null,
        recruiterName: `${candidate?.recruiter?.first} ${candidate?.recruiter?.last}`,
        availabilityDate:
          availableOnStartValue !== ''
            ? moment(availableOnStartValue).format('yyyy-MM-DD') !== 'Invalid date'
              ? moment(availableOnStartValue).format('yyyy-MM-DD')
              : availableOnStartValue
            : candidate?.availableOn,
        callBackDate:
          callbackToValue !== ''
            ? moment(callbackToValue).format('yyyy-MM-DD') !== 'Invalid date'
              ? moment(callbackToValue).format('yyyy-MM-DD')
              : callbackToValue
            : candidate?.callBackDate,
        brandId: candidate?.brandId,
        currentEmployeeId: user.userInfo?.employeeId || 0,
        currentEmployeeName: user.userInfo?.firstName + ' ' + user.userInfo?.lastName,
        primaryPhoneNumber: candidate.primaryPhoneNumber,
        mobilePhoneNumber: candidate.mobilePhoneNumber,
        primaryEmail: candidate.primaryEmail,
        secondaryEmail: candidate.secondaryEmail?.length > 0 ? candidate.secondaryEmail : '',
        candidateStatusId: candidate.candidateStatusId,
        notes:
          data === '' || (data && data !== missingField)
            ? data
            : savedValues.candidateSummary
            ? savedValues.candidateSummary
            : candidate.candidateSummary,
        timestamp: savedValues.timestamp || candidate?.amieTravelerTimestamp,
        candidateStatus: candidate.candidateStatus,
      };
      postUpdatedHeaderDetails(candidate, updatedHeaderDetails);
    }
  };

  const getCandidateSummaryValue = candidate => {
    if (candidateSummaryValue === '' && saveStatus && clickCandidateSummary) {
      return missingField;
    }
    const summaryValue =
      candidateSummaryValue || savedValues.candidateSummary || candidate.candidateSummary || missingField;
    return summaryValue && summaryValue !== missingField ? decodeBase64String(summaryValue) : missingField;
  };

  const getCandidateTabs = candidate => {
    return [
      {
        name: 'Summary',
        kpiTiles: KPIs(candidate),
        tags: <TagsWrapper candidateDetails={candidate} />,
        info: {
          brand: candidate.brand || missingField,
          address: candidate?.address,
          recruiter:
            candidate.recruiter?.first && candidate.recruiter?.last
              ? `${candidate.recruiter.first} ${candidate.recruiter.last}`
              : missingField,
          time: missingField,
        },
        detailsSection: [
          {
            info: t('candidate.sidePanel.callBack'),
            value: candidate.callBackDate
              ? moment(new Date(candidate.callBackDate)).format('MM/DD/YYYY')
              : missingField,
            component: (
              <InlineEditText
                data={callbackToValue !== '' ? callbackToValue : candidate.callBackDate ? candidate.callBackDate : null}
                name={EditType.callbackTo}
                saveStatus={saveStatus}
                isHandleClick={clickCallbackTo}
                isEditable={true}
                type={'date'}
                disabled={false}
                setIsHandleClick={value => setClickCallbackTo(value)}
                handleChangeValue={handleChangeValue}
                handleSave={handleSave}
                reset={() => resetFields(candidate)}
                customDateRangePresets={defaultDateRange}
                isHidden={isCallbackHidden}
                setIsHidden={setIsCallbackHidden}
              />
            ),
          },
          {
            info: t('candidate.sidePanel.lastContacted'),
            value: candidate.lastContactedOn
              ? moment(new Date(candidate.lastContactedOn)).format('MM/DD/YYYY')
              : missingField,
          },
          {
            info: t('candidate.sidePanel.availableToStart'),
            value: candidate?.availableOn
              ? moment(new Date(candidate?.availableOn)).format('MM/DD/YYYY')
              : missingField,
            component: (
              <InlineEditText
                data={
                  availableOnStartValue !== ''
                    ? availableOnStartValue
                    : candidate?.availableOn
                    ? candidate?.availableOn
                    : null
                }
                name={EditType.availableOn}
                saveStatus={saveStatus}
                isHandleClick={clickAvailableOn}
                isEditable={true}
                type={'date'}
                disabled={false}
                setIsHandleClick={value => setClickAvailableOn(true)}
                handleChangeValue={handleChangeValue}
                handleSave={handleSave}
                reset={() => resetFields(candidate)}
                customDateRangePresets={defaultDateRange}
                isHidden={isAvailablilityHidden}
                setIsHidden={setIsAvailablilityHidden}
              />
            ),
          },
          {
            info: t('candidate.sidePanel.lastAssignmentEnded'),
            value: lastAssignmentEnded(candidate.activePlacements, candidate.placement?.placementInfo) || missingField,
          },
          {
            info: t('candidate.sidePanel.nextPlacementStarts'),
            value: nextPlacementStartDate(candidate.activePlacements),
          },
          {
            info: t('candidate.sidePanel.lastApplicationDate'),
            value: candidate.applicationDate
              ? moment(new Date(candidate.applicationDate)).format('MM/DD/YYYY') || missingField
              : missingField,
          },
        ],
        additionalSection: [
          {
            header: t('candidate.sidePanel.summary'),
            editable: true,
            open: clickCandidateSummary,
            onClickEdit: value => {
              setClickCandidateSummary(true);
            },
            comments: '',
            summary: getCandidateSummaryValue(candidate)
              .replace(/(?:\\[n])+/g, `\n`)
              .replace(/(?:\\[r])+/g, `\r`),
            component: (
              <InlineEditText
                data={getCandidateSummaryValue(candidate)}
                name={EditType.candidateSummary}
                saveStatus={saveStatus}
                isHandleClick={clickCandidateSummary}
                isEditable={true}
                type={'text'}
                disabled={false}
                setIsHandleClick={value => {}}
                handleChangeValue={handleChangeValue}
                handleSave={handleSave}
                reset={() => resetFields(candidate)}
              />
            ),
          },
        ],
        summary: 'testing',
      },
      {
        name: t('candidate.sidePanel.contact'),
        summary: 'contact',
        component: (
          <ContactTab
            onClose={handleClose}
            isSearchGrid={false}
            candidateDetails={candidate}
            handleNotificationData={handleNotificationData}
            isViewDetails={false}
            consentsData={consentsData}
          />
        ),
      },
      {
        name: t('candidate.sidePanel.notes'),
        summary: 'notes',
      },
    ];
  };

  const getCandidateDescriptionHeader = (candidate: ICandidateDetails) => {
    return {
      imageSrc: '',
      name: `${candidate.name?.first} ${candidate.name?.last}`,
      relationSets: candidateSkills(candidate.verifiedSkills),
      status: getCandidateStatus(candidate.candidateStatusId, candidate.candidateStatus),
      passport: candidate.hasAcceptedMobileTermsAndConditions === 'Y',
      operations: [
        {
          value:
            candidate?.phoneNumbers && candidate?.phoneNumbers?.length > 0 ? (
              <CommunicationStack
                channelType={ChannelType.voice}
                phoneNumbers={candidate?.phoneNumbers || []}
                candidateDetails={candidate}
                showWarningMessage={!consentsData.hasCallConsents}
                candidateProfile
              />
            ) : (userPreference && userPreference?.deviceList && userPreference.deviceList?.length === 0) ||
              userPreference.deviceLine?.length === 0 ? (
              t('notification.consents.noDevice')
            ) : (
              t('notification.consents.noNumber')
            ),
          icon:
            (candidate.primaryPhoneNumber || candidate.mobilePhoneNumber) && !consentsData?.hasCallConsents
              ? PhoneWithdrawYellow
              : PhoneIconOutline,
          type: OperationType.Phone,
          disabled: !candidate.primaryPhoneNumber && !candidate.mobilePhoneNumber,
          handler: () => {
            if (
              candidate?.primaryPhoneNumber &&
              userPreference &&
              userPreference?.deviceList &&
              userPreference?.deviceList?.length !== 0 &&
              userPreference?.deviceLine?.length
            ) {
              handleNotificationData(ChannelType.voice, ContactType.primary);
            } else if (
              candidate?.mobilePhoneNumber &&
              userPreference &&
              userPreference?.deviceList &&
              userPreference?.deviceList?.length !== 0 &&
              userPreference?.deviceLine?.length !== 0
            ) {
              handleNotificationData(ChannelType.voice, ContactType.secondary);
            }
          },
        },
        {
          value:
            candidate.primaryEmail || candidate.secondaryEmail ? (
              <EmailStack
                emails={[candidate.primaryEmail, candidate.secondaryEmail].filter(Boolean)}
                candidateDetails={candidate}
                showWarningMessage={!consentsData.hasEmailConsents}
                candidateProfile
              />
            ) : (
              t('notification.consents.noEmail')
            ),
          icon:
            (candidate?.primaryEmail || candidate?.secondaryEmail) && !consentsData?.hasEmailConsents
              ? emailWithdrawYellow
              : MailBoxIconOutline,
          type: OperationType.Mail,
          disabled: !candidate.primaryEmail && !candidate.secondaryEmail,
          handler: () => {
            if (candidate.primaryEmail) {
              handleNotificationData(ChannelType.email, ContactType.primary);
            } else if (candidate.secondaryEmail) {
              handleNotificationData(ChannelType.email, ContactType.secondary);
            }
          },
        },
        {
          value:
            user.userInfo && (!user.userInfo.phoneNumber || user.userInfo.phoneNumber === '') ? (
              t('notification.consents.textingDisabled')
            ) : candidate?.phoneNumbers && candidate?.phoneNumbers?.length > 0 ? (
              <CommunicationStack
                channelType={ChannelType.sms}
                phoneNumbers={candidate?.phoneNumbers || []}
                candidateDetails={candidate}
                showWarningMessage={!consentsData.hasTextConsents}
                candidateProfile
              />
            ) : (
              t('notification.consents.noNumber')
            ),
          icon:
            (candidate.primaryPhoneNumber || candidate.mobilePhoneNumber) && !consentsData?.hasTextConsents
              ? smsWithdrawYellow
              : MessageIconOutline,
          type: OperationType.Message,
          disabled: !candidate.primaryPhoneNumber && !candidate.mobilePhoneNumber,
          handler: () => {
            if (candidate.primaryPhoneNumber) {
              handleNotificationData(ChannelType.sms, ContactType.primary);
            } else if (candidate.mobilePhoneNumber) {
              handleNotificationData(ChannelType.sms, ContactType.secondary);
            }
          },
        },
      ],
    };
  };

  const KPIs = candidate => {
    const kpis: string[] = [];
    const totalExperienceData = getExperienceData(
      candidate?.experience?.candidateWorkExperience?.totalYearsOfExp,
      candidate?.experience?.candidateWorkExperience?.totalMonthsOfExp,
    );
    kpis.push(`Experience=${candidate?.experience?.skillsets?.length > 0 ? totalExperienceData : missingField}`);
    kpis.push(`Placements=${candidate.activePlacements ? `${candidate.activePlacements?.length}` : missingField}`);
    kpis.push(
      `Booked=${
        candidate.placement?.weeksBooked
          ? candidate.placement?.weeksBooked !== 0
            ? `${candidate.placement?.weeksBooked}W`
            : '0'
          : missingField
      }`,
    );
    return kpis;
  };

  const getLastEndedActivePlacementDate = activePlacements => {
    const sortedActivePlacement = _.cloneDeep(candidateDetails?.activePlacements)?.sort((a, b) => {
      return moment(new Date(a.startDate)).isBefore(moment(new Date(b.startDate))) ? -1 : 1;
    });
    if (sortedActivePlacement && sortedActivePlacement?.length > 0) {
      return sortedActivePlacement[sortedActivePlacement?.length - 1]
        ? moment(new Date(sortedActivePlacement[sortedActivePlacement?.length - 1].endDate)).format('MM/DD/YYYY')
        : missingField;
    }
    return missingField;
  };

  const getLastPlacementEndDate = placementInfo => {
    const sortedPlacementInfo = _.cloneDeep(placementInfo)?.sort((a, b) => {
      return moment(new Date(a.startDate)).isBefore(moment(new Date(b.startDate))) ? -1 : 1;
    });
    if (sortedPlacementInfo && sortedPlacementInfo?.length > 0) {
      return sortedPlacementInfo[sortedPlacementInfo?.length - 1].endDate
        ? moment(new Date(sortedPlacementInfo[sortedPlacementInfo?.length - 1].endDate)).format('MM/DD/YYYY')
        : missingField;
    }
    return missingField;
  };

  const lastAssignmentEnded = (activePlacements, placementInfo) =>
    activePlacements && activePlacements?.length > 0
      ? getLastEndedActivePlacementDate(activePlacements)
      : getLastPlacementEndDate(placementInfo);

  const nextPlacementStartDate = activePlacements => {
    const sortedPlacementInfo = _.cloneDeep(activePlacements)?.sort((a, b) => {
      return moment(new Date(a.startDate)).isBefore(moment(new Date(b.startDate))) ? -1 : 1;
    });
    if (sortedPlacementInfo && sortedPlacementInfo?.length > 0) {
      const nextPlacementDateDifference =
        (new Date(sortedPlacementInfo[0]?.endDate) as any) - (new Date(sortedPlacementInfo[0]?.startDate) as any);
      return new Date(nextPlacementDateDifference).getUTCDate() - 1 > 1
        ? moment(new Date(sortedPlacementInfo[0].startDate)).format('MM/DD/YYYY') || missingField
        : missingField;
    }
    return missingField;
  };

  return (
    <>
      {isLoad || candidateSearchData?.length < 1 ? (
        <CircularProgress className={classes.MuiCircularProgressRoot} />
      ) : candidateSearchData.find(x => x.rowId === selectedRowId) &&
        candidateSearchData.find(x => x.rowId === selectedRowId)?.candidateDetails ? (
        <div data-testid="candidate-side-panel">
          <SideDrawer
            topHeader={{
              title: `CID ${candidateSearchData.find(x => x.rowId === selectedRowId)?.candidateDetails.travelerId}`,
              panelControls: hideNavigationArrows
                ? new Map<PanelControls, Function>([[PanelControls.Close, handleClose]])
                : new Map<PanelControls, Function>([
                    [PanelControls.Close, handleClose],
                    [PanelControls.Up, NavigateCandidateSearchDataItem],
                    [PanelControls.Down, NavigateCandidateSearchDataItem],
                  ]),
            }}
            navigate={navigateToCandidateDetails}
            descHeader={getCandidateDescriptionHeader(
              candidateSearchData.find(x => x.rowId === selectedRowId)?.candidateDetails,
            )}
            tabs={getCandidateTabs(candidateSearchData.find(x => x.rowId === selectedRowId)?.candidateDetails)}
            closeProfileDrawer={handleClose}
            openProfileDrawer
            isDrawer={isDrawer}
            consentsData={consentsData}
            setOpenCreatePlacementForm={setOpenCreatePlacementForm}
            division={selectOrderUiDetails?.orderDetails?.division}
            orderType={selectOrderUiDetails?.orderDetails?.orderType}
            maxFilesSent={selectOrderUiDetails?.orderDetails?.maxFilesSent}
            positionsAvailable={selectOrderUiDetails?.quickGlanceDetails?.positionsAvailable}
          />
          {openCreatePlacementForm && currentCandidateDetails.travelerId && currentCandidateDetails.brandId && (
            <CreatePlacementWrapper
              isDefaultCandidate
              open
              handleClose={() => setOpenCreatePlacementForm(false)}
              initialArgs={{
                candidateId: currentCandidateDetails.travelerId,
                brandId: currentCandidateDetails.brandId,
              }}
            />
          )}
        </div>
      ) : undefined}
    </>
  );
};
