/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useState } from 'react';
import { AttachmentsTabPanel } from './AttachmentsViewer/AttachmentsTabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { AttachmentsSelection } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.selector';
import { useParams } from 'react-router-dom';
import { getAttachments } from 'app/services/CredentialingServices';
import { candidateDetailActions } from 'app/components/Candidate/CandidateProfile/Profile/CandidateDetails.redux';
import { IAttachments } from 'app/models/Credentialing/Credentials';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { AttachmentsForm } from './AttachmentsViewer/AttachmentsForm';
import { CustomBackdrop } from 'app/components/Common/Backdrop/CustomBackdrop';
import { globalActions } from 'app/ApplicationRoot/Global.redux';
import { isPositiveNumber } from 'app/helpers/componentHelpers';
import { useTranslation } from 'react-i18next';

interface RouteParams {
  travelerId?: string;
  brandId?: string;
  reqId?: string;
  reqTypeId?: string;
}

export const CredentialingAttachmentsViewer = props => {
  const dispatch = useDispatch();
  const attachment = useSelector(AttachmentsSelection);
  const params = useParams<RouteParams>();
  const [attachmentRes, setAttachmentRes] = useState<IAttachments[]>();
  const { promiseInProgress } = usePromiseTracker({ area: 'attachments', delay: 0 });
  const [openAddAttachment, setOpenAddAttachment] = useState<boolean>(false);
  const { t } = useTranslation();

  const onAddDocumentClick = () => {
    dispatch(candidateDetailActions.setReLoading(true));
    setOpenAddAttachment(true);
  };

  const closeForm = () => {
    dispatch(candidateDetailActions.setNewAttachments([]));
    setOpenAddAttachment(false);
  };

  const fetchData = async flag => {
    if (isPositiveNumber(params.travelerId) && isPositiveNumber(params.reqId)) {
      await getAttachments(params.travelerId, params.reqId, flag).then(
        response => {
          setAttachmentRes(response ? (response as unknown as IAttachments[]) : []);
        }
      );
    } else {
      dispatch(
        globalActions.setSnackBar({
          message: t('common.failedLoad'),
          severity: 'error',
        }),
      );
    }
  };

  useEffect(() => {
    trackPromise(fetchData(true), 'attachments');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.travelerId, params?.reqId]);

  useEffect(() => {
    if (attachmentRes) {
      dispatch(candidateDetailActions.setNewAttachments([]));
      dispatch(candidateDetailActions.setAttachments(attachmentRes as IAttachments[]));
      dispatch(candidateDetailActions.setExistingAttachments(attachmentRes as IAttachments[]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachmentRes]);

  return (
    <>
      {promiseInProgress ? (
        <CustomBackdrop open={promiseInProgress} />
      ) : (
        !promiseInProgress &&
        (attachmentRes || attachment) && (
          <div>
            <AttachmentsTabPanel
              attachments={attachmentRes ? attachmentRes : attachment}
              onAddDocumentClick={onAddDocumentClick}
            />
          </div>
        )
      )}
      {!promiseInProgress && (attachmentRes || attachment) && (
        <AttachmentsForm
          openAddAttachment={openAddAttachment}
          closeForm={closeForm}
          attachments={attachmentRes ? attachmentRes : attachment}
        />
      )}
    </>
  );
};

export default CredentialingAttachmentsViewer;
