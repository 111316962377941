import {
  IPlacementSummary,
  IRequirementCounts,
  IServiceFailureKey,
  ISubmissionAmnRequirementKey,
  ISubmissionOrderRequirementKey,
} from 'app/models/Placement/PlacementSummary';
import { initialState } from './slice';
import { HttpStatusCode } from 'axios';
import { IPlacementSubmissionPacketDetail, PlacementOrderType } from 'app/models/Placement/PlacementDetails';
import { missingField } from 'app/constants';
import { deriveInitFilter } from '@AMIEWEB/Placement/PlacementDetails/PlacementTabPanel/PlacementSummaryTab/GroupedRequirements';

export interface IConsolidatedRequirementResponse<T> extends IRequirementCounts {
  status: HttpStatusCode;
  message: string;
  data?: T;
  [key: string]: any;
}

export const passportPlacementCreatorId = 11822; // ESAPI ID

interface InitPlacementSummary extends IPlacementSummary {}
class InitPlacementSummary {
  basePayload: {
    placementOrderType: PlacementOrderType;
    isOrderGeneralRequirement?: boolean;
    requirementCheckFlagEnabled: boolean;
    checkClassicPacketInOkToSendEnabled: boolean;
    credentialLookup: any[];
  };

  constructor(
    _placementOrderType: PlacementOrderType,
    _isOrderGeneralRequirement: boolean,
    _requirementCheckFlagEnabled: boolean,
    _checkClassicPacketInOkToSendEnabled: boolean,
    _credentialLookup: any[],
  ) {
    let reqCounts = { aggregate: 0, passed: 0, failed: 0, risks: 0 };
    let nullString = null as unknown as string;
    let initialTravelDetails = {
      statusOfTravel: nullString,
      airline: nullString,
      flightNumber: nullString,
      departureAirport: nullString,
      arrivalDate: nullString,
      arrivalTime: nullString,
      arrivalAirport: nullString,
      itineraryLink: nullString,
    };
    this.basePayload = {
      placementOrderType: _placementOrderType,
      isOrderGeneralRequirement: _isOrderGeneralRequirement,
      requirementCheckFlagEnabled: _requirementCheckFlagEnabled,
      checkClassicPacketInOkToSendEnabled: _checkClassicPacketInOkToSendEnabled,
      credentialLookup: _credentialLookup,
    };

    this.serviceFailures = {};
    this.orderInformation = { ...initialState.orderInformation };
    this.submissionInfo = {
      requirementFilter: {
        key: 'submission',
        filter: [],
        children: [
          { key: 'submission-amn', filter: [] },
          { key: 'submission-order', filter: [] },
        ],
      },
      amnReq: {
        reqCounts,
        amn: {
          skillset: { type: -1, data: { verifiedByCmType: -1, strikeMatchType: -1 } },
          status: null,
          audit: null,
          education: null,
          experience: null,
          workHistory: null,
          references: '--',
          skillsCheckList: null,
          application: null,
        },
        candidate: {
          skillset: null,
          status: null,
          audit: null,
          education: null,
          experience: null,
          workHistory: null,
          references: null,
          skillsCheckList: null,
          application: null,
          distance: null,
        },
      },
      orderReq: {
        reqCounts,
        order: {
          skillset: null,
          status: null,
          facilityStatus: null,
          dates: null,
          schedule: null,
          experience: null,
          workHistory: null,
          references: null,
          skillsCheckList: null,
          licenses: null,
          certifications: null,
          milesRadius: null,
          miceDetails: null,
          interview: null,
          covid: null,
          dob: null,
          ssn: null,
          documents: null,
          orderDob: null,
          orderSsn: null,
          virtualInterview: null,
        },
        candidate: {
          skillset: null,
          status: null,
          dates: null,
          schedule: null,
          experience: null,
          workHistory: null,
          references: null,
          skillsCheckList: null,
          licenses: null,
          certifications: null,
          milesRadius: null,
          miceDetails: null,
          interview: null,
          covid: null,
          dob: null,
          ssn: null,
          documents: null,
        },
      },
      skillReq: {
        containers: null,
        containerRegistry: {},
        reqCounts,
      },
    };

    this.interviewInfo = {
      requirementFilter: {
        key: 'interview',
        filter: [],
        children: [{ key: 'interview-virtual', filter: [] }],
      },
      interview: {
        reqCounts,
      },
      virtualInterview: {
        reqCounts,
        order: {
          type: -1,
          data: null,
          view: {
            rating: missingField,
            vaAutoOffer: missingField,
            skillset: missingField,
            matchingSkillset: missingField,
          },
        },
        candidate: [],
      },
      interviewScheduled: {
        reqCounts,
      },
    };
    this.offerInfo = {
      requirementFilter: {
        key: 'offer',
        filter: [],
        children: [
          { key: 'offer-confirmation', filter: [] },
          { key: 'offer-transportation', filter: [] },
        ],
      },
      confirmation: {
        reqCounts,
        datesConfirmed: null,
        dnsCleared: null,
        travelConfirmed: {
          type: -1,
          data: {
            checked: false,
            lastUpdatedAt: null,
            lastUpdatedBy: null,
            lastUpdatedById: null,
          },
        },
      },
      transportation: {
        reqCounts,
        gender: { type: -1, data: null },
        transportation: {
          type: -1,
          data: {
            transportation: null,
            transportationId: null,
          },
        },
        travelTimePreference: {
          type: -1,
          data: {
            travelTimePreference: null,
            travelTimePreferenceId: null,
          },
        },
        dob: { type: -1 },
        ssn: { type: -1 },
        departureAirport: { type: -1, data: null },
        departureItineraryLink: { type: -1, data: null },
        arrivalDate: { type: -1, data: null },
        arrivalTime: { type: -1, data: null },
        picklists: {
          airportCodes: [],
          transportationOpts: [],
          travelTimePreferenceOpts: [],
        },
      },
      strikeTravelDetails: {
        travelFromStrike: { ...initialTravelDetails, departureDate: nullString, departureTime: nullString },
        travelToStrike: { ...initialTravelDetails, arrivalDate: nullString, arrivalTime: nullString },
      },
    };
    this.bookingInfo = {
      requirementFilter: {
        key: 'booking',
        filter: [],
        children: [
          { key: 'booking-confirmation', filter: [] },
          { key: 'booking-credentialing', filter: [] },
        ],
      },
      credentialing: {
        reqCounts,
        gtgComplete: null,
        finalizedByQs: null,
      },
      confirmation: {
        reqCounts,
        arrived: null,
        directDeposit: null,
        wisely: null,
      },
      /** Placement holder for hidden requirements */
    };
    this.assignmentInformation = {
      placementId: null,
      placementStatusId: null,
      placementStatus: null,
      startDate: null,
      endDate: null,
      endDateTravelerOptions: null,
    };
  }

  setServiceFailureByKey(payload: { key: IServiceFailureKey; failureStatus?: boolean }) {
    this.serviceFailures[payload.key] = payload.failureStatus;
  }

  setSubmissionInfoByKey(payload: {
    key: ISubmissionAmnRequirementKey | ISubmissionOrderRequirementKey;
    amnRequirement: any;
    orderRequirement: any;
    candidateRequirement: any;
    /**
     * Specify for OrderRequirement service call as {isOrderReq: true}
     * use of orderRequirement directly for null checks is
     * not suitable as service can fail or due to similar exceptions
     */
    isOrderReq?: boolean;
  }) {
    let { amnRequirement, orderRequirement, candidateRequirement, isOrderReq = false, key } = payload;

    if (isOrderReq) {
      this.submissionInfo.orderReq.order[key] = { ...orderRequirement };
      this.submissionInfo.orderReq.candidate[key] = { ...candidateRequirement };
    } else {
      this.submissionInfo.amnReq.amn[key] = { ...amnRequirement };
      this.submissionInfo.amnReq.candidate[key] = { ...candidateRequirement };
    }
  }

  setOrderInformation(payload) {
    this.orderInformation = payload;
  }

  setSubmissionPacketDetail(payload: IPlacementSubmissionPacketDetail) {
    this.submissionPacketDetail = payload;
  }

  setReferenceAndEmploymentVerificationRecords(payload) {
    this.referenceAndEmploymentVerificationRecords = payload;
  }

  setClassicSubmissionPacketInOkToSendExists(payload: boolean) {
    this.classicPacketInOkToSendExists = payload;
  }

  setOfferConfirmationInfo(payload) {
    this.offerInfo.confirmation = { ...this.offerInfo.confirmation, ...payload };
  }

  setOfferTransportationInfo(payload) {
    this.offerInfo.transportation = { ...this.offerInfo.transportation, ...payload };
  }

  setOfferTransportationPickListByKey(payload) {
    this.offerInfo.transportation.picklists = {
      ...this.offerInfo.transportation.picklists,
      [payload?.key]: [...payload?.data],
    };
  }

  setBookingConfirmationInfo(payload) {
    this.bookingInfo.confirmation = { ...this.bookingInfo.confirmation, ...payload };
  }

  setBookingCredentialingInfo(payload) {
    this.bookingInfo.credentialing = { ...this.bookingInfo.credentialing, ...payload };
  }

  setOfferTravelDetailsInfo(payload) {
    this.offerInfo.strikeTravelDetails = payload;
  }

  setVirtualInterviewRequirement(payload) {
    this.interviewInfo.virtualInterview.candidate = payload.candidate;
    this.interviewInfo.virtualInterview.order.type = {
      ...payload.order,
      type: payload.type,
    };
  }

  setAssignmentInformation(payload) {
    this.assignmentInformation = payload;
  }

  setExtensionsInfo(payload) {
    this.extensionsInfo = payload;
  }

  setSubmissionSkillsetRequirements(payload) {
    const requirementFilter = deriveInitFilter(this.submissionInfo.requirementFilter, payload.containers);

    this.submissionInfo = {
      ...this.submissionInfo,
      skillReq: { ...payload, reqCounts: payload.containers.counts },
      requirementFilter,
    };
  }

  get = (): IPlacementSummary => {
    //@ts-ignore
    return this;
  };
}

export default InitPlacementSummary;
