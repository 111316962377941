/* eslint-disable array-callback-return */
/* eslint-disable i18next/no-literal-string */
import React, { useState, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Paper, Grid, Divider, Button, Typography, IconButton } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import countries from 'app/assets/jsons/Country.json';
import states from 'app/assets/jsons/State.json';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'oidc/user.selectors';
import { userRoles } from 'oidc/userRoles';
import treeData from 'app/assets/jsons/placementStatusFilter.json';
import { candidateDetailsSelection } from '../../../Profile/CandidateDetails.selector';
import { FormInputDatePicker } from './Controls/FormInputDatePicker';
import { FormInputDropdown } from './Controls/FormInputDropdown';
import { FormInputText } from './Controls/FormInputText';
import { checkDuplicateRoles, dates, IsDateValid, setValues, isEmptyCheckbox, getAddType } from './PersonalInfoHelper';
import { candidateDetailActions } from '../../../Profile/CandidateDetails.redux';
import { GetMomentOfDate } from 'utils/dates/moment';
import _ from 'lodash';
import { AddRoleTypeSection } from './AddRoleTypeSection';
import { BuildRoleTypeChips, getCurrent, getFuture, getPrevious } from './Controls/BuildRoleTypeChips';
import { useTranslation } from 'react-i18next';
import { Authorized } from 'oidc/userHelper';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { formatAddress } from '../Cards/helper';

const useStyles = makeStyles()(() => ({
  root: {
    padding: '0px 10px 10px',
  },
  container: {
    width: '100%',
    boxShadow: 'none',
    border: '1px solid #e5e5e5',
    borderRadius: '4px',
    marginBottom: '14px',
  },
  divider: {
    margin: '10px 0px 0px 0px',
  },
  child: {
    overflow: 'hidden',
    fontSize: '14px',
  },
  fontTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  secondary: {
    fontSize: '14px',
    color: '#888888',
  },
  addressButton: {
    backgroundColor: '#F7F7F7',
    borderRadius: '30px',
    border: '1px solid #888888',
    fontSize: '14px',
    height: '28px',
    color: '#000',
    textTransform: 'none',
    lineHeight: 0,
    '&:hover': {
      backgroundColor: '#00000014',
    },
  },
  allainment: {
    paddingTop: '12px',
    paddingLeft: '12px',
  },
  alignment: {
    padding: '12px 12px 0px 12px',
  },
  submitButton: {
    color: '#888888',
  },
  updateButton: {
    color: '#0D6FB9',
  },
  alignButton: {
    padding: 0,
    alignSelf: 'center',
  },
  addRoleButton: {
    color: '#C4C6C9',
    background: 'none !important',
    padding: '0 5px 0 0 !important',
    '&:hover': {
      color: '#2870B9',
    },
  },
  addRoleIcon: {
    width: 40,
    height: 40,
  },
  addRolesText: {
    fontWeight: 500,
    fontSize: '12px !important',
    paddingLeft: '5px',
  },
  ExpandStyle: {
    marginLeft: '350px',
    marginTop: 4,
    textTransform: 'none',
    color: '#006FB9',
  },
}));

export const AddressSectionWrapper = props => {
  const { classes } = useStyles();
  const { address, state, index, toggleAddAddress, checkRoleTypeEdit } = props;
  const [isHandleClick, setIsHandleClick] = useState<boolean>(false);
  const user = useSelector(selectUser);
  const candidateDetails = useSelector(candidateDetailsSelection);
  const [stateValue, setStateValue] = useState<any>(state || address?.stateProvinceId);
  const [moveInValue, setMoveInValue] = useState<any>(address.dateMoveIn);
  const [moveOutValue, setMoveOutValue] = useState<any>(address.dateMoveOut);
  const [countryValue, setCountryValue] = useState<any>(address.country);
  const [address1Value, setAddress1Value] = useState<any>(address.address1 ?? '--');
  const [address2Value, setAddress2Value] = useState<any>(address.address2);
  const [cityValue, setCityValue] = useState<any>(address.city);
  const [zipValue, setzipValue] = useState<any>(address.zip ?? '');
  const [prevMoveInValue, setPrevMoveInValue] = useState<any>(address.moveInValue);
  const { t } = useTranslation();
  const [cityLabel, setCityLabel] = useState(address?.country?.toLowerCase() === 'usa' ? 'City' : 'Province');
  const [zipLabel, setZipLabel] = useState(
    address?.country?.toLowerCase() === 'usa' ? 'Zip/Postal Code' : 'Postal Code',
  );
  // const [stateDropDownValue, setStateDropDownValue] = useState<any>(states.filter(state => state.CountryID === countries.find(x=>x.Description === address.country)?.ID));
  const [addressData, setAddressData] = useState<any>(address || '');
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [roleData, setRoleData] = useState<any[]>(addressData.addressDates || []);
  const [previousRoles, setPreviousRoles] = useState<any[]>([]);
  const [isMoveOutReq, setIsMoveOutReq] = useState(
    address.addressType === 'Company Assignment' || address.addressType === 'Company Assignment - Hotel',
  );
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [stateList, setStateList] = useState([]);
  const [indexValue, setIndexValue] = useState<number | null>();

  const { errors, getValues, setValue, setError, clearErrors, trigger } = useFormContext();
  const dispatch = useDispatch();
  const [newRoleTypeCount, setNewRoleTypeCount] = useState(0);
  const booked = treeData.filter(item => item.name === 'Booked');
  const onAssignment = treeData
    .filter(item => item.name == 'On Assignment')[0]
    .children.filter(x => x.value === '3' || x.value === '23');
  const inSumbite = treeData
    .filter(item => item.name === 'In Submission')[0]
    .children.filter(x => x.value === '3' || x.value === '2');
  const inSumbitePrior = treeData
    .filter(item => item.name === 'In Submission')[0]
    .children.filter(x => x.value !== '3' && x.value !== '2');
  const Offer = treeData.filter(item => item.name === 'Offer');
  const allowedStatuses: any[] = [];
  const allowedPriorStatuses: any[] = [];
  const notAllowedAtBookedStatus: any[] = [];
  const requiredCondition = getAddType(address?.addressType) === 2 || getAddType(address?.addressType) === 5;

  booked[0].children.map(item => {
    allowedStatuses.push(item.name);
    notAllowedAtBookedStatus.push(item.name);
  });

  Offer[0].children.map(item => {
    allowedPriorStatuses.push(item.name);
  });

  inSumbite.map(item => {
    if (item.value === '3' || item.value === '2') allowedStatuses.push(item.name);
  });

  onAssignment.map(item => {
    notAllowedAtBookedStatus.push(item.name);
  });

  inSumbitePrior.map(item => {
    allowedPriorStatuses.push(item.name);
  });

  const enableEditMode = () => {
    setIsMoveOutReq(
      addressData.addressType === 'Company Assignment' || addressData.addressType === 'Company Assignment - Hotel',
    );
    setValues(setValue, 'Address', candidateDetails);
    setIsHandleClick(true);
    toggleAddAddress();
    addressData.addressDates.map((e, i) => {
      clearErrors(`CheckboxError${address.travelerAddressId}${i + 1}`);
    });
  };

  useEffect(() => {
    sortRoleTypes(addressData, addressData.addressDates);
    updateStateOptions(addressData?.country);
  }, [addressData]);

  useEffect(() => {
    setValue(`MoveIn${index}`, moveInValue);
    setValue(`MoveOut${index}`, moveOutValue);
    setValue(`country${index}`, countryValue);
    setValue(`Address1${index}`, address1Value);
    setValue(`Address2${index}`, address2Value);
    setValue(`City${index}`, cityValue);
    setValue(`state${index}`, stateValue);
    setValue(`Zip${index}`, zipValue);
    // eslint-disable-next-line prettier/prettier
  }, [
    address1Value,
    address2Value,
    cityValue,
    countryValue,
    index,
    moveInValue,
    moveOutValue,
    setValue,
    stateValue,
    zipValue,
  ]);

  const handleCancel = () => {
    setMoveInValue(address.dateMoveIn);
    setMoveOutValue(addressData.dateMoveOut);
    setStateValue(state);
    setCityValue(address.city);
    setCountryValue(address.country);
    setAddress1Value(address.address1);
    setAddress2Value(address.address2);
    setzipValue(address.zip);
    setIsHandleClick(false);
    setNewRoleTypeCount(0);
    setIsExpanded(false);
    toggleAddAddress();
    checkRoleTypeEdit('cancelAddRoleType');
  };

  const updateStateOptions = (countryDescription: string) => {
    const selectedCountry = countries?.countries?.find(e => e.Description === countryDescription);
    const updatedStateOptions = states?.states?.filter(e => e.CountryID === selectedCountry?.ID);
    setStateList(updatedStateOptions);
  };

  const getSelectedValue = (controlId, role) => {
    const controlValue = getValues(controlId);
    const selectedValue =
      controlValue != undefined && controlValue != null ? controlValue : role ? role.selected : controlValue;
    return selectedValue ? 1 : 0;
  };

  const getAddressRoles = (addressId, val, roles?: any[]) => {
    var addressRoles: any = [];
    const cobraAddressRole = roles?.find(x => x.addressRoleId === 5);
    addressRoles.push({
      description: 'Cobra',
      addressRoleId: 5,
      selected: getSelectedValue(`Cobra${addressId}${val + 1}`, cobraAddressRole),
    });
    const dependentRole = roles?.find(x => x.addressRoleId === 6);
    addressRoles.push({
      description: 'Dependent Cobra',
      addressRoleId: 6,
      selected: getSelectedValue(`DependentCobra${addressId}${val + 1}`, dependentRole),
    });
    const mailersRole = roles?.find(x => x.addressRoleId === 4);
    addressRoles.push({
      description: 'Mailers',
      addressRoleId: 4,
      selected: getSelectedValue(`Mailers${addressId}${val + 1}`, mailersRole),
    });
    const paycheckRole = roles?.find(x => x.addressRoleId === 2);
    addressRoles.push({
      description: 'Paycheck',
      addressRoleId: 2,
      selected: getSelectedValue(`Paycheck${addressId}${val + 1}`, paycheckRole),
    });
    const subsidyRole = roles?.find(x => x.addressRoleId === 1);
    addressRoles.push({
      description: 'Subsidy',
      addressRoleId: 1,
      selected: getSelectedValue(`Subsidy${addressId}${val + 1}`, subsidyRole),
    });
    const w2Role = roles?.find(x => x.addressRoleId === 3);
    addressRoles.push({
      description: 'W2',
      addressRoleId: 3,
      selected: getSelectedValue(`W2${addressId}${val + 1}`, w2Role),
    });
    return addressRoles;
  };

  const isEmpty = (str: string | any) => {
    if (str === null || str === '' || str === undefined) return true;
    return false;
  };

  const validateRoleTypes = updateId => {
    let isDuplicate = false;
    var isRoleTypesValid = true;
    if (newRoleTypeCount > 0) {
      [...Array(newRoleTypeCount)].map((e, i) => {
        if (isEmpty(getValues(`Start${updateId}${i + 1}`))) isRoleTypesValid = false;

        if (
          isEmpty(getValues(`End${updateId}${i + 1}`)) &&
          (address.addressType === 'Company Assignment' || address.addressType === 'Company Assignment - Hotel')
        )
          isRoleTypesValid = false;
        if (
          !getValues(`W2${updateId}${i + 1}`) &&
          !getValues(`Paycheck${updateId}${i + 1}`) &&
          !getValues(`Mailers${updateId}${i + 1}`) &&
          !getValues(`Subsidy${updateId}${i + 1}`) &&
          !getValues(`Cobra${updateId}${i + 1}`) &&
          !getValues(`DependentCobra${updateId}${i + 1}`)
        ) {
          isRoleTypesValid = false;
          setError(`CheckboxError${updateId}${i + 1}`, {
            type: 'required',
            message: 'Role type selection cannot be empty',
          });
        }
      });
      var currentAddressDates: any[] = addressData.addressDates;
      var tempAddressData: any[] = [];
      var count = 0;
      [...Array(newRoleTypeCount)].map((e, i) => {
        var data = {
          travelerAddressId: updateId,
          addressDateRangeID: 0,
          fromDate: moment(getValues(`Start${updateId}${i + 1}`)).format('yyyy-MM-DD'),
          toDate:
            getValues(`End${updateId}${i + 1}`) !== undefined
              ? moment(getValues(`End${updateId}${i + 1}`)).format('yyyy-MM-DD')
              : null,
          insertDate: null,
          lastUpdateUserID: user.userInfo?.employeeId,
          addressRoles: checkDuplicateRoles(updateId, i, getValues),
          timestamp: null,
        };
        tempAddressData.push(data);

        currentAddressDates.map(item => {
          if (
            (moment(item.fromDate).format('yyyy-MM-DD') === data.fromDate &&
              item.toDate == null &&
              _.isEqual(item.addressRoles, data.addressRoles)) ||
            (moment(item.fromDate).format('yyyy-MM-DD') === data.fromDate &&
              moment(item.toDate).format('yyyy-MM-DD') === data.toDate &&
              _.isEqual(item.addressRoles, data.addressRoles))
          ) {
            isDuplicate = true;
          }
        });
        if (isDuplicate) {
          isRoleTypesValid = false;
          setError(`CheckboxError${updateId}${i + 1}`, {
            type: 'required',
            message: 'New address role type should not have same details as an existing address role type',
          });
          return;
        } else {
          clearErrors(`CheckboxError${updateId}${i + 1}`);
        }
      });
      tempAddressData.map(x => {
        var temp = 0;
        count = 0;
        tempAddressData.map((y, idy) => {
          if (
            (moment(x.fromDate).format('yyyy-MM-DD') === y.fromDate &&
              x.toDate == null &&
              _.isEqual(x.addressRoles, y.addressRoles)) ||
            (moment(x.fromDate).format('yyyy-MM-DD') === y.fromDate &&
              moment(x.toDate).format('yyyy-MM-DD') === y.toDate &&
              _.isEqual(x.addressRoles, y.addressRoles))
          ) {
            count++;
            if (count > 1) temp = idy;
          }
        });
        if (count > 1) {
          isRoleTypesValid = false;
          setError(`CheckboxError${updateId}${temp + 1}`, {
            type: 'required',
            message: 'New address role type should not have same details as an existing address role type',
          });
          return;
        } else {
          clearErrors(`CheckboxError${updateId}${temp + 1}`);
        }
      });
    }
    return isRoleTypesValid;
  };

  const fireValidation = updateId => {
    if (newRoleTypeCount > 0) {
      [...Array(newRoleTypeCount)].map(async (e, i) => {
        if (isEmpty(getValues(`Start${updateId}${i + 1}`)))
          setError(`Start${updateId}${i + 1}`, {
            type: 'required',
            message: 'Required',
          });

        if (
          isEmpty(getValues(`End${updateId}${i + 1}`)) &&
          (address.addressType === 'Company Assignment' || address.addressType === 'Company Assignment - Hotel')
        )
          setError(`End${updateId}${i + 1}`, {
            type: 'required',
            message: 'Required',
          });
        if (
          isEmptyCheckbox(getValues(`W2${updateId}${i + 1}`)) &&
          isEmptyCheckbox(getValues(`Paycheck${updateId}${i + 1}`)) &&
          isEmptyCheckbox(getValues(`Mailers${updateId}${i + 1}`)) &&
          isEmptyCheckbox(getValues(`Subsidy${updateId}${i + 1}`)) &&
          isEmptyCheckbox(getValues(`Cobra${updateId}${i + 1}`)) &&
          isEmptyCheckbox(getValues(`DependentCobra${updateId}${i + 1}`))
        )
          setError(`CheckboxError${updateId}${i + 1}`, {
            type: 'required',
            message: 'Role type selection cannot be empty',
          });
      });
    }
  };

  const validateEditRoleType = (updateId, i) => {
    if (
      isEmptyCheckbox(getValues(`W2edit${updateId}${i + 1}`)) &&
      isEmptyCheckbox(getValues(`Paycheckedit${updateId}${i + 1}`)) &&
      isEmptyCheckbox(getValues(`Mailersedit${updateId}${i + 1}`)) &&
      isEmptyCheckbox(getValues(`Subsidyedit${updateId}${i + 1}`)) &&
      isEmptyCheckbox(getValues(`Cobraedit${updateId}${i + 1}`)) &&
      isEmptyCheckbox(getValues(`DependentCobraedit${updateId}${i + 1}`))
    ) {
      setError(`CheckboxErroredit${updateId}${i + 1}`, {
        type: 'required',
        message: t('candidate.profileInformation.RoleTypeError'),
      });
      return false;
    }
    return true;
  };
  //Hande Check Box Validation on Edit
  const validateOnEdit = updateId => {
    roleData?.forEach((item, i) => {
      if (getValues(`Startedit${updateId}${i + 1}`) && IsRoleEditable(getValues(`Startedit${updateId}${i + 1}`))) {
        validateEditRoleType(updateId, i);
      }
    });
  };

  const handleUpdate = async updateId => {
    validateOnEdit(updateId);
    const isVal = validateRoleTypes(updateId);
    //validate moveout date
    if (isMoveOutReq && (moveOutValue === '' || moveOutValue === null)) {
      //Validate date
      IsDateValid(setError, `MoveOut${index}`, moveOutValue);
      await trigger(`MoveOut${index}`);
      return false;
    }
    if (!_.isEmpty(errors)) return;
    var filteraddress: any = _.cloneDeep(
      candidateDetails.addressData?.filter(x => x.travelerAddressId === updateId)[0],
    );
    var tempAddress: any = _.cloneDeep(candidateDetails.addressData);
    const originalAddressData: any = _.cloneDeep(candidateDetails.addressData);
    if (isVal) {
      var tempAddressDates: any[] = addressData.addressDates.map((item, i) => {
        return {
          travelerAddressId: item.travelerAddressID ? item.travelerAddressID : item.travelerAddressId,
          addressDateRangeId: item.addressDateRangeID ? item.addressDateRangeID : item.addressDateRangeId,
          fromDate:
            getValues(`Startedit${address.travelerAddressId}${i + 1}`) !== undefined
              ? moment(getValues(`Startedit${address.travelerAddressId}${i + 1}`)).format('yyyy-MM-DD')
              : item?.fromDate,
          toDate:
            getValues(`Endedit${address.travelerAddressId}${i + 1}`) !== undefined
              ? moment(getValues(`Endedit${address.travelerAddressId}${i + 1}`)).format('yyyy-MM-DD')
              : item?.toDate,
          insertDate: item.insertDate,
          lastUpdateUserID: user.userInfo?.employeeId,
          addressRoles: getAddressRoles(`edit${address.travelerAddressId}`, i, item.addressRoles),
          timestamp: item.timestamp,
        };
      });
      if (newRoleTypeCount > 0) {
        [...Array(newRoleTypeCount)].map((e, i) => {
          var data = {
            travelerAddressId: updateId,
            addressDateRangeID: 0,
            fromDate: moment(getValues(`Start${updateId}${i + 1}`)).format('yyyy-MM-DD'),
            toDate:
              getValues(`End${updateId}${i + 1}`) !== undefined
                ? moment(getValues(`End${updateId}${i + 1}`)).format('yyyy-MM-DD')
                : null,
            insertDate: null,
            lastUpdateUserID: user.userInfo?.employeeId,
            addressRoles: getAddressRoles(updateId, i),
            timestamp: null,
          };
          tempAddressDates = [...tempAddressDates, data];
        });
      }
      filteraddress!.address1 = address1Value;
      filteraddress!.address2 = address2Value;
      filteraddress!.city = cityValue;
      filteraddress!.stateProvinceId =
        states.states.find(state => state.Description === stateValue)?.ID || stateValue || '';
      filteraddress!.state = stateValue;
      filteraddress!.zip = zipValue;
      filteraddress!.country = countryValue;
      filteraddress!.housingPropertyPhone = filteraddress?.housingPropertyPhone || null;
      filteraddress!.dateMoveIn = moveInValue ? moment(moveInValue).format('yyyy-MM-DD') : null;
      filteraddress!.dateMoveOut = moveOutValue ? moment(moveOutValue).format('yyyy-MM-DD') : null;
      filteraddress!.lastUpdate = null;
      filteraddress!.addressDates = tempAddressDates;
      setAddressData(filteraddress);
      tempAddress?.map(item => {
        if (item.travelerAddressId === filteraddress?.travelerAddressId) {
          item.address1 = filteraddress.address1;
          item.address2 = filteraddress.address2;
          item.city = filteraddress.city;
          item.stateProvinceId = filteraddress.stateProvinceId;
          item.state = filteraddress.state;
          item.zip = filteraddress.zip;
          item.country = filteraddress.country;
          item.dateMoveIn = filteraddress.dateMoveIn;
          item.dateMoveOut = filteraddress.dateMoveOut;
          item.lastUpdate = filteraddress.lastUpdate;
          item.addressDates = filteraddress.addressDates;
        }
      });
      tempAddress?.forEach(x => {
        if (x.addressType === addressData.addressType && x.dateMoveOut === prevMoveInValue) {
          x.dateMoveOut = moment(moveInValue).format('MM/DD/YYYY');
        }
      });
      dispatch(
        candidateDetailActions.setCandidateDetails({
          ...candidateDetails,
          addressData: tempAddress,
          originalAddressData: originalAddressData,
        }),
      );
      //call the update API
      setNewRoleTypeCount(0);
      setIsHandleClick(false);
    } else {
      fireValidation(updateId);
    }
  };

  const handleCountryChange = event => {
    // setStateDropDownValue(states.filter(state => state.CountryID === countries.find(x=>x.Description === event.target.value)?.ID))
    setCountryValue(event.target.value);
    updateLabels(event.target.value);
    updateStateOptions(event.target.value);
    if (event.target.value !== 'USA' || event.target.value !== 'Canada') {
      setStateValue(null);
    }
  };

  const updateLabels = val => {
    if (val.toLowerCase() === 'usa') {
      setCityLabel('City');
      setZipLabel('Zip/Postal Code');
    } else {
      setCityLabel('Province');
      setZipLabel('Postal Code');
    }
  };
  const handleStateChange = event => {
    setStateValue(event.target.value);
  };

  const handleA1Change = event => {
    setAddress1Value(event.target.value);
  };

  const handleA2Change = event => {
    setAddress2Value(event.target.value);
  };
  const handleCityChange = event => {
    setCityValue(event.target.value);
  };
  const handleZipChange = event => {
    if ((event?.target?.value?.length && /^[a-zA-Z0-9]+$/.test(event?.target?.value)) || event?.target?.value === '') {
      setzipValue(event?.target?.value);
    }
  };

  const handleMoveInDate = async event => {
    const currentDate = GetMomentOfDate(new Date()).format('MM/DD/YYYY');
    setPrevMoveInValue(moveInValue);
    if (!event || event === '') {
      setMoveInValue(address.dateMoveIn);
    }

    if (event && event !== '' && moveOutValue) {
      const MOId = isMoveOutReq ? `MoveOut${index}` : `MoveOutOptional${index}`;
      const MI = GetMomentOfDate(event).format('MM/DD/YYYY');
      const MO = GetMomentOfDate(moveOutValue).format('MM/DD/YYYY');

      const gt = dates.gt(MI, MO);
      const eq = dates.eq(MI, MO);

      setMoveInValue(MI);

      if (gt || eq) {
        setError(`MoveIn${index}`, { type: 'manual', message: t('candidate.profileInformation.moveInDateError') });
      } else clearErrors(`MoveIn${index}`);

      !gt && clearErrors(MOId);
    }
    if (event && indexValue !== undefined) {
      const startDate = GetMomentOfDate(getValues(`Start${indexValue}`) ?? '').format('MM/DD/YYYY');
      const MI = GetMomentOfDate(event).format('MM/DD/YYYY');
      if (dates.gt(startDate, MI) && dates.gt(startDate, currentDate)) clearErrors(`Start${indexValue}`);
      else if (dates.lt(startDate, MI) || dates.eq(startDate, MI))
        setError(`Start${indexValue}`, {
          type: 'manual',
          message: t('candidate.profileInformation.StartDateError'),
        });
    }

    if (event && event !== '') {
      const MI = GetMomentOfDate(event).format('MM/DD/YYYY');
      setMoveInValue(MI);
    }
  };

  const handleMoveOutDate = async event => {
    const MOId = isMoveOutReq ? `MoveOut${index}` : `MoveOutOptional${index}`;
    if (!event || event === '') {
      setMoveOutValue('');
    }

    if (event && event !== '' && moveInValue) {
      const MO = GetMomentOfDate(event).format('MM/DD/YYYY');
      const MI = GetMomentOfDate(moveInValue).format('MM/DD/YYYY');

      const lt = dates.lt(MO, MI);
      const eq = dates.eq(MO, MI);
      if (!lt) clearErrors(`MoveIn${index}`);

      setMoveOutValue(MO);

      if (lt || eq) {
        setError(MOId, { type: 'manual', message: t('candidate.profileInformation.moveOutDateError') });
      } else clearErrors(MOId);
    }

    if (event && indexValue !== undefined) {
      const EndDate = GetMomentOfDate(getValues(`End${indexValue}`) ?? '').format('MM/DD/YYYY');
      const MO = GetMomentOfDate(event).format('MM/DD/YYYY');
      const EndDateLessThanMODate = dates.lt(EndDate, MO);
      if (EndDateLessThanMODate) clearErrors(`End${indexValue}`);
      else if (dates.gt(EndDate, MO))
        setError(`End${indexValue}`, {
          type: 'manual',
          message: t('candidate.profileInformation.EndDateError2'),
        });
    } else {
      setValue(MOId, event);
    }
  };
  const sortRoleTypes = (address, roleTypes) => {
    const roleTypeStatus: {
      future: any[];
      current: any[];
      previous: any[];
    } = {
      future: [],
      current: [],
      previous: [],
    };
    const roles = _.cloneDeep(roleTypes);
    roles
      .sort((x1, x2) => new Date(x2.fromDate).getTime() - new Date(x1.fromDate).getTime())
      .map(x => {
        if (getFuture(x.fromDate, x.toDate, address.dateMoveIn, address.dateMoveOut)) {
          roleTypeStatus.future.push(x);
        } else if (getCurrent(x.fromDate, x.toDate, address.dateMoveIn, address.dateMoveOut)) {
          roleTypeStatus.current.push(x);
        } else if (getPrevious(x.fromDate, x.toDate, address.dateMoveIn, address.dateMoveOut)) {
          roleTypeStatus.previous.push(x);
        }
      });

    const sortedRoleTypes: any = [];
    roleTypeStatus.future.map(item => sortedRoleTypes.push(item));
    roleTypeStatus.current.map(item => sortedRoleTypes.push(item));
    roleTypeStatus.previous.map(item => sortedRoleTypes.push(item));
    setPreviousRoles(roleTypeStatus.previous);
    setRoleData(sortedRoleTypes);
  };

  const editAccessForAddress = () => {
    let isEditShow = false;
    if (address.addressType === 'Tax Home') {
      isEditShow = Authorized([userRoles.housingAccountExecutive, userRoles.workersCompCoordinator], user.userInfo);
    } else {
      isEditShow = true;
    }
    return isEditShow;
  };

  const getAddRoleTypeAccess = () => {
    return {
      startDate: true,
      endDate: true,
      cobra: true,
      dependentCobra: true,
      mailers: true,
      paycheck: true,
      subsidy: true,
      w2: true,
    };
  };

  const addRoleDisabled = moveOut => {
    let pastMoveOutDate = false;
    if (moveOut) {
      const currentDate = GetMomentOfDate(new Date()).format('MM/DD/YYYY');
      const moveOutDate = GetMomentOfDate(moveOut).format('MM/DD/YYYY');
      pastMoveOutDate = dates.gt(currentDate, moveOutDate);
    }
    return pastMoveOutDate;
  };

  useEffect(() => {
    if (isHandleClick) toggleAddAddress();
  }, [isHandleClick, toggleAddAddress]);

  const handleAddRoleType = () => {
    setNewRoleTypeCount(newRoleTypeCount + 1);
    checkRoleTypeEdit('roleType');
  };

  const onDeleteRoleType = (index: number) => {
    setDeleteIndex(index);
    setNewRoleTypeCount(newRoleTypeCount - 1);
  };

  const BuildRoleTypeChip = props => {
    const { item } = props;
    return (
      <>
        <Typography style={{ marginBottom: `${item.addressDates && item.addressDates.length > 0 ? '10px' : '5px'}` }}>
          {t('candidate.addressCard.roleType')}
        </Typography>
        {item.addressDates && item.addressDates.length > 0
          ? roleData.map(x => {
              return (
                <LayoutGrid direction="column" spacing={2} key={index}>
                  <LayoutGridItem>
                    <BuildRoleTypeChips
                      roleTypeItems={x.addressRoles}
                      fromDate={x.fromDate}
                      toDate={x.toDate}
                      moveIn={item.dateMoveIn}
                      moveOut={item.dateMoveOut}
                    />
                  </LayoutGridItem>
                </LayoutGrid>
              );
            })
          : '-'}
      </>
    );
  };

  const newRoles = useMemo(() => {
    if (newRoleTypeCount > 0) {
      if (deleteIndex !== null) {
        const roles = [...Array(newRoleTypeCount + 1)]
          .map(
            (e, i) =>
              i !== deleteIndex && (
                <>
                  <Grid item>
                    <AddRoleTypeSection
                      title={
                        !addressData.addressDates || addressData.addressDates?.length === 0
                          ? newRoleTypeCount === 1
                            ? `Role Types`
                            : `Role Type ${i > deleteIndex ? i : i + 1}`
                          : `Role Type ${addressData.addressDates?.length + (i > deleteIndex ? i : i + 1)}`
                      }
                      index={`${address.travelerAddressId}${i > deleteIndex ? i : i + 1}`}
                      isNewRole
                      newRoleID={i < deleteIndex ? i : i - 1}
                      roleTypeAccess={getAddRoleTypeAccess()}
                      moveInDate={moveInValue}
                      moveOutDate={moveOutValue}
                      onDelete={onDeleteRoleType}
                      setIndexValue={setIndexValue}
                      require={requiredCondition}
                    ></AddRoleTypeSection>
                  </Grid>
                  <Grid item>
                    <Divider className={classes.divider}></Divider>
                  </Grid>
                </>
              ),
          )
          .filter((item: any) => item !== false);
        setDeleteIndex(null);
        return roles;
      } else
        return [...Array(newRoleTypeCount)].map((e, i) => (
          <>
            <Grid item>
              <AddRoleTypeSection
                title={
                  !addressData.addressDates || addressData.addressDates?.length === 0
                    ? newRoleTypeCount === 1
                      ? `Role Types`
                      : `Role Type ${i + 1}`
                    : `Role Type ${addressData.addressDates?.length + i + 1}`
                }
                index={`${address.travelerAddressId}${i + 1}`}
                isNewRole
                newRoleID={i}
                setIndexValue={setIndexValue}
                roleTypeAccess={getAddRoleTypeAccess()}
                moveInDate={moveInValue}
                moveOutDate={moveOutValue}
                onDelete={onDeleteRoleType}
                require={requiredCondition}
              ></AddRoleTypeSection>
            </Grid>
            <Grid item>
              <Divider className={classes.divider}></Divider>
            </Grid>
          </>
        ));
    } else return [];
  }, [newRoleTypeCount, moveInValue, moveOutValue]);

  const { addressLine1, addressLine2, addressLine3 } = formatAddress({
    address1: address1Value,
    address2: address2Value,
    city: cityValue,
    stateAbbrev: stateValue,
    zipValue: zipValue,
    country: countryValue,
  });

  const IsRoleEditable = (startDate: string) => {
    const currentDateTime = new Date().setUTCHours(0, 0, 0, 0);
    const roleStartDateTime = startDate ? new Date(startDate).getTime() : currentDateTime;
    return roleStartDateTime >= currentDateTime;
  };

  return (
    <LayoutGrid container direction="column" className={classes.root}>
      <Paper className={classes.container}>
        {!isHandleClick ? (
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent={'space-between'}
            style={{ paddingBottom: '12px' }}
            xs={12}
          >
            <Grid item xs={7.5}>
              <Grid container spacing={2} direction="column" style={{ flexWrap: 'initial' }}>
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justifyContent={'space-between'}
                    xs={12}
                    className={classes.allainment}
                  >
                    <Grid item>
                      <Typography className={classes.secondary}>
                        {`${
                          moveInValue
                            ? moment(moveInValue).format('MM/DD/YYYY')
                            : address.dateMoveIn
                            ? moment(new Date(address.dateMoveIn)).format('MM/DD/YYYY')
                            : 'N/A'
                        } - ${
                          moveOutValue
                            ? moment(moveOutValue).format('MM/DD/YYYY')
                            : address.dateMoveOut
                            ? moment(new Date(address.dateMoveOut)).format('MM/DD/YYYY')
                            : 'N/A'
                        }`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="space-between"
                    xs={12}
                    style={{ paddingLeft: '12px' }}
                  >
                    <Grid item xs={6} className={classes.child}>
                      <Typography className={classes.fontTitle}>{addressLine1}</Typography>
                      {addressLine2 && <Typography className={classes.fontTitle}>{addressLine2}</Typography>}
                      {addressLine3 && <Typography className={classes.fontTitle}>{addressLine3}</Typography>}
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginLeft: '12px' }}>
                    {addressData.addressDates && <BuildRoleTypeChip item={addressData} />}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4.5}>
              <Grid container spacing={1} direction="column" style={{ paddingRight: '12px', paddingTop: '12px' }}>
                <Grid item alignSelf={'end'}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <IconButton color="primary" onClick={() => {}} className={classes.submitButton} size="small">
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid item>
                      <IconButton
                        color="primary"
                        onClick={() => {
                          enableEditMode();
                        }}
                        disabled={!editAccessForAddress()}
                        className={classes.submitButton}
                        size="small"
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item alignSelf={'end'}>
                  <Button
                    onClick={() => {}}
                    disableRipple
                    disableFocusRipple
                    className={classes.addressButton}
                    variant="text"
                    data-testid="secondary-button-id"
                    sx={{ marginTop: '5px' }}
                  >
                    {address.addressType}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid container spacing={2} direction="column" style={{ flexWrap: 'initial' }}>
              <Grid item>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="space-between"
                  className={classes.alignment}
                >
                  <Grid item>
                    <Button
                      disableRipple
                      disableFocusRipple
                      className={classes.addressButton}
                      sx={{ marginLeft: '8px' }}
                      variant="text"
                      data-testid="secondary-button-id"
                    >
                      {address.addressType}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" spacing={2} style={{ padding: '0px 20px' }}>
                  <Grid item>
                    <Grid container direction="column" spacing={2} style={{ width: '60%' }}>
                      <Grid item>
                        <Grid container direction="row" spacing={2}>
                          <Grid item xs={4}>
                            <FormInputDatePicker
                              name={`MoveIn${index}`}
                              label={'Move In *'}
                              inputValue={moveInValue}
                              handleChange={handleMoveInDate}
                              require={moveInValue ? false : true}
                            ></FormInputDatePicker>
                          </Grid>
                          {isMoveOutReq ? (
                            <Grid item xs={4}>
                              <FormInputDatePicker
                                name={`MoveOut${index}`}
                                label={'Move Out *'}
                                handleChange={handleMoveOutDate}
                                inputValue={moveOutValue}
                                require={moveOutValue ? false : true}
                              ></FormInputDatePicker>
                            </Grid>
                          ) : (
                            <Grid item xs={4}>
                              <FormInputDatePicker
                                name={`MoveOutOptional${index}`}
                                label={'Move Out (Optional)'}
                                handleChange={handleMoveOutDate}
                                inputValue={moveOutValue}
                                require={false}
                              ></FormInputDatePicker>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={4}>
                        <FormInputDropdown
                          name={`country${index}`}
                          label={`Country`}
                          require={false}
                          options={countries.countries}
                          handleChange={handleCountryChange}
                          inputValue={countryValue}
                        ></FormInputDropdown>
                      </Grid>
                      <Grid item className={classes.child}>
                        <FormInputText
                          name={`Address1${index}`}
                          label={'Address 1 *'}
                          inputValue={address1Value}
                          handleChange={handleA1Change}
                          require={true}
                          isCheckBox={true}
                        ></FormInputText>
                      </Grid>
                      <Grid item className={classes.child}>
                        <FormInputText
                          name={`Address2${index}`}
                          label={'Address 2 (Optional)'}
                          inputValue={address2Value}
                          handleChange={handleA2Change}
                          require={false}
                          isCheckBox={true}
                        ></FormInputText>
                      </Grid>
                      <Grid item>
                        <Grid container direction="row" justifyContent="space-between" spacing={4}>
                          <Grid item className={classes.child} xs={4}>
                            <FormInputText
                              name={`City${index}`}
                              label={`${cityLabel} *`}
                              inputValue={cityValue}
                              handleChange={handleCityChange}
                              require={true}
                              isCheckBox={true}
                            ></FormInputText>
                          </Grid>
                          <Grid item className={classes.child} xs={4}>
                            {countryValue === 'USA' || countryValue === 'Canada' ? (
                              <FormInputDropdown
                                name={`state${index}`}
                                label={`State`}
                                require={false}
                                options={stateList ?? []}
                                handleChange={handleStateChange}
                                inputValue={stateValue}
                              ></FormInputDropdown>
                            ) : (
                              <FormInputText
                                name={`state${index}`}
                                label={`State`}
                                inputValue={stateValue}
                                handleChange={handleStateChange}
                                require={false}
                                isCheckBox={true}
                              ></FormInputText>
                            )}
                          </Grid>
                          <Grid item className={classes.child} xs={4}>
                            <FormInputText
                              name={`Zip${index}`}
                              label={zipLabel}
                              inputValue={zipValue}
                              handleChange={handleZipChange}
                              require={true}
                            ></FormInputText>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider className={classes.divider}></Divider>
                  </Grid>
                  {addressData.addressDates &&
                    addressData.addressDates?.length > 0 &&
                    roleData.map((rolesData, i) =>
                      !getPrevious(
                        rolesData.fromDate,
                        rolesData.toDate,
                        addressData.dateMoveIn,
                        addressData.dateMoveOut,
                      ) ? (
                        <>
                          {rolesData && !IsRoleEditable(rolesData?.fromDate) ? (
                            <>
                              <Grid item>
                                <Typography variant="subtitle2" gutterBottom component="div">
                                  {address.addressDates.length === 1
                                    ? `Role Types ${
                                        getPrevious(null, rolesData?.toDate, null, null) ? '(Previous)' : ''
                                      }`
                                    : `Role Type ${i + 1} ${
                                        getPrevious(null, rolesData?.toDate, null, null) ? '(Previous)' : ''
                                      }`}
                                </Typography>
                              </Grid>
                              <Grid item>
                                <BuildRoleTypeChips
                                  roleTypeItems={rolesData?.addressRoles}
                                  fromDate={rolesData?.fromDate}
                                  toDate={rolesData?.toDate}
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item>
                                <AddRoleTypeSection
                                  title={
                                    address.addressDates.length === 1
                                      ? `Role Types ${
                                          getPrevious(
                                            rolesData.fromDate,
                                            rolesData.toDate,
                                            addressData.dateMoveIn,
                                            addressData.dateMoveOut,
                                          )
                                            ? '(Previous)'
                                            : ''
                                        }`
                                      : `Role Type ${i + 1} ${
                                          getPrevious(
                                            rolesData.fromDate,
                                            rolesData.toDate,
                                            addressData.dateMoveIn,
                                            addressData.dateMoveOut,
                                          )
                                            ? '(Previous)'
                                            : ''
                                        }`
                                  }
                                  index={`edit${address.travelerAddressId}${i + 1}`}
                                  data={rolesData}
                                  setIndexValue={setIndexValue}
                                  roleTypeAccess={getAddRoleTypeAccess()}
                                  moveInDate={moveInValue}
                                  moveOutDate={moveOutValue}
                                  future={getFuture(
                                    rolesData.fromDate,
                                    rolesData.toDate,
                                    addressData.dateMoveIn,
                                    addressData.dateMoveOut,
                                  )}
                                  require={requiredCondition}
                                ></AddRoleTypeSection>
                              </Grid>
                            </>
                          )}
                          <Grid item>
                            <Divider className={classes.divider}></Divider>
                          </Grid>
                        </>
                      ) : (
                        isExpanded && (
                          <>
                            {!IsRoleEditable(rolesData.fromDate) ? (
                              <>
                                <Grid item>
                                  <Typography variant="subtitle2" gutterBottom component="div">
                                    {address.addressDates.length === 1
                                      ? `Role Types ${
                                          getPrevious(null, rolesData?.toDate, null, null) ? '(Previous)' : ''
                                        }`
                                      : `Role Type ${i + 1} ${
                                          getPrevious(null, rolesData?.toDate, null, null) ? '(Previous)' : ''
                                        }`}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <BuildRoleTypeChips
                                    roleTypeItems={rolesData?.addressRoles}
                                    fromDate={rolesData?.fromDate}
                                    toDate={rolesData?.toDate}
                                  />
                                </Grid>
                              </>
                            ) : (
                              <>
                                <Grid item>
                                  <AddRoleTypeSection
                                    title={
                                      address.addressDates.length === 1
                                        ? `Role Types ${
                                            getPrevious(
                                              rolesData.fromDate,
                                              rolesData.toDate,
                                              addressData.dateMoveIn,
                                              addressData.dateMoveOut,
                                            )
                                              ? '(Previous)'
                                              : ''
                                          }`
                                        : `Role Type ${i + 1} ${
                                            getPrevious(
                                              rolesData.fromDate,
                                              rolesData.toDate,
                                              addressData.dateMoveIn,
                                              addressData.dateMoveOut,
                                            )
                                              ? '(Previous)'
                                              : ''
                                          }`
                                    }
                                    index={`edit${address.travelerAddressId}${i + 1}`}
                                    data={rolesData}
                                    roleTypeAccess={getAddRoleTypeAccess()}
                                    moveInDate={moveInValue}
                                    moveOutDate={moveOutValue}
                                    setIndexValue={setIndexValue}
                                    future={getFuture(
                                      rolesData.fromDate,
                                      rolesData.toDate,
                                      addressData.dateMoveIn,
                                      addressData.dateMoveOut,
                                    )}
                                    require={requiredCondition}
                                  ></AddRoleTypeSection>
                                </Grid>
                              </>
                            )}
                            <Grid item>
                              <Divider className={classes.divider}></Divider>
                            </Grid>
                          </>
                        )
                      ),
                    )}
                  {newRoles}
                  <Grid item>
                    <Grid container alignItems={'center'}>
                      <Grid item style={{ padding: 0 }}>
                        <IconButton
                          color="primary"
                          onClick={handleAddRoleType}
                          disabled={addRoleDisabled(moveOutValue)}
                          className={classes.addRoleButton}
                          size="small"
                          disableFocusRipple
                          disableTouchRipple
                        >
                          <AddBoxIcon className={classes.addRoleIcon} />
                        </IconButton>
                      </Grid>
                      <Grid item style={{ padding: 0 }}>
                        <Typography className={classes.addRolesText}>{t(`Add Roles`)}</Typography>
                      </Grid>
                    </Grid>
                    {addressData.addressDates && previousRoles.length > 0 && !isExpanded ? (
                      <Button
                        className={classes.ExpandStyle}
                        style={{ textAlign: 'end' }}
                        disableElevation
                        disableRipple
                        disableFocusRipple
                        disableTouchRipple
                        variant="text"
                        onClick={() => setIsExpanded(true)}
                      >{`Show ${previousRoles.length} Previous Role Types`}</Button>
                    ) : (
                      addressData.addressDates &&
                      previousRoles.length > 0 && (
                        <Button
                          className={classes.ExpandStyle}
                          style={{ textAlign: 'end' }}
                          disableElevation
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                          variant="text"
                          onClick={() => setIsExpanded(false)}
                        >{`Hide ${previousRoles.length} Previous Role Types`}</Button>
                      )
                    )}
                  </Grid>
                  <Grid item>
                    <Divider style={{ marginTop: 2 }}></Divider>
                  </Grid>
                  <Grid item>
                    <Grid container justifyContent={'end'} spacing={2} style={{ paddingBottom: '12px' }}>
                      <Grid item>
                        <Button
                          onClick={() => {
                            handleCancel();
                          }}
                          disableRipple
                          disableFocusRipple
                          className={`${classes.submitButton} ${classes.alignButton}`}
                          variant="text"
                          data-testid="secondary-button-id"
                        >
                          {`Cancel`}
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => {
                            handleUpdate(address.travelerAddressId);
                          }}
                          disableRipple
                          disableFocusRipple
                          className={`${classes.updateButton} ${classes.alignButton}`}
                          variant="text"
                          data-testid="secondary-button-id"
                        >
                          {`Update`}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </LayoutGrid>
  );
};
