import React from 'react';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { formatPhoneNumber } from 'app/helpers/phoneHelpers';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import { Grid, Typography } from 'amn-ui-core';
import { IUser } from 'oidc/user.redux';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { missingField } from 'app/constants';
import { TFunction } from 'i18next';
import { IFacilityLogs, IValues } from '../store/FacilityCommunicationHistory.redux';
import { getEndOfDate, getStartOfDate } from 'app/helpers/dateHelper';

export const getContactInfo = log => {
  const channel = log?.channel;
  const tos = log?.message?.tos || [];

  switch (channel) {
    case 'Email': {
      return tos
        .map(to => to?.email)
        .filter(Boolean)
        .join(', ');
    }
    case 'Voice': {
      return tos
        .map(to => {
          const phoneNumber = to?.phoneNumber;
          if (phoneNumber) {
            return phoneNumber.length > 10 && phoneNumber.startsWith('71')
              ? formatPhoneNumber(phoneNumber.substring(2))
              : formatPhoneNumber(phoneNumber);
          }
          return null;
        })
        .filter(Boolean)
        .join(', ');
    }
    case 'Conversation': {
      return tos
        .map(to => {
          const phoneNumber = to?.phoneNumber;
          if (phoneNumber) {
            return phoneNumber?.length > 10 && phoneNumber.startsWith('+1')
              ? formatPhoneNumber(phoneNumber.substring(2))
              : formatPhoneNumber(phoneNumber);
          }
          return null;
        })
        .filter(Boolean)
        .join(', ');
    }
    default: {
      return '';
    }
  }
};

export function getConatctNames(log) {
  if (!log || !log?.message || !Array.isArray(log?.message?.tos)) {
    return '';
  }
  const tosNames = log?.message?.tos
    .filter(tosEntry => tosEntry?.name)
    .map(tosEntry => tosEntry?.name)
    .join(', ');

  return tosNames;
}

export const identifyCommunicationIcon = (
  communicationLog: any,
  fontSize: string = '1.5rem',
  marginBottom: string = '7px',
  actAsBoolean: boolean = false,
) => {
  switch (communicationLog?.channel) {
    case 'Conversation':
      return actAsBoolean ? true : <ChatOutlinedIcon style={{ fontSize: fontSize, marginBottom: marginBottom }} />;
    case 'Voice':
      return actAsBoolean ? true : <CallOutlinedIcon style={{ fontSize: fontSize, marginBottom: marginBottom }} />;
    case 'Email':
      return actAsBoolean ? true : <EmailOutlinedIcon style={{ fontSize: fontSize, marginBottom: marginBottom }} />;

    default:
      return actAsBoolean ? false : null;
  }
};

export const getDelieveryStatusMessage = (obj, missingField) => {
  if (obj?.deliveryStatus) {
    return obj?.deliveryStatus[0]?.toUpperCase() + obj?.deliveryStatus.slice(1);
  } else {
    return missingField;
  }
};

export const FacilityCommunictionHistoryFlattenData = items =>
  items.map((item, index) => {
    const data = {
      id: `${item.rowID}-${index}`,
      indexCounter: index,
      contactName: item?.contactName,
      communicationType: item?.communicationType,
      contactInfo: item?.contactInfo,
      date: item?.date,
      from: item?.from,
      userRole: item?.userRole,
      status: item?.status,
      type: item?.type,
      notes: item?.notes,
    };
    return data;
  });

export const getFilterClientContactLookups = (
  contacts: { contactId: number; firstName: string; lastName: string }[],
): ISelection[] => {
  return contacts.map(contact => ({
    id: contact.contactId,
    name: `${contact?.firstName} ${contact?.lastName}`,
    value: contact.contactId,
  }));
};

export const getFilterUnitLookups = (units: { unitId: number; name: string }[]): ISelection[] => {
  return units.map(unit => ({
    id: unit.unitId,
    name: unit?.name,
    value: unit?.unitId,
  }));
};

export const getFilterLookups = (array: string[]): ISelection[] => {
  return array.map((item, index) => ({
    id: index,
    name: item,
    value: index,
  }));
};

export const getNames = (data): any[] => {
  if (Array.isArray(data)) {
    return data.map(item => item.name);
  } else if (data && 'name' in data) {
    return [data.name];
  }
  return [];
};

export const getValues = (data: any): string[] => {
  if (Array.isArray(data)) {
    return data.map(item => String(item.value));
  } else if (data && 'value' in data) {
    return [String(data.value)];
  }
  return [];
};

export const requestFilterPayload = (values: IValues): IFacilityLogs => {
  return {
    channel: getNames(values?.communicationType),
    initiatedBy: getNames(values?.initiatedBy),
    contactNames: getNames(values?.contactName),
    unitIds: getValues(values?.units),
    startDate: values?.date?.startDate ? getStartOfDate(values?.date?.startDate) : null,
    endDate: values?.date?.endDate ? getEndOfDate(values?.date?.endDate) : null,
    label: values?.date?.label,
    contactStatus: getValues(values?.contactStatus).toString(),
    userRoles: getNames(values?.userRole),
  };
};

function isValidObject(obj) {
  return obj && typeof obj === 'object' && Object.values(obj).some(value => value !== null && value !== undefined);
}
export function hasChipsFilterValue(obj) {
  if (!obj || typeof obj !== 'object') {
    return false;
  }
  return Object.values(obj).some(isValidObject);
}

export interface INotes {
  rowId: number;
  open: boolean;
  data: any;
  isPreview: boolean;
}

export const defaultNotes: INotes = {
  rowId: 0,
  open: false,
  data: null,
  isPreview: false,
};

export enum communicationType {
  Voice = 'Voice',
  Conversation = 'Conversation',
  Email = 'Email',
}

export const getNotes = (
  notes: string,
  log: any,
  rowId: number,
  classes: any,
  user: IUser,
  setNotesModal: (notes: INotes) => void,
) => {
  const coveringEmployeeId = log?.associatedRecords?.find(record => record?.name === 'coveringEmployeeId');
  const isActualSender = log?.message?.sender?.userId === user?.userInfo?.employeeId?.toString();

  return (
    <div className={classes.notes}>
      <Typography
        className={classes.conversationNotes}
        onClick={() =>
          isActualSender || coveringEmployeeId?.value
            ? setNotesModal({ rowId: rowId, open: true, data: log, isPreview: false })
            : setNotesModal({ rowId: rowId, open: true, data: log, isPreview: true })
        }
      >
        {notes}
      </Typography>
      {(isActualSender || coveringEmployeeId?.value) && (
        <EditIcon
          sx={{ margin: notes?.length < 20 ? '0 0 0 8px' : '8px 0 0 0 0x' }}
          className={classes.notesEditIcon}
          color="primary"
          onClick={() => setNotesModal({ rowId: rowId, open: true, data: log, isPreview: false })}
        />
      )}
    </div>
  );
};

export const addNotes = (
  log: any,
  rowId: number,
  user: IUser,
  classes: any,
  setNotesModal: (notes: INotes) => void,
  t: TFunction,
) => {
  const coveringEmployeeId = log?.associatedRecords?.find(record => record?.name === 'coveringEmployeeId');

  return log?.message?.sender?.userId === user?.userInfo?.employeeId?.toString() || coveringEmployeeId?.value ? (
    <Grid
      className={classes.notes}
      container
      spacing={0}
      direction="row"
      alignItems="flex-start"
      justifyContent="flex-start"
      onClick={() => setNotesModal({ rowId: rowId, open: true, data: log, isPreview: false })}
    >
      <Grid item>
        <Typography className={classes.addNote} color="primary">
          {t('facility.notesModal.addNoteButtonText')}
        </Typography>
      </Grid>
      <Grid item>
        <AddIcon className={classes.notesEditIcon} color="primary" />
      </Grid>
    </Grid>
  ) : (
    missingField
  );
};

const getEmailNotes = (
  fieldValue: string,
  row: number,
  channel: string,
  classes: any,
  handleClick: (row: number, channel: string) => void,
) => {
  return (
    <div className={classes.notes}>
      <Typography className={classes.conversationNotes} onClick={() => handleClick(row, channel)}>
        {fieldValue}
      </Typography>
    </div>
  );
};

export const communicationNotesOrSubject = (
  log: any,
  index: number,
  user: IUser,
  classes: any,
  setNotesModal: (notes: INotes) => void,
  handleClick: (row: number, channel: string) => void,
  t: TFunction,
) => {
  const notes = log?.response?.notes || '';
  const emailSubject = log?.message?.subject || '';
  switch (log?.channel) {
    case communicationType.Voice: {
      if (notes !== '') {
        return getNotes(notes, log, index, classes, user, setNotesModal);
      } else return addNotes(log, index, user, classes, setNotesModal, t);
    }
    case communicationType.Conversation: {
      if (notes !== '') {
        return getNotes(notes, log, index, classes, user, setNotesModal);
      } else return addNotes(log, index, user, classes, setNotesModal, t);
    }
    case communicationType.Email: {
      if (emailSubject !== '') {
        return getEmailNotes(emailSubject, index, log?.channel, classes, handleClick);
      } else return missingField;
    }
    default:
      return missingField;
  }
};
