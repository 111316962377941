import React from 'react';
import { useSelector } from 'react-redux';
import { selectPlacementDetails } from 'store/redux-store/placement-details/selectors';
import { makeStyles } from 'tss-react/mui';
import { Concatenate } from 'utils/string/string';
import { DrivingDistance } from '.';

const useStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: '8px',
  },
  divider: {
    '&::before': {
      content: '"|"',
    },
  },
}));

export const FacilityLocation = () => {
  const { classes } = useStyles();
  const {
    header: {
      facility: { city, county, state, postalCode },
    },
  } = useSelector(selectPlacementDetails);

  const facilityAddress = React.useMemo(
    () => Concatenate([city, county && Concatenate([county, ' County'], ' '), state, postalCode], ', '),
    [city, county, state, postalCode],
  );
  return (
    <div className={classes.root}>
      <div>{facilityAddress}</div>
      <div className={classes.divider} />
      <DrivingDistance />
    </div>
  );
};
