import { IOptionsList } from '../Tasks/Tasks';

export interface IClientInfo {
  clientContactId: number;
  name: string;
  title: string;
  email: string;
  status: string;
  dateOfLastContact: string | null;
  lastContactDate: Date | null;
  accountManager: string | null;
  phoneNumber: string;
  facilityName: string;
  facilityId: number;
  unitList: string[];
  unitListHoverViewData: string[];
  contactType: string[];
  contactTypeHoverViewData: string[];
  AMNDivision: string;
  serviceStatus: IconWithText;
  serviceStatusText: string;
}

export interface IconWithText {
  image?: string | null;
  text?: string | null;
}

export const contactPreferenceChoice = [
  { Id: 1, Description: 'Ok to Call' },
  { Id: 2, Description: 'Call discreetly' },
  { Id: 3, Description: 'Do not call contact' },
  { Id: 4, Description: 'File Administrator' },
];

export const contactStatusChoice = [
  { Id: 1, Description: 'Active' },
  { Id: 2, Description: 'Inactive' },
];

export const NEW_CONTACT = 'NEW CONTACT';
export const CLONE_EXISTING = 'CLONE EXISTING';

export const formInputs = {
  firstName: 'firstName',
  lastName: 'lastName',
  title: 'title',
  email: 'email',
  countryCode: 'countryCode',
  phoneNumber: 'phoneNumber',
  contactPreference: 'contactPreference',
  contactPreferenceTypeId: 'contactPreferenceTypeId',
  contactStatus: 'contactStatus',
  statusId: 'statusId',
};

export interface IActionList {
  id: number;
  description: string;
}


export interface IClientContactPhoneNumber {
  id: string;
  phoneTypeId: number;
  phoneNumber: string;
  countryDialingCode: string;
  extensionNotes: string;
  timeStamp: string;
  phoneType: string;
  softDelete: boolean;
}

export interface IClientContact {
  createClientContact?: IClientContactSave;
  createdContactId?: number;
  actionList?: IOptionsList[];
  facilityUnits?: IOptionsList[];
  clientContact?: IClientContactDetails;
  isClientContactUpdated?: IClientContactUpdated;
  isEditClientContactOpen: boolean;
  clientContactPhoneNumber: IClientContactPhoneNumber[];
}

export interface IClientContactUpdated {
  refreshModal: boolean;
  closeModal: boolean;
  responseStatus?: number;
}

export interface IClientContactSave {
  affiliateId?: number;
  facilityId: number;
  unitId: number;
  firstName: string;
  lastName: string;
  prefix: string;
  goesBy: string;
  title: string;
  aMHTitleId?: number;
  email: string;
  address1: string;
  address2: string;
  city: string;
  stateProvinceId: string;
  zip: string;
  faxInstruction: string;
  contactId?: number;
  insertdate: Date;
  statusId: number;
  evaluationDeliveryTypeId: number;
  confirmationDeliveryTypeId: number;
  fileDeliveryTypeId: number;
  contactPreferenceTypeId: number;
  activeSSC: boolean;
  text: string;
  phoneTypeID: number;
  phoneNumber: number;
  extensionNotes: string;
  countryDialingCode: string;
  unitIds: number[];
  roleActionIds: number[];
}

export interface IClientContactDetails {
  contactId: number;
  firstName: string;
  lastName: string;
  title: string;
  email?: string;
  doNotCall: boolean;
  contactPreferenceTypeId: number;
  contactStatusId: number;
  phoneNumbers: IClientPhoneNumber[];
  actions: IAction[];
  units: IUnits[];
  facilityID: number;
  timestamp: string;
  responseCode: number;
  address1: string;
  address2?: string;
  city?: string;
  stateProvinceID?: string;
  country?: string;
  zip?: string;
  state?: string;
  confirmationDeliveryTypeID?: number;
  fileDeliveryTypeID?: number;
  evaluationDeliveryTypeID?: number;
  notes?: string;
}

export interface IClientPhoneNumber {
  countryDialingCode: string;
  phoneNumber: string;
  extensionNotes: string;
  phoneTypeId: number;
  timestamp?: string;
}

export interface IAction {
  id?: number;
  description: string;
}

export interface IUnits {
  unitId: number;
  name: string;
}

export enum ResponseCodes {
  conflict = 60000,
  inActiveStatus = 60102,
  noDelete = 60270,
  refresh = 50704,
  notFound = 404,
}

export enum DescriptionType {
  sendFileTo = 'Send file to',
}

export interface IContactTypeOption {
  ID: number;
  Description: string;
}

export interface IFormClientPhoneNumber {
  countryDialingCode: string;
  phoneNumber: string;
  extensionNotes: string;
  phoneTypeId: number;
  timeStamp?: string;
  softDelete: boolean;
  phoneType?: string;
  id?: string;
}

export enum facilityDeliveryType {
  Fedex = 1,
  EmailPdf = 2,
  Fax = 3,
  EmailHtml = 4
}
export enum facilityPhoneType {
  FAX_1 = 'Fax1',
  FAX_2 = 'Fax2',
}

export enum ActionDescription {
  SEND_FILE_TO = "Send file to",
  SEND_WRITTEN_MATERIALS_REQUIREMENTS_TO = "Send Written Materials requirements to",
  SEND_MEDICAL_REQUIREMENTS_TO = "Send Medical requirements to",
  SEND_CONFIRMATION_TO = "Send Confirmation To",
  SEND_EVALUATION_TO = "Send evaluation to"
}