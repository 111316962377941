import React from 'react';
import { Box, Typography } from 'amn-ui-core';
import { LayoutGridItem } from 'app/layout/LayoutGrid';
import moment from 'moment';
import { makeStyles } from 'tss-react/mui';
import { ISkillChecklistSection } from '../Config/model';
import { missingField } from 'app/constants';

const useTextAndDateStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  dateContainer: {
    justifyContent: 'flex-end',
  },
  dateRegion: {
    textAlign: 'left',
    font: 'normal normal medium 12px/19px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.dimGray,
  },
  textRegion: {
    textAlign: 'left',
    font: 'normal normal normal 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.charcoal,
    opacity: 1,
  },
  questionRegion: {
    textAlign: 'left',
    font: 'normal normal medium 12px/16px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.dimGray,
    opacity: 1,
  },
  dateValueContainer: {
    textAlign: 'left',
    font: 'normal normal normal 14px/19px Roboto',
    letterSpacing: '0px',
    color: theme.palette.framework.system.charcoal,
  },
}));

const TextAndDatePanel = ({ currentItem, index }: { currentItem: ISkillChecklistSection; index: number }) => {
  const { classes } = useTextAndDateStyles();

  /**
   * Method to get text
   */
  const getItemText = (currentItem: ISkillChecklistSection) => {
    const text = currentItem?.text?.split('#');
    return <Typography variant="body2">{`${text[0]?.replace(/:/g, '') ?? ''}`}</Typography>;
  };

  const getDateType = (currentItem: ISkillChecklistSection) => {
    const text = currentItem?.text?.split('#');
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <Typography variant="body2" width={'70px'} className={classes.dateRegion}>{`${
          text[2]?.replace(/:/g, '')?.replace(/&amp;/g, '&') ?? ''
        }`}</Typography>
        <Typography variant="body2" className={classes.dateValueContainer}>{`${
          currentItem?.hasAnswer && currentItem?.dValue
            ? moment(currentItem.dValue).format('MM/DD/YYYY')
            : currentItem?.hasAnswer && currentItem?.conversionDateValue
            ? moment(currentItem?.conversionDateValue).format('MM/DD/YYYY')
            : missingField
        }`}</Typography>
      </Box>
    );
  };

  return (
    <LayoutGridItem container item key={index} display="flex" justifyContent={'flex-end'}>
      <LayoutGridItem item xs={4} sx={{ paddingRight: '8px' }} className={classes.questionRegion}>
        {getItemText(currentItem)}
      </LayoutGridItem>
      <LayoutGridItem item xs={4}>
        {currentItem?.hasAnswer && currentItem?.tValue && (
          <Typography variant="body2" className={classes.textRegion}>
            {currentItem.tValue}
          </Typography>
        )}
      </LayoutGridItem>

      <LayoutGridItem item xs={4} className={classes.dateContainer} sx={{ paddingLeft: '8px' }}>
        {getDateType(currentItem)}
      </LayoutGridItem>
    </LayoutGridItem>
  );
};

export default TextAndDatePanel;
