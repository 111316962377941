import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { TextField } from 'amn-ui-core';
import { formInputs } from 'app/models/ClientContact/ClientContact';
import { useSelector } from 'react-redux';
import { selectClientContactDetails } from '../store/ClientContact.selector';

export const CCTitle = formMethods => {
  const { t } = useTranslation();

  const { formMethods: controls, disabled } = formMethods;
  const selectedClientContactDetails = useSelector(selectClientContactDetails);

  const {
    control,
    setError,
    clearErrors,
    setValue,
    formState: { isDirty, errors },
  } = controls;

  useEffect(() => {
    if (selectedClientContactDetails?.contactId)
      setValue('title', selectedClientContactDetails?.title, { shouldDirty: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientContactDetails?.contactId]);

  return (
    <Controller
      control={control}
      name={'title'}
      render={({ ref, onChange, value, ...rest }) => (
        <TextField
          variant="filled"
          color="primary"
          label={t('clientContactModal.labels.title')}
          inputProps={{ maxLength: 101 }}
          InputLabelProps={{ shrink: !!value }}
          disabled={disabled}
          autoComplete="off"
          value={value}
          {...rest}
          error={errors?.title ? true : false || (isDirty && errors?.title) ? true : false}
          onChange={e => {
            if (e.target.value?.length > 100) {
              setError(formInputs.title, {
                type: 'maxLength',
                message: t('clientContactModal.messages.charMax100'),
              });
            } else if (errors?.title) {
              e.target.value?.length <= 100 && clearErrors(formInputs.title);
            }
            onChange(e);
          }}
          helperText={errors?.title?.message}
        />
      )}
    />
  );
};
