/* eslint-disable @typescript-eslint/no-shadow */
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';
import { initialState } from './Facility.redux';

const selectDomain = (state: RootState) => state?.facilityData || initialState;

export const selectFacility = createSelector([selectDomain], selectFacility => selectFacility);

export const selectFacilityDetail = createSelector([selectDomain], selectFacility => selectFacility.facilityResponse);

export const selectSearchResults = createSelector([selectDomain], selectFacility => selectFacility.searchResults);

export const selectContractDetails = createSelector([selectDomain], selectFacility => selectFacility.contractDetails);

export const selectAdditionalContractDetails = createSelector(
  [selectDomain],
  selectFacility => selectFacility.additionalContractDetails,
);

export const selectFacilityDisipline = createSelector([selectDomain], selectFacility => selectFacility.disciplineList);

export const selectSelectedDiscipline = createSelector(
  [selectDomain],
  selectFacility => selectFacility.selectedDiscipline,
);

export const selectDisciplineSpecialityRates = createSelector(
  [selectDomain],
  selectFacility => selectFacility.disciplineSpecialityRates,
);

export const selectRateElements = createSelector([selectDomain], selectFacility => selectFacility.rateElements);

export const selectSpecialtySubSpecialty = createSelector(
  [selectDomain],
  selectFacility => selectFacility.selectedSpecialtySubSpecialty,
);
export const selectOffContract = createSelector(
  [selectDomain],
  selectFacility => selectFacility.offContractRateElements,
);

export const selectCreatedOffContractId = createSelector(
  [selectDomain],
  selectFacility => selectFacility.createdOffContractId,
);

export const selectApprovalDocuments = createSelector(
  [selectDomain],
  selectFacility => selectFacility.offContractApprovalDocuments,
);

export const selectUpdatedOffContract = createSelector(
  [selectDomain],
  selectFacility => selectFacility.updatedOffContractRateElement,
);

export const selectApproveRejectSelectedOption = createSelector(
  [selectDomain],
  selectFacility => selectFacility.selectedApproveRejectOption,
);

export const selectUploadedDocuments = createSelector(
  [selectDomain],
  selectFacility => selectFacility.uploadedDocuments,
);

export const selectedApproveRejectRows = createSelector(
  [selectDomain],
  selectFacility => selectFacility.selectedApproveRejectRows,
);

export const isUploadDocumentModalOpen = createSelector(
  [selectDomain],
  selectFacility => selectFacility.uploadDocumentModalOpen,
);

export const selectFacilityMiscellaneousData = createSelector(
  [selectDomain],
  selectFacility => selectFacility.miscellaneous,
);

export const selectFacilityInternalContacts = createSelector(
  [selectDomain],
  selectFacility => selectFacility.internalContacts || initialState.internalContacts,
);

export const selectEditFacilityInternalContacts = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.editInternalContacts || initialState.editInternalContacts,
);

export const selectIsFacilityByIdRequestSuccess = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.isFacilityByIdRequestSuccess,
);

export const selectFacilityRegionOptions = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.facilityRegions,
);

export const selectEditFacilityRegion = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.editFacilityRegion,
);

export const selectFacilityInstructions = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.instructions,
);

export const selectIsFacilityIntructionsUpdateSuccess = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.isFacilityIntructionsUpdateSucess,
);

export const selectFacilityRegionModalState = createSelector([selectDomain], state => ({
  ...state.facilityRegionModalState,
  open: !!state.facilityRegionModalState?.open,
}));

export const selectSaveModalState = createSelector([selectDomain], state => ({
  ...state.saveModalState,
  open: !!state.saveModalState?.open,
}));

export const selectFacilityContractAffiliate = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.contractAffiliateData,
);

export const selectFacilityDrugScreenPolicies = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.facilityDrugScreenPolicies,
);

export const selectFacilitiesForAffiliate = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.facilitiesForAffiliate,
);

export const selectFacilityContacts = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.facilityClientContacts,
);

export const selectHttpStatus = createSelector([selectDomain], selectFacility => selectFacility?.httpStatus);

export const selectKeyContactsTabHeight = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.tabHeight || initialState.tabHeight,
);

export const selectKeyContactsTabExpanded = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.isTabExpanded || initialState.isTabExpanded,
);

export const selectEditFacilityClientContacts = createSelector(
  [selectDomain],
  selectFacility => selectFacility?.editClientContacts || initialState.editClientContacts,
);

export const selectFacilityDetailLocation = createSelector([selectDomain], selectFacility => selectFacility?.facilityResponse?.location);
