import React, { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, ClickAwayListener, Fade, Grid, Paper, Popper } from 'amn-ui-core';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { ISelection } from 'app/ComponentLibrary/Filter/TypeAheadDropdown/CustomTypeAhead';
import { theme } from 'styles/global-styles';
import { CustomizedSubCertificateUnitTypeAhead } from './CustomizedUnitSubCertificateTypeAhead';
import { useFormContext } from 'react-hook-form';
import { CollectionType } from 'app/enums/Common';
import { useDispatch } from 'react-redux';
import { FacilityUnitActions } from '@AMIEWEB/Facility/FacilityDetails/FacilityTabs/UnitTab/Store/FacilityUnit.redux';
import { useStyles } from './SubCertificateUnitTypeAhead.styles';
import { FormPageType, SubCertificateUnitTypeAheadDropdownProps } from './SubCertificate.types';
import { appendNewCertificateRows, handleAddSubCert, handleDeleteSubCert } from './Helper';
import { orderDataActions } from '@AMIEWEB/Order/Store/Order.redux';

export const SubCertificateUnitTypeAheadDropdown = (props: SubCertificateUnitTypeAheadDropdownProps) => {
  const {
    name,
    isMultiSelect,
    value: selected,
    selectAllValue,
    options: providedOptions,
    filterName,
    isDisabled,
    /** Prop to utilize external anchor element */
    anchorRef,
    customClass,
    hasControlError,
    fallbackPlacements,
    minWidth,
    apply,
    hiddenApplyBtn,
    anchorAccesorid,
    hideApply,
    controlErrorText,
    removeCertificate,
    requirementIndex,
    appendRequirement,
    groupIndex,
    skillSetIndex,
    selectedSubCerts,
    parentPage,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [options, setOptions] = useState<ISelection[]>([]);
  const [selectedSubCertificates, setSelectedSubCertificates] = useState<any[]>([]);
  const [clearErrors, setClearErrors] = useState(false);
  const { watch } = useFormContext();
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const { classes } = useStyles({
    isSelected: isMultiSelect ? selected && selected.length > 0 : selected !== null,
    open,
    anchorRef,
  });

  const id = open ? `custom-type-ahead-${name}` : undefined;

  const handleApply = () => {
    setClearErrors(true);
    let addedSubCerts = [];
    let isSubCertRemoved = false;
    const currentRowValues = watch(
      `skillSetGroup.groups[${groupIndex}].skillsets[${skillSetIndex}].collections[${CollectionType.CertificationsIndex}].requirements[${requirementIndex}]`,
    );
    let allSelectedSubCerts = selectedSubCerts?.length ? selectedSubCerts : [];
    if (!selected?.length) {
      if (selectedSubCertificates?.length === 1) {
        apply(selectedSubCertificates);
        allSelectedSubCerts = handleAddSubCert(
          allSelectedSubCerts,
          selectedSubCertificates?.[0]?.subCertificate?.id,
          skillSetIndex,
        );
      } else if (selectedSubCertificates?.length > 1) {
        allSelectedSubCerts = handleAddSubCert(
          allSelectedSubCerts,
          selectedSubCertificates?.[0]?.subCertificate?.id,
          skillSetIndex,
        );
        const newArray = selectedSubCertificates.slice(1);
        newArray.forEach(sub => {
          addedSubCerts.push(sub.subCertificate);
        });
        allSelectedSubCerts = appendNewCertificateRows(
          addedSubCerts,
          allSelectedSubCerts,
          currentRowValues,
          appendRequirement,
          skillSetIndex,
        );
        // negligible delay to ensure correct form state update
        setTimeout(() => {
          apply([selectedSubCertificates?.[0]]);
        }, 0);
        setSelectedSubCertificates([selectedSubCertificates?.[0]]);
      }
    } else {
      if (selectedSubCertificates?.length === 0) {
        isSubCertRemoved = true;
      } else {
        selectedSubCertificates.forEach(sub => {
          if (sub.subCertificate.id !== selected?.[0]?.subCertificate.id) {
            addedSubCerts.push(sub.subCertificate);
          }
        });
        isSubCertRemoved = !selectedSubCertificates.some(
          each => each.subCertificate.id === selected?.[0]?.subCertificate.id,
        );
      }
      if (isSubCertRemoved) {
        // negligible delay to ensure correct form state update
        setTimeout(() => {
          removeCertificate(requirementIndex);
        }, 0);
        allSelectedSubCerts = handleDeleteSubCert(selectedSubCerts, selected?.[0]?.subCertificate.id, skillSetIndex);
      }
      if (addedSubCerts.length > 0) {
        allSelectedSubCerts = appendNewCertificateRows(
          addedSubCerts,
          allSelectedSubCerts,
          currentRowValues,
          appendRequirement,
          skillSetIndex,
        );
        setSelectedSubCertificates([selectedSubCertificates?.[0]]);
      }
    }
    parentPage === FormPageType.Unit
      ? dispatch(FacilityUnitActions.setSelectedSubCert(allSelectedSubCerts))
      : dispatch(orderDataActions.setSelectedSubCert(allSelectedSubCerts));
    closeActions();
  };

  const closeActions = () => {
    setAnchorEl(null);
  };

  const cancelChanges = () => {
    setSelectedSubCertificates(selected);
    closeActions();
  };

  const clearAll = () => {
    setSelectedSubCertificates([]);
  };

  const handleOpen = event => {
    if (!open) {
      setAnchorEl(anchorRef ? anchorRef.current : event.currentTarget);
    } else {
      if (hideApply) {
        handleApply();
        return;
      }
      cancelChanges();
    }
  };

  const onClickAway = event => {
    if (event.target.type !== 'submit') {
      setSelectedSubCertificates(selected);
      closeActions();
    }
  };

  useEffect(() => {
    setSelectedSubCertificates(selected);
  }, [selected]);

  useEffect(() => {
    if ((providedOptions || []).length > 0) {
      setOptions(providedOptions);
    }
  }, [providedOptions]);

  return (
    <Box>
      <div className={classes.root}>
        <Button
          disableRipple
          variant="outlined"
          sx={{
            borderColor: !clearErrors && hasControlError ? theme.palette.system.guardsmanRed : '',
            color: !clearErrors && hasControlError ? theme.palette.system.guardsmanRed : '',
            height: '',
            minWidth: minWidth ? minWidth : '',
          }}
          aria-describedby={id}
          disabled={isDisabled}
          endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          classes={{ root: classes.outlineAnchor }}
          onClick={handleOpen}
          id={anchorAccesorid}
        >
          <div className={classes.valueContainer}>
            <div
              style={{
                fontSize: selected?.[0]?.subCertificate?.id ? '10px' : '14px',
                color: !clearErrors && hasControlError ? theme.palette.system.guardsmanRed : '',
              }}
            >
              {filterName}
            </div>
            <div className={classes.subCertValue}>{selected?.[0]?.subCertificate?.name}</div>
          </div>
        </Button>
        {!clearErrors && hasControlError && (
          <div
            style={{
              color: `${theme.palette.system.errorRed}`,
              fontSize: '12px',
              paddingLeft: '12px',
              fontWeight: 500,
            }}
          >
            <span>{controlErrorText}</span>
          </div>
        )}
      </div>
      <Popper
        className={customClass ? `${customClass}` : `${classes.popper}`}
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: fallbackPlacements,
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={onClickAway}>
            <Fade {...TransitionProps} in={open} timeout={300}>
              <Paper aria-label={id} classes={{ root: classes.paperDropdown }}>
                <Grid
                  item
                  container
                  justifyContent={isMultiSelect ? 'space-between' : 'flex-end'}
                  alignItems="center"
                  classes={{
                    root: classes.headerContainerNoSearchBoxSingleOption,
                  }}
                >
                  {isMultiSelect ? (
                    <div className={classes.countTag}>{`${
                      selectAllValue &&
                      options?.length &&
                      selectedSubCertificates?.length &&
                      selectedSubCertificates?.length === options?.length
                        ? options?.length - 1
                        : selectedSubCertificates?.length || 0
                    } ${t('search.filter.Selected')}`}</div>
                  ) : null}
                  <Button
                    className="clear-all-button"
                    aria-label="clear-all-button"
                    variant="text"
                    color="primary"
                    disableRipple
                    disableFocusRipple
                    disableTouchRipple
                    onClick={() => clearAll()}
                    disabled={!isMultiSelect}
                    classes={{ text: ` ${classes.button} ${classes.clearAllButton}` }}
                  >
                    {t('search.filter.clearAll')}
                  </Button>
                </Grid>
                <CustomizedSubCertificateUnitTypeAhead
                  options={options}
                  selectedValues={selectedSubCertificates}
                  setSelectedSubCertificates={setSelectedSubCertificates}
                  requirementIndex={requirementIndex}
                  groupIndex={groupIndex}
                  skillSetIndex={skillSetIndex}
                  selectedSubCerts={selectedSubCerts}
                />
                <div className={classes.actionContainer}>
                  <Grid item container justifyContent="flex-end" alignItems="center">
                    <Button
                      className="clear-all-button"
                      aria-label="apply-button"
                      variant="text"
                      color="primary"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      onClick={() => handleApply()}
                      classes={{ text: `${classes.button} ${classes.actionButton}` }}
                      id={hiddenApplyBtn}
                    >
                      {t('search.filter.apply')}
                    </Button>
                    <Button
                      className="clear-all-button"
                      aria-label="cancel-button"
                      variant="text"
                      disableRipple
                      disableFocusRipple
                      disableTouchRipple
                      onClick={() => cancelChanges()}
                      classes={{ text: `${classes.button} ${classes.actionButton} ${classes.inActive}` }}
                    >
                      {t('search.filter.cancel')}
                    </Button>
                  </Grid>
                </div>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </Box>
  );
};
