import { IconButton, Theme, Button, Grid, Alert, TextField, Divider } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import { LayoutGrid, LayoutGridItem } from 'app/layout/LayoutGrid';
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import { useTranslation } from 'react-i18next';
import { defaultCountry } from 'app/constants';
import { useFormContext, Controller } from 'react-hook-form';
import { FormInputDropdown } from '@AMIEWEB/Candidate/CandidateProfile/CandidateTabPanel/PersonalInfoTab/Form/Controls/FormInputDropdown';
import { IContactTypeOption, IFormClientPhoneNumber } from 'app/models/ClientContact/ClientContact';
import {
  extractIndexFromControllerName,
  getPhoneType,
  registerNonFormFieldValuesForPhoneNumber,
} from '@AMIEWEB/Facility/FacilityDetails/helper';
import { theme } from 'styles/global-styles';
import { clientContactActions } from '../store/ClientContact.redux';
import { useDispatch } from 'react-redux';
import CCNotes from './CCNotes';

const enum CountryCode {
  US = 'us',
}

export const useInputCountryDropdownStyles = makeStyles()((theme: Theme) => ({
  root: {
    padding: '0px',
  },
  arrowClass: {
    paddingLeft: '2px',
    '&.react-tel-input .selected-flag .arrow': {
      background: `url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSI4LjgyOSIgdmlld0JveD0iMCAwIDE2IDguODI5Ij4KICA8cGF0aCBpZD0iQXJyb3ciIGQ9Ik0yNS4xNTgsMzEuNzIxYS43NzQuNzc0LDAsMCwxLS41NDgtLjIyN2wtNy4yNzctNy4yNzZhLjc3Ni43NzYsMCwxLDEsMS4xLTEuMWw2LjcyOCw2LjcyOCw2LjYyMS02LjYyMmEuNzc3Ljc3NywwLDEsMSwxLjEsMS4xbC03LjE3LDcuMTdBLjc3NS43NzUsMCwwLDEsMjUuMTU4LDMxLjcyMVoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xNy4xMDQgLTIyLjg5MikiIGZpbGw9IiM4ODgiLz4KPC9zdmc+Cg==")`,
      borderLeft: '0px',
      borderRight: '0px',
      borderTop: '0px',
      width: '10px',
      height: '9px',
      paddingLeft: '16px',
      zoom: '0.6',
    },
    '&.react-tel-input .selected-flag': {
      width: '60px',
    },
    '&.react-tel-input .selected-flag .arrow.up': {
      borderBottom: '0px',
    },
    '&.react-tel-input .form-control': {
      paddingLeft: '70px',
    },
    '&.react-tel-input .selected-flag .flag': {
      paddingLeft: '8px',
      zoom: '1.5',
    },
    '&.react-tel-input .country-list': {
      backgroundColor: theme.palette.framework.system.whiteSmoke,
      maxHeight: '225px !important',
    },
    '&.react-tel-input .country-list .country': {
      display: 'grid',
      gridTemplateColumns: '1fr 4fr 1fr',
      alignItems: 'center',
      padding: '8px 2px 8px 12px',
    },
    '&.react-tel-input .country-list .country .dial-code': {
      textAlign: 'right',
      color: theme.palette.framework.system.charcoal,
    },
    '&.react-tel-input .country-list .flag': {
      zoom: '1.5',
    },
    '&.react-tel-input .country-list .search': {
      backgroundColor: theme.palette.framework.system.whiteSmoke,
      padding: '10px 0 10px 10px',
    },
    '&.react-tel-input .country-list .search-box': {
      height: '35px',
      width: '95% !important',
    },
    '&.react-tel-input .flag-dropdown.open .selected-flag': {
      background: theme.palette.framework.system.paleGray,
    },
  },
  alert: {
    width: '74.2%',
    padding: '0px 6px 3px 6px',
    marginLeft: '7px',
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      '& div.MuiAlert-icon': {
        flexGrow: 1,
      },
      '& div.MuiAlert-message': {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 30,
        justifyContent: 'space-between',
      },
    },
  },

  deleteIcon: {
    marginTop: '20px',
  },
  errorText: {
    margin: '2px',
    paddingLeft: '12px',
    fontSize: '12px',
    fontWeight: 400,
    fontFamily: 'Roboto',
    color: theme.palette.framework.system.coralRed,
  },
  divider: {
    marginTop: '12px',
    marginBottom: '12px',
  },
  sectionFields: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
  },
}));

interface IContactTypePhoneNumber {
  options?: IContactTypeOption[];
  controllerName: string;
  phoneNumberItem: IFormClientPhoneNumber;
  totalItems: number;
  index: number;
}

export const ContactTypePhoneNumber = (props: IContactTypePhoneNumber) => {
  const { options = [], controllerName, phoneNumberItem, index } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { register, control, setValue, errors, watch, clearErrors } = useFormContext();
  const [countryCode, setCountryCode] = useState<string>(phoneNumberItem?.countryDialingCode ?? defaultCountry);
  const registerItems = {
    phoneTypeId: phoneNumberItem?.phoneTypeId,
    timeStamp: phoneNumberItem?.timeStamp,
    softDelete: phoneNumberItem?.softDelete,
    countryDialingCode: phoneNumberItem?.countryDialingCode,
    phoneType: getPhoneType(phoneNumberItem?.phoneTypeId, options),
  };
  const phoneNumberError = errors?.phoneNumbers?.[index]?.phoneNumber;
  const { classes } = useInputCountryDropdownStyles();
  const isSoftDeleted = watch(`${controllerName}.softDelete`);
  const contactPreference = watch('phoneNumbers');

  useEffect(() => {
    registerNonFormFieldValuesForPhoneNumber(register, setValue, `${controllerName}`, registerItems);
    dispatch(clientContactActions.setClientContactPhoneNumber(contactPreference));
  }, []);

  const onSoftDelete = () => {
    const index = extractIndexFromControllerName(controllerName);
    setValue(`${controllerName}.softDelete`, true, { shouldDirty: true });

    const updatedContactPref = [...contactPreference];

    if (updatedContactPref[index]) {
      updatedContactPref[index].softDelete = true;
    }

    const filteredContactPref = updatedContactPref.filter(item => !item.softDelete);

    setClientContactDetails(filteredContactPref);
    clearErrors(`${controllerName}.phoneNumber`);
  };

  const undoDelete = () => {
    const index = extractIndexFromControllerName(controllerName);
    const updatedContactPref = [...contactPreference];

    if (updatedContactPref[index]) {
      updatedContactPref[index].softDelete = false;
    }

    setValue(`${controllerName}.softDelete`, false);
    setClientContactDetails(updatedContactPref);
  };

  const setClientContactDetails = (updatedContactPref: typeof contactPreference) => {
    dispatch(clientContactActions.setClientContactPhoneNumber(updatedContactPref));
  };

  const clearError = (value: string) => {
    if (countryCode === CountryCode.US) {
      if (value?.length === 11) {
        clearErrors(`${controllerName}.phoneNumber`);
      }
    } else {
      if (value?.length >= 6) {
        clearErrors(`${controllerName}.phoneNumber`);
      }
    }
  };

  return (
    <>
      {!isSoftDeleted || (
        <div className={classes.alert}>
          <Alert severity="warning">
            {t('facility.clientContacts.warning')}
            <Button variant="text" onClick={undoDelete}>
              {t('facility.clientContacts.undo')}
            </Button>
          </Alert>
        </div>
      )}

      <LayoutGridItem item>
        <LayoutGrid
          container
          classes={{ root: classes.root }}
          direction="row"
          data-testid="container"
          justifyContent="flex-start"
          className={classes.sectionFields}
        >
          <LayoutGridItem item py="12px !important">
            <Controller
              name={`${controllerName}.phoneType`}
              control={control}
              render={({ onChange, value, name }) => (
                <FormInputDropdown
                  inputValue={value}
                  handleChange={event => {
                    const phoneTypeId = options?.find(x => x.Description === event.target.value)?.ID;
                    setValue(`${controllerName}.phoneTypeId`, phoneTypeId);
                    onChange(event);
                    getPhoneType(phoneNumberItem?.phoneTypeId, options);
                    const updatedContactPref = [...contactPreference];
                    if (updatedContactPref[index]) {
                      updatedContactPref[index].phoneTypeId = phoneTypeId;
                      updatedContactPref[index].phoneType = getPhoneType(phoneTypeId, options);
                    }
                    setClientContactDetails(updatedContactPref);
                  }}
                  name={name}
                  label={
                    <Grid container spacing={2}>
                      <Grid item>{t('facility.clientContacts.type')}</Grid>
                    </Grid>
                  }
                  require={false}
                  options={options}
                  readOnly={isSoftDeleted}
                />
              )}
            />
          </LayoutGridItem>
          <LayoutGridItem item py="12px !important">
            <Controller
              name={`${controllerName}.phoneNumber`}
              control={control}
              rules={{
                validate: value => {
                  if (countryCode === CountryCode.US) {
                    return (
                      value?.length === 11 || isSoftDeleted || (t('facility.clientContacts.errorMessage') as string)
                    );
                  } else {
                    return value?.length >= 6 || isSoftDeleted || (t('facility.clientContacts.errorMessage') as string);
                  }
                },
              }}
              render={({ onChange, value }) => (
                <PhoneInput
                  containerClass={classes.arrowClass}
                  value={value}
                  disabled={isSoftDeleted}
                  country={defaultCountry}
                  placeholder={'(...) ...-....'}
                  enableSearch={true}
                  disableSearchIcon={true}
                  onChange={(event, data: any) => {
                    setCountryCode(data?.countryCode);
                    setValue(`${controllerName}.countryDialingCode`, data?.dialCode);
                    onChange(event);
                    setClientContactDetails(contactPreference);
                    clearError(event);
                  }}
                  searchPlaceholder="Search Country"
                  preferredCountries={['us', 'ca', 'mx']}
                  buttonStyle={{
                    border: 0,
                    borderBottom: phoneNumberError
                      ? `1px solid ${theme.palette.framework.system.coralRed}`
                      : `1px solid ${theme.palette.framework.system.blackGray}`,
                    borderRight: '1px solid lightgrey',
                    background: theme.palette.framework.system.whiteSmoke,
                  }}
                  inputStyle={{
                    height: 54,
                    width: '100%',
                    border: 0,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    borderBottom: phoneNumberError
                      ? `1px solid ${theme.palette.framework.system.coralRed}`
                      : `1px solid ${theme.palette.framework.system.blackGray}`,
                    color: theme.palette.framework.system.charcoal,
                    background: theme.palette.framework.system.whiteSmoke,
                  }}
                  searchStyle={{
                    width: 'fit-content',
                  }}
                />
              )}
            />
            {phoneNumberError && <p className={classes.errorText}>{phoneNumberError?.message}</p>}
          </LayoutGridItem>
          <LayoutGridItem item py="12px !important" justifyContent={'flex-end'} display={'flex'}>
            <IconButton
              color="primary"
              disabled={isSoftDeleted}
              size="small"
              className={classes.deleteIcon}
              onClick={onSoftDelete}
            >
              <DeleteOutline />
            </IconButton>
          </LayoutGridItem>
        </LayoutGrid>
        <LayoutGridItem item xs={12} style={{ maxWidth: '95%' }}>
          <CCNotes
            controllerName={`${controllerName}.extensionNotes`}
            disabled={isSoftDeleted}
            maxRows={3}
            notesLabel={t('facility.clientContacts.extensionNotes')}
          />
        </LayoutGridItem>
        <Divider className={classes.divider} />
      </LayoutGridItem>
    </>
  );
};
