import React from 'react';
import { DateAndTextType, Heading4, Heading3, TextType, TextandYear } from '../Config/constants';
import TextAndDatePanel from './TextAndDatePanel';
import { RatingPanel } from './RatingPanel';
import TextAndYears from './TextAndYears';
import { TextRegion } from './TextRegion';
import { ISkillChecklistSection } from '../Config/model';
import { Typography, styled } from 'amn-ui-core';

export const getFieldSections = ({ currentItem, index }: { currentItem: ISkillChecklistSection; index: number }) => {
  if (DateAndTextType.includes(currentItem?.typeId)) {
    return <TextAndDatePanel currentItem={currentItem} index={index} />;
  } else if (TextandYear.includes(currentItem?.typeId)) {
    return <TextAndYears currentItem={currentItem} index={index} />;
  } else if (currentItem?.typeId === TextType) {
    return <TextRegion currentItem={currentItem} index={index} />;
  } else {
    return <RatingPanel currentItem={currentItem} key={index} />;
  }
};

export const groupByTypeAndIndent = data => {
  let result = [];
  let currentGroup = null;
  let subGroup = null;

  data?.forEach(item => {
    if (item?.typeId === Heading4 && !item?.hasAnswer) {
      if (!currentGroup) {
        currentGroup = [item];
      } else if (item?.indent > currentGroup?.[0]?.indent) {
        if (subGroup && item.indent === Heading3) {
          currentGroup.push(subGroup);
          subGroup = [item];
        } else if (item?.indent === Heading3) {
          subGroup = [item];
        } else {
          result.push(currentGroup);
          currentGroup = [item];
          subGroup = null;
        }
      } else {
        if (subGroup) currentGroup.push(subGroup);
        result.push(currentGroup);
        currentGroup = [item];
        subGroup = null;
      }
    } else if (item?.hasAnswer && currentGroup) {
      if (subGroup) {
        subGroup.push(item);
      } else {
        currentGroup.push(item);
      }
    } else if (item?.typeId === Heading4 && item?.indent === Heading3) {
      if (subGroup) {
        currentGroup.push(subGroup);
        subGroup = [item];
      } else {
        subGroup = [item];
      }
    }
  });
  if (subGroup) currentGroup.push(subGroup);
  if (currentGroup) result.push(currentGroup);

  return result;
};

export const handleSubScripts = (inputString: string): JSX.Element => {
  inputString = inputString?.replace(/&amp;/g, '&');
  const regex = /<sub>(.*?)<\/sub>/g;
  const elements: (string | JSX.Element)[] = [];
  let lastIndex = 0;

  inputString?.replace(regex, (match, content, index) => {
    if (index > lastIndex) {
      elements?.push(inputString?.slice(lastIndex, index));
    }

    elements?.push(<StyledSubscript key={index}>{content}</StyledSubscript>);

    lastIndex = index + match?.length;

    return match;
  });

  if (lastIndex < inputString?.length) {
    elements?.push(inputString?.slice(lastIndex));
  }
  return (
    <Typography variant="body2" display={'inline'}>
      {elements}
    </Typography>
  );
};

const StyledSubscript = styled(Typography)`
  vertical-align: sub;
  font-size: smaller;
  display: inline;
  padding-right: 2px;
`;
