import { RequirementType } from 'app/enums/Facility';
import { AndOrOperator, CollectionType } from 'app/enums/Common';
import { AMNDivisionType } from 'app/models/enums/AMNDivisionType';

export const validateDuplicateCertification = (requirementIndex, requirements, t) => {
  const certificationToCheck = requirements[requirementIndex];
  if (
    requirementIndex === 0 ||
    !certificationToCheck?.certificates ||
    certificationToCheck?.certificates?.object?.requirementTypeID === RequirementType.cert
  ) {
    return undefined;
  }
  const previousValidCertifications = requirements?.filter(
    (value, idx) => idx !== requirementIndex && value.certificates !== null,
  );

  if (!certificationToCheck.subCertificate?.length && !certificationToCheck.issuedBy) {
    if (
      previousValidCertifications.find(
        value =>
          !value.subCertificate?.length &&
          !value.issuedBy &&
          value.certificates.object.requirementTypeID === certificationToCheck.certificates.object.requirementTypeID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  } else if (!certificationToCheck.issuedBy) {
    if (
      previousValidCertifications.find(
        value =>
          !value.issuedBy &&
          value.certificates.object.requirementTypeID === certificationToCheck.certificates.object.requirementTypeID &&
          value.subCertificate?.length === certificationToCheck.subCertificate?.length &&
          value.subCertificate?.every(obj1 =>
            certificationToCheck.subCertificate?.some(obj2 => obj1?.subCertificate?.id === obj2?.subCertificate?.id),
          ),
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  } else if (!certificationToCheck.subCertificate?.length) {
    if (
      previousValidCertifications.find(
        value =>
          !value.subCertificate?.length &&
          value.certificates.object.requirementTypeID === certificationToCheck.certificates.object.requirementTypeID &&
          value.issuedBy?.object?.id === certificationToCheck.issuedBy?.object?.id,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  } else {
    if (
      previousValidCertifications.find(
        value =>
          value.certificates.object.requirementTypeID === certificationToCheck.certificates.object.requirementTypeID &&
          (value.issuedBy?.object?.id === certificationToCheck.issuedBy?.object?.id ||
            (value.subCertificate?.length === certificationToCheck.subCertificate?.length &&
              value.subCertificate?.every(obj1 =>
                certificationToCheck.subCertificate?.some(
                  obj2 => obj1?.subCertificate?.id === obj2?.subCertificate?.id,
                ),
              ))),
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  }
};

export const validateDuplicateSkillset = (skillSetCollection, skillSetIndex, operation, enableDuplicateSkillSet, t) => {
  const skillSetToCheck = skillSetCollection?.[skillSetIndex]?.skillSet;
  const isAnd = `${operation}` === AndOrOperator.And;

  if (skillSetIndex === 0 || !skillSetToCheck?.discipline || !skillSetToCheck?.specialty) {
    return undefined;
  }
  const previousValidSkillSets = skillSetCollection.slice(0, skillSetIndex).map(skillSet => skillSet?.skillSet);
  if (!skillSetToCheck?.subSpecialty) {
    if (
      isAnd &&
      enableDuplicateSkillSet &&
      previousValidSkillSets.find(
        value =>
          !value.subSpecialty &&
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else if (
      !enableDuplicateSkillSet &&
      previousValidSkillSets.find(
        value =>
          !value.subSpecialty &&
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  } else {
    if (
      isAnd &&
      enableDuplicateSkillSet &&
      previousValidSkillSets.find(
        value =>
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID &&
          value?.subSpecialty &&
          value?.subSpecialty?.object?.SubSpecialtyID === skillSetToCheck?.subSpecialty?.object?.SubSpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else if (
      !enableDuplicateSkillSet &&
      previousValidSkillSets.find(
        value =>
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID &&
          value?.subSpecialty &&
          value?.subSpecialty?.object?.SubSpecialtyID === skillSetToCheck?.subSpecialty?.object?.SubSpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  }
};
export enum SkillSetField {
  Discipline = 'discipline',
  Specialty = 'specialty',
  SubSpecialty = 'subSpecialty',
}
export const validateOrderDuplicateSkillset = ({
  skillSetCollection,
  skillSetIndex,
  operation,
  enableDuplicateSkillSet,
  divisionId,
  field,
  t,
}) => {
  if (
    divisionId === AMNDivisionType.amnNurse &&
    skillSetCollection.length > 0 &&
    skillSetIndex !== 0 &&
    enableDuplicateSkillSet
  ) {
    return validateUniqueSkillSet({ skillSetCollection, skillSetIndex, field });
  }
  //Only for AMN NUrse Discipline validation
  if (field === SkillSetField.Discipline) {
    return undefined;
  }
  const skillSetToCheck = skillSetCollection?.[skillSetIndex]?.skillSet;
  const isAnd = `${operation}` === AndOrOperator.And;
  const duplicateSkillSetValidation = (isAnd && enableDuplicateSkillSet) || !enableDuplicateSkillSet;

  if (skillSetIndex === 0 || !skillSetToCheck?.discipline || !skillSetToCheck?.specialty) {
    return undefined;
  }
  const previousValidSkillSets = skillSetCollection.slice(0, skillSetIndex).map(skillSet => skillSet?.skillSet);
  if (!skillSetToCheck?.subSpecialty && duplicateSkillSetValidation) {
    if (
      previousValidSkillSets.find(
        value =>
          !value.subSpecialty &&
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  } else if (duplicateSkillSetValidation) {
    if (
      previousValidSkillSets.find(
        value =>
          value?.discipline?.object?.ID === skillSetToCheck?.discipline?.object?.ID &&
          value?.specialty?.object?.SpecialtyID === skillSetToCheck?.specialty?.object?.SpecialtyID &&
          value?.subSpecialty &&
          value?.subSpecialty?.object?.SubSpecialtyID === skillSetToCheck?.subSpecialty?.object?.SubSpecialtyID,
      )
    ) {
      return `${t('facility.units.unitDetails.unitRequirements.duplicated')}`;
    } else {
      return undefined;
    }
  }
};

export const validateUniqueSkillSet = ({ skillSetCollection, skillSetIndex, field }) => {
  const firstSkillSet = skillSetCollection[0]?.skillSet;
  if (
    field === SkillSetField.Discipline &&
    firstSkillSet?.discipline?.object?.ID !== skillSetCollection[skillSetIndex]?.skillSet?.discipline?.object?.ID
  ) {
    return `unique`;
  } else if (
    field === SkillSetField.Specialty &&
    firstSkillSet?.specialty?.object?.SpecialtyID !==
      skillSetCollection[skillSetIndex]?.skillSet?.specialty?.object?.SpecialtyID
  ) {
    return `unique`;
  } else if (
    field === SkillSetField.SubSpecialty &&
    firstSkillSet?.subSpecialty?.object?.SubSpecialtyID !==
      skillSetCollection[skillSetIndex]?.skillSet?.subSpecialty?.object?.SubSpecialtyID
  ) {
    return `unique`;
  }
  return undefined;
};

export const getHelperText = ({ field, error, enableDuplicateSkillSet, value = '', t }) => {
  if (field === SkillSetField.Discipline) {
    if (error?.discipline?.type === 'required') {
      return t('facility.units.unitDetails.unitRequirements.required');
    } else if (error?.discipline?.message === 'unique' && enableDuplicateSkillSet) {
      return t('facility.units.unitDetails.unitRequirements.uniqueDiscipline');
    }
  } else if (field === SkillSetField.Specialty) {
    if (error?.specialty?.type === 'required') {
      return t('facility.units.unitDetails.unitRequirements.required');
    } else if (error?.specialty?.message === 'unique' && enableDuplicateSkillSet) {
      return t('facility.units.unitDetails.unitRequirements.uniqueSpeciality');
    } else if (error?.specialty?.message) {
      return t('facility.units.unitDetails.unitRequirements.duplicatedSpeciality');
    }
    return '';
  } else if (field === SkillSetField.SubSpecialty) {
    if (error?.subSpecialty?.message === 'unique') {
      return t('facility.units.unitDetails.unitRequirements.uniqueSubSpeciality');
    } else if (error?.subSpecialty?.message && enableDuplicateSkillSet) {
      return t('facility.units.unitDetails.unitRequirements.duplicatedSubSpeciality');
    }
    return '';
  }
};

export const validateAMNNurseDuplicateSkillset = ({ groupIndex, skillSets, trigger, field }) => {
  let fieldNamesToValidate = [];
  if (field === SkillSetField.Discipline) {
    fieldNamesToValidate = skillSets
      .slice(1)
      .map((_, index) => `skillSetGroup.groups[${groupIndex}].skillsets[${index + 1}].skillSet.discipline`);
  } else if (field === SkillSetField.Specialty) {
    fieldNamesToValidate = skillSets
      .slice(1)
      .map((_, index) => `skillSetGroup.groups[${groupIndex}].skillsets[${index + 1}].skillSet.specialty`);
  } else if (field === SkillSetField.SubSpecialty) {
    fieldNamesToValidate = skillSets
      .slice(1)
      .map((_, index) => `skillSetGroup.groups[${groupIndex}].skillsets[${index + 1}].skillSet.subSpecialty`);
  }
  if (fieldNamesToValidate.length) trigger(fieldNamesToValidate);
};

export const subCertValidator = (formData, setError, t) => {
  const certificateRequirements = [];
  formData?.skillSetGroup?.groups[0]?.skillsets?.forEach((x, idx) => {
    x?.collections[1]?.requirements?.forEach((y, id) => {
      certificateRequirements.push(y);
      const requirementsCertification =
        formData?.skillSetGroup?.groups?.[0]?.skillsets?.[idx]?.collections?.[CollectionType.CertificationsIndex]
          ?.requirements?.[id];
      if (
        requirementsCertification?.certificates?.object?.requirementTypeID === RequirementType.cert &&
        (requirementsCertification?.subCertificate?.length === 0 || requirementsCertification?.subCertificate === null)
      ) {
        setError(
          `skillSetGroup.groups.[0].skillsets.[${idx}].collections.[${CollectionType.CertificationsIndex}].requirements.[${id}].subCertificate`,
          {
            type: 'required',
            message: t('required'),
          },
        );
      }
    });
  });
  const subCerificateHasError = certificateRequirements?.some(
    data =>
      data?.certificates?.object?.requirementTypeID === RequirementType.cert &&
      (data?.subCertificate === null || data?.subCertificate.length === 0),
  );
  return subCerificateHasError;
};
