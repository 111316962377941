import React, { useState } from 'react';
import { Box, CircularProgress, Grid } from 'amn-ui-core';
import { APIStatus } from 'app/enums/ApiStatus';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from 'app/ComponentLibrary/Filter/AutoCompleteTextBox/AutoCompleteTextBoxStyles';
import { Row } from '../../../PersonalInfoTab/Cards/styles';
import { TypeAhead } from 'app/components/Common/TypeAheads/TypeAhead';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ITypeAheadOption } from '../../../PreferencesTab/CustomComponents/ControlledTypeAhead';
import { RowContainer } from '@AMIEWEB/Candidate/Common/RowContainer';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface IManageGenderProps {
  options: ITypeAheadOption[];
  open: boolean;
  handleClose: () => void;
  handleSave: (event) => void;
  saveStatus: number | string;
  width?: string;
  saveBtnId: string;
  closeBtnId: string;
  selected?: any;
  required?: any;
  clearError?: () => void;
}

/**
 *  Manage race/Ethnicity for profile and summary tab
 * @param props
 */
const ManageGender = ({
  options,
  open,
  handleClose,
  handleSave,
  saveStatus,
  width,
  saveBtnId,
  closeBtnId,
  selected,
  required = false,
  clearError,
}: IManageGenderProps) => {
  const { classes } = useStyles();
  const [selectedValue, setSelectedValue] = useState<{ ID: string; Description: string }>();

  return (
    <Grid container>
      <Grid item style={{ marginTop: 5 }}>
        <>
          <RowContainer>
            <Row>
              <Box style={{ width: width || '220px' }}>
                <TypeAhead
                  variant="standard"
                  options={options || []}
                  popupIcon={<ExpandMoreIcon />}
                  onChange={newValue => {
                    setSelectedValue(newValue);
                    clearError();
                  }}
                  enableCustomScroll={true}
                  removeCloseIcon
                  inputValue={selected || ''}
                  isCustomTheme={true}
                />
              </Box>
            </Row>
            {/* Save status Icons-Progrss,Saved and Failed */}
            <Row style={{ width: 'fit-content' }}>
              {required && (
                <>
                  <ErrorOutlineIcon style={{ width: '15px', height: '15px', color: '#ff0000' }} />
                  <label className={classes.saveFailedText}>{`Required`}</label>
                </>
              )}
              {saveStatus === APIStatus.isLoading && <CircularProgress style={{ width: '12px', height: '12px' }} />}
              {saveStatus === APIStatus.Completed && (
                <>
                  <CheckIcon style={{ width: '15px', height: '15px', color: '#008000' }} />
                  <label className={classes.saveSuccessText}>{`Saved`}</label>
                </>
              )}
              {saveStatus === APIStatus.Error && (
                <>
                  <CloseIcon style={{ width: '15px', height: '15px', color: '#ff0000' }} />
                  <label className={classes.saveFailedText}>{`Failed to update`}</label>
                </>
              )}
            </Row>
          </RowContainer>
        </>
      </Grid>
      <Grid item style={{ marginTop: 5, marginLeft: '0px' }}>
        {/* Render only if clicked inside Text field */}
        {open && (
          <span>
            {/* save Icon */}
            <span
              id={saveBtnId}
              className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainAcceptItem}`}
              onClick={() => handleSave(selectedValue)}
              aria-hidden="true"
            >
              <CheckIcon data-testid="saveIcon" id="check-icon-race-ethnicity-icon" />
            </span>
            {/* close Icon */}
            <span
              id={closeBtnId}
              className={`${classes.inlineBoxEditItemPadding} ${classes.inlineBoxEditMainRejectItem}`}
              onClick={() => handleClose()}
              onKeyDown={() => handleClose()}
              aria-hidden="true"
            >
              <CloseIcon data-testid="closeIcon" id="close-icon-race-ethnicity-icon" />
            </span>
          </span>
        )}
      </Grid>
    </Grid>
  );
};

export default ManageGender;
