import { Button, Grid, Paper, Snackbar, Typography } from 'amn-ui-core';
import { makeStyles } from 'tss-react/mui';
import WarningIcon from 'app/assets/images/Notification/warning.svg';
import React, { createContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useInjectReducer } from 'redux-injectors';
import { ChannelType } from '../Constants';
import { notificationDataKey, notificationDataReducer } from 'store/redux-store/notification/notification.redux';
import { notificationSelection } from 'store/redux-store/notification/notification.selector';
import { GenericDialog } from '@AMIEWEB/Alerts/GenericDialog';

export const NotificationContext = createContext<any | null>(null);

const SMS_MESSAGE = `Are you sure you want to discard this message?`;
const CLOSE_MESSAGE = `Are you sure you want to close?`;

const useStyles = makeStyles()(theme => ({
  paper: {
    background: '#333',
    borderRadius: '5',
    margin: '6% 6% 0 0',
    padding: '3%',
  },
  changeTemplatePaper: {
    background: '#333',
    borderRadius: '5',
    width: '680px',
    margin: '2% 1% 0 0',
    height: '50px',
    padding: '1px',
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '7%',
    gap: '10px',
  },
  actionBtn: {
    color: `${theme.palette.framework.system.white} !important`,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '14px',
    padding: '0px',
    minWidth: theme.spacing(4),
  },
  container: {
    margin: '2%',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    justify: 'center',
    color: '#fff',
    paddingLeft: '1%',
    fontWeight: 'lighter',
  },
  warningContent: {
    justify: 'center',
    font: 'normal normal normal 14px/16px Roboto',
    letterSpacing: '0px',
    color: '#663B01',
    opacity: 1,
    padding: '1%',
  },
  warningContainer: {
    margin: '1%',
  },

  warningPaper: {
    background: '#FEF6EE',
    borderRadius: 0,
    height: '50px',
    padding: '1%',
    border: '1px solid #FFA22E',
  },
  infoIcon: {
    padding: '3px',
  },
  actionButton: {
    fontSize: 12,
    padding: 0,
    minWidth: 30,
  },
  actionNoBtn: {
    color: theme.palette.framework.system.neutralGray,
    paddingRight: '2%',
  },
  actionYesBtn: {
    color: `${theme.palette.components.icons.secondary.backgroundColor} !important`,
  },
  closeContent: {
    background: theme.palette.framework.system.darkGray,
    borderRadius: '5',
    margin: '2% 1% 0 0',
    height: '50px',
    padding: '5px',
    width: '440px',
  },
  modalTitle: {
    paddingTop: '20px',
  },
  modalContent: {
    fontSize: '14px',
    color: theme.palette.framework.system.charcoal,
  },
}));

export function CloseConfirmation(props) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const notificationData = useSelector(notificationSelection);
  const { yesAction, noAction } = props;

  useInjectReducer({ key: notificationDataKey, reducer: notificationDataReducer });

  const SnackbarAction = () => {
    return (
      <div className={classes.action}>
        <Button classes={{ root: classes.actionBtn }} onClick={yesAction}>
          {t('defaultPage.yes')}
        </Button>
        <Button classes={{ root: classes.actionBtn }} onClick={noAction}>
          {t('defaultPage.no')}
        </Button>
      </div>
    );
  };

  return (
    <Paper
      className={classes.paper}
      style={{
        margin: notificationData?.snackbarData?.channel === 'sms' ? '0% 0% 0 0' : '6% 6% 0 0',
        minWidth: notificationData?.snackbarData?.channel === 'sms' ? '420px' : '325px',
        height: notificationData?.snackbarData?.channel === 'sms' ? '60px' : '50px',
      }}
      elevation={15}
    >
      <Grid container spacing={2} className={classes.container}>
        <Typography className={classes.content} variant="subtitle2">
          {notificationData?.snackbarData?.channel === 'sms' ? SMS_MESSAGE : CLOSE_MESSAGE}
        </Typography>
        {SnackbarAction()}
      </Grid>
    </Paper>
  );
}

export function WarningConfirmation() {
  const { classes } = useStyles();
  const globalNotificationData = useSelector(notificationSelection);

  useInjectReducer({ key: notificationDataKey, reducer: notificationDataReducer });
  return (
    <Paper
      className={classes.warningPaper}
      style={{
        minWidth: globalNotificationData?.snackbarData?.channel === 'sms' ? '350px' : '500px',
      }}
    >
      <Grid container spacing={2} className={classes.warningContainer} direction="row">
        <img src={WarningIcon} />
        <Typography className={classes.warningContent} variant="subtitle2">
          {globalNotificationData.snackbarData?.channel === ChannelType.sms
            ? `Please finish the current conversation`
            : `Please finish completing the current log and submit`}
        </Typography>
      </Grid>
    </Paper>
  );
}

export function ChangeConfirmationWithAction(props) {
  const { yesAction, noAction, message } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const SnackbarAction = () => {
    return (
      <div className={classes.action}>
        <Button classes={{ root: classes.actionBtn }} onClick={yesAction}>
          {t('defaultPage.yes')}
        </Button>
        <Button classes={{ root: classes.actionBtn }} onClick={noAction}>
          {t('defaultPage.no')}
        </Button>
      </div>
    );
  };

  return (
    <Paper className={classes.changeTemplatePaper} style={props.style} elevation={15}>
      <Grid container spacing={2} className={classes.container}>
        <Typography className={classes.content} variant="subtitle2" style={{ paddingRight: '1rem' }}>
          {message}
        </Typography>
        {SnackbarAction()}
      </Grid>
    </Paper>
  );
}

export function CustomInfoConfirmation(props) {
  const { classes } = useStyles();
  useEffect(() => {
    setTimeout(() => {
      props.handleClose();
    }, 3000);
  }, [props]);

  return (
    <Paper
      className={classes.paper}
      style={{
        width: '112%',
        color: 'white',
        fontSize: '0.8rem',
      }}
      elevation={15}
    >
      <Grid container spacing={2} className={classes.container}>
        <span className={classes.infoIcon}>
          {' '}
          <img src={WarningIcon} />{' '}
        </span>{' '}
        {props.infoMessage}
      </Grid>
    </Paper>
  );
}

export const SnackbarConfirmation = props => {
  const { classes } = useStyles();
  const { yesAction, noAction, showWarning, snackbarText } = props;
  const { t } = useTranslation();

  const SnackbarAction = () => (
    <div className={classes.action}>
      <Button variant="text" classes={{ root: classes.actionButton, text: classes.actionNoBtn }} onClick={noAction}>
        {t('defaultPage.no')}
      </Button>
      <Button variant="text" classes={{ root: classes.actionButton, text: classes.actionYesBtn }} onClick={yesAction}>
        {t('defaultPage.yes')}
      </Button>
    </div>
  );

  return (
    <Snackbar
      open
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      autoHideDuration={3000}
    >
      <Paper className={classes.closeContent} elevation={15}>
        <Grid container spacing={2} className={classes.container}>
          {showWarning && <img src={WarningIcon} style={{ marginRight: '15px'}}/>}
          <Typography className={classes.content} variant="subtitle2">
            {snackbarText ? snackbarText : t('notification.profileDrawer.closeConfirmation')}
          </Typography>
          <SnackbarAction />
        </Grid>
      </Paper>
    </Snackbar>
  );
};

export const GenericConfirmationDialog = props => {
  const { open, onYesAction, onCancelAction, titleText, contentText } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <GenericDialog
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          event.preventDefault();
          event.stopProgagation();
          return;
        }
      }}
      disableEscapeKeyDown
      maxWidth="sm"
      fullWidth
      dialogTitleProps={{
        className: classes.modalTitle,
        text: titleText,
        closeButton: false,
      }}
      dialogActions={[
        {
          text: 'Cancel',
          variant: 'text',
          color: 'tertiary',
          onClick: () => onCancelAction(),
        },
        {
          text: 'Yes',
          variant: 'contained',
          color: 'primary',
          onClick: () => {
            onYesAction();
          },
        },
      ]}
    >
      <span className={classes.modalContent}>{t(contentText)}</span>
    </GenericDialog>
  );
};
